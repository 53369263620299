import React, { FC } from 'react';
import { useField } from 'react-final-form';

import InputWithDate, { IInputWithDate } from '../../Fields/DatePicker/inputWithDate/inputWithDate';

type IProps = {
  name: string;
  size?: 's' | 'l';
} & IInputWithDate;

const FormDatePicker: FC<IProps> = ({ name, size, ...props }) => {
  const { input } = useField(name);
  return <InputWithDate {...input} {...props} size={size} />;
};

export default FormDatePicker;
