import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { store } from '../../store';
import { convertNotificationToAlert } from './utils';

import apiConstants from '../../constants/apiConstants';
import LocalStorageService from '../../services/LocalStorageService';

import { Dispatch, iRootState } from '../../store';

import Item from './Item';
import useSound from 'use-sound';
import alertSound from '../../assets/sounds/alert.wav';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'fixed',
    top: 78,
    right: 24,
    width: 380,
    zIndex: 10000,
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

const mapState = (state: iRootState) => ({
  alerts: state.alerts.list,
  soundEnabled: state.alerts.soundEnabled,
  isAuth: state.auth.isAuth
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getAlerts: d.alerts.getAlerts
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Alerts: FC<IProps> = ({ getAlerts, alerts, soundEnabled, isAuth }) => {
  const classes = useStyles();

  const { REACT_APP_DEV_URL } = process.env;
  const { Disconnected } = HubConnectionState;
  const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;
  const { accessToken } = LocalStorageService.getToken();

  const [play, { stop }] = useSound(alertSound, {
    loop: 1,
    interrupt: true,
    volume: Number(soundEnabled)
  });

  const listSize = 3;

  const connection = new HubConnectionBuilder()
    .withUrl(`${url}hubs/notifications`, {
      accessTokenFactory: () => {
        return `${accessToken}`;
      }
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => {
        return 5000;
      }
    })
    .build();

  const signalRStart = async () => {
    if (connection && connection.state === Disconnected) {
      await connection.start().then(() => {
        connection.invoke('SubscribePlatform');
      });

      connection.on('OnNotificationMessage', payload => {
        const alert = convertNotificationToAlert(payload);
        // tslint:disable-next-line:no-console
        console.log(payload);

        store.dispatch.alerts.addAlert(alert);
      });
      connection.onreconnected(error => {
        getAlerts();
        connection.invoke('SubscribePlatform');
      });
    }
  };

  const signalRStop = () => {
    if (connection) {
      connection.stop();
    }
  };

  useEffect(() => {
    getAlerts();
    signalRStart();

    return signalRStop;
  }, []);

  return (
    <Grid className={classes.root}>
      {alerts.slice(0, listSize).map(alert => (
        <Item key={alert.uid} alert={alert} play={play} stop={stop} />
      ))}
    </Grid>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Alerts);
