import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TopIconButton from '@top-ui/core/lib/TopIconButton';
import TopIcon from '@top-ui/core/lib/TopIcon';

import { Dispatch } from '../../../store';

const useStyles = makeStyles(({ spacing }) => ({
  back: {
    fontSize: 25
  },
  left: { float: 'left' },
  right: { float: 'right' },
  backButton: {
    margin: spacing(1)
  }
}));

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    collapseDrawer: d.drawer.collapseDrawer,
    getMap: d.infrastructureMap.getMap
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

enum Modes {
  back = 'back',
  expand = 'expand'
}

type IModes = {
  [key in Modes]: {
    handle?: () => void;
    iconName: string;
  };
};

type IProps = {
  toUrl?: string;
  onClose?: () => void;
} & connectedProps;

const DrawerAction: FC<IProps> = ({ toUrl = '', onClose, collapseDrawer, getMap }) => {
  const classes = useStyles();
  const history = useHistory();

  const handle = () => {
    if (toUrl) {
      history.push(toUrl);
    } else {
      history.goBack();
    }

    if (onClose) {
      onClose();
    }
  };

  const modes: IModes = {
    back: {
      handle,
      iconName: 'back'
    },
    expand: {
      handle: () => {
        collapseDrawer();
        getMap();
      },
      iconName: 'clear'
    }
  };

  return (
    <div className={classes.backButton}>
      <div className={classes.left}>
        <TopIconButton size="small" onClick={modes.expand.handle}>
          <TopIcon className={classes.back} name={modes.expand.iconName} />
        </TopIconButton>
      </div>
      <div className={classes.right}>
        <TopIconButton size="small" onClick={modes.back.handle}>
          <TopIcon className={classes.back} name={modes.back.iconName} />
        </TopIconButton>
      </div>
    </div>
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(DrawerAction);
