import React, { FC } from 'react';
import { useField } from 'react-final-form';

import ColorPicker, { IProps as IColorPickerProps } from '../../Fields/ColorPicker';

type IProps = {
  name: string;
} & IColorPickerProps;

const FormColorPicker: FC<IProps> = ({ name, ...props }) => {
  const { input } = useField(name);

  return <ColorPicker {...input} {...props} />;
};

export default FormColorPicker;
