/**
 * Функция, HEX в HSL
 * @param H Строка с кодом цвета
 */

const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin: number = Math.min(r, g, b);
  const cmax: number = Math.max(r, g, b);
  const delta: number = cmax - cmin;
  let h: number;
  let s: number;
  let l: number;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  return { H: h, S: s, L: l };
};

/**
 * Функция, возвращает контрастный цвет относительно входящего цвета
 * @param color Строка с кодом цвета
 */
const getContrastTextColor = (color: string) => {
  const { H, L } = hexToHSL(color);
  let colorText;
  if (L >= 65 || (L >= 45 && H > 40 && H < 90)) {
    colorText = '#000'; // black
  } else {
    colorText = '#FFF'; // white
  }

  return colorText;
};
export { hexToHSL, getContrastTextColor };
