import React, { FC } from 'react';
import { Switch } from 'react-router-dom';

import { guids, paths } from '../../../Root/paths';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components/Drawer/DrawerContent';

import Form from './Form';
import PrivateRoute from '../../../Root/PrivateRoute';

const Plan: FC = () => {
  return (
    <>
      {/*<DrawerAction toUrl={paths.map.office.root} />*/}
      <DrawerContent>
        <Switch>
          {/*<Route path={paths.map.office.plan.create} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.plan.create
            }}
            path={paths.map.office.plan.create}
            component={Form}
          />
          {/*<Route path={paths.map.office.plan.edit()} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.plan.edit()
            }}
            path={paths.map.office.plan.edit()}
            component={Form}
          />
        </Switch>
      </DrawerContent>
    </>
  );
};

export default Plan;
