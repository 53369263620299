import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '../../Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(0.5)
    }
  },
  separator: {
    padding: spacing(0, 1)
  }
}));

interface IProps {
  label: string;
  separator: string;
  left: ReactNode;
  right: ReactNode;
}

const FormInfoAllowedTime: FC<IProps> = ({ label, separator, left, right }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Typography variant="p2Regular" color="text-secondary" nowrap>
        {label}
      </Typography>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs>
          {left}
        </Grid>
        <Grid item>
          <Typography variant="p2Bold" color="background-stroke" className={classes.separator}>
            {separator}
          </Typography>
        </Grid>
        <Grid item xs>
          {right}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormInfoAllowedTime;
