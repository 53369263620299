import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../store';
import webSsoConstants from '../../constants/webSsoConstants';
import LocalStorageService from '../../services/LocalStorageService';
import { useAsync } from '../../hooks';

import Spinner from '../../components_/Spinner';
import ErrorPage from '../../components/ErrorPage';
import ErrorForbiddenPage from '../../components/ErrorForbiddenPage';
import Login from '../Login';

const mapState = (state: iRootState) => ({
  isAuth: state.auth.isAuth,
  webSSO: state.auth.webSSO
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    initialLogin: d.auth.initialLogin,
    onLogin: d.auth.onLogin,
    onLogout: d.auth.onLogout
  };
};

type IProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Authorization: FC<IProps> = ({
  initialLogin,
  onLogin,
  onLogout,
  children,
  isAuth,
  webSSO
}) => {
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);

  const [runInitialLogin, isLoadingInitialLogin] = useAsync(initialLogin);
  const [runOnLogin, isLoadingOnLogin] = useAsync(onLogin);

  const code = new URLSearchParams(window.location.search).get('code');
  const { accessToken, refreshToken } = LocalStorageService.getToken();

  const isDevelopment = process.env.NODE_ENV === 'development';
  const isLoading = isLoadingInitialLogin || isLoadingOnLogin;

  // tslint:disable-next-line: no-shadowed-variable
  const handleInitialLogin = async (code: string) => {
    const error = await runInitialLogin(code);

    if (error) {
      if (error.response.status === 401 && error.response.data) {
        setIsForbidden(true);

        return;
      }

      setIsError(true);
    }
  };

  const handleOnLogin = async () => {
    const error = await runOnLogin();

    if (error) {
      if (error.response.status === 401 && error.response.data) {
        setIsForbidden(true);

        return;
      }

      setIsError(true);
    }
  };

  useEffect(() => {
    if (code) {
      handleInitialLogin(code);
    }

    if (accessToken) {
      handleOnLogin();
    }
  }, []);

  const handleErrorActionClick = () => {
    document.location.reload();
  };

  const handleForbiddenActionClick = () => {
    onLogout();
  };

  if (webSSO && !code && !accessToken && !refreshToken && !isError && !isDevelopment) {
    window.location.replace(webSsoConstants.loginUrl);
  }
  const content = () => {
    if (isError) {
      return (
        <ErrorPage
          text="Что-то пошло не так…"
          description="Не переживайте, мы знаем об этом и скоро всё поправим!"
          action="Попробовать ещё раз"
          actionOnClick={handleErrorActionClick}
        />
      );
    }
    if (isForbidden) {
      return <ErrorForbiddenPage onLogout={handleForbiddenActionClick} />;
    }
    if (isAuth) {
      return children;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return <Login />;
  };
  return <>{content()}</>;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Authorization);
