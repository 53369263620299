import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import reactStringReplace from 'react-string-replace';

import { INotificationVariable } from '../../../models/types';
import { getVariableTemplate } from './utils';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 500
  }
}));

interface IProps {
  text: string;
  notificationVariables: INotificationVariable[];
}

const StrTemplate: FC<IProps> = ({ text, notificationVariables }) => {
  const classes = useStyles();

  const variablesMap = notificationVariables.map(
    item => `${getVariableTemplate(item.placeholder)}|${getVariableTemplate(item.descr)}`
  );

  const variables = variablesMap.join('|');

  const regExp = new RegExp(`(${variables})`);

  const renderVariable = (match: string) => {
    const defaultResult = { descr: '' };
    const { descr: result } =
      notificationVariables.find(item =>
        Object.values(item).find(value => getVariableTemplate(value) === match)
      ) || defaultResult;

    return getVariableTemplate(result);
  };

  return (
    <>
      {reactStringReplace(text, regExp, (match, index) => (
        <span key={index} className={classes.root}>
          {renderVariable(match)}
        </span>
      ))}
    </>
  );
};

export default StrTemplate;
