import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../../routes/Root/paths';
import { useAsync } from '../../../../hooks';

import Content from '../../../../components_/Content';
import Spinner from '../../../../components_/Spinner';
import EmptyState from '../../../../components_/EmptyState';

import Form from './Form';

interface IParams {
  id: string;
}

const mapState = (state: iRootState) => ({
  data: state.settingNotifications.data,
  item: state.settingNotifications.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.settingNotifications.getData,
    getDataUsers: d.settingNotifications.getDataUsers,
    getItem: d.settingNotifications.getItem,
    updateOrCreate: d.settingNotifications.updateOrCreate
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({ data, item, getData, getDataUsers, getItem, updateOrCreate }) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);
  const [runUpdateOrCreate, isLoadingUpdateOrCreate, isUpdateOrCreateLoadingError] = useAsync(
    updateOrCreate
  );
  const { id } = useParams<IParams>();
  const history = useHistory();
  const isCreate = Boolean(useRouteMatch(paths.settings.notifications.create));
  const isEdit = Boolean(useRouteMatch(paths.settings.notifications.edit()));

  useEffect(() => {
    runGetData();

    if (isEdit) {
      runGetItem(id);
    }
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const isLoading = isLoadingGetData || isLoadingGetItem;
  const isLoadingError = isGetDataLoadingError || isGetItemLoadingError;

  const title = isCreate ? 'Добавить шаблон' : isEdit && item ? item.name : '';

  return (
    <Content
      breadcrumb={[
        { name: 'Настройки', url: paths.settings.protectionTypes.root },
        { name: title }
      ]}
      title={title}
    >
      {!isLoading && !isLoadingError && (
        <>
          <Form
            data={data}
            item={item}
            isCreate={isCreate}
            isEdit={isEdit}
            getDataUsers={getDataUsers}
            onSubmitAction={runUpdateOrCreate}
            onCancel={handleCancel}
          />
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
