import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';
import { useAsync, useParams } from '../../../hooks';
import { IGetStayInAreaParams } from '../../../services/ReportService';

import Content from '../../../components_/Content';
import Spinner from '../../../components_/Spinner';
import EmptyState from '../../../components_/EmptyState';
import Pagination from '../../../components_/Pagination';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetStayInAreaParams, 'pageIndex'> = {
  mapId: '',
  areaId: '',
  listOfEmployees: [],
  from: new Date().toDateString(),
  to: new Date().toDateString()
};

const mapState = (state: iRootState) => ({
  data: state.reportStayInAreas.data,
  list: state.reportStayInAreas.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.reportStayInAreas.getData,
    getDataAreas: d.reportStayInAreas.getDataAreas,
    resetDataAreas: d.reportStayInAreas.resetDataAreas,
    getList: d.reportStayInAreas.getList,
    resetList: d.reportStayInAreas.resetList,
    exportList: d.reportStayInAreas.exportList,
    reset: d.reportStayInAreas.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const StayInAreas: FC<IProps> = ({
  data,
  list: { data: list, pagesCount },
  getData,
  getDataAreas,
  resetDataAreas,
  getList,
  resetList,
  exportList,
  reset
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetDataAreas, isLoadingGetDataAreas, isGetDataAreasLoadingError] = useAsync(
    getDataAreas
  );
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const [runExportList, isLoadingExportList, isExportListLoadingError] = useAsync(exportList);
  const {
    pageIndex,
    filter,
    handlePageChange,
    handleFilterChange,
    handleFilterApply,
    handleFilterReset
  } = useParams(initialFilter, runGetList, false);

  useEffect(() => {
    runGetData();

    return () => {
      reset();
    };
  }, []);

  const isLoading = isLoadingGetData || isLoadingGetList;
  const isAllDataLoadingError = isGetDataLoadingError || isGetDataAreasLoadingError;
  const isLoadingError = isAllDataLoadingError || isGetListLoadingError || isExportListLoadingError;

  const handleExportList = () => {
    runExportList(filter);
  };

  const title = 'Пребывание в зонах';

  return (
    <Content
      breadcrumb={[{ name: 'Отчёты', url: paths.reports.root }, { name: title }]}
      title={title}
    >
      {!isLoadingGetData && !isAllDataLoadingError && (
        <>
          <Filter
            getDataAreas={runGetDataAreas}
            resetDataAreas={resetDataAreas}
            data={data}
            filter={filter}
            isLoadingGetList={isLoadingGetList}
            isLoadingExportList={isLoadingExportList}
            list={list}
            onChange={handleFilterChange}
            onClickApply={handleFilterApply}
            onClickExport={handleExportList}
            onClickReset={() => {
              handleFilterReset(true);
              resetList();
            }}
          />
          {!isLoading && !isLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyReport" />}
                  {Boolean(list.length) && (
                    <>
                      <List list={list} />
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(StayInAreas);
