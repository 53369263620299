import React, { FC } from 'react';

import { DropdownSizes } from '@mts-ds/components/dist/types/components/dropdown/enums/sizes.enum';

import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormDoubleField from '../../../../../../components_/Form/FormDoubleField';
import FormDropdown from '../../../../../../components_/Form/FormDropdown';
import FormColorPicker from '../../../../../../components_/Form/FormColorPicker';

import { required } from '../../../../../../utils/validation';
import { hoursStep, minutesStep } from '../../../../../../constants/timeSteps';

import { IProps as IFormProps } from './Form';

interface IProps {
  data: IFormProps['data'];
}

const FormSettings: FC<IProps> = ({ data }) => {
  const { roles } = data;

  const roleOptions = roles.map(({ name, friendlyName }) => ({ id: name, name: friendlyName }));

  return (
    <FormGroup title="Настройки" size="s">
      <FormRow>
        <FormCol>
          <FormDropdown
            name="role"
            label="Роль"
            placeholder="Выберите роль"
            size={'s' as DropdownSizes}
            options={roleOptions}
            useFieldConfig={{ validate: required }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDoubleField
            label="Интервал обездвиженности (необязательно)"
            separator="-"
            left={
              <FormDropdown
                name="immobilizedHours"
                placeholder="Часы"
                size={'s' as DropdownSizes}
                options={hoursStep}
              />
            }
            right={
              <FormDropdown
                name="immobilizedMinutes"
                placeholder="Минуты"
                size={'s' as DropdownSizes}
                options={minutesStep}
              />
            }
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDropdown
            name="workshiftDurationHours"
            label="Длительность смены (необязательно)"
            placeholder="Выберите длительность смены"
            size={'s' as DropdownSizes}
            options={hoursStep}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDoubleField
            label="Длительность перерыва (необязательно)"
            separator="-"
            left={
              <FormDropdown
                name="breakHours"
                placeholder="Часы"
                size={'s' as DropdownSizes}
                options={hoursStep}
              />
            }
            right={
              <FormDropdown
                name="breakMinutes"
                placeholder="Минуты"
                size={'s' as DropdownSizes}
                options={minutesStep}
              />
            }
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormColorPicker name="mapColor" label="Цвет сотрудника на карте" />
        </FormCol>
      </FormRow>
    </FormGroup>
  );
};

export default FormSettings;
