import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { getChargeColor } from './utils';

import Icon from '../..';

const useStyles = makeStyles(({ spacing }) => ({
  root: ({ charge }: IStyleProps) => ({
    color: getChargeColor(charge)
  })
}));

interface IStyleProps {
  charge: number;
}

interface IProps extends IStyleProps {
  className?: string;
}

const BatteryIcon: FC<IProps> = ({ charge, className }) => {
  const classes = useStyles({ charge });

  return <Icon name="battery" size="s" className={clsx(classes.root, className)} />;
};

export default BatteryIcon;
