/**
 * Модель для работы со сводными данными
 */

import { createModel, ModelConfig } from '@rematch/core';

import UsersService from '../services/UsersService';

interface IState {
  usersTotal: number;
}

const initialState: IState = {
  usersTotal: 0
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUsersTotal(state: IState, payload: number): IState {
      return {
        ...state,
        usersTotal: payload
      };
    }
  },
  effects: {
    async getUsersTotal() {
      const {
        data: { total }
      } = await UsersService.getAll({ pageSize: 0 });

      this.setUsersTotal(total);
    }
  }
};

export const infrastructureMain: typeof model = createModel(model);
