import React, { ChangeEvent, FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, getColor }) => ({
  input: ({ size }: IStyleProps) => ({
    height: sizes[size].height,
    width: '100%',
    border: `2px solid ${getColor('constant-greyscale-200')}`,
    borderRadius: 7,
    fontFamily: 'MTS Sans, sans-serif',
    fontSize: sizes[size].fontSize,
    fontWeight: 400,
    padding: spacing(1),
    '&:focus': {
      outline: 'none',
      borderColor: getColor('constant-blueberry-dark')
    },
    '&::placeholder': {
      color: getColor('constant-greyscale-400')
    },
    '&[type="date"]::-webkit-calendar-picker-indicator': {
      display: 'none'
    }
  }),
  label: {
    fontSize: 14,
    marginBottom: 4,
    color: getColor('constant-greyscale-500')
  }
}));

type ISize = 's' | 'm';
interface ISizeParams {
  height: number;
  fontSize: number;
}

type ISizes = Record<ISize, ISizeParams>;

const sizes: ISizes = {
  s: {
    height: 32,
    fontSize: 14
  },
  m: {
    height: 44,
    fontSize: 17
  }
};

interface IStyleProps {
  size: ISize;
}
type IDelegate = (event: ChangeEvent | any) => void;

export type IProps = {
  label?: string;
  placeholder?: string;
  size?: ISize;
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size'
>;

const FileInput: FC<IProps> = ({ label, placeholder, size = 'm', onChange, onLoad, ...props }) => {
  const classes = useStyles({ size });
  const [img, setImg] = useState<string | null>(null);

  const handleChange = (delegate: IDelegate | undefined) => (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (onLoad && file) {
      onLoad(e);
    }

    if (delegate) {
      delegate(file);
    }
    if (file) {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target) {
          setImg(event.target.result as string);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <label htmlFor="file" className={classes.label}>
        {label}
      </label>
      <input
        id="file"
        type="file"
        placeholder={placeholder}
        className={classes.input}
        onChange={handleChange(onChange)}
        {...props}
      />
    </div>
  );
};

export default FileInput;

// --color-black: #000;
// --color-white: #fff;
// --color-deep-dark: #202d3d;
// --color-green: #03a17b;
// --color-primary: #e30611;
// --color-primary-hover: #c8050f;
// --color-primary-active: #ad050d;
// --color-midnight-blue: #202d3d;
// --color-primary-alternate: #003057;
// --color-primary-alternate-hover: #202d3d;
// --color-primary-alternate-active: #001424;
// --color-text-primary: #001424;
// --color-text-secondary: #6e7782;
// --color-text-tertiary: #9198a0;
// --color-text-disabled: #bbc1c7;
// --color-gray: #e2e5eb;
// --color-background-light: #f2f3f7;
// --color-error: #f95721;
// --color-link: #007cff;
// --color-link-hover: #006de0;
// --color-link-active: #005ec2;
// --space-4xs: 4px;
// --space-3xs: 8px;
// --space-2xs: 12px;
// --space-xs: 16px;
// --space-s: 24px;
// --space-m: 32px;
// --space-l: 40px;
// --space-xl: 52px;
// --space-2xl: 64px;
// --space-3xl: 80px;
// --space-4xl: 96px;
// --shadow-xs: 0 0 8px rgba(20,20,20,0.08),0 2px 4px rgba(0,0,0,0.1);
// --shadow-s: 0 0 8px rgba(0,0,0,0.08),0 6px 12px rgba(0,0,0,0.08);
// --shadow-m: 0 0 8px rgba(0,0,0,0.08),0 9px 10px rgba(0,0,0,0.08);
// --shadow-l: 0 0 8px rgba(0,0,0,0.12),0 20px 30px rgba(0,0,0,0.1);
// --shadow-xl: 0 0 8px rgba(0,0,0,0.08),0 40px 44px rgba(0,0,0,0.1);
// -webkit-box-direction: normal;
// font-family: MTS Sans;
// grid-column: span 12/span 12;
// margin-bottom: 10px;

// <div className="mts-grid__item mts-grid__item_span-12 vjsf-grid-item ">
//   <div className="mts-input mts-input_m is-required">
//     <div className="vjsf-download-link-list"></div>
//     <p className="mts-input__label vjsf-label"><span
//         className="vjsf-label-text">АКТ приема-передачи Основной номер</span><span
//         className="vjsf-tooltip vjsf-label-icon"><mts-tooltip><span className="vjsf-question-icon"><svg width="16px"
//                                                                                                         height="16px"
//                                                                                                         viewBox="0 0 16 16"
//                                                                                                         className="vjsf-icon-wrapper"><path
//         fill="currentColor"
//         d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z"></path><path
//         fill="white" d="M7.2 11.5L7 13H9L8.8 11.5H7.2Z"></path><path fill="white"
//                                                                      d="M7.99999 4.47862C8.82841 4.47862 9.49998 5.11821 9.49998 5.90719C9.50262 6.28628 9.34281 6.65 9.05748 6.91433L8.12749 7.81433C7.56607 8.35128 7.25059 9.07801 7.24999 9.83576V10.1929H8.74998C8.69169 9.42505 9.01687 8.67596 9.62748 8.17148L10.3025 7.51433C10.751 7.08885 11.0022 6.51013 11 5.90719C11 4.32924 9.65683 3.05005 7.99999 3.05005C6.34314 3.05005 5 4.32924 5 5.90719H6.49999C6.49999 5.11821 7.17156 4.47862 7.99999 4.47862Z"></path></svg></span></mts-tooltip></span>
//     </p>
//     <label className="mts-input mts-input_m"
//            style="opacity: 0; position: absolute; width: 0px; height: 0px; margin: 0px; padding: 0px; min-height: 0px; min-width: 0px; overflow: hidden;"><!---->
//       <div className="mts-input__inner-box"><!----><input type="text" placeholder="" autoComplete="nope" role="combobox"
//                                                           aria-autocomplete="list" aria-haspopup="false"
//                                                           autoCapitalize="off" autoCorrect="off"
//                                                           className="mts-input__inner">
//         <div className="mts-input__suffix-box"></div></div>
//       <!----></label>
//     <div
//         style="background: rgb(255, 255, 255); border: 2px dashed rgb(226, 229, 235); border-radius: 4px; min-height: 80px; display: flex; align-items: center; justify-content: center;">
//       <div style="text-align: center;">
//         <div className="mts-typography__body-m" style="margin-bottom: 2px;"><span
//             style="color: rgb(0, 124, 255); cursor: pointer;">Выберите файлы</span> или перетащите их сюда
//         </div>
//         <div className="mts-typography__body-s" style="margin-bottom: 2px;">Максимальный размер файла: 20Мб. Не более 3
//           файлов
//         </div>
//       </div>
//     </div>
//     <input type="file" multiple="multiple"
//            style="opacity: 0; position: absolute; width: 0px; height: 0px; margin: 0px;"></div>
// </div>
