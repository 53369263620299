interface IInitial {
  [key: string]: any;
}

/**
 * Функция возвращающая начальные значения фильтра
 * @param object Initial
 */
const getInitialFilter = <T>(initial: IInitial): T => {
  const result = { ...initial };

  const queryParams = new URLSearchParams(window.location.search);

  Object.entries(result).forEach(([key, value]) => {
    let arrayParam;
    const param = queryParams.get(key);

    if (Array.isArray(value) && param) {
      arrayParam = param.split(',');
    }

    result[key] = arrayParam || param || value;
  });

  return result as T;
};

export default getInitialFilter;
