import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { useAsync, useRequestHandler } from '../../../../hooks';

import Spinner from '../../../../components_/Spinner';
import EmptyState from '../../../../components_/EmptyState';

import Form from './Form';
import { useMtsModal } from '@mts-ds/components-react';
import Modal from '../../../../components_/Modal';
import { IDepartament } from '../../../../models/types';
import FormTree from './FromTree';
import Grid from '@material-ui/core/Grid';

const mapState = (state: iRootState) => ({
  data: state.staffDepartaments.data,

  list: state.staffDepartaments.list,
  item: state.staffDepartaments.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getList: d.staffDepartaments.getList,
    getData: d.staffDepartaments.getData,
    getDataUsers: d.staffDepartaments.getDataUsers,
    getItem: d.staffDepartaments.getItem,
    updateOrCreate: d.staffDepartaments.updateOrCreate,
    remove: d.staffDepartaments.remove,
    reset: d.staffDepartaments.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Index: FC<IProps> = ({
  data,
  getData,
  updateOrCreate,
  getList,
  item,
  getItem,
  getDataUsers,
  remove,
  list,
  reset
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetList, isLoadingGetList, isGetListError] = useAsync(getList);
  const [runGetDepartment, isLoadingGetDepartment, isGetDepartmentError] = useAsync(getItem);
  const [runGetDataUsers, isLoadingGetDataUsers, isGetDataUsersLoadingError] = useAsync(
    getDataUsers,
    true
  );
  const history = useHistory();
  const query = useQuery();
  const [active, setActive] = useState<IDepartament | null>(null);

  const requestHandler = useRequestHandler();

  const [runUpdateOrCreate, isLoadingUpdateOrCreate, isUpdateOrCreateLoadingError] = useAsync(
    updateOrCreate
  );

  useEffect(() => {
    runGetData();
    runGetList();
    return () => {
      reset();
    };
  }, []);

  const treeItem = query.get('root');

  useEffect(() => {
    if (treeItem) {
      runGetDepartment(treeItem);
    }
  }, [query.get('root')]);

  const handleClickUpdateOnCrate = async (values: IDepartament) => {
    const error = await runUpdateOrCreate({ ...item, ...values });

    requestHandler({
      error,
      entity: 'departament',
      onSuccess: () => {
        runGetList();
        if (treeItem) {
          runGetDepartment(treeItem);
        }
      },
      isCreate: !values.id,
      isEdit: !!values.id
    });
  };

  const handleClickDelete = (departament: IDepartament) => () => {
    setActive(departament);
    openDeleteModal();
  };

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удаление подразделения',
    text: 'Вы действительно хотите удалить подразделение?',
    buttonTextConfirm: 'Удалить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await remove(active.id);
        requestHandler({
          error,
          entity: 'departament',
          isDelete: true,
          onSuccess: () => {
            history.push('/staff/departaments');
            runGetList();
            reset();
          }
        });
      }
    })
  });

  const isLoading = isLoadingGetData || isLoadingGetList || isLoadingUpdateOrCreate;
  const isLoadingError = isGetDataLoadingError || isGetListError || isGetDepartmentError;

  return (
    <>
      {!isLoading && !isLoadingError && (
        <Grid container wrap="nowrap" spacing={5}>
          <Grid item xs="auto">
            <FormTree
              secTreeHierarchy={list}
              defaultSelected={treeItem}
              onClickUpdateOnCrate={handleClickUpdateOnCrate}
              onClickDelete={handleClickDelete}
            />
          </Grid>
          <Grid item xs={7}>
            {item ? (
              <Form
                data={data}
                item={item}
                getDataUsers={getDataUsers}
                onSubmitAction={handleClickUpdateOnCrate}
              />
            ) : (
              <EmptyState variant="clickTree" />
            )}
          </Grid>
        </Grid>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
