import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Input, { IProps as IInputProps } from '../../../components_/Fields/Input';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(2)
  }
}));

interface IProps {
  placeholder: IInputProps['placeholder'];
  value: IInputProps['value'];
  onMtsChange: IInputProps['onMtsChange'];
}

const DrawerContentListSearch: FC<IProps> = props => {
  const classes = useStyles();

  return (
    <Grid>
      <Input {...props} id="inputSearch" type="search" clear className={classes.root} />
    </Grid>
  );
};

export default DrawerContentListSearch;
