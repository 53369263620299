import { getTimeFromMinutes } from '../../../utils/convertTime';

const getFormatTimeFromMinutes = (minutes: number) => {
  const time = getTimeFromMinutes(minutes);
  time.push(0);

  return time
    .map(item => {
      return item.toString().length < 2 ? `0${item}` : item;
    })
    .join(':');
};

export { getFormatTimeFromMinutes };
