/**
 * Модель для работы с реестром СИЗ
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IProtectionType, IProtectionTypeType, IId } from './types';

import ProtectionsService, {
  IUpdateOrCreateType as IUpdateOrCreate
} from '../services/ProtectionsService';

interface IData {
  types: IProtectionTypeType[];
}

interface IList {
  data: IProtectionType[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  item: IUpdateOrCreate | null;
}

const initialState: IState = {
  data: {
    types: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IUpdateOrCreate): IState {
      return { ...state, item: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [{ data: types }] = await Promise.all([ProtectionsService.getTypeTypeAll()]);

        this.setData({ types });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await ProtectionsService.getTypeAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async getItem(id: IId) {
      try {
        const { data } = await ProtectionsService.getType(id);

        this.setItem(data);
      } catch (error) {
        return error;
      }
    },
    async updateOrCreate(body: IUpdateOrCreate) {
      try {
        await ProtectionsService.updateOrCreateType(body);
      } catch (error) {
        return error;
      }
    },
    async remove(id: IId) {
      try {
        await ProtectionsService.deleteType(id);
      } catch (error) {
        return error;
      }
    }
  }
};

// tslint:disable-next-line: variable-name
export const settingProtectionTypes: typeof model = createModel(model);
