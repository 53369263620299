import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';

import Icon, { IProps as IIconProps } from '../../../components_/Icon';

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(0.5)
  }
}));

export type IProps = {
  icon?: IIconProps['name'];
} & typeof MtsButton.defaultProps;

const Button: FC<IProps> = ({ children, icon, ...props }) => {
  const classes = useStyles();

  return (
    <MtsButton {...props}>
      <Grid container justify="center" alignItems="center" wrap="nowrap">
        {icon && <Icon name={icon} size="s" className={classes.icon} />}
        {children}
      </Grid>
    </MtsButton>
  );
};

export default Button;
