import React, { FC } from 'react';

import { ITargetSimple } from '../../../models/types';

import Icon, { IProps as IPropsIcon } from '../../Icon';

import Tooltip from '../../Tooltip';

interface ITargetSimpleOptions {
  icon: IPropsIcon['name'];
  tooltip: string;
}

type ITargetSimples = Record<ITargetSimple, ITargetSimpleOptions>;

const targetSimples: ITargetSimples = {
  Web: {
    icon: 'desktop',
    tooltip: 'Веб-приложение'
  },
  Mobile: {
    icon: 'smartphone',
    tooltip: 'Мобильное приложение'
  },
  Other: {
    icon: 'desktop',
    tooltip: 'Веб-приложение'
  }
};

interface IProps {
  targetSimple: ITargetSimple;
  size?: IPropsIcon['size'];
}

const NotificationTarget: FC<IProps> = ({ targetSimple, size = 'm' }) => {
  const { icon, tooltip } = targetSimples[targetSimple];

  return (
    <Tooltip label={tooltip}>
      <Icon name={icon} size={size} />
    </Tooltip>
  );
};

export default NotificationTarget;
