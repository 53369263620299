import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import { Map as LeafletMap, ImageOverlay } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';

// import getBlobImage from '../../../utils/getBlobImage';
import { IHeatMap } from '../../../models/reportHeatMap';
import { IReportHeatMapPoint } from '../../../models/types';
// import { mapColor } from '../../../theme';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    width: '100%',
    height: 700,
    zIndex: 0
  }
}));

interface IProps {
  heatMap: IHeatMap;
}

const Map: FC<IProps> = ({ heatMap }) => {
  const classes = useStyles();

  const { map, planImg, data } = heatMap;
  const { preview, previewFormat, bbox } = map;

  // const imageOverlayUrl = getBlobImage(preview, previewFormat);

  const { center, bounds } = createCoord(bbox);

  return (
    <LeafletMap center={center} crs={L.CRS.Simple} zoom={4} className={classes.root}>
      <ImageOverlay url={planImg} bounds={bounds} zIndex={0} />
      <HeatmapLayer
        points={data}
        latitudeExtractor={(point: IReportHeatMapPoint) => point.y}
        longitudeExtractor={(point: IReportHeatMapPoint) => point.x}
        intensityExtractor={(point: IReportHeatMapPoint) => point.weight}
        radius={20}
        max={0.5}
        // gradient={{
        //   0.4: mapColor.normalBlackberry,
        //   0.6: mapColor.darkestPlum,
        //   0.7: mapColor.darkestBanana,
        //   0.8: mapColor.darkOrange,
        //   1.0: mapColor.mtsRed
        // }}
      />
    </LeafletMap>
  );
};

const createCoord = ([x, y, z, w]: [number, number, number, number]) => {
  const center = [w + y, z + x].map(coord => coord / 2) as [number, number];
  const bounds = [[w, x], [y, z]] as [[number, number], [number, number]];

  return { center, bounds };
};

export default Map;
