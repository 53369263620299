import React, { FC } from 'react';
import { MtsModalContent, MtsModalHeader, MtsModalText } from '@mts-ds/components-react';
import { ModalSizes, ModalTextAlign } from '@mts-ds/components/dist/types/components/modal/enums/';

type IProps = {
  header: string;
  text: string;
} & typeof MtsModalContent.defaultProps;

const UnbindModal: FC<IProps> = ({ header, text, ...props }) => (
  <MtsModalContent
    id="modalContent"
    size={'s' as ModalSizes}
    textAlign={'center' as ModalTextAlign}
    buttonTextCancel="Отмена"
    {...props}
  >
    <MtsModalHeader id="modalHeader">{header}</MtsModalHeader>
    <MtsModalText id="modalText">{text}</MtsModalText>
  </MtsModalContent>
);

export default UnbindModal;
