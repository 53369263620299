export type IEntity =
  | 'user'
  | 'departament'
  | 'notification'
  | 'protectionType'
  | 'role'
  | 'right'
  | 'area'
  | 'protection'
  | 'device'
  | 'beacon'
  | 'camera'
  | 'office'
  | 'login'
  | 'plan';

export type IAction = 'create' | 'edit' | 'copy' | 'delete' | 'unbind';
export type IResult = 'success' | 'error';

const toastConstants: Record<IEntity, Record<IAction, Record<IResult, string>>> = {
  user: {
    create: {
      success: 'Сотрудник добавлен',
      error: 'Ошибка добавления сотрудника'
    },
    edit: {
      success: 'Сотрудник изменён',
      error: 'Ошибка изменения сотрудника'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Сотрудник удалён',
      error: 'Ошибка удаления сотрудника'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  departament: {
    create: {
      success: 'Подразделение добавлено',
      error: 'Ошибка добавления подразделения'
    },
    edit: {
      success: 'Подразделение изменено',
      error: 'Ошибка изменения подразделения'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Подразделение удалёно',
      error: 'Ошибка удаления подразделения'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  notification: {
    create: {
      success: 'Шаблон уведомления добавлен',
      error: 'Ошибка добавления шаблона уведомления'
    },
    edit: {
      success: 'Шаблон уведомления изменён',
      error: 'Ошибка изменения шаблона уведомления'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Шаблон уведомления удалён',
      error: 'Ошибка удаления шаблона уведомления'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  protectionType: {
    create: {
      success: 'Тип экипировки добавлен',
      error: 'Ошибка добавления типа экипировки'
    },
    edit: {
      success: 'Тип экипировки изменён',
      error: 'Ошибка изменения типа экипировки'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Тип экипировки удалён',
      error: 'Ошибка удаления типа экипировки'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  role: {
    create: {
      success: 'Роль добавлена',
      error: 'Ошибка добавления роли'
    },
    edit: {
      success: 'Роль изменена',
      error: 'Ошибка изменения роли'
    },
    copy: {
      success: 'Роль скопирована',
      error: 'Ошибка копирования роли'
    },
    delete: {
      success: 'Роль удалена',
      error: 'Ошибка удаления роли'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  right: {
    create: {
      success: 'Права сохранены',
      error: 'Ошибка сохранения прав'
    },
    edit: {
      success: '',
      error: ''
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: '',
      error: ''
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  area: {
    create: {
      success: 'Зона добавлена',
      error: 'Ошибка добавления зоны'
    },
    edit: {
      success: 'Зона изменена',
      error: 'Ошибка изменения зоны'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Зона удалена',
      error: 'Ошибка удаления зоны'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  protection: {
    create: {
      success: 'СИЗ добавлен',
      error: 'Ошибка добавления СИЗ'
    },
    edit: {
      success: 'СИЗ изменён',
      error: 'Ошибка изменения СИЗ'
    },
    copy: {
      success: 'СИЗ скопирован',
      error: 'Ошибка копирования СИЗ'
    },
    delete: {
      success: 'СИЗ удалён',
      error: 'Ошибка удаления СИЗ'
    },
    unbind: {
      success: 'СИЗ откреплён',
      error: 'Ошибка открепления СИЗ'
    }
  },
  device: {
    create: {
      success: 'Носимое устройство добавлено',
      error: 'Ошибка добавления носимого устройства'
    },
    edit: {
      success: 'Носимое устройство изменено',
      error: 'Ошибка изменения носимого устройства'
    },
    copy: {
      success: 'Носимое устройство скопировано',
      error: 'Ошибка копирования носимого устройства'
    },
    delete: {
      success: 'Носимое устройство удалено',
      error: 'Ошибка удаления носимого устройства'
    },
    unbind: {
      success: 'Носимое устройство откреплено',
      error: 'Ошибка открепления носимого устройства'
    }
  },
  beacon: {
    create: {
      success: 'Анкер добавлен',
      error: 'Ошибка добавления анкера'
    },
    edit: {
      success: 'Анкер изменён',
      error: 'Ошибка изменения анкера'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Анкер удалён',
      error: 'Ошибка удаления анкера'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  camera: {
    create: {
      success: 'Камера добавлена',
      error: 'Ошибка добавления камеры'
    },
    edit: {
      success: 'Камера изменена',
      error: 'Ошибка изменения камеры'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Камера удалена',
      error: 'Ошибка удаления камеры'
    },
    unbind: {
      success: '',
      error: ''
    }
  },

  office: {
    create: {
      success: 'Место добавлено',
      error: 'Ошибка добавления места'
    },
    edit: {
      success: 'Место изменено',
      error: 'Ошибка изменения места'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'Место удалено',
      error: 'Ошибка удаления места'
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  login: {
    create: {
      success: 'Добро пожаловать!',
      error: 'Ошибка'
    },
    edit: {
      success: '',
      error: ''
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: '',
      error: ''
    },
    unbind: {
      success: '',
      error: ''
    }
  },
  plan: {
    create: {
      success: 'План добавлен',
      error: 'Ошибка добавления плана'
    },
    edit: {
      success: 'План изменён',
      error: 'Ошибка изменения плана'
    },
    copy: {
      success: '',
      error: ''
    },
    delete: {
      success: 'План удалён',
      error: 'Ошибка удаления плана'
    },
    unbind: {
      success: '',
      error: ''
    }
  }
};

export default toastConstants;
