import React, { FC, useEffect } from 'react';

import { guids, paths } from '../../Root/paths';

import ContentTabs from '../../../components_/Content/ContentTabs';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import isSatisfied from '../../../utils/isSatisfied';
import { MtsTab } from '@mts-ds/components-react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const tabs = [
  {
    name: 'Роли',
    url: paths.settings.secTree.roles.root,
    uid: guids.settings.root
  },
  {
    name: 'Доступы',
    url: paths.settings.secTree.rights.root,
    uid: guids.settings.protectionTypes.root
  }
];

const useStyles = makeStyles(
  {
    root: {
      background: 'var(--color-control-tertiary-active)',
      borderRadius: 8,
      border: 0,
      color: 'var(--button-color)',
      height: 48,
      padding: '0 45px',
      '&$selected': {
        backgroundColor: 'var(--color-control-active-tab-bar)',
        color: 'var(--color-constant-greyscale-0)',
        '&:hover': {
          backgroundColor: 'rgba(212, 6, 16, 1)'
        }
      }
    },
    label: {
      textTransform: 'capitalize'
    },
    selected: {}
  },
  { name: 'MuiToggleButton' }
);

const ToggleButtons: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [alignment, setAlignment] = React.useState<string | null>(
    paths.settings.secTree.roles.root
  );
  const classes = useStyles();
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value) {
      setAlignment(value);
      history.push(value);
      // console.log(value, event);
    }
  };
  useEffect(() => {
    setAlignment(pathname);
  }, []);
  return (
    <ContentTabs>
      <ToggleButtonGroup
        color="secondary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {tabs.map(({ name, url, uid }, index) => {
          // console.log(url)
          return (
            <ToggleButton
              classes={{
                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                label: classes.label // class name, e.g. `classes-nesting-label-x`
              }}
              key={uid}
              size="large"
              color="secondary"
              value={url}
            >
              {name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </ContentTabs>
  );
};

export default ToggleButtons;
