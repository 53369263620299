import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../../store';
import { useAsync, useParams } from '../../../../hooks';
import { IGetAllParams } from '../../../../services/CamerasService';

import Spinner from '../../../../components_/Spinner';
import Pagination from '../../../../components_/Pagination';
import EmptyState from '../../../../components_/EmptyState';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetAllParams, 'pageIndex'> = {
  plans: '',
  name: ''
};

const mapState = (state: iRootState) => ({
  data: state.equipmentCameras.data,
  list: state.equipmentCameras.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.equipmentCameras.getData,
    getList: d.equipmentCameras.getList,
    reset: d.equipmentCameras.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({ getData, getList, reset, data, list: { data: list, pagesCount } }) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const { pageIndex, filter, handlePageChange, handleFilterChange, handleFilterReset } = useParams(
    initialFilter,
    runGetList
  );

  useEffect(() => {
    runGetData();

    return () => {
      reset();
    };
  }, []);

  const isLoading = isLoadingGetData || isLoadingGetList;
  const isLoadingError = isGetDataLoadingError || isGetListLoadingError;

  return (
    <>
      {!isLoadingGetData && !isGetDataLoadingError && (
        <>
          <Filter
            data={data}
            filter={filter}
            onChange={handleFilterChange}
            onReset={handleFilterReset}
          />
          {!isLoadingGetList && !isGetListLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                  {Boolean(list.length) && (
                    <>
                      <List list={list} />
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
