import React, { FC } from 'react';

import { guids, paths } from '../Root/paths';

import ContentTabs from '../../components_/Content/ContentTabs';
import Tabbar from '../../components_/Tabbar';

const tabs = [
  {
    name: 'Сотрудники',
    url: paths.staff.root,
    uid: guids.staff.root
  },
  {
    name: 'Структурные подразделения',
    url: paths.staff.departaments.root,
    uid: guids.staff.departaments.root
  }
];

const Tabs: FC = () => {
  return (
    <ContentTabs>
      <Tabbar tabs={tabs} />
    </ContentTabs>
  );
};

export default Tabs;
