import { v4 as uuidv4 } from 'uuid';

import sessionStorageConstants from './sessionStorageConstants';
import createUrl from '../utils/createUrl';

const { REACT_APP_DEV_URL } = process.env;

if (!sessionStorage.getItem(sessionStorageConstants.sessionUuid)) {
  sessionStorage.setItem(sessionStorageConstants.sessionUuid, uuidv4());
}

const sessionUuid = sessionStorage.getItem(sessionStorageConstants.sessionUuid);
const redirectUriHost = REACT_APP_DEV_URL || window.location.origin;
const redirectUri = `${redirectUriHost}/websso`;

const loginUrl = 'https://login.mts.ru/amserver/oauth2/authorize';
const loginUrlParams = {
  client_id: 'test_PRO_360_Unit',
  scope: 'profile',
  state: sessionUuid,
  redirect_uri: redirectUri,
  response_type: 'code'
};

const logoutUrl = 'https://login.mts.ru/amserver/UI/Logout';
const logoutUrlParams = {
  goto: redirectUri
};

const webSsoConstants = {
  loginUrl: createUrl(loginUrl, loginUrlParams),
  logoutUrl: createUrl(logoutUrl, logoutUrlParams),
  redirectUri
};

export default webSsoConstants;
