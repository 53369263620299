import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import getSearchedData from '../../../../utils/getSearchedData';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../components_/Drawer/DrawerContentListSearch';
import DrawerContentItemBeacon from '../../../../components_/Drawer/DrawerContentItemBeacon';
import EmptyState from '../../../../components_/EmptyState';
import Button from '../../../../components_/Fields/Button';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import DrawerSticky from '../../../../components/Drawer/DrawerSticky';
import { makeStyles } from '@material-ui/core/styles';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setMarkerCreateMode: d.infrastructureMap.setMarkerCreateMode
  };
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%',
    padding: spacing(2, 4, 2)
  }
}));

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Beacons: FC<IProps> = ({ map, setMarkerCreateMode }) => {
  const { name: mapName, beacons } = map;
  const classes = useStyles();

  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  const handleActionClick = () => {
    history.push(paths.map.office.infrastructure.create);
    setMarkerCreateMode();
  };

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const searchedBeacons = getSearchedData(beacons, ['macAddress', 'name'], search);
  const searchedBeaconsLength = (searchedBeacons || []).length;

  return (
    <>
      <DrawerAction toUrl={paths.map.office.root} />
      <DrawerContent title={mapName}>
        <DrawerContentList
          title="Инфраструктура"
          count={searchedBeaconsLength}
          // actionName="Добавить анкер"
          // actionOnClick={handleActionClick}
        >
          <DrawerContentListSearch
            placeholder="Найти анкер"
            value={search}
            onMtsChange={handleChangeSearch}
          />
          {searchedBeacons && (
            <>
              {searchedBeacons.map(beacon => (
                <DrawerContentItemBeacon key={beacon.macAddress} beacon={beacon} />
              ))}
              {!searchedBeaconsLength && <EmptyState variant="emptyRequest" />}
            </>
          )}
        </DrawerContentList>
        <DrawerSticky>
          <Button
            icon="plus"
            color={'secondary' as ButtonColors}
            className={classes.button}
            onClick={handleActionClick}
          >
            Добавить анкер
          </Button>
        </DrawerSticky>
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Beacons);
