import React, { FC } from 'react';
import { MtsButton } from '@mts-ds/components-react';

import { useQueryParams } from '../../../hooks';
import checkFilter from '../../../utils/checkFilter';
import { IProps as IHeatMapProps } from '../HeatMap';

import ContentFilter from '../../../components_/Content/ContentFilter';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import Dropdown from '../../../components_/Fields/Dropdown';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import Button from '../../../components_/Fields/Button';
import InputWithDate from '../../../components_/Fields/DatePicker/inputWithDate/inputWithDate';

interface IProps {
  setFilter: IHeatMapProps['setFilter'];
  resetFilter: IHeatMapProps['resetFilter'];
  getHeatMap: IHeatMapProps['getHeatMap'];
  resetHeatMap: IHeatMapProps['resetHeatMap'];
  data: IHeatMapProps['data'];
  filter: IHeatMapProps['filter'];
  isLoadingGetHeatMap: boolean;
  heatMap: IHeatMapProps['heatMap'];
}

const Filter: FC<IProps> = ({
  setFilter,
  resetFilter,
  getHeatMap,
  resetHeatMap,
  data,
  filter,
  isLoadingGetHeatMap,
  heatMap
}) => {
  const { offices, brigades } = data;
  const { mapId, userList, from, to } = filter;

  const { isApply, isShowReset } = checkFilter(
    filter,
    ['mapId', 'from', 'to'],
    heatMap ? [{}] : null
  );
  const { setQueryParams } = useQueryParams();

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilter({ [name]: value });
  };

  const handleClick = () => {
    getHeatMap(0);
    setQueryParams(filter);
  };

  const handleReset = () => {
    resetFilter();
    resetHeatMap();
    setQueryParams({});
  };

  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));
  const brigadeOptions = brigades.map(({ name, employees }) => ({
    [name]: employees && employees.map(({ userId, fio }) => ({ id: userId, name: fio }))
  }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Dropdown
            name="userList"
            label="Сотрудник/Подразделение (необязательно)"
            placeholder="Выберите значение"
            groupOptions={brigadeOptions}
            multiple
            value={userList}
            onMtsChange={handleChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="mapId"
            label="Место/План"
            placeholder="Выберите значение"
            groupOptions={officeOptions}
            value={mapId}
            onMtsChange={handleChange}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={handleChange} />*/}
          <InputWithDate
            name="from"
            label="Дата от"
            value={from}
            maxDate={new Date()}
            onChange={handleChange}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={handleChange} />*/}
          <InputWithDate
            name="to"
            label="Дата до"
            value={to}
            maxDate={new Date()}
            onChange={handleChange}
          />
        </FormCol>
        <FormCol xs="auto">
          <MtsButton disabled={isApply} loading={isLoadingGetHeatMap} onClick={handleClick}>
            Применить
          </MtsButton>
        </FormCol>
        {isShowReset && (
          <FormCol xs={12}>
            <Button color={'ghost' as ButtonColors} onClick={handleReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
