/**
 * Функция проверяет права на доступ и взаимодействие с контентом
 */
import { ISecurableRight } from '../models/types';

const isSatisfied = (requirement: any, rights: ISecurableRight[]) => {
  // tslint:disable-next-line:prettier
  const result = rights.find(right=> right.uid === requirement.uid && right.action === requirement.action)?.result
return result === 'Grant'
};

export default isSatisfied;
