import React, { FC } from 'react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
import { Select as AntSelect } from 'antd';
import { SelectProps } from 'antd/es/select';

interface ISelectProps {
  name: string;
  valid?: boolean;
  onChange?: any;
  value?: any;
}

export type IProps = IFormGroupTextProps & ISelectProps & SelectProps;

const Select: FC<IProps> = ({
  label,
  tooltip,
  description,
  name,
  onChange,
  valid,
  value,
  ...props
}) => {
  const handleChange = (id: string) => {
    onChange({ target: { name, value: id } });
  };

  return (
    <FormGroup label={label} description={description} tooltip={tooltip} valid={valid}>
      <AntSelect value={value} style={{ width: '100%' }} onChange={handleChange} {...props} />
    </FormGroup>
  );
};

export default Select;
