import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsSpinner } from '@mts-ds/components-react';
import {
  SpinnerColors,
  SpinnerSizes
} from '@mts-ds/components/dist/types/components/spinner/enums';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1)
  }
}));

export type IProps = typeof MtsSpinner.defaultProps;

const Spinner: FC<IProps> = props => {
  const classes = useStyles();

  return (
    <Grid item xs container justify="center" alignItems="center" className={classes.root}>
      <MtsSpinner size={'l' as SpinnerSizes} color={'mts-red' as SpinnerColors} {...props} />
    </Grid>
  );
};

export default Spinner;
