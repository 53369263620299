import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as BeaconSvg } from '../../../../assets_/icons/bleBeacon.svg';
import { ReactComponent as GatewaySvg } from '../../../../assets_/icons/bleGateway.svg';
import { ReactComponent as UwbSvg } from '../../../../assets_/icons/uwbAnchor.svg';
import { ReactComponent as InfrastructureSvg } from '../../../../assets_/icons/infrastructure.svg';

import { getColor } from '../../../../theme';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginRight: spacing(1.5),
    fontSize: 24,
    width: '1em',
    height: '1em'
  }
}));

interface IProps {
  type: string;
  text: string;
}

const ListItemType: FC<IProps> = ({ type, text }) => {
  const classes = useStyles();

  const icon = type ? (
    type === 'BLE_BEACON' ? (
      <BeaconSvg style={{ color: getColor('constant-blueberry-dark') }} className={classes.root} />
    ) : type === 'BLE_GATEWAY' ? (
      <GatewaySvg
        style={{ color: getColor('constant-blueberry-light') }}
        className={classes.root}
      />
    ) : type === 'UWB_ANCHOR' ? (
      <UwbSvg style={{ color: getColor('constant-apple-light') }} className={classes.root} />
    ) : (
      <InfrastructureSvg className={classes.root} />
    )
  ) : (
    <InfrastructureSvg className={classes.root} />
  );

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {icon} {text}
    </Grid>
  );
};

export default ListItemType;
