import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../../store';
import getFormData from '../../../../../../utils/getFormData';

import Form from './Form';

const mapState = (state: iRootState) => ({
  user: state.infrastructureUsers.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.staffUsers.getData,
    getUser: d.infrastructureUsers.getUser
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IParams {
  userName: string;
}

const User: FC<IProps> = ({ getData, getUser, user }) => {
  const { userName } = useParams<IParams>();

  useEffect(() => {
    getData();

    getUser(userName);
  }, [userName]);

  if (!user) {
    return null;
  }

  return <Form user={user} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(User);
