import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { MtsBadge } from '@mts-ds/components-react';
import Typography from '../../../components_/Typography';
import Icon from '../../../components_/Icon';

import { IBeacon } from '../../../models/types';

import MarkerTooltip from './components/MarkerTooltip';

const useStyles = makeStyles(({ spacing, colors }) => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  }
}));

interface IProps {
  item: IBeacon;
}

const BeaconMarkerListItemTooltip: FC<IProps> = ({ item }) => {
  const { macAddress, name, hardwareTypeFriendly, lat, lon, z, charge } = item;

  const classes = useStyles();

  const chargeLevel = `${charge || '--'}%`;

  return (
    <MarkerTooltip>
      <Grid className={classes.content}>
        <MtsBadge size="s">{hardwareTypeFriendly}</MtsBadge>
        <Typography variant="p2Bold">{name}</Typography>
        <Typography variant="p2Medium">MAC {macAddress}</Typography>
        {/*<Typography variant="p3Regular">{`широта ${lat}, долгота ${lon}, высота ${z}`}</Typography>*/}
        <Grid container alignItems="center" className={classes.label}>
          <Icon name="battery" size="s" color="constant-apple-dark" />
          <Typography variant="p3Medium">{chargeLevel}</Typography>
        </Grid>
      </Grid>
    </MarkerTooltip>
  );
};

export default BeaconMarkerListItemTooltip;
