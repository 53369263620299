import { guids, paths } from '../../routes/Root/paths';

interface ITab {
  name: string;
  url: string;
  uid: string;
}

const tabs: ITab[] = [
  { name: 'Главная', url: paths.map.root, uid: guids.map.root },
  { name: 'Персонал', url: paths.staff.root, uid: guids.staff.root },
  { name: 'Оборудование', url: paths.equipment.root, uid: guids.equipment.root },
  { name: 'Отчёты', url: paths.reports.root, uid: guids.reports.root },
  { name: 'Уведомления', url: paths.notifications, uid: guids.notifications },
  { name: 'Настройки', url: paths.settings.root, uid: guids.settings.root }
];

export { tabs };
