import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './';

interface IProps {
  forms: IFormProps['forms'];
  isEdit: boolean;
  setSubmitForms: any;
  onCancel: any;
}

const FormActions: FC<IProps> = ({ forms, isEdit, setSubmitForms, onCancel }) => {
  const { list: formList } = forms;

  const handleSubmitButton = () => {
    setSubmitForms(true);
  };

  const isDisabled = !formList.every(({ isValidate }) => isValidate);

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  return (
    <Grid container spacing={4} justify="center">
      <Grid item>
        <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
          Отмена
        </MtsButton>
      </Grid>
      <Grid item>
        <MtsButton disabled={isDisabled} onClick={handleSubmitButton}>
          {submitButtonTitle}
        </MtsButton>
      </Grid>
    </Grid>
  );
};

export default FormActions;
