/**
 * Сервис для работы с СИЗ
 */

import axios from 'axios';

import { IId } from '../models/types';
import { pageSize } from '../constants/pagination';

export interface IGetAllParams {
  fullTextSearch: string;
  pageIndex: number;
}

export interface IGetTypeAllParams {
  search?: string;
  classType?: string;
  pageIndex?: number;
  pageSize?: number;
}

export interface IUpdateOrCreateBody {
  id: IId;
  number: string;
  classId: IId;
  numberType: string;
  mappingType: string;
  userId: IId | null;
  bestBefore: string | null;
}

export interface IUpdateOrCreateType {
  id: IId;
  name: string;
  classType: string;
  multiUse: boolean;
}

const url = '/protection';

const ProtectionsService = {
  get: (id: IId) => axios.get(`${url}/instance?id=${id}`),
  getAll: (params: IGetAllParams) =>
    axios.get(`${url}/instances`, { params: { pageSize, ...params } }),
  getType: (id: IId) => axios.get(`${url}/classes/${id}`),
  getTypeAll: (params: IGetTypeAllParams = {}) =>
    axios.get(`${url}/classes`, { params: { pageSize, ...params } }),
  updateOrCreateType: (body: IUpdateOrCreateType) => axios.post(`${url}/classes`, body),
  deleteType: (id: IId) => axios.delete(`${url}/classes`, { params: { id } }),
  getTypeTypeAll: () => axios.get(`${url}/deviceClasses`),
  getTechnologyAll: () => axios.get(`${url}/numberTypes`),
  updateOrCreate: (body: IUpdateOrCreateBody) => axios.post(`${url}/instances`, body),
  unbind: (id: IId) => axios.post(`${url}/unbind?id=${id}`),
  delete: (id: IId) => axios.delete(`${url}/instance`, { params: { id } })
};

export default ProtectionsService;
