import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import getClearParams from '../utils/getClearParams';

/**
 * Хук используется для получения и изменения параметров поиска
 */
const useQueryParams = () => {
  const history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search);

  const setQueryParams = (params: object) => {
    const clearParams = getClearParams(params);

    history.replace({
      pathname: history.location.pathname,
      search: new URLSearchParams(clearParams).toString()
    });
  };

  return { queryParams, setQueryParams };
};

export default useQueryParams;
