import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge } from '@mts-ds/components-react';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(4, 3),
    marginBottom: spacing(6.5)
  },
  title: {
    marginBottom: spacing(2.5)
  }
}));

interface IProps {
  title?: string;
}

const DrawerContent: FC<IProps> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      {title && (
        <Grid container className={classes.title}>
          <MtsBadge id="BadgeTitle">{title}</MtsBadge>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default DrawerContent;
