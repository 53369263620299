import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  }
}));

const TableActionGroup: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container justify="flex-end" wrap="nowrap" className={classes.root}>
      {children}
    </Grid>
  );
};

export default TableActionGroup;
