import { createMuiTheme, Theme } from '@material-ui/core/styles';

export type IColor =
  | 'brand'
  | 'constant-greyscale-0'
  | 'constant-greyscale-100'
  | 'constant-greyscale-200'
  | 'constant-greyscale-300'
  | 'constant-greyscale-400'
  | 'constant-greyscale-500'
  | 'constant-greyscale-600'
  | 'constant-greyscale-700'
  | 'constant-greyscale-800'
  | 'constant-greyscale-900'
  | 'constant-blackberry-lightest'
  | 'constant-blackberry-light'
  | 'constant-blackberry-normal'
  | 'constant-blackberry-dark'
  | 'constant-blackberry-darkest'
  | 'constant-blueberry-lightest'
  | 'constant-blueberry-light'
  | 'constant-blueberry-normal'
  | 'constant-blueberry-dark'
  | 'constant-blueberry-darkest'
  | 'constant-mint-lightest'
  | 'constant-mint-light'
  | 'constant-mint-normal'
  | 'constant-mint-dark'
  | 'constant-mint-darkest'
  | 'constant-apple-lightest'
  | 'constant-apple-light'
  | 'constant-apple-normal'
  | 'constant-apple-dark'
  | 'constant-apple-darkest'
  | 'constant-lime-lightest'
  | 'constant-lime-light'
  | 'constant-lime-normal'
  | 'constant-lime-dark'
  | 'constant-lime-darkest'
  | 'constant-banana-lightest'
  | 'constant-banana-light'
  | 'constant-banana-normal'
  | 'constant-banana-dark'
  | 'constant-banana-darkest'
  | 'constant-orange-lightest'
  | 'constant-orange-light'
  | 'constant-orange-normal'
  | 'constant-orange-dark'
  | 'constant-orange-darkest'
  | 'constant-raspberry-lightest'
  | 'constant-raspberry-light'
  | 'constant-raspberry-normal'
  | 'constant-raspberry-dark'
  | 'constant-raspberry-darkest'
  | 'constant-cranberry-lightest'
  | 'constant-cranberry-light'
  | 'constant-cranberry-normal'
  | 'constant-cranberry-dark'
  | 'constant-cranberry-darkest'
  | 'constant-plum-lightest'
  | 'constant-plum-light'
  | 'constant-plum-normal'
  | 'constant-plum-dark'
  | 'constant-plum-darkest'
  | 'text-headline'
  | 'text-primary'
  | 'text-secondary'
  | 'text-tertiary'
  | 'text-inverted'
  | 'text-positive'
  | 'text-negative'
  | 'text-primary-link'
  | 'text-primary-link-hover'
  | 'text-primary-link-visited'
  | 'text-secondary-link'
  | 'text-secondary-link-hover'
  | 'text-secondary-link-visited'
  | 'background-primary'
  | 'background-stroke'
  | 'background-primary-elevated'
  | 'background-secondary'
  | 'background-secondary-elevated'
  | 'background-modal'
  | 'background-inverted'
  | 'background-overlay'
  | 'background-hover'
  | 'background-hover-inverted'
  | 'accent-active'
  | 'accent-positive'
  | 'accent-warning'
  | 'accent-negative'
  | 'icon-primary'
  | 'icon-secondary'
  | 'icon-tertiary';

const getColor = (name: IColor) => `var(--color-${name})`;

type IShadow = 'lowest' | 'low' | 'middle' | 'upper-middle' | 'high';

const getShadow = (name: IShadow) => `var(--shadow-${name})`;

type IMapColor =
  | 'mtsRed'
  | 'deepBlue'
  | 'linkWater'
  | 'sinApple'
  | 'dodgerBlue'
  | 'black'
  | 'blackSmoke'
  | 'white'
  | 'whiteSmoke'
  | 'greyRaven'
  | 'greyChateau'
  | 'greyHeather'
  | 'greySlate'
  | 'greySteel'
  | 'greyStone'
  | 'greyScale'
  | 'lightPositive'
  | 'lightWarning'
  | 'lightBlueberry'
  | 'lightPlum'
  | 'lightOrange'
  | 'lightMint'
  | 'lightLime'
  | 'lightBanana'
  | 'lightRaspberry'
  | 'lightBlackberry'
  | 'lightCranberry'
  | 'lightestApple'
  | 'lightestPlum'
  | 'lightestOrange'
  | 'lightestBanana'
  | 'lightestBlackberry'
  | 'lightestLime'
  | 'lightestRaspberry'
  | 'lightestBlueberry'
  | 'darkApple'
  | 'darkLime'
  | 'darkPositive'
  | 'darkGray'
  | 'darkOrange'
  | 'darkBlueberry'
  | 'darkWarning'
  | 'darkBlackberry'
  | 'darkNegative'
  | 'darkestBanana'
  | 'darkestOrange'
  | 'darkestPlum'
  | 'darkestRaspberry'
  | 'darkestCranberry'
  | 'normalBanana'
  | 'normalBlackberry'
  | 'normalOrange'
  | 'normalMint';

type IMapColors = {
  [key in IMapColor]: string;
};

/**
 * Цветовая палитра
 */
const mapColor: IMapColors = {
  mtsRed: '#E30611',
  deepBlue: '#001424',
  linkWater: '#E2E5EB',
  sinApple: '#03AD00',
  dodgerBlue: '#0097FD',
  black: '#000',
  blackSmoke: '#1D2023',
  white: '#FFF',
  whiteSmoke: '#F2F3F7',
  greyRaven: '#6E7782',
  greyChateau: '#9198A0',
  greyHeather: '#BBC1C7',
  greySlate: '#626C77',
  greySteel: '#969FA8',
  greyStone: '#434A51',
  greyScale: '#2C3135',
  lightPositive: '#26CD58',
  lightWarning: '#FAC031',
  lightBlueberry: '#45B6FC',
  lightPlum: '#A86EA7',
  lightOrange: '#FFA080',
  lightMint: '#00D9BC',
  lightLime: '#D3F36B',
  lightBanana: '#FDF177',
  lightRaspberry: '#F55F7E',
  lightBlackberry: '#6384E0',
  lightCranberry: '#E677AD',
  lightestApple: '#E8FAEB',
  lightestPlum: '#F0E7F0',
  lightestOrange: '#FBE9E7',
  lightestBanana: '#FFFDE8',
  lightestBlackberry: '#E7EAFA',
  lightestLime: '#F8FEE7',
  lightestRaspberry: '#FFE4E9',
  lightestBlueberry: '#E1F3FE',
  darkApple: '#04AA42',
  darkLime: '#A6C100',
  darkPositive: '#74DF8B',
  darkGray: '#565656',
  darkOrange: '#E04A17',
  darkBlueberry: '#007CFF',
  darkWarning: '#FAD67D',
  darkBlackberry: '#003DB7',
  darkNegative: '#FA8A64',
  darkestBanana: '#F37F19',
  darkestOrange: '#BA360A',
  darkestPlum: '#471C61',
  darkestRaspberry: '#8E003F',
  darkestCranberry: '#7F3363',
  normalBanana: '#FBE739',
  normalBlackberry: '#014FCE',
  normalOrange: '#F95721',
  normalMint: '#00C19B'
};

/**
 * Расширяем дефолтный интерфейс Theme
 */
declare module '@material-ui/core/styles/createMuiTheme' {
  // tslint:disable-next-line
  interface Theme {
    getColor: typeof getColor;
    getShadow: typeof getShadow;
    colors: IMapColors;
  }
  // allow configuration using `createMuiTheme`
  // tslint:disable-next-line
  interface ThemeOptions {
    getColor: typeof getColor;
    getShadow: typeof getShadow;
    colors: IMapColors;
  }
}

const theme: Theme = createMuiTheme({
  typography: {
    fontFamily: '"MTSSans", "Arial", sans-serif',
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: 64,
      fontWeight: 600
    },
    h2: {
      fontSize: 48,
      fontWeight: 600
    },
    h3: {
      fontSize: 32,
      fontWeight: 600
    },
    h4: {
      fontSize: 24,
      fontWeight: 600
    },
    h5: {
      fontSize: 20,
      fontWeight: 600
    },
    h6: {
      fontSize: 20,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 500
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    }
  },
  palette: {
    primary: {
      main: mapColor.mtsRed
    },
    /**
     * Перезаписываем дефолтный цвет background
     */
    background: {
      default: getColor('background-primary')
    }
  },
  getColor,
  getShadow,
  colors: mapColor
});

export { getColor, getShadow, mapColor };
export default theme;
