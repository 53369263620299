import React, { FC } from 'react';
import { MtsSwitch } from '@mts-ds/components-react';

export type IProps = typeof MtsSwitch.defaultProps;

const Switch: FC<IProps> = ({ ...props }) => {
  return <MtsSwitch {...props} />;
};

export default Switch;
