import React, { FC } from 'react';
import { Switch } from 'react-router-dom';

import { guids, paths } from '../../../Root/paths';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components_/Drawer/DrawerContent';

import Form from './Form';
import Show from './Show';
import PrivateRoute from '../../../Root/PrivateRoute';

const Office: FC = () => {
  return (
    <>
      <DrawerAction toUrl={paths.map.root} />
      <DrawerContent>
        <Switch>
          {/*<Route exact path={paths.map.office.root} component={Show} />*/}
          <PrivateRoute
            exact
            requirement={{
              uid: guids.map.office.root
            }}
            path={paths.map.office.root}
            component={Show}
          />
          {/*<Route path={paths.map.office.create} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.create
            }}
            path={paths.map.office.create}
            component={Form}
          />
          {/*<Route path={paths.map.office.edit()} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.edit()
            }}
            path={paths.map.office.edit()}
            component={Form}
          />
        </Switch>
      </DrawerContent>
    </>
  );
};

export default Office;
