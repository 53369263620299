import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { MtsLink } from '@mts-ds/components-react';

import { paths } from '../../../routes/Root/paths';

import Icon from '../../Icon';
import IconWrapper from '../../Icon/IconWrapper';
import Typography from '../../Typography';

import DrawerContentItem from '../DrawerContentItem';

interface IProps {
  isChange?: boolean;
}

const DrawerContentCompany: FC<IProps> = ({ isChange }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(paths.map.office.root);
  };

  const icon = (
    <IconWrapper variant="circle" size="xxl" color="icon-tertiary">
      <Icon name="company" size="l" />
    </IconWrapper>
  );

  const content = <Typography variant="h2Bold">МТС ИТ</Typography>;

  const action = (
    <Typography variant="p3Regular">
      <MtsLink onClick={handleClick}>Все места</MtsLink>
    </Typography>
  );

  return (
    <DrawerContentItem variant="card" icon={icon} content={content} {...(isChange && { action })} />
  );
};

export default DrawerContentCompany;
