export type IId = number | string;

export type ICoord = number[];
export type ICoordXY = [number, number];
export type ICoordXYXY = [ICoordXY, ICoordXY];
export type ICoordXYZW = [number, number, number, number];

export interface ISelect {
  id: IId;
  name: string;
}

export interface IDepartments {
  id: string;
  name: string;
  children: IDepartments[];
}

export interface IRole {
  id: IId | null;
  name: string;
  friendlyName: string;
  actions: {};
}

export interface IPost {
  id: IId;
  name: string;
}

export interface IOffice {
  id: IId;
  name: string;
  address: string;
  location: {
    lat: number;
    lon: number;
  };
  employeesCount: number;
  plans: Array<{
    id: IId;
    name: string;
  }>;
}

export enum UserStatusPriority {
  HIDDEN = 'HIDDEN',
  NORMAL = 'NORMAL',
  CRITICAL = 'CRITICAL',
  FOCUSED = 'FOCUSED'
}

export interface IUserStatus {
  priority: UserStatusPriority;
  ntfy: {
    class: string;
    classFriendly: string;
    time: string;
  };
}

interface IUserMeasurement {
  Type: string;
  Value: string;
}

export interface ITrackInfo {
  online: boolean;
  mapId: number;
  mapColor: string;
  lastPosition: string;
  states: IUserStatus[];
  protAssigned: IId[];
  protChecked: IId[];
  measurements: IUserMeasurement[];
}

export interface IUser {
  id: IId;
  username: string;
  fullName: string;
  name: string;
  lastname: string;
  secondname: string;
  phone: string;
  email: string;
  title: string;
  role: string;
  brigadeId: number | null;
  brigadeName: string;
  mapColor: string;
  immobilizedTimeMinutes: number;
  workshiftDurationHours: number;
  workshiftBreakMinutes: number;
  descr: string;
  lead: IUser | null;
  protectionsList: IId[];
  birthday: string;
  tabularNumber: string;
  photoContent: string;
  photoContentType: string;
  trackInfo?: ITrackInfo;
  charge?: {
    precent: number;
    isCharging: boolean;
    time: string;
    volt: any;
  };
}

export interface IEmployeesList {
  id: IId;
  fullName: string;
  post?: {
    id: IId;
    name: string;
  };
  department?: {
    id: IId;
    name: string;
    rootId: string;
    rootName: string;
  };
  deviceId?: number;
  deviceCharge?: string;
  lastPositionTime?: string;
}

export interface IUserEmployees {
  id: IId;
  tenantId?: IId;
  name: string;
  lastName: string;
  secondName: string;
  fullName?: string;
  phone: string;
  email: string;
  birthday: string;
  authorizationData: {
    login: string;
    password: string;
    hasSsoAuth: boolean;
    changePass: boolean;
  };
  workInfo: {
    department: {
      id: IId;
      name: string;
    };
    post: {
      id: IId;
      name: string;
    };
    tabularNumber: string;
    protections: IId[];
  };
  settings: {
    role: string;
    planColor: string;
    immobilizedTimeMinutes: number;
    workshiftDurationHours: number;
    workshiftBreakMinutes: number;
  };
  trackerInfo?: {
    deviceId: number;
    deviceCharge: string;
  };
  photoContent?: string;
  photoContentType?: string;
  trackInfo?: ITrackInfo;
  charge?: {
    precent: number;
    isCharging: boolean;
    time: string;
    volt: any;
  };
}

export interface IUserMap extends IUser {
  userId: IId;
  rootDepartmentId?: IId;
  rootDepartmentName?: IId;
  position: {
    type: string;
    officeId: IId;
    mapId: IId;
    areaId: IId;
    x: number;
    y: number;
    z: number;
    isDefinedLocal: boolean;
    lat: number;
    lon: number;
    isDefinedGlobal: boolean;
    kinematic: number;
    tolerance: number;
    timeStart: string;
    timeEnd: string;
    'receive-backend': any;
  };
}

export interface IUserPosition {
  user: IUser;
  pos: {
    mapId: IId;
  };
}

export interface IShortUser {
  userId: IId;
  fio: string;
}

export enum AreaModes {
  Default = 'DEFAULT',
  Edit = 'EDIT',
  Create = 'CREATE'
}

export enum AreaTypes {
  Box = 'Box',
  Circle = 'Circle',
  Polygon = 'Polygon',
  Marker = 'Marker'
}

export enum EditPlanMode {
  Rotate = 'rotate',
  Translate = 'translate',
  Scale = 'scale',
  Lock = 'none'
}

export interface IArea {
  id: IId;
  mapId: IId;
  name: string;
  descr: string;
  dangerClassId: number;
  mapColor: string;
  workStart: string;
  workEnd: string;
  workDurationMinutes: number;
  protectionsList: IId[];
  denyFrom: IId[];
  employees: IUser[];
  employeesList: IId[];
  geomType: AreaTypes;
  geomCoords: ICoord;
  geomGlobalCoords: ICoord;
}

export interface IBeacon {
  macAddress: string;
  name: string;
  hardwareType: string;
  hardwareTypeFriendly: string;
  lat: number;
  lon: number;
  alt: number;
  x: number;
  y: number;
  z: number;
  officeName: string;
  officeId: IId;
  mapName: string;
  charge: number;
  queryTime: string;
  isActive: boolean;
  calibratedRssi: number;
  calibratedDistance: number;
  instanceId: number;
  major: number;
  minor: number;
}

export interface IBeaconType {
  systemName: string;
  friendlyName: string;
}

export interface ICamera {
  id: IId;
  customerId: IId;
  name: string;
  focalLength: number;
  rotationAngle: number;
  description: string;
  isNotificationEnabled: boolean;
  createDateTime: string;
  videoStreamUrl: string;
  location: {
    coordinateX: number;
    coordinateY: number;
    coordinateZ: number;
    lat: number;
    lon: number;
    alt: number;
  };
  plan: {
    id: IId;
    name: string;
  };
  place: {
    id: IId;
    name: string;
  };
}

export interface IInfrastructure {
  id: IId;
  macAddress: string;
  name: string;
  hardwareTypeFriendly: string;
  hardwareType: string;
  x: number;
  y: number;
  z: number;
  officeName: string;
  mapName: string;
  charge: number;
  queryTime: string;
  isActive: boolean;
  instanceId: number;
}

export interface IMap {
  contentId: IId;
  id: IId;
  name: string;
  preview: string;
  previewFormat: string;
  bbox: ICoordXYZW;
  gbox: any;
  areas: IArea[];
  beacons: IBeacon[];
  cameras: ICamera[];
  file?: File | null;
}

export interface IMaps {
  id: IId;
  name: string;
}

export interface IReportAreas {
  userId: IId;
  employeeName: string;
  areaId: IId;
  areaName: string;
  enterTime: string;
  leaveTime: string;
  durationMin: number;
}

export type IAlertVariant = 'success' | 'warning' | 'error';

export interface IAlert {
  uid: IId;
  userId: IId;
  variant?: IAlertVariant;
  type: string;
  title: string;
  description: string;
  color: string;
  isConfirm: boolean;
  date: string;
  severity: NotificationSeverity;
  targetSimple: string;
  trackInfo?: ITrackInfo;
}

export enum ITargetSimple {
  Web = 'Web',
  Mobile = 'Mobile',
  Other = 'Other'
}

export enum NotificationSeverity {
  Ignored = 'Ignored',
  Low = 'Low',
  Med = 'Med',
  High = 'High',
  Critical = 'Critical'
}

export type INotificationClass =
  | 'NotificationSos'
  | 'NotificationEnteringDangerZone'
  | 'NotificationLeaveDangerZone'
  | 'NotificationForgottenProtection'
  | 'NotificationSilhouetteWoProtection'
  | 'NotificationImmobilized'
  | 'NotificationFall'
  | 'NotificationInformational'
  | 'NotificationWorkStart'
  | 'NotificationWorkEnd'
  | 'NotificationEmergency'
  | 'NotificationHealthPulseAbnormal'
  | 'NotificationAreaExceedingTime'
  | 'NotificationAreaOfftime'
  | 'NotificationProtectionProblem'
  | 'NotificationAreaAccessViolation'
  | 'NotificationTrackerCharging'
  | 'NotificationTrackerUncharged'
  | 'NotificationTrackerLowCharge';

export interface INotification {
  id: IId;
  uid: IId;
  userId: IId;
  text: string;
  class: INotificationClass;
  classFriendly: string;
  target: string;
  isNeedConfirmation: boolean;
  raisingTime: string;
  targetSimple: ITargetSimple;
  severity: NotificationSeverity;
  url: string;
  trackInfo?: ITrackInfo;
}

export interface INotificationChannel {
  channelType: string;
  friendlyName: string;
  description: string;
  channelSpecs: [
    {
      channelCap: string;
      description: string;
    }
  ];
}

export interface INotificationTarget {
  target: string;
  targetSimple: ITargetSimple;
  descr: string;
  channels: INotificationChannel[];
  isList: boolean;
  isReadConfirm: boolean;
  isSound: boolean;
  isVibro: boolean;
}

export interface INotificationType {
  descr: string;
  class: string;
  vars: string;
  targets: INotificationTarget[];
}

export interface INotificationVariable {
  placeholder: string;
  descr: string;
}

export interface INotificationSetting {
  id: IId;
  color: string;
  notificationClass: INotificationClass;
  notificationClassName: string;
  name: string;
  target: string;
  targetName: string;
  template: string;
  employeesList: [];
  isNeedConfirmation: boolean;
  isNeedSound: boolean;
  isNeedVibro: boolean;
  targetSimple: ITargetSimple;
}

export interface IProtection {
  id: IId;
  classId: IId;
  className: string;
  numberType: string;
  number: string;
  mappingType: string;
  userId: IId;
  fio: string;
  bestBefore: string;
}

export interface IDevice {
  id: IId;
  number: string;
  className: string;
  mappingType: string;
  fio: string;
  bestBefore: string;
  model: string;
  invent: string;
  serial: string;
  notes: string;
}

export interface IDepartament {
  id: IId;
  name: string;
  parentId: IId;
  // leadId: IId;
  // leadFio: string;
  // employeesList: IId[];
  employees: Array<{
    userId: IId;
    fio: string;
  }>;
}

export interface IViolationsTypes {
  id: IId;
  name: string;
}

export interface IReportViolations {
  id: IId;
  employeeName: string;
  areaName: string;
  mapName: string;
  officeName: string;
  violation: string;
  cause: string;
  time: string;
}

export interface IReportNotifications {
  id: IId;
  time: string;
  mapName: string;
  officeName: string;
  areaName: string;
  eventType: string;
  eventText: string;
  employeeName: string;
}

export interface IReportWorkingTime {
  id: IId;
  employeeName: string;
  start: string;
  end: string;
  duration: number;
  pause: number;
  durationFact: number;
}

export interface IReportUseProtection {
  id: IId;
  number: string;
  class: {
    id: IId;
    name: string;
  };
  mappingType: string;
  employeeName: string;
  from: string;
  to: string;
}

export interface IReportStayInAreas {
  id: IId;
  employeeName: string;
  mapName: string;
  officeName: string;
  areaName: string;
  restrictions: string;
  enterTime: string;
  leaveTime: string;
  duration: string;
}

export interface IReportPhysicalState {
  id: IId;
  physType: string;
  employeeName: string;
  time: string;
  areaName: string;
  mapName: string;
  officeName: string;
}

export interface IReportPhysicalStateType {
  id: IId;
  name: string;
}

export interface IHeatMapPoint {
  x: number;
  y: number;
}

export interface ITracks {
  from: string;
  to: string;
  tracks: [];
}

export interface ITrackPoint {
  userId: IId;
  user: {
    id: IId;
    phone: string;
    email: string;
    title: string;
    username: string;
    role: string;
    name: string;
    lastname: string;
    secondname: string;
    descr: string;
  };
  points: [
    {
      // location: {
      //   lat: number;
      //   lon: number;
      //   alt: number;
      // };
      lat: number;
      lon: number;
      alt: number;
      events: [];
      mapId?: IId;
      time?: string;
    }
  ];
}

export interface IReportHeatMapPoint extends IHeatMapPoint {
  weight: number;
}

export interface IProtectionType {
  id: IId;
  name: string;
  className: string;
  multiUse: boolean;
}

export interface IProtectionTypeType {
  systemName: string;
  friendlyName: string;
}

export interface IProtectionTechnology {
  numberType: string;
}

export interface ISecurableRight {
  id: IId;
  uid: string;
  roleId?: IId;
  userId?: IId;
  action?: IId;
  result?: IId;
}

export interface IRoleType {
  id: IId;
  name: string;
  friendlyName: string;
}

export interface ICompanyConfig {
  companyName: string;
  dispatcherPhoneNumber: string;
  tenantId: string;
  allowRemapPermProtections: boolean;
  isWebSsoUsed: boolean;
  isLobbyUsed: boolean;
  isGoodwinBppuUsed: boolean;
  isCamUsed: boolean;
  isTabularNumberUsed: boolean;
  isPulseUsed: boolean;
  isProtectionsUsed: boolean;
  isTitleUsed: boolean;
  userActivityTrackingSettings: {
    offlineTimeoutSecondsMin: number;
    offlineTimeoutSeconds: number;
    actualPositionTimeoutSecondsMin: number;
    actualPositionTimeoutSeconds: number;
    leaveAreaTimeoutSecondsMin: number;
    leaveAreaTimeoutSeconds: number;
    immobilizedTimeMinutes: number;
    immobilizedAfterFallSeconds: number;
    immobilizedCheckWindowSeconds: number;
    sendSuccessTimeoutSeconds: number;
    sendErrorRepeatTimeoutSeconds: number;
    immobilizedZoneFlags: string;
    immobilizedOrientation: string;
    fallOrientation: string;
    sourceFlags: string;
  };
  workshiftSettings: {
    workshiftDurationHours: number;
    workshiftBreakMinutes: number;
    workshiftCooldownMinutes: number;
    workshiftCheckPeriodMinutes: number;
    immobilizedWorkshift: string;
  };
  mapKeys: {
    tomtom: string;
    egis: string;
    mapbox: string;
  };
  loaded: string;
}
