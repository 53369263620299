import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../../store';
import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, colors, getColor }) => ({
  root: {
    '&:hover': {
      cursor: '	pointer',
      '& path': { fill: getColor('brand') }
    },
    display: 'inline-flex',
    position: 'relative',
    flexShrink: 0,
    verticalAlign: 'middle'
  }
}));
const mapState = (state: iRootState) => ({
  soundEnabled: state.alerts.soundEnabled
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setSoundEnabled: d.alerts.setSoundEnabled
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const HeaderSoundEnabler: FC<IProps> = ({ setSoundEnabled, soundEnabled }) => {
  const classes = useStyles();
  const handler = () => {
    setSoundEnabled(!soundEnabled);
  };

  return (
    <Grid>
      <Tooltip
        size="l"
        label={soundEnabled ? 'Звук уведомлений включён' : 'Звук уведомлений выключен'}
      >
        <span className={classes.root} onClick={handler}>
          <Icon name={soundEnabled ? 'soundOn' : 'soundOff'} />
        </span>
      </Tooltip>
    </Grid>
  );
};
const withConnect = connect(
  mapState,
  mapDispatch
);
export default withConnect(HeaderSoundEnabler);
