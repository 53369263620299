import moment, { MomentInput } from 'moment';

/**
 * Функция, возвращающая часы и минуты
 * @param min Минуты
 */
const getTimeFromMinutes = (min: number) => {
  const time = moment.duration(min, 'minutes');

  const hours = time.hours();
  const minutes = time.minutes();

  return [hours, minutes];
};

/**
 * Функция, возвращающая минуты
 * @param hours Часы
 * @param minutes Минуты
 */
const getMinutesFromTime = (hours: number, minutes: number) => {
  const duration = { hours, minutes };

  return moment.duration(duration).asMinutes();
};

/**
 * Функция, возвращающая календарное время
 * @param hours Часы
 * @param format Формат
 * @param short Короткая форма
 */
const getCalendarTime = (time: MomentInput, format: string, short: boolean = false) => {
  const formats = {
    sameDay: `[Сегодня]${short ? '' : `, ${format}`}`,
    lastDay: `[Вчера]${short ? '' : `, ${format}`}`,
    nextDay: format,
    lastWeek: format,
    nextWeek: format,
    sameElse: format
  };

  return moment(time).calendar(null, formats);
};

export { getTimeFromMinutes, getMinutesFromTime, getCalendarTime };
