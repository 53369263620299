import React, { FC } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import Input, { IProps as IInputProps } from '../../Fields/Input';

type IProps = {
  name: string;
  description?: string;
  useFieldConfig?: UseFieldConfig<string>;
} & IInputProps;

const FormInput: FC<IProps> = ({ name, useFieldConfig, description, ...props }) => {
  const {
    input,
    meta: { touched, error, invalid }
  } = useField(name, useFieldConfig);

  // Адаптер под mts-ds
  input.onMtsChange = input.onChange;
  input.onMtsFocus = input.onFocus;
  input.onMtsBlur = input.onBlur;

  const message = touched && error ? error : description;

  const isValid = touched && invalid ? !Boolean(error) : undefined;

  return <Input description={message} valid={isValid} {...input} {...props} />;
};

export default FormInput;
