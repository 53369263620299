/**
 * Отчищает объект от пустых свойств
 */

const getClearParams = (object: any) => {
  const result = { ...object };

  Object.keys(result).forEach(key => {
    if (
      result[key] === '' ||
      result[key] === undefined ||
      (Array.isArray(result[key]) && !result[key].length) ||
      (key === 'pageIndex' && result[key] === 0)
    ) {
      delete result[key];
    }
  });

  return result;
};

export default getClearParams;
