import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { IColor } from '../../theme';

type IVariant =
  | 'h1Black'
  | 'h1Bold'
  | 'h2Black'
  | 'h2Bold'
  | 'h2Medium'
  | 'h3Bold'
  | 'h3Medium'
  | 'h3Regular'
  | 'p1Bold'
  | 'p1Medium'
  | 'p1Regular'
  | 'p2Bold'
  | 'p2Medium'
  | 'p2MediumUc'
  | 'p2Regular'
  | 'p3Bold'
  | 'p3Medium'
  | 'p3MediumUc'
  | 'p3Regular'
  | 'promo';

type IVariants = {
  [key in IVariant]: string;
};

const variants: IVariants = {
  h1Black: 'mts-h1-black',
  h1Bold: 'mts-h1-bold',
  h2Black: 'mts-h2-black',
  h2Bold: 'mts-h2-bold',
  h2Medium: 'mts-h2-medium',
  h3Bold: 'mts-h3-bold',
  h3Medium: 'mts-h3-medium',
  h3Regular: 'mts-h3-regular',
  p1Bold: 'mts-p1-bold',
  p1Medium: 'mts-p1-medium',
  p1Regular: 'mts-p1-regular',
  p2Bold: 'mts-p2-bold',
  p2Medium: 'mts-p2-medium',
  p2MediumUc: 'mts-p2-medium-uc',
  p2Regular: 'mts-p2-regular',
  p3Bold: 'mts-p3-bold',
  p3Medium: 'mts-p3-medium',
  p3MediumUc: 'mts-p3-medium-uc',
  p3Regular: 'mts-p3-regular',
  promo: 'mts-promo'
};

interface IStyleProps {
  color?: IColor;
  nowrap?: boolean;
}

export interface IProps extends IStyleProps {
  variant: IVariant;
  className?: string;
  onClick?: () => void;
  id?: string;
}

const useStyles = makeStyles(({ getColor }) => ({
  root: ({ color, nowrap }: IStyleProps) => ({
    color: color ? getColor(color) : getColor('constant-greyscale-900'),
    ...(nowrap && { whiteSpace: 'nowrap' }),
    margin: 0,
    maxWidth: 'none'
  })
}));

const Typography: FC<IProps> = ({ children, variant, color, nowrap, className, ...props }) => {
  const classes = useStyles({ color, nowrap });

  return (
    <div className={clsx(classes.root, variants[variant], className)} {...props}>
      {children}
    </div>
  );
};

export default Typography;
