/**
 * Модель для работы с персоналом
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IRole, ISelect, IEmployeesList, IUserEmployees, IDepartments, IPost } from './types';

import UsersService, { IUpdateOrCreateBody } from '../services/UsersService';
import ProtectionsService from '../services/ProtectionsService';

export interface IData {
  roles: IRole[];
  posts: IPost[];
  protections: ISelect[];
  departments: IDepartments[];
}

interface IList {
  data: IEmployeesList[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  item: IUserEmployees | null;
}

const initialState: IState = {
  data: {
    roles: [],
    posts: [],
    protections: [],
    departments: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IUserEmployees): IState {
      return { ...state, item: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          { data: roles },
          { data: posts },
          {
            data: { data: protections }
          },
          { data: departments }
        ] = await Promise.all([
          UsersService.getRoles(),
          UsersService.getPosts(),
          ProtectionsService.getTypeAll({ pageSize: 100 }),
          UsersService.getDepartmentsAll()
        ]);
        this.setData({ roles, posts, protections, departments });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await UsersService.getAllEmployees(params);

        this.setList({
          data: data.employees,
          pagesCount: data.pagesCount
        });
      } catch (error) {
        return error;
      }
    },
    async getItem(id: string) {
      const { data } = await UsersService.getEmployees(id);

      this.setItem(data);
    },
    async updateOrCreate(body: IUpdateOrCreateBody) {
      try {
        await UsersService.updateOrCreateEmployees(body);
      } catch (error) {
        return error;
      }
    },
    async remove(id) {
      try {
        await UsersService.deleteEmployees(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const staffUsers: typeof model = createModel(model);
