/**
 * Модель для работы с анкерами
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import { Dispatch } from '../store';
import { IId, ICoordXY, IBeacon, IBeaconType, IOffice } from './types';
import BeaconsService, { IUpdateOrCreateBody } from '../services/BeaconsService';
import MapsService from '../services/MapsService';

export interface IData {
  types: IBeaconType[];
  offices: IOffice[];
}

interface IState {
  data: IData;
  item: IBeacon | null;
  coordinates: ICoordXY | null;
}

const initialState: IState = {
  data: {
    types: [],
    offices: []
  },
  item: null,
  coordinates: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setItem(state: IState, payload: IBeacon): IState {
      return { ...state, item: payload };
    },
    setCoordinates(state: IState, payload: ICoordXY): IState {
      return { ...state, coordinates: payload };
    },
    reset(): IState {
      return initialState;
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async getData() {
        const [
          { data: types },
          {
            data: { data: offices }
          }
        ] = await Promise.all([
          BeaconsService.getTypeAll({ hideSpecial: true }),
          MapsService.getOffices()
        ]);

        this.setData({ types, offices });
      },
      async getItem(mac: IId) {
        const { data } = await BeaconsService.get(mac);

        this.setItem(data);
      },
      async updateOrCreate(body: IUpdateOrCreateBody) {
        try {
          await BeaconsService.updateOrCreate(body);
        } catch (error) {
          return error;
        }
      },
      async remove(id: IId) {
        try {
          await BeaconsService.remove(id);
        } catch (error) {
          return error;
        }
      },
      focus(center: ICoordXY) {
        // const payload = { zoom: 20, center };

        const payload = { center };

        d.infrastructureMap.setFocus(payload);
      }
    };
  }
};

export const infrastructureBeacons: typeof model = createModel(model);
