import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

const useStyles = makeStyles(({ getColor, getShadow, spacing }) => ({
  default: {
    padding: spacing(2, 3),
    borderRadius: 16,
    boxShadow: getShadow('low')
  },
  card: {},
  form: {},
  current: {
    padding: spacing(1.5, 2),
    borderRadius: 8,
    backgroundColor: getColor('background-secondary')
  },
  click: {
    cursor: '	pointer'
  },
  hover: {
    '&:hover': {
      boxShadow: getShadow('middle')
    }
  },
  icon: {
    marginRight: spacing(2)
  }
}));

enum Variants {
  default = 'default',
  card = 'card',
  form = 'form',
  current = 'current'
}

interface IProps {
  icon: ReactNode;
  content: ReactNode;
  action?: ReactNode;
  variant?: keyof typeof Variants;
  align?: GridProps['alignItems'];
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const DrawerContentItem: FC<IProps> = ({
  icon,
  content,
  action,
  variant = Variants.default,
  align = 'center',
  onClick,
  onMouseOver,
  onMouseOut
}) => {
  const classes = useStyles();

  const isIcon = Boolean(icon);
  const isContent = Boolean(content);
  const isAction = Boolean(action);
  const isOnClick = Boolean(onClick);
  const isOnHover = Boolean(onMouseOver && onMouseOut);

  return (
    <Grid
      id="item"
      container
      alignItems={align}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={clsx(classes[variant], {
        [classes.click]: isOnClick,
        [classes.hover]: isOnHover
      })}
    >
      {isIcon && (
        <Grid id="icon" item xs={2} className={classes.icon}>
          <Grid container>{icon}</Grid>
        </Grid>
      )}
      {isContent && (
        <Grid id="content" item xs={8}>
          {content}
        </Grid>
      )}
      {isAction && (
        <Grid id="action" item xs={1}>
          {action}
        </Grid>
      )}
    </Grid>
  );
};

export default DrawerContentItem;
