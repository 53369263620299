import React, { FC } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../../components_/Content/ContentFilter';
import FormRow from '../../../../../components_/Form/FormRow';
import FormCol from '../../../../../components_/Form/FormCol';
import Button from '../../../../../components_/Fields/Button';

interface IProps {
  onClickCreate: () => void;
}

const Filter: FC<IProps> = ({ onClickCreate }) => {
  return (
    <ContentFilter>
      <FormRow justify="space-between">
        <FormCol xs={4}>
          <></>
        </FormCol>
        <FormCol xs="auto">
          <Button icon="plus" color={'secondary' as ButtonColors} onClick={onClickCreate}>
            Добавить роль
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
