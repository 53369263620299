import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { iRootState } from '../../../store';
import { paths } from '../../Root/paths';

import CameraMarkerListItem from './CameraMarkerListItem';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  layers: state.infrastructureMap.layers
});

type connectedProps = ReturnType<typeof mapState>;

type IProps = connectedProps;

interface ICameraParams {
  id: string;
}

const CameraMarkerList: FC<IProps> = ({ map, layers }) => {
  const { cameras } = map;
  const { isCameras } = layers;

  const cameraEditMatch = useRouteMatch<ICameraParams>(paths.map.office.cameras.edit());

  const cameraParamId = cameraEditMatch ? cameraEditMatch.params.id : null;
  const cameraList = cameraParamId ? cameras.filter(item => item.id !== cameraParamId) : cameras;

  return (
    <>
      {isCameras &&
        cameraList.map(camera => (
          <CameraMarkerListItem key={camera.id} item={camera} hidden={Boolean(cameraParamId)} />
        ))}
    </>
  );
};

const withConnect = connect(mapState);

export default withConnect(CameraMarkerList);
