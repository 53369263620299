import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBreadcrumb, MtsBreadcrumbItem } from '@mts-ds/components-react';

import Typography from '../Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(3, 11, 7)
  },
  breadcrumb: {
    marginTop: spacing(2)
  },
  title: {
    marginTop: spacing(2)
  }
}));

interface IBreadcrumb {
  name: string;
  url?: string;
}

interface IProps {
  title: string;
  breadcrumb?: IBreadcrumb[];
}

const Content: FC<IProps> = ({ children, title, breadcrumb }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleBreadcrumbItemClick = (url: string) => () => {
    history.push(url);
  };

  return (
    <Grid item xs container direction="column" wrap="nowrap" className={classes.root}>
      {breadcrumb && (
        <MtsBreadcrumb size="s" className={classes.breadcrumb}>
          {breadcrumb.map(({ name, url }, index, array) => (
            <MtsBreadcrumbItem
              key={index}
              active={index === array.length - 1}
              {...(url && { onClick: handleBreadcrumbItemClick(url) })}
            >
              {name}
            </MtsBreadcrumbItem>
          ))}
        </MtsBreadcrumb>
      )}
      <Typography variant="h1Bold" className={classes.title}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

export default Content;
