import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../../../store';
import { useAsync, useRequestHandler } from '../../../../../../hooks';
import { paths } from '../../../../../Root/paths';

import Spinner from '../../../../../../components_/Spinner';
import Modal from '../../../../../../components_/Modal';

import Form from './Form';

const mapState = (state: iRootState) => ({
  item: state.infrastructureCameras.item,
  coordinates: state.infrastructureCameras.coordinates,
  map: state.infrastructureMap.map,
  isGeo: state.infrastructureMap.layers.isGeo,
  cameraEdit: state.infrastructureCameras.cameraEdit
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getItem: d.infrastructureCameras.getItem,
    updateOrCreate: d.infrastructureCameras.updateOrCreate,
    remove: d.infrastructureCameras.remove,
    reset: d.infrastructureCameras.reset,
    getMap: d.infrastructureMap.getMap,
    setDefaultMode: d.infrastructureMap.setDefaultMode,
    setLayers: d.infrastructureMap.setLayers,
    setItemEdit: d.infrastructureCameras.setItemEdit
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

interface IParams {
  id: string;
}

const CameraShow: FC<IProps> = ({
  item,
  coordinates,
  map,
  getItem,
  updateOrCreate,
  remove,
  reset,
  getMap,
  setDefaultMode,
  isGeo,
  setItemEdit,
  cameraEdit
}) => {
  const requestHandler = useRequestHandler();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);

  const handleRemove = () => {
    // setDefaultMode();
    history.push(paths.map.office.cameras.root);
  };

  const handleSuccessOrCancel = () => {
    getMap();
    if (id) {
      history.push(paths.map.office.cameras.show(id));
    } else {
      history.push(paths.map.office.cameras.root);
    }
  };

  const [openRemoveModal] = useMtsModal(Modal, {
    header: 'Удалить камеру',
    text: `Вы действительно хотите удалить камеру ${item && item.name}?`,
    buttonTextConfirm: 'Удалить',
    ...(item && {
      onMtsModalConfirm: async () => {
        const error = await remove(item.id);

        requestHandler({
          error,
          entity: 'camera',
          onSuccess: handleRemove,
          isDelete: true
        });
      }
    })
  });

  useEffect(() => {
    return () => {
      setDefaultMode();
      reset();
    };
  }, []);

  useEffect(() => {
    if (id) {
      runGetItem(id);
    }
  }, [id]);

  const isLoading = isLoadingGetItem;
  const isLoadingError = isGetItemLoadingError;

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Form
          // data={data}
          setItemEdit={setItemEdit}
          item={item}
          cameraEdit={cameraEdit}
          coordinates={coordinates}
          map={map}
          isGeo={isGeo}
          updateOrCreate={updateOrCreate}
          onCancel={handleSuccessOrCancel}
          onSuccess={handleSuccessOrCancel}
          onClickRemove={openRemoveModal}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(CameraShow);
