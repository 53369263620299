import React, { FC } from 'react';

import { IProps as IInfrastructureProps } from '../../Infrastructure/List';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Dropdown from '../../../../components_/Fields/Dropdown';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import Button from '../../../../components_/Fields/Button';
import checkFilter from '../../../../utils/checkFilter';
import Input from '../../../../components_/Fields/Input';
import Checkbox from '../../../../components_/Fields/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  data: IInfrastructureProps['data'];
  filter: any;
  onChange: any;
  onReset: () => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  checkBox: {
    marginBottom: '15px'
  }
}));

const Filter: FC<IProps> = ({ data, filter, onChange, onReset }) => {
  const classes = useStyles();
  const { offices, types } = data;
  const { mapId, hardwareType, search, showInactive } = filter;
  const { isShowReset } = checkFilter(filter);

  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));
  const typeOptions = types.map(({ friendlyName, systemName }) => ({
    id: systemName,
    name: friendlyName
  }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Input
            name="search"
            type="search"
            placeholder="MAC-адрес, название"
            clear
            value={search}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <Dropdown
            name="hardwareType"
            label="Тип"
            placeholder="Выберите тип"
            options={typeOptions}
            value={hardwareType}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <Dropdown
            name="mapId"
            label="Расположение"
            placeholder="Выберите значение"
            groupOptions={officeOptions}
            value={mapId}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <Checkbox
            className={classes.checkBox}
            name="showInactive"
            label="Показывать не активные"
            checked={showInactive}
            onMtsChange={onChange}
          />
        </FormCol>
        {/*<FormCol xs={3}>*/}
        {/*  <Dropdown*/}
        {/*    name="hardwareType"*/}
        {/*    label="Протокол"*/}
        {/*    placeholder="Выберите протокол"*/}
        {/*    options={typeOptions}*/}
        {/*    value={hardwareType}*/}
        {/*    onMtsChange={onChange}*/}
        {/*  />*/}
        {/*</FormCol>*/}
        {isShowReset && (
          <FormCol xs={3}>
            <Button color={'ghost' as ButtonColors} onClick={onReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
