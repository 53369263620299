import { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../store';

import config from './config';

const mapState = (state: iRootState) => ({});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    updateSecurableTree: d.securable.updateSecurableTree
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const SecurableTree: FC<IProps> = ({ updateSecurableTree }) => {
  useEffect(() => {
    updateSecurableTree(config);
  }, []);

  return null;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(SecurableTree);
