import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { TopDrawer, TopIcon, TopTypography } from '@top-ui/core';
import { IHeaderMenuPropsDefault } from './types';

const useStyles = makeStyles(({ colors, spacing }) => ({
  back: {
    padding: `${spacing(1)}px 20px`,
    cursor: 'pointer',
    color: colors.greyRaven
  },
  paper: {
    width: '100%'
  }
}));

const Mobile: FC<IHeaderMenuPropsDefault> = ({ children, onClose, open }) => {
  const classes = useStyles();

  return (
    <TopDrawer open={open} anchor="right" onClose={onClose} classes={{ paper: classes.paper }}>
      <Box className={classes.back} display="flex" alignItems="center" onClick={onClose}>
        <TopIcon name="back" size="sm" />
        <TopTypography variant="subtitle1">Назад</TopTypography>
      </Box>

      {children}
    </TopDrawer>
  );
};

export default Mobile;
