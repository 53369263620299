import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormSpy, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { required } from '../../../../../utils/validation';
import useRequestHandler from '../../../../../hooks/useRequestHandler';

import ContentForm from '../../../../../components_/Content/ContentForm';
import Icon from '../../../../../components_/Icon';
import Tooltip from '../../../../../components_/Tooltip';
import FormBase from '../../../../../components_/Form';
import FormRow from '../../../../../components_/Form/FormRow';
import FormCol from '../../../../../components_/Form/FormCol';
import FormInput from '../../../../../components_/Form/FormInput';
import FormButton from '../../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './index';
import FormTree from '../FromTree';
import { IRole } from '../../../../../models/types';
import List from './List';

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    width: 365
  },
  switch: {
    marginBottom: 10
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IValues = IRole;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IValues | null;
  secTreeHierarchy: any;
  isCreate: boolean;
  isEdit: boolean;
  isCopy: boolean;
  treeItem: any;
  onSubmitAction: any;
  getNodeACL: any;
  onCancel: any;
}

const defaultValues: IValues = {
  id: null,
  name: '',
  friendlyName: '',
  actions: {}
};

const Form: FC<IProps> = ({
  data,
  item,
  isCreate,
  isEdit,
  isCopy,
  treeItem,
  onSubmitAction,
  onCancel,
  secTreeHierarchy
}) => {
  const { securableTypes, nodeACL } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...((isEdit || isCopy) && {
      ...item,
      actions: nodeACL
    }),
    ...(isCopy && {
      id: defaultValues.id,
      name: defaultValues.name
    })
  };

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const error = await onSubmitAction(values);

    requestHandler({
      error,
      entity: 'role',
      onSuccess: onCancel,
      isCreate,
      isEdit,
      isCopy
    });
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <ContentForm>
          <FormRow>
            <FormCol xs={3}>
              <FormInput
                name="friendlyName"
                label="Название"
                placeholder="Введите название"
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol xs={3}>
              <FormTree defaultSelected={treeItem} secTreeHierarchy={secTreeHierarchy} />
            </FormCol>
            <FormCol xs={9}>
              {treeItem && <List key={treeItem} treeItem={treeItem} list={securableTypes} />}
              {/*<pre>{JSON.stringify(values, undefined, 2)}</pre>*/}
              <Grid container justify="center" className={classes.buttons}>
                <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
                  Отмена
                </MtsButton>
                <FormButton>{submitButtonTitle}</FormButton>
              </Grid>
            </FormCol>
          </FormRow>
        </ContentForm>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
