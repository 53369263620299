import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../../../store';
import { useAsync, useRequestHandler } from '../../../../../../hooks';
import { paths } from '../../../../../Root/paths';

import Spinner from '../../../../../../components_/Spinner';
import Modal from '../../../../../../components_/Modal';

import Form from './Form';

const mapState = (state: iRootState) => ({
  data: state.infrastructureBeacons.data,
  item: state.infrastructureBeacons.item,
  coordinates: state.infrastructureBeacons.coordinates,
  map: state.infrastructureMap.map,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.infrastructureBeacons.getData,
    getItem: d.infrastructureBeacons.getItem,
    updateOrCreate: d.infrastructureBeacons.updateOrCreate,
    remove: d.infrastructureBeacons.remove,
    reset: d.infrastructureBeacons.reset,
    getMap: d.infrastructureMap.getMap,
    setDefaultMode: d.infrastructureMap.setDefaultMode,
    setLayers: d.infrastructureMap.setLayers
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

interface IParams {
  mac: string;
}

const AreaShow: FC<IProps> = ({
  data,
  item,
  coordinates,
  map,
  getItem,
  getData,
  updateOrCreate,
  remove,
  reset,
  getMap,
  setDefaultMode,
  isGeo
}) => {
  const requestHandler = useRequestHandler();
  const history = useHistory();
  const { mac } = useParams<IParams>();
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);

  const handleRemove = () => {
    // setDefaultMode();
    history.push(paths.map.office.infrastructure.root);
  };

  const handleSuccessOrCancel = () => {
    getMap();
    if (mac) {
      history.push(paths.map.office.infrastructure.show(mac));
    } else {
      history.push(paths.map.office.infrastructure.root);
    }
  };

  const [openRemoveModal] = useMtsModal(Modal, {
    header: 'Удалить анкер',
    text: 'Вы действительно хотите удалить анкер?',
    buttonTextConfirm: 'Удалить',
    ...(item && {
      onMtsModalConfirm: async () => {
        const error = await remove(item.macAddress);

        requestHandler({
          error,
          entity: 'beacon',
          onSuccess: handleRemove,
          isDelete: true
        });
      }
    })
  });

  useEffect(() => {
    return () => {
      setDefaultMode();
      reset();
    };
  }, []);

  useEffect(() => {
    getData();
    if (mac) {
      runGetItem(mac);
    }
  }, [mac]);

  const isLoading = isLoadingGetItem;
  const isLoadingError = isGetItemLoadingError;

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Form
          data={data}
          item={item}
          coordinates={coordinates}
          map={map}
          isGeo={isGeo}
          updateOrCreate={updateOrCreate}
          onCancel={handleSuccessOrCancel}
          onSuccess={handleSuccessOrCancel}
          onClickRemove={openRemoveModal}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(AreaShow);
