import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';
import { useAsync } from '../../../hooks';

import Content from '../../../components_/Content';
import Spinner from '../../../components_/Spinner';
import EmptyState from '../../../components_/EmptyState';

import Filter from './Filter';
import Image from './Image';
import Map from './Map';

const mapState = (state: iRootState) => ({
  data: state.reportHeatMap.data,
  filter: state.reportHeatMap.filter,
  heatMap: state.reportHeatMap.heatMap
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.reportHeatMap.getData,
    setFilter: d.reportHeatMap.setFilter,
    resetFilter: d.reportHeatMap.resetFilter,
    getHeatMap: d.reportHeatMap.getHeatMap,
    resetHeatMap: d.reportHeatMap.resetHeatMap,
    reset: d.reportHeatMap.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const HeatMap: FC<IProps> = ({
  getData,
  setFilter,
  resetFilter,
  getHeatMap,
  resetHeatMap,
  reset,
  data,
  filter,
  heatMap
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetHeatMap, isLoadingGetHeatMap, isGetHeatMapLoadingError] = useAsync(getHeatMap);

  useEffect(() => {
    runGetData();

    return () => {
      reset();
    };
  }, []);

  const isLoading = isLoadingGetData || isLoadingGetHeatMap;
  const isLoadingError = isGetDataLoadingError || isGetHeatMapLoadingError;

  const title = 'Тепловая карта';

  return (
    <Content
      breadcrumb={[{ name: 'Отчёты', url: paths.reports.root }, { name: title }]}
      title={title}
    >
      {!isLoadingGetData && !isGetDataLoadingError && (
        <>
          <Filter
            setFilter={setFilter}
            getHeatMap={runGetHeatMap}
            data={data}
            filter={filter}
            isLoadingGetHeatMap={isLoadingGetHeatMap}
            heatMap={heatMap}
            resetFilter={resetFilter}
            resetHeatMap={resetHeatMap}
          />
          {!isLoading && !isLoadingError && (
            <>
              {!heatMap && <EmptyState variant="generateReport" />}
              {/* {heatMap && <Image heatMap={heatMap} />} */}
              {heatMap && <Map heatMap={heatMap} />}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(HeatMap);
