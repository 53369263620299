import React, { FC } from 'react';
import moment from 'moment';

import { IInfrastructure } from '../../../../models/types';

import Table from '../../../../components_/Table';
import TableColText from '../../../../components_/Table/TableColText';

import ListItemType from './ListItemType';
import ListItemCharge from './ListItemCharge';

interface IProps {
  list: IInfrastructure[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      name: <TableColText>{item.name}</TableColText>,
      macAddress: <TableColText>{item.macAddress}</TableColText>,
      // hardwareTypeSimple: (
      //   <TableColText>
      //     <ListItemType type={item.hardwareType} />
      //   </TableColText>
      // ),
      hardwareTypeFriendly: (
        <TableColText>
          <ListItemType type={item.hardwareType} text={item.hardwareTypeFriendly} />
        </TableColText>
      ),
      instanceId: <TableColText>{item.instanceId}</TableColText>,
      charge: (
        <TableColText>
          <ListItemCharge charge={item.charge} />
        </TableColText>
      ),
      queryTime: (
        <TableColText>
          {item.queryTime &&
            moment(item.queryTime)
              .startOf('hour')
              .fromNow()}
        </TableColText>
      ),
      isActive: <TableColText>{item.isActive ? 'Да' : 'Нет'}</TableColText>,
      location: (
        <TableColText>{[item.officeName, item.mapName].filter(str => str).join(', ')}</TableColText>
      )
    }
  }));

  const cols = [
    {
      title: 'Название',
      field: 'name'
    },
    {
      title: 'MAC-адрес',
      field: 'macAddress'
    },
    {
      title: 'Тип',
      field: 'hardwareTypeFriendly'
    },
    // {
    //   title: 'Протокол',
    //   field: 'hardwareType'
    // },
    {
      title: 'Instance ID',
      field: 'instanceId'
    },
    {
      title: 'Заряд батареи',
      field: 'charge'
    },
    {
      title: 'Был в сети',
      field: 'queryTime'
    },
    {
      title: 'Активность',
      field: 'isActive'
    },
    {
      title: 'Расположение',
      field: 'location'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
