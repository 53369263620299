import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { MtsBadge } from '@mts-ds/components-react';
import Typography from '../../../components_/Typography';

import { IArea } from '../../../models/types';

import MarkerTooltip from './components/MarkerTooltip';

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

interface IProps {
  area: IArea;
}

const AreaListItemTooltip: FC<IProps> = ({ area }) => {
  const { name, descr } = area;

  const classes = useStyles();

  return (
    <MarkerTooltip>
      <Grid className={classes.content}>
        <MtsBadge size="s">Зона</MtsBadge>
        <Typography variant="p2Bold">{name}</Typography>
        {descr && (
          <Typography variant="p3Regular" color="text-secondary">
            {descr}
          </Typography>
        )}
      </Grid>
    </MarkerTooltip>
  );
};

export default AreaListItemTooltip;
