/**
 * Модель для работы с правилами уведомлений
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import UsersService, { IShortUsersParams } from '../services/UsersService';
import NotificationsService, { ITemplateBody } from '../services/NotificationsService';
import { defaultPageSize } from '../constants';
import {
  IShortUser,
  INotificationType,
  INotificationTarget,
  INotificationVariable,
  INotificationSetting
} from './types';

const queryParams = new URLSearchParams(window.location.search);

interface IGetSettingsNotification {
  page?: number;
  sync?: boolean;
}

interface IList {
  data: INotificationSetting[];
  total: number | null;
}

export interface IData {
  users: IShortUser[];
  usersFilter: IShortUser[];
  notificationTypes: INotificationType[];
  notificationTargets: INotificationTarget[];
  notificationVariables: INotificationVariable[];
}

interface IFilter {
  notificationType: string;
  notificationTarget: string;
  employeesList: string;
}

interface IState {
  data: IData;
  item: INotificationSetting | null;
  list: IList;
  filter: IFilter;
}

const initialFilter: IFilter = {
  notificationType: '',
  notificationTarget: '',
  employeesList: ''
};

const initialState: IState = {
  data: {
    users: [],
    usersFilter: [],
    notificationTypes: [],
    notificationTargets: [],
    notificationVariables: []
  },
  item: null,
  list: {
    data: [],
    total: null
  },
  filter: {
    notificationType: queryParams.get('notificationType') || initialFilter.notificationType,
    notificationTarget: queryParams.get('notificationTarget') || initialFilter.notificationTarget,
    employeesList: queryParams.get('employeesList') || initialFilter.employeesList
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload): IState {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    },
    setSettingNotification(state: IState, payload: INotificationSetting): IState {
      return {
        ...state,
        item: payload
      };
    },
    setSettingsNotification(state: IState, payload: IList): IState {
      return {
        ...state,
        list: payload
      };
    },
    setFilter(state: IState, payload): IState {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload
        }
      };
    },
    resetFilter(state: IState): IState {
      return {
        ...state,
        filter: initialFilter
      };
    },
    reset(): IState {
      return initialState;
    }
  },
  effects: {
    async getData() {
      const [
        { data: users },
        { data: notificationTypes },
        { data: notificationTargets },
        { data: notificationVariables }
      ] = await Promise.all([
        UsersService.getAllShort(),
        NotificationsService.getTypesAll(),
        NotificationsService.getTargetsAll(),
        NotificationsService.getVariablesAll()
      ]);

      this.setData({ users, notificationTypes, notificationTargets, notificationVariables });
    },
    async getDataUsersFilter(filter: IShortUsersParams) {
      const { data: usersFilter } = await UsersService.getAllShort(filter);

      this.setData({ usersFilter });
    },
    async getSettingNotification(id: number) {
      const { data } = await NotificationsService.getTemplate(id);

      this.setSettingNotification(data);
    },
    async getSettingsNotification(
      { page = 0, sync = false }: IGetSettingsNotification = {},
      rootState: iRootState
    ) {
      const { filter, list } = rootState.settingsNotifications;

      const pageSize = sync ? list.data.length : defaultPageSize;

      const params = {
        pageIndex: page,
        pageSize,
        ...filter
      };

      const {
        data: { data, total }
      } = await NotificationsService.getTemplatesAll(params);

      const payloadData = page ? [...list.data, ...data] : data;

      const payload = {
        data: payloadData,
        total
      };

      this.setSettingsNotification(payload);
    },
    async updateOrCreateSettingsNotification(body: ITemplateBody) {
      try {
        await NotificationsService.updateOrCreateTemplate(body);
      } catch (error) {
        return error;
      }
    },
    async deleteSettingNotification(id: number) {
      try {
        await NotificationsService.deleteTemplate(id);

        this.getSettingsNotification({ sync: true });
      } catch (error) {
        return error;
      }
    }
  }
};

export const settingsNotifications: typeof model = createModel(model);
