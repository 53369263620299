import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsDropdown, MtsDropdownOption } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../store';
import { IId } from '../../../models/types';

const useStyles = makeStyles(() => ({
  select: {
    width: '400px',
    borderRadius: '8px',
    dropShadow: '(0px 4px 16px rgba(0, 0, 0, 0.08))',
    '&:host .mts-input__control-container': {
      borderRadius: '8px'
    }
  }
}));

interface IOption {
  id: IId;
  name: string;
}

const mapState = (state: iRootState) => ({
  maps: state.infrastructureMaps.list,
  map: state.infrastructureMap.map,
  office: state.infrastructureOffices.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getMap: d.infrastructureMap.getMap,
    getMaps: d.infrastructureMaps.getMaps,
    resetMaps: d.infrastructureMaps.resetMaps
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Maps: FC<IProps> = ({ getMap, getMaps, resetMaps, maps, map, office }) => {
  const { id: mapId } = map;

  const classes = useStyles();

  useEffect(() => {
    if (office) {
      getMaps(office.id);
    }
  }, [office]);

  useEffect(() => {
    return () => {
      resetMaps();
    };
  }, []);
  const onChange = (event: any) => {
    const { name, value } = event.target;
    getMap(value);
  };

  const renderOptions = (options: IOption[]) =>
    options.map(({ id, name }) => (
      <MtsDropdownOption key={id} value={id as string}>
        {name}
      </MtsDropdownOption>
    ));

  return (
    <Grid item>
      <MtsDropdown className={classes.select} value={mapId as string} onMtsChange={onChange}>
        <Fragment>{renderOptions(maps)}</Fragment>
      </MtsDropdown>
    </Grid>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Maps);
