import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { iRootState, Dispatch } from '../../../../../../store';

import Spinner from '../../../../../../components_/Spinner';
import NotificationDrawerList from '../../../../../../components_/Notification/NotificationDrawerList';

import Card from './Card';
import Attributes from './Attributes';

const mapState = (state: iRootState) => ({
  isLoadingUser: state.loading.effects.infrastructureUsers.getUser,
  isLoadingData: state.loading.effects.infrastructureUsers.getData,
  isLoadingNotifications: state.loading.effects.infrastructureUsers.getNotifications,
  user: state.infrastructureUsers.item,
  data: state.infrastructureUsers.data,
  notifications: state.infrastructureUsers.notifications,
  isTabularNumberUsed: state.admin.isTabularNumberUsed,
  isPulseUsed: state.admin.isPulseUsed,
  isProtectionsUsed: state.admin.isProtectionsUsed
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getUser: d.infrastructureUsers.getUser,
    getData: d.infrastructureUsers.getData,
    getNotifications: d.infrastructureUsers.getNotifications
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IParams {
  userName: string;
}

const Show: FC<IProps> = ({
  getUser,
  getData,
  getNotifications,
  isLoadingUser,
  isLoadingData,
  isLoadingNotifications,
  user,
  data,
  notifications,
  isTabularNumberUsed,
  isPulseUsed,
  isProtectionsUsed
}) => {
  const { userName } = useParams<IParams>();

  useEffect(() => {
    getUser(userName);
    getData();
  }, [userName]);

  useEffect(() => {
    if (user) {
      const { id } = user;

      getNotifications(id);
    }
  }, [user ? user.id : null]);

  const isLoading = isLoadingUser || isLoadingData || isLoadingNotifications;

  return (
    <>
      <Card isLoading={isLoading} user={user} />
      {isLoading && <Spinner />}
      {!isLoading && user && (
        <>
          <Attributes
            user={user}
            data={data}
            isTabularNumberUsed={isTabularNumberUsed}
            isPulseUsed={isPulseUsed}
            isProtectionsUsed={isProtectionsUsed}
          />
          <NotificationDrawerList notifications={notifications} />
        </>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Show);
