import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as SuccessIcon } from '../../../../components_/Fields/DatePicker/icons/input-success.svg';
import { ReactComponent as ErrorIcon } from '../../../../components_/Fields/DatePicker/icons/input-error.svg';
import { ReactComponent as CloseIcon } from '../../../../components_/Fields/DatePicker/icons/input-close.svg';

import styles from './Input.module.css';

export interface ICustomInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  clearable?: boolean;
  isValid?: boolean;
  size?: 's' | 'l';
  label?: string;
  onClear?: () => void;
  error?: string;
  maxLength?: number;
  inputMode?:
    | 'search'
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  customIcons?: JSX.Element;
  description?: string;
  event?: any;
}

const Input = React.forwardRef<HTMLInputElement, ICustomInputProps>(
  (
    {
      className,
      size,
      label,
      placeholder,
      error,
      customIcons,
      clearable = false,
      isValid,
      onClear,
      value,
      disabled,
      onChange,
      onFocus,
      onBlur,
      maxLength,
      style,
      inputMode = 'text',
      description,
        event,
      ...props
    },
    ref
  ) => {
    const [inputFocused, setInputFocused] = useState(false);
    const [iconsLength, setIconsLength] = useState(0);
    const iconsRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        // tslint:disable-next-line:prettier
      setIconsLength(iconsRef.current?.children.length || 0);
    }, [inputFocused, isValid, value, clearable]);

      const dateMask = (date: any) => {
          const v = date.replace(/\D/g,'').slice(0, 8);
          if (v.length >= 5) {
              return `${v.slice(0,2)}.${v.slice(2,4)}.${v.slice(4)}`;
          }
          else if (v.length >= 3) {
              return `${v.slice(0,2)}.${v.slice(2)}`;
          }
          return v
      }

    return (
      <>
        {label && <label className={styles.label}>{label}</label>}
        <div
          className={clsx(
            styles.wrapper,
            size && styles[`size-${size}`],
            error && styles.wrapperError,
            disabled && styles.disabled,
            className
          )}
          data-testid="input-wrapper"
        >
          <input
            inputMode={inputMode}
            data-testid="input"
            placeholder={placeholder}
            className={styles.input}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onFocus={(e) => {
              setInputFocused(true);
                // tslint:disable-next-line:no-unused-expression
              onFocus && onFocus(e);
            }}
            onBlur={(e) => {
              setInputFocused(false);
                // tslint:disable-next-line:no-unused-expression
              onBlur && onBlur(e);
            }}
            value={dateMask(value)}
            ref={ref}
            style={{ paddingRight: `${iconsLength * 24}px`, ...style }}
            {...props}
          />
          <div ref={iconsRef} className={styles.icons}>
            {customIcons}
            {!inputFocused &&
              isValid !== undefined &&
              (isValid ? (
                <SuccessIcon data-testid="input-valid-icon" />
              ) : (
                <ErrorIcon
                  data-testid="input-error-icon"
                  onMouseDown={onClear}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            {clearable && inputFocused && value && (
              <CloseIcon
                data-testid="input-clear-icon"
                onMouseDown={onClear}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          {error && <span className={styles.error}>{error}</span>}
          {description && !error && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
      </>
    );
  }
);

Input.displayName = 'Input';

export default Input;
