import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import Icon from '../../../components_/Icon';
import IconWrapper from '../../../components_/Icon/IconWrapper';

import { Dispatch } from '../../../store';
import { IArea } from '../../../models/types';
import { paths } from '../../../routes/Root/paths';

import Typography, { IProps as ITypographyProps } from '../../Typography';

import DrawerContentItem from '../DrawerContentItem';

const useStyles = makeStyles(() => ({
  iconWrapper: ({ mapColor }: IStyleProps) => ({
    border: `2px solid ${mapColor}`
  }),
  iconSvg: ({ mapColor }: IStyleProps) => ({
    color: mapColor
  })
}));

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setHover: d.infrastructureAreas.setHover,
    resetHover: d.infrastructureAreas.resetHover
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

enum Variants {
  default = 'default',
  card = 'card',
  form = 'form'
}

type IVariants = {
  [key in Variants]: {
    typographyNameVariant: ITypographyProps['variant'];
    isClick: boolean;
    isHover: boolean;
    isDescription: boolean;
  };
};

interface IStyleProps {
  mapColor: string;
}

type IProps = {
  isLoading?: boolean;
  area: IArea | null;
  color?: string;
  variant?: keyof typeof Variants;
  action?: ReactNode;
} & connectedProps;

const DrawerContentItemArea: FC<IProps> = ({
  setHover,
  resetHover,
  isLoading,
  area,
  color,
  variant = Variants.default,
  action
}) => {
  const { id, name = 'Новая зона', descr = '', mapColor = '' } = area || {};

  const classes = useStyles({ mapColor: color || mapColor });
  const history = useHistory();

  const variants: IVariants = {
    default: {
      typographyNameVariant: 'p2Bold',
      isClick: true,
      isHover: true,
      isDescription: true
    },
    card: {
      typographyNameVariant: 'p1Bold',
      isClick: false,
      isHover: false,
      isDescription: true
    },
    form: {
      typographyNameVariant: 'h3Bold',
      isClick: false,
      isHover: false,
      isDescription: false
    }
  };

  const handleClick = () => {
    history.push(paths.map.office.areas.show(id));
  };

  const onMouseOver = () => {
    setHover(area);
  };

  const onMouseOut = () => {
    resetHover();
  };

  const icon = isLoading ? (
    <Skeleton variant="rect" width={48} height={48} />
  ) : (
    <IconWrapper variant="square" size="l" className={classes.iconWrapper}>
      <Icon name="texture" className={classes.iconSvg} />
    </IconWrapper>
  );

  const content = (
    <>
      <Typography variant={variants[variant].typographyNameVariant}>
        {isLoading ? <Skeleton /> : name}
      </Typography>
      {variants[variant].isDescription && (
        <Typography variant="p2Regular" color="text-secondary">
          {isLoading ? <Skeleton /> : descr}
        </Typography>
      )}
    </>
  );

  return (
    <DrawerContentItem
      variant={variant}
      icon={icon}
      content={content}
      action={isLoading ? undefined : action}
      onClick={variants[variant].isClick ? handleClick : undefined}
      onMouseOver={variants[variant].isHover ? onMouseOver : undefined}
      onMouseOut={variants[variant].isHover ? onMouseOut : undefined}
    />
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(DrawerContentItemArea);
