import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input from '../../../components_/Fields/Input';

import { useDebounce } from '../../../hooks';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: spacing(3, 0)
  }
}));

interface IProps {
  getUsers: any;
}

const FormUserList: FC<IProps> = ({ getUsers }) => {
  const classes = useStyles();
  const [fullTextSearch, setFullTextSearch] = useState('');

  const debounced = useDebounce(fullTextSearch, 500);

  useEffect(() => {
    getUsers({ fullTextSearch });
  }, [debounced]);

  const handleChange = (event: any) => {
    setFullTextSearch(event.target.value);
  };

  return (
    <Input
      type="search"
      placeholder="Найти сотрудника"
      value={fullTextSearch}
      clear
      onMtsChange={handleChange}
      className={classes.root}
    />
  );
};

export default FormUserList;
