import React, {FC} from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {Map as LeafletMap} from 'react-leaflet';
import { makeStyles } from '@material-ui/core';

import 'leaflet/dist/leaflet.css';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';

import WMSTileLayer from './WMSTileLayer';
import ZoomControl from './ZoomControl';
// import ImageOverlay from './ImageOverlay';
import List from './List';
import OfficeMarkerList from './OfficeMarkerList';
import UserMarkerList from './UserMarkerList';
import BeaconMarkerList from './BeaconMarkerList';
import AreaList from './AreaList';
import Edit from './Edit';
import HeatmapLayer from './HeatmapLayer';
import LayersControl from './LayersControl';

// Исправляет штатные иконки
// --------------------------------------------------
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import EditPlan from "./EditPlan";
import CameraMarkerList from "./CameraMarkerList";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
// --------------------------------------------------

const useStyles = makeStyles(({ getColor, getShadow }) => ({
  root: {
    zIndex: 0,
    height: '100%',
    marginLeft: ({ drawerIsExpand }: IStyleParams) => (drawerIsExpand ? 380 : 48),
    backgroundColor: getColor('constant-greyscale-0'),
    // Инструмент редактирования/создания зон
    '& .leaflet-draw': {
      display: 'none'
    },
    // Маркеры редактирования зон
    '& .leaflet-editing-icon': {
      backgroundColor: getColor('constant-apple-dark'),
      borderColor: getColor('constant-apple-dark'),
      width: '16px  !important',
      height: '16px  !important'
    },
    '& .leaflet-edit-move': {
      backgroundColor: getColor('constant-greyscale-0'),
      borderColor: getColor('constant-greyscale-400')
    },
    '& .leaflet-image-layer': {
      boxShadow: getShadow('low')
    }
  },
  list:{
    position: 'absolute',
    zIndex: 0,
    top: 60,
     left: ({ drawerIsExpand }: IStyleParams) => (drawerIsExpand ? 390 : 58),
    boxShadow: '0px 0px 16px rgb(0 0 0 / 8%), 0px 4px 16px rgb(0 0 0 / 8%)'
  }
}));

const mapState = (state: iRootState) => ({
  control: state.infrastructureMap.control,
  isGeo: state.infrastructureMap.layers.isGeo,
  drawerIsExpand: state.drawer.isExpand
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {};
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IStyleParams {
  drawerIsExpand: boolean;
}

const Map: FC<IProps> = ({ control, drawerIsExpand, isGeo }) => {
  const { zoom, center } = control;

  const classes = useStyles({ drawerIsExpand });

  // tslint:disable-next-line:prettier
  const isOffices = useRouteMatch(paths.map.root)?.isExact;
  const isOfficeCreate = useRouteMatch(paths.map.office.create)?.isExact;
  const isOfficeUpdate = useRouteMatch(paths.map.office.edit())?.isExact;
  const isPlaneCreate = useRouteMatch(paths.map.office.plan.create)?.isExact;
  const isPlaneUpdate = useRouteMatch(paths.map.office.plan.edit())?.isExact;
  return (
      <>
    <LeafletMap
        key={isGeo ? 'geo' : 'local'} // leaflet требует перезагрузки при смене crs
      center={center}
      zoom={zoom}
      maxZoom={30}
      minZoom={3}
      animate={true}
      duration={1}
      zoomSnap={0}
      crs={isGeo ? L.CRS.EPSG3857 : L.CRS.Simple}
      zoomControl={false}
      attributionControl={false}
      className={classes.root}
      maxBounds= {[[-86.5, -180], [85, 180]]}
      worldCopyJump={isGeo}
    >
      {isGeo && <WMSTileLayer />} {/* Карта */}
      <ZoomControl /> {/* Инструмент управления зумом */}
      {isOffices ? (
        <>
          <OfficeMarkerList /> {/* Маркеры офисов */}
          {/*<Edit />*/}
        </>
      ) : (
        <>
          <EditPlan />
          {(!isPlaneCreate && !isPlaneUpdate) && <>
            <LayersControl /> {/* Переключение плана */}
          {/*<List /> /!* Список планов *!/*/}
          {/*{(!isPlaneCreate) && <ImageOverlay />} /!* План *!/*/}
          <UserMarkerList /> {/* Маркеры сотрудников */}
          <BeaconMarkerList /> {/* Маркеры анкеров */}
          <CameraMarkerList /> {/* Маркеры камер */}
          <AreaList /> {/* Зоны */}
          {/*<Edit /> /!* Инструмент рисования зон *!/*/}
          <HeatmapLayer /> {/* Тепловая карта */}
            </>}
        </>
      )}
      <Edit />
    </LeafletMap>
        {(!isOffices && !isOfficeCreate && !isOfficeUpdate && !isPlaneCreate && !isPlaneUpdate) && <div className={classes.list}><List /></div>} {/* Список планов */}
        </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Map);
