import React, { FC } from 'react';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@top-ui/core';
import Default from './Default';
import Mobile from './Mobile';
import { IHeaderMenuPropsDefault } from './types';

const HeaderMenu: FC<IHeaderMenuPropsDefault> = ({ children, ...restOfProps }) => {
  const theme = useTheme();
  const variant = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : null;

  let Component = Default;

  if (variant === 'mobile') {
    Component = Mobile;
  }

  return <Component {...restOfProps}>{children}</Component>;
};

export default HeaderMenu;
