import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { getColor } from '../../../theme';

type ISize = 's' | 'm';

type ISizes = {
  [key in ISize]: {
    size: number;
    borderSize: number;
    triangleSize: number;
    fontSize: number;
  };
};

const sizes: ISizes = {
  s: {
    size: 40,
    borderSize: 5,
    triangleSize: 10,
    fontSize: 14
  },
  m: {
    size: 65,
    borderSize: 10,
    triangleSize: 15,
    fontSize: 16
  }
};

const useStyles = makeStyles(({ spacing }) => ({
  root: ({ size }: IStyleProps) => ({
    backgroundColor: getColor('constant-greyscale-0'),
    border: `${sizes[size].borderSize}px solid ${getColor('constant-blueberry-light')}`,
    borderRadius: '50%',
    width: sizes[size].size,
    height: sizes[size].size,
    color: getColor('constant-blueberry-light'),
    fontWeight: 500,
    fontSize: sizes[size].fontSize
  }),
  triangle: ({ size }: IStyleProps) => ({
    border: `${sizes[size].borderSize}px solid transparent`,
    borderTop: `${sizes[size].triangleSize}px solid ${getColor('constant-blueberry-light')}`,
    marginTop: spacing(-0.2)
  })
}));

interface IStyleProps {
  size: ISize;
}

interface IProps extends IStyleProps {
  index: number;
}

const OfficeMarkerIcon: FC<IProps> = ({ size, index }) => {
  const classes = useStyles({ size });

  return (
    <>
      <Grid item container justify="center" alignItems="center" className={classes.root}>
        {index}
      </Grid>
      <Grid container justify="center">
        <Grid className={classes.triangle} />
      </Grid>
    </>
  );
};

export default OfficeMarkerIcon;
