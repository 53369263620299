/**
 * Сервис для работы с уведомлениями
 */

import axios from 'axios';

import { IId } from '../models/types';
import getFormData from '../utils/getFormData';
import { pageSize } from '../constants/pagination';

export interface IGetAllParams {
  notificationType: string;
  notificationTarget: string;
  employeesList: string | undefined;
  from: string;
  to: string;
  pageIndex: number;
}

export interface IBody {
  text: string;
  employeesList: [];
}

export interface IUpdateOrCreateTemplate {
  id: IId;
  notificationClass: string;
  name: string;
  target: string;
  channelType: string;
  template: string;
  employeesList: [];
  isNeedConfirmation: boolean;
  isNeedSound: boolean;
  isNeedVibro: boolean;
}

// tslint:disable-next-line: no-empty-interface
export interface ITemplateBody extends IUpdateOrCreateTemplate {}

export interface IParams {
  areaId?: IId;
  employeesList?: IId;
  needConfirmRead?: boolean;
  notificationTarget?: string;
  pageIndex?: number;
  pageSize?: number;
}

export interface IParamsTemplate {
  areaId?: IId;
  employeesList?: IId;
  needConfirmRead?: boolean;
  notificationTarget?: string;
  pageIndex?: number;
  pageSize?: number;
}

export interface IGetTemplateAllParams {
  notificationType: string;
  notificationTarget: string;
  employeesList: string;
  pageIndex: number;
}

const url = '/notification';

const NotificationsService = {
  getAll: (params: IGetAllParams) =>
    axios.get(`${url}/messages`, { params: { pageSize, ...params } }),
  getFullAll: (params: IParams = {}) => axios.get(`${url}/messages`, { params }),
  getTypesAll: () => axios.get(`${url}/types`),
  getVariablesAll: () => axios.get(`${url}/variables`),
  getTargetsAll: () => axios.get(`${url}/targets`),
  getTemplatesAll: (params: IParamsTemplate = {}) => axios.get(`${url}/templates`, { params }),
  getTemplateAll: (params: IGetTemplateAllParams) =>
    axios.get(`${url}/templates`, { params: { pageSize, ...params } }),
  getTemplate: (id: IId) => axios.get(`${url}/template?id=${id}`),
  send: (body: IBody) => axios.post(`${url}/informational`, getFormData(body)),
  confirm: (uid: IId) => axios.post(`${url}/confirm?uid=${uid}`),
  updateOrCreateTemplate: (body: IUpdateOrCreateTemplate) => axios.post(`${url}/template`, body),
  deleteTemplate: (id: IId) => axios.delete(`${url}/template?id=${id}`)
};

export default NotificationsService;
