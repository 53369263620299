import React, { FC } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Input from '../../../../components_/Fields/Input';
import Button from '../../../../components_/Fields/Button';
import Dropdown from '../../../../components_/Fields/Dropdown';
import { IData } from '../../../../models/equipmentDevices';
import checkFilter from '../../../../utils/checkFilter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    float: 'right',
    marginBottom: '0.25rem'
  },
  resetButton: {
    marginBottom: '0.25rem'
  }
}));
interface IProps {
  data: IData;
  filter: any;
  onChange: any;
  onClickCreate: () => void;
  onReset: () => void;
}

const Filter: FC<IProps> = ({ data, filter, onChange, onClickCreate, onReset }) => {
  const { fullTextSearch, ProtectionClassId } = filter;
  const { isShowReset } = checkFilter(filter);
  const classes = useStyles();
  const { types } = data;

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Input
            name="fullTextSearch"
            type="search"
            label=""
            placeholder="ID, тип устройства, владелец"
            clear
            value={fullTextSearch}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <Dropdown
            name="ProtectionClassId"
            label="Тип устройства"
            placeholder="Выберите тип устройства"
            value={ProtectionClassId}
            options={types}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          {isShowReset && (
            <Button
              className={classes.resetButton}
              color={'ghost' as ButtonColors}
              onClick={onReset}
            >
              Сбросить фильтры
            </Button>
          )}
        </FormCol>

        <FormCol alignContent="flex-end" xs={3}>
          <Button
            className={classes.addButton}
            icon="plus"
            color={'secondary' as ButtonColors}
            onClick={onClickCreate}
          >
            Добавить носимое устройство
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
