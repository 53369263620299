import React, { FC } from 'react';
import { MtsFormGroup, MtsLabel } from '@mts-ds/components-react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '../../../Tooltip';
import Icon from '../../../Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    verticalAlign: 'middle'
  },
  group: {
    position: 'relative'
  },
  wrapper: {
    paddingLeft: '5px'
  }
}));

export interface IPropsText {
  label?: string;
  description?: string;
  tooltip?: string;
}

type IProps = IPropsText & typeof MtsFormGroup.defaultProps;

const FormGroup: FC<IProps> = ({ children, label, tooltip, description, ...props }) => {
  const classes = useStyles();
  return (
    <MtsFormGroup className={classes.group} {...props}>
      {label && (
        <MtsLabel className={classes.root}>
          {label}
          {tooltip && (
            <Tooltip label={tooltip}>
              <Grid item xs container className={classes.wrapper}>
                <Icon name="info" size="s" color="icon-tertiary" />
              </Grid>
            </Tooltip>
          )}
        </MtsLabel>
      )}
      {children}
      <MtsFormGroup slot="bottom">
        <MtsLabel>{description}</MtsLabel>
      </MtsFormGroup>
    </MtsFormGroup>
  );
};

export default FormGroup;
