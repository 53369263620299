import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Dispatch, iRootState } from '../../store';
import isSatisfied from '../../utils/isSatisfied';
import ErrorPage from '../../components/ErrorPage';
import Loading from '@top-ui/core/lib/TopTable/Loading';

const mapState = (state: iRootState) => ({
  auth: state.auth.isAuth,
  rights: state.securable.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    onLogout: d.auth.onLogout
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps &
  RouteProps & {
    component: any;
    /**
     * UID страницы
     */
    requirement?: {};
  };

const PrivateRoute: FC<IProps> = ({
  component: Component,
  auth,
  rights,
  requirement = {},
  onLogout,
  ...rest
}) => {
  /**
   * Проверяем, разрешен ли доступ пользователю по данному руту
   */
  // const checkAccess = (): boolean => requirement.includes(right);
  const checkAccess = (): boolean => {
    return isSatisfied({ ...requirement, ...{ action: 'Visibility' } }, rights);
  };

  const handleForbiddenActionClick = () => {
    onLogout();
  };

  const render = (props: any) => {
    if (auth && !rights.length) {
      return <Loading />;
    }

    if (auth && checkAccess()) {
      return <Component {...props} />;
    }

    return (
      <ErrorPage
        text="У Вас нет доступа к разделу"
        description="Смените учётную запись или вернитесь на главную"
        action="Выйти"
        actionOnClick={handleForbiddenActionClick}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(PrivateRoute);
