import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { paths } from '../../routes/Root/paths';

import ErrorPage from '../../components/ErrorPage';

const NotFound: FC = () => {
  const history = useHistory();

  const handleActionClick = () => {
    history.push(paths.root);
  };

  return (
    <ErrorPage
      code={404}
      text="Такой страницы не существует"
      action="Перейти на главную"
      actionOnClick={handleActionClick}
    />
  );
};

export default NotFound;
