import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import useRequestHandler from '../../../../../hooks/useRequestHandler';

import ContentForm from '../../../../../components_/Content/ContentForm';
import FormBase from '../../../../../components_/Form';
import FormRow from '../../../../../components_/Form/FormRow';
import FormCol from '../../../../../components_/Form/FormCol';
import FormButton from '../../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './index';
import FormTree from '../FromTree';
import List from './List';

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    width: 365
  },
  switch: {
    marginBottom: 10
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

interface IValues {
  actions: {};
}

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  treeItem: string | null;
  secTreeHierarchy: any;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues: IValues = {
  actions: {}
};

const Form: FC<IProps> = ({ data, onSubmitAction, onCancel, secTreeHierarchy, treeItem }) => {
  const { roles, securableTypes, nodeACL } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...(treeItem && { actions: nodeACL })
  };

  const onSubmit = async (values: IValues) => {
    const error = await onSubmitAction(values);

    requestHandler({
      error,
      entity: 'right',
      onSuccess: onCancel,
      isCreate: true
    });
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <ContentForm>
          <FormRow>
            <Grid item={true} xs={3} zeroMinWidth={true}>
              <FormTree defaultSelected={treeItem} secTreeHierarchy={secTreeHierarchy} />
            </Grid>
            <Grid xs={9} zeroMinWidth={true}>
              {treeItem && (
                <List key={treeItem} treeItem={treeItem} list={securableTypes} roles={roles} />
              )}
              {/*<pre>{JSON.stringify(values, undefined, 2)}</pre>*/}
              {treeItem && (
                <Grid container justify="center" className={classes.buttons}>
                  <FormButton>Сохранить</FormButton>
                </Grid>
              )}
            </Grid>
          </FormRow>
        </ContentForm>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
