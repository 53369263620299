import React, { FC } from 'react';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { getVariableTemplate } from './../utils';

import Typography from '../../../../components_/Typography';

import { IProps as IFormProps } from './';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1, 0)
  },
  button: {
    border: 'none',
    borderRadius: 100,
    padding: '2px 12px 4px',
    cursor: 'pointer',
    fontFamily: 'MTS Sans, sans-serif',
    fontSize: 14,
    fontWeight: 500
  }
}));

interface IProps {
  data: IFormProps['data'];
}

const FormTemplateChip: FC<IProps> = ({ data }) => {
  const { notificationTypes, notificationVariables } = data;

  const classes = useStyles();
  const {
    values: { notificationClass }
  } = useFormState();

  if (!notificationClass) {
    return null;
  }

  const renderVariable = (variable: string) => {
    const { descr: result = '' } =
      notificationVariables.find(item => item.placeholder === variable) || {};

    return getVariableTemplate(result);
  };

  const handleClick = (template: string) => () => {
    document.execCommand('insertText', false, renderVariable(template));
  };

  const { vars } = notificationTypes.find(item => item.class === notificationClass) || {};

  const templates = vars ? vars.split(',') : [];

  return (
    <Grid container alignItems="center" spacing={1} className={classes.root}>
      <Grid item>
        <Typography variant="p2Medium" color="constant-greyscale-500">
          Выберите переменные:
        </Typography>
      </Grid>
      {templates.map((template, index) => (
        <Grid item key={index}>
          <button type="button" onClick={handleClick(template)} className={classes.button}>
            {renderVariable(template)}
          </button>
        </Grid>
      ))}
    </Grid>
  );
};

export default FormTemplateChip;
