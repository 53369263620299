import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '../../Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  input: {
    width: 28,
    height: 28
  }
}));

export type IProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
};

const ColorPicker: FC<IProps> = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      <input id="colorPicker" type="color" {...props} className={classes.input} />
      <label htmlFor="colorPicker">
        <Typography variant="p1Regular">{label}</Typography>
      </label>
    </Grid>
  );
};

export default ColorPicker;
