/**
 * Сервис для работы с разделом администратора
 */

import axios from 'axios';
import { IId } from '../models/types';

const url = '/admin';

const AdminService = {
  getCompanyConfig: () => axios.get(`/v0/config/company`),
  getSecurableTypes: (id: IId) => axios.get(`${url}/securableTypes?uid=${id}`)
};

export default AdminService;
