import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import { createBrowserHistory } from 'history';
import { logoutMiddleware } from './middlewares/logoutMiddleware';
import models from './models';

export const history = createBrowserHistory();

const loadingPlugin = createLoadingPlugin({ asNumber: false });

export const store = init({
  plugins: [loadingPlugin],
  models,
  redux: {
    // middlewares: [logoutMiddleware],
    rootReducers: {
      resetStore: () => undefined
    }
  }
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<typeof models>;
export type iRootState = RematchRootState<typeof models>;

export const dispatchResetStore = () => store.dispatch({ type: 'resetStore' });
