import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { prepareIsGroup } from './utils';

import Typography from '../Typography';

const useStyles = makeStyles(({ spacing, getColor, getShadow }) => ({
  root: {
    margin: spacing(2, -2, 0),
    padding: spacing(2),
    overflow: 'auto'
  },
  table: {
    width: '100%',
    borderSpacing: spacing(0, 1)
  },
  tr: {
    boxShadow: getShadow('low'),
    borderRadius: 8
  },
  col: {
    '&:not(:last-child)': {
      paddingRight: spacing(1.5)
    },
    '&:not(:first-child)': {
      paddingLeft: spacing(1.5)
    },
    '&:first-child': {
      paddingLeft: spacing(3),
      borderRadius: '8px 0 0 8px'
    },
    '&:last-child': {
      paddingRight: spacing(3),
      borderRadius: '0 8px 8px 0'
    },
    whiteSpace: 'nowrap'
  },
  th: {
    textAlign: 'left',
    paddingTop: spacing(1),
    paddingBottom: spacing(1)
  },
  td: {
    backgroundColor: getColor('constant-greyscale-0'),
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },
  groupTd: {
    padding: spacing(4, 0, 1, 3),
    whiteSpace: 'nowrap'
  }
}));

interface ICol {
  title: string;
  field: string;
}

interface IRow {
  group?: string;
  items: { [key: string]: ReactNode };
}

interface IProps {
  cols: ICol[];
  rows: IRow[];
  hideHead?: boolean;
}

const Table: FC<IProps> = ({ cols, rows, hideHead }) => {
  const classes = useStyles();

  const isGroup = prepareIsGroup();

  return (
    <Grid className={classes.root}>
      <table className={classes.table}>
        {!hideHead && (
          <thead>
            <tr>
              {cols.map(({ title }, colIndex) => (
                <th key={colIndex} className={clsx(classes.col, classes.th)}>
                  <Typography variant="p3Regular">{title}</Typography>
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.group && isGroup(row.group) && (
                <tr>
                  <td colSpan={Object.keys(row.items).length} className={classes.groupTd}>
                    <Typography variant="p3Regular">{row.group}</Typography>
                  </td>
                </tr>
              )}

              <tr className={classes.tr}>
                {cols.map(({ field }, colIndex) => (
                  <td key={colIndex} className={clsx(classes.col, classes.td)}>
                    {row.items[field]}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};

export default Table;
