import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ colors }) => ({
  root: {
    position: 'fixed',
    bottom: 80,
    maxWidth: '330px',
    width: 'inherit',
    backgroundImage: `linear-gradient(to top, ${colors.white}, transparent)`,
    zIndex: 1
  }
}));

const DrawerSticky: FC = ({ children }) => {
  const classes = useStyles();

  return <Grid className={classes.root}>{children}</Grid>;
};

export default DrawerSticky;
