import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MtsTabbar, MtsTab } from '@mts-ds/components-react';
import isSatisfied from '../../utils/isSatisfied';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';

const mapState = (state: iRootState) => ({
  rights: state.securable.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {};
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

interface ITab {
  name: string;
  url: string;
  uid?: string;
}

export type IProps = {
  tabs: ITab[];
  startsWith?: boolean;
} & typeof MtsTabbar.defaultProps &
  connectedProps;

const Tabbar: FC<IProps> = ({ startsWith, tabs, rights, ...params }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const checkActive = (path: string) =>
    startsWith ? pathname.startsWith(path) : path === pathname;

  const handleClick = (path: string) => () => {
    if (checkActive(path)) {
      window.location.replace(path);
    } else {
      history.push(path);
    }
  };

  return (
    <MtsTabbar {...params}>
      {tabs.map(({ name, url, uid }, index) => {
        if (isSatisfied({ ...{ uid }, ...{ action: 'Visibility' } }, rights)) {
          return (
            <MtsTab key={uid} active={checkActive(url)} onClick={handleClick(url)}>
              {name}
            </MtsTab>
          );
        }
      })}
    </MtsTabbar>
  );
};
const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Tabbar);
