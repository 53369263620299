import { getColor } from '../../../../theme';

const getChargeColor = (charge: number) =>
  charge < 10
    ? getColor('constant-greyscale-400')
    : charge < 25
    ? getColor('accent-negative')
    : getColor('accent-positive');

export { getChargeColor };
