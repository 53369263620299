import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  root: {
    width: '100%',
    height: spacing(4),
    border: `2px solid ${getColor('constant-greyscale-200')}`,
    borderRadius: '6px',
    padding: spacing(0, 1),
    '&[type="time"]::-webkit-calendar-picker-indicator': {
      display: 'none'
    },
    '&:focus': {
      outline: 'none',
      borderColor: getColor('constant-blueberry-dark')
    }
  }
}));

export type IProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const TimePicker: FC<IProps> = props => {
  const classes = useStyles();

  return <input type="time" {...props} className={classes.root} />;
};

export default TimePicker;
