import React, { FC } from 'react';

import { guids, paths } from '../../routes/Root/paths';

import ContentTabs from '../../components_/Content/ContentTabs';
import Tabbar from '../../components_/Tabbar';

const tabs = [
  {
    name: 'Инфраструктура',
    url: paths.equipment.root,
    uid: guids.equipment.root
  },
  {
    name: 'Реестр СИЗ',
    url: paths.equipment.protections.root,
    uid: guids.equipment.protections.root
  },
  {
    name: 'Носимые устройства',
    url: paths.equipment.devices.root,
    uid: guids.equipment.devices.root
  },
  {
    name: 'Камеры',
    url: paths.equipment.cameras.root,
    uid: guids.equipment.cameras.root
  }
];

const Tabs: FC = () => {
  return (
    <ContentTabs>
      <Tabbar tabs={tabs} />
    </ContentTabs>
  );
};

export default Tabs;
