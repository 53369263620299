import React, { FC } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import Checkbox, { IProps as ICheckboxProps } from '../../Fields/Checkbox';

type IProps = {
  name: string;
  useFieldConfig?: UseFieldConfig<string>;
} & ICheckboxProps;

const FormCheckbox: FC<IProps> = ({ name, useFieldConfig, ...props }) => {
  const { input } = useField(name, { type: 'checkbox', ...useFieldConfig });

  // Адаптер под mts-ds
  input.onMtsChange = input.onChange;
  input.onMtsFocus = input.onFocus;
  input.onMtsBlur = input.onBlur;

  return <Checkbox {...input} {...props} />;
};

export default FormCheckbox;
