/**
 * Модель для работы с инфраструктурой
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IOffice, IInfrastructure, IBeaconType } from './types';

import MapsService from '../services/MapsService';
import BeaconsService from '../services/BeaconsService';

interface IData {
  offices: IOffice[];
  types: IBeaconType[];
}

interface IList {
  data: IInfrastructure[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    offices: [],
    types: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: offices }
          },
          { data: types }
        ] = await Promise.all([MapsService.getOffices(), BeaconsService.getTypeAll()]);

        this.setData({ offices, types });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await BeaconsService.getAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const equipmentInfrastructure: typeof model = createModel(model);
