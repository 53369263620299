import React, { FC, useState, useRef, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { MtsTooltip } from '@mts-ds/components-react';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1001
  },
  wrapper: {
    display: 'inline-block',
    verticalAlign: 'middle'
  }
}));

export type IProps = {
  label: ReactNode;
} & typeof MtsTooltip.defaultProps;

const Tooltip: FC<IProps> = ({ children, label, className, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const childrenRef = useRef<any>();
  const classes = useStyles();

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseOut = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={classes.wrapper}
        ref={childrenRef}
      >
        {children}
      </div>
      <MtsTooltip
        target={childrenRef.current}
        visible={isOpen}
        className={clsx(classes.root, className)}
        {...props}
      >
        {label}
      </MtsTooltip>
    </>
  );
};

export default Tooltip;
