/**
 * Правила валидации
 */

interface IValidationMessages {
  [key: string]: string;
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const phoneRegex = /^\d{10}$/;

const macRegex = /^[0-9a-f]{2}(:[0-9a-f]{2}){5}$/i;

const validCharactersRegex = /[a-zA-Z0-9+_@.-]$/i;

const validationMessages: IValidationMessages = {
  required: 'Поле должно быть заполнено',
  email: 'Некорректный формат',
  phone: 'Некорректный формат',
  mac: 'Некорректный формат',
  size: 'Допустимое количество символов:',
  numMax: 'Максимально допустимое значение: ',
  validCharacters: 'Допустимые символы: aA-zZ, 0-9, +@._-'
};

/**
 * Функция, проверяющая адрес почты на соответствие формату
 * @param value Значение поля ввода
 */
const email = (value: string) => value && !emailRegex.test(value) && validationMessages.email;

/**
 * Функция, проверяющая номер телефона на соответствие формату
 * @param value Значение поля ввода
 */
const phone = (value: string) => value && !phoneRegex.test(value) && validationMessages.phone;

/**
 * Функция, проверяющая аппаратный адрес на соответствие формату
 * @param value Значение поля ввода
 */
const mac = (value: string) => value && !macRegex.test(value) && validationMessages.mac;

/**
 * Функция, проверяющая наличие запрещенных символов
 * @param value Значение поля ввода
 */
const validCharacters = (value: string) =>
  value && !validCharactersRegex.test(value) && validationMessages.validCharacters;

/**
 * Функция, проверяющая, заполнено ли поле
 * @param value Значение поля ввода
 */
const required = (value: string | string[]) => {
  const incomingValue = Array.isArray(value) ? value.length : value;
  return !incomingValue && validationMessages.required;
};

/**
 * Функция, проверяющая, количество символов введенное в поле
 * @param value Значение поля ввода
 * @param quantity Допустимое количество символов
 */
const size = (value: string, quantity: number) => {
  return (
    value &&
    value.length > quantity &&
    validationMessages.size + ' ' + quantity + ' Введено: ' + value.length
  );
};

/**
 * Функция, проверяющая, размер числа введеного в поле
 * @param value Значение поля ввода
 * @param quantity Допустимый размер числа
 */
const numMax = (value: string, quantity: number) => {
  return value && Number(value) > quantity && validationMessages.numMax + ' ' + quantity;
};

export { email, phone, mac, required, size, numMax, validCharacters };
