/**
 * Модель для работы ролями пользователей
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';

import UsersService from '../services/UsersService';
import { ISecurableRight } from './types';

// tslint:disable-next-line: no-empty-interface
interface IState {
  data: ISecurableRight[];
}

const initialState: IState = {
  data: []
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: ISecurableRight[]): IState {
      return { ...state, data: payload };
    },
    reset(): IState {
      return initialState;
    }
  },
  effects: {
    async updateSecurableTree(config, rootState: iRootState) {
      const { version: appVersion, root, securableTree } = config;

      try {
        const {
          data: { version: securableTreeVersion }
        } = await UsersService.getSecurableTreeVersion(root);

        if (appVersion > securableTreeVersion) {
          await UsersService.createSecurableTree(root, securableTree);
        }
      } catch (error) {
        return error;
      }
    },
    async getSecurables(config, rootState: iRootState) {
      const { root } = config;
      try {
        const { data } = await UsersService.getSecurables(root);
        this.setData(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const securable: typeof model = createModel(model);
