import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../../store';
import { paths } from '../../../../Root/paths';
import { IRoleType } from '../../../../../models/types';
import { useAsync, useRequestHandler, useParams } from '../../../../../hooks';
import { IGetTypeAllParams } from '../../../../../services/ProtectionsService';

import Spinner from '../../../../../components_/Spinner';
import EmptyState from '../../../../../components_/EmptyState';
import Modal from '../../../../../components_/Modal';

import Filter from './Filter';
import List from './List';
import Grid from '@material-ui/core/Grid';

const initialFilter: Omit<IGetTypeAllParams, 'pageIndex'> = {
  search: '',
  classType: ''
};

const mapState = (state: iRootState) => ({
  list: state.settingSecTree.listRoles,
  secTreeHierarchy: state.settingSecTree.secTreeHierarchy
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getHierarchy: d.settingSecTree.getHierarchy,
    getListRoles: d.settingSecTree.getListRoles,
    remove: d.settingSecTree.remove
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({ list, getListRoles, remove }) => {
  const [active, setActive] = useState<IRoleType | null>(null);

  const history = useHistory();
  const requestHandler = useRequestHandler();

  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getListRoles);
  const { pageIndex, filter, handlePageChange, handleFilterChange } = useParams(
    initialFilter,
    runGetList
  );

  const updateList = () => {
    getListRoles({ ...filter, pageIndex });
  };

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удаление роли',
    text: 'Вы действительно хотите удалить роль?',
    buttonTextConfirm: 'Удалить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await remove(active.id);

        requestHandler({
          error,
          entity: 'role',
          isDelete: true,
          onSuccess: updateList
        });
      }
    })
  });

  const handleClickCreate = () => {
    history.push(paths.settings.secTree.roles.create);
  };

  const handleClickEdit = ({ id }: IRoleType) => () => {
    history.push(paths.settings.secTree.roles.edit(id));
  };
  const handleClickCopy = ({ id }: IRoleType) => () => {
    history.push(paths.settings.secTree.roles.copy(id));
  };

  const handleClickDelete = (role: IRoleType) => () => {
    setActive(role);
    openDeleteModal();
  };

  const isLoading = isLoadingGetList;
  const isLoadingError = isGetListLoadingError;

  return (
    <Grid style={{ marginTop: '-80px' }} item>
      <>
        <Filter onClickCreate={handleClickCreate} />
        {!isLoadingGetList && !isGetListLoadingError && (
          <>
            {!list && <EmptyState variant="generateReport" />}
            {list && (
              <>
                {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                {Boolean(list.length) && (
                  <>
                    <List
                      onClickEdit={handleClickEdit}
                      onClickDelete={handleClickDelete}
                      onClickCopy={handleClickCopy}
                      list={list}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Grid>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
