import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Icon from '../../Icon';
import IconWrapper from '../../Icon/IconWrapper';

import { INotificationClass } from '../../../models/types';
import { notificationClasses } from '../../../constants/notifications';

type ISize = 's' | 'm' | 'l';
interface ISizeParams {
  icon: number;
  iconWrapper: number;
}

type ISizes = Record<ISize, ISizeParams>;

const sizes: ISizes = {
  s: {
    icon: 10,
    iconWrapper: 24
  },
  m: {
    icon: 14,
    iconWrapper: 32
  },
  l: {
    icon: 16,
    iconWrapper: 36
  }
};

const useStyles = makeStyles(() => ({
  iconWrapper: ({ size = 'l' }: IStyleProps) => ({
    width: sizes[size].iconWrapper,
    height: sizes[size].iconWrapper
  }),
  icon: ({ size = 'l' }: IStyleProps) => ({
    fontSize: sizes[size].icon
  })
}));

interface IStyleProps {
  size?: ISize;
}

interface IProps extends IStyleProps {
  notificationClass: INotificationClass;
}

const NotificationIcon: FC<IProps> = ({ notificationClass, size }) => {
  const classes = useStyles({ size });

  const notification = notificationClasses[notificationClass];

  return notification ? (
    <IconWrapper
      variant="square"
      size="s"
      color={notification.color}
      className={classes.iconWrapper}
    >
      <Icon
        name={notification.icon}
        size="s"
        color="constant-greyscale-0"
        className={classes.icon}
      />
    </IconWrapper>
  ) : (
    <IconWrapper variant="square" size="s" className={classes.iconWrapper}>
      <Icon name="hills" size="s" className={classes.icon} />
    </IconWrapper>
  );
};

export default NotificationIcon;
