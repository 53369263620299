import { IId } from '../../models/types';

interface IPaths {
  root: string;
  websso: string;
  ui: string;
  map: {
    root: string;
    office: {
      plan: {
        create: string;
        edit(id?: IId): string;
      };
      users: {
        root: string;
        show(id?: IId): string;
        edit(id?: IId): string;
      };
      areas: {
        root: string;
        create: string;
        show(id?: IId): string;
        edit(id?: IId): string;
      };
      infrastructure: {
        root: string;
        create: string;
        show(mac?: IId): string;
        edit(mac?: IId): string;
      };
      cameras: {
        root: string;
        create: string;
        show(id?: IId): string;
        edit(id?: IId): string;
      };
      root: string;
      create: string;
      edit(id?: IId): string;
    };
    sending: string;
  };
  staff: {
    root: string;
    users: {
      root: string;
      create: string;
      edit(id?: IId): string;
    };
    departaments: {
      root: string;
      create: string;
      edit(id?: IId): string;
      copy(id?: IId): string;
    };
  };
  equipment: {
    root: string;
    beacons: {
      root: string;
    };
    cameras: {
      root: string;
    };
    protections: {
      root: string;
      create: string;
      edit(id?: IId): string;
      copy(id?: IId): string;
    };
    devices: {
      root: string;
      create: string;
      edit(id?: IId): string;
      copy(id?: IId): string;
    };
  };
  reports: {
    root: string;
    violations: string;
    notifications: string;
    workingTime: string;
    useProtection: string;
    stayInAreas: string;
    physicalState: string;
    heatMap: string;
    tracks: string;
  };
  notifications: string;
  settings: {
    root: string;
    notifications: {
      root: string;
      create: string;
      edit(id?: IId): string;
    };
    protectionTypes: {
      root: string;
      create: string;
      edit(id?: IId): string;
    };
    secTree: {
      root: string;
      roles: {
        root: string;
        create: string;
        edit(id?: IId): string;
        copy(id?: IId): string;
      };
      rights: {
        root: string;
      };
    };
  };
  account(section?: string): string;
}

const paths: IPaths = {
  root: '/',
  websso: '/websso',
  ui: '/ui',
  map: {
    root: '/map',
    office: {
      plan: {
        create: '/map/office/plan/create',
        edit: id => `/map/office/plan/${id || ':id'}/edit`
      },
      users: {
        root: '/map/office/users',
        show: userName => `/map/office/users/${userName || ':userName'}`,
        edit: userName => `/map/office/users/${userName || ':userName'}/edit`
      },
      areas: {
        root: '/map/office/areas',
        create: '/map/office/areas/create',
        show: id => `/map/office/areas/${id || ':id'}`,
        edit: id => `/map/office/areas/${id || ':id'}/edit`
      },
      infrastructure: {
        root: '/map/office/infrastructure',
        create: '/map/office/infrastructure/create',
        show: mac => `/map/office/infrastructure/${mac || ':mac'}`,
        edit: mac => `/map/office/infrastructure/${mac || ':mac'}/edit`
      },
      cameras: {
        root: '/map/office/cameras',
        create: '/map/office/cameras/create',
        show: id => `/map/office/cameras/${id || ':id'}`,
        edit: id => `/map/office/cameras/${id || ':id'}/edit`
      },
      root: '/map/office',
      create: '/map/office/create',
      edit: id => `/map/office/${id || ':id'}/edit`
    },
    sending: '/map/sending'
  },
  staff: {
    root: '/staff',
    users: {
      root: '/staff/users',
      create: '/staff/users/create',
      edit: id => `/staff/users/${id || ':id'}/edit`
    },
    departaments: {
      root: '/staff/departaments',
      create: '/staff/departaments/create',
      edit: id => `/staff/departaments/${id || ':id'}/edit`,
      copy: id => `/staff/departaments/${id || ':id'}/copy`
    }
  },
  equipment: {
    root: '/equipment',
    beacons: {
      root: '/equipment/beacons'
    },
    cameras: {
      root: '/equipment/cameras'
    },
    protections: {
      root: '/equipment/protections',
      create: '/equipment/protections/create',
      edit: id => `/equipment/protections/${id || ':id'}/edit`,
      copy: id => `/equipment/protections/${id || ':id'}/copy`
    },
    devices: {
      root: '/equipment/devices',
      create: '/equipment/devices/create',
      edit: id => `/equipment/devices/${id || ':id'}/edit`,
      copy: id => `/equipment/devices/${id || ':id'}/copy`
    }
  },
  reports: {
    root: '/reports',
    violations: '/reports/violations',
    notifications: '/reports/notifications',
    workingTime: '/reports/working-time',
    useProtection: '/reports/use-protection',
    stayInAreas: '/reports/stay-in-areas',
    physicalState: '/reports/physical-state',
    heatMap: '/reports/heatmap',
    tracks: '/reports/tracks'
  },
  notifications: '/notifications',
  settings: {
    root: '/settings',
    notifications: {
      root: '/settings/notifications',
      create: '/settings/notifications/create',
      edit: id => `/settings/notifications/${id || ':id'}/edit`
    },
    protectionTypes: {
      root: '/settings/protectionTypes',
      create: '/settings/protectionTypes/create',
      edit: id => `/settings/protectionTypes/${id || ':id'}/edit`
    },
    secTree: {
      root: '/settings/secTree',
      roles: {
        root: '/settings/secTree/roles',
        create: '/settings/secTree/roles/create',
        edit: id => `/settings/secTree/roles/${id || ':id'}/edit`,
        copy: id => `/settings/secTree/roles/${id || ':id'}/copy`
      },
      rights: {
        root: '/settings/secTree/rights'
      }
    }
  },
  account: section => `/account/${section || ''}`
};

const guids: IPaths = {
  root: '2a816185-af3d-46ae-9a94-65d3109b0408',
  websso: '/websso',
  ui: '/ui',
  map: {
    root: 'b39d78c0-ec94-4ddf-8bc6-ad9cb738e3a6',
    office: {
      plan: {
        create: '320fa6c3-4c74-4715-bf0f-674a986734aa',
        edit: id => `fde2e668-6d3c-4565-84b8-3b8645f1f37b`
      },
      users: {
        root: 'f0843502-d986-441d-91fc-25043d077c33',
        show: userName => `8b41d800-963e-4eb4-bb8b-b1a9eeb94227`,
        edit: userName => `54ebc6ae-b806-45c6-8c9c-b905a97eb306`
      },
      areas: {
        root: '4467bc4e-1717-4258-a12a-a045874d24e4',
        create: '9ea62306-bcfc-4d57-9168-5496f3045bac',
        show: id => `5f55affd-4184-41f8-bb67-0b87e3ff1b97`,
        edit: id => `76382a0e-943d-4b6d-b3b3-d1cf25294de6`
      },
      infrastructure: {
        root: '96e901ae-d0af-403a-b14c-ce739243a349',
        create: 'b83be04b-2636-4b98-82ca-8b4c3f6f46fc',
        show: mac => `87b9337b-9b40-4ed7-a81a-391bf2401a80`,
        edit: mac => `a60eb725-8193-42dc-bfee-134c88fe271b`
      },
      cameras: {
        root: 'c57c5cce-eeae-4685-b7fb-fa2e0e055a72',
        create: 'cc30b1d0-86ad-4e47-88af-f3108249af91',
        show: id => 'c603c819-f8a2-4c4a-ab40-975f1854794a',
        edit: id => '16337146-ebba-4058-b9f5-5ab975c77af3'
      },
      root: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
      create: '6790141d-9bff-419f-a99f-72a1b01811ba',
      edit: id => `101950a3-058d-43f8-9b41-e45990f8432b`
    },
    sending: '/map/sending'
  },
  staff: {
    root: 'c94e1c86-0196-4b5a-8725-20b44f58c501',
    users: {
      root: 'e31fed38-39d5-4a0f-bc31-ab11359a7ba1',
      create: 'c8d4164b-9c8e-4172-aa91-153d9fcc4221',
      edit: userName => `00b3e603-fc37-4488-8ca7-e669164e265f`
    },
    departaments: {
      root: 'e3b37b2c-1a43-4f9e-b37b-c7980dcfa390',
      create: 'e173edef-34fe-4a68-91a8-656baeeaf64b',
      edit: id => `566a5e7e-01c8-4513-8796-5c1b7f9e19da`,
      copy: id => `c5aac0a7-e91a-4fd2-8a4a-cb39f38e5fcf`
    }
  },
  equipment: {
    root: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    beacons: {
      root: 'a368a169-bc91-4027-807f-606e91d274cd'
    },
    cameras: {
      root: 'bc392241-9631-465b-b643-58e2cc3fca21'
    },
    protections: {
      root: '53ee0ba1-7d87-4483-a1b4-50716da8d853',
      create: '6b745e07-dcfa-43f3-8d1b-1b88d912622f',
      edit: id => `ae4bb5be-c99a-461d-bcfd-4dfef044862f`,
      copy: id => `2cf56803-d908-4728-b5aa-f3fd230e485e`
    },
    devices: {
      root: '75424e4e-0284-4ca8-afab-26d964501eeb',
      create: '942d7082-1103-47ab-9635-3e101a988007',
      edit: id => `ebddaf01-bb52-4322-a5ea-dc00bd73cbfc`,
      copy: id => `1809bb70-5f3f-470f-8c02-98add6c87fd3`
    }
  },
  reports: {
    root: '3c200fbf-b6a7-4897-ba15-e971c56fb022',
    violations: '/reports/violations',
    notifications: '/reports/notifications',
    workingTime: '/reports/working-time',
    useProtection: '/reports/use-protection',
    stayInAreas: '/reports/stay-in-areas',
    physicalState: '/reports/physical-state',
    heatMap: '/reports/heatmap',
    tracks: '/reports/tracks'
  },
  notifications: '22917d31-07c5-4687-9df3-de62146bd413',
  settings: {
    root: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    notifications: {
      root: '28bbda63-b762-4a35-8bb5-c329594d6934',
      create: 'c74e8cfe-0339-4ee3-a7bd-29bcee6ce429',
      edit: id => `c2208671-1de6-4c81-a73b-10d5f754e31a`
    },
    protectionTypes: {
      root: '4d02eed1-f060-45a6-8fd7-dcf10a4a1233',
      create: 'fa8e519f-ad5a-4531-b055-4e1381142d65',
      edit: id => `4a33aed2-8134-4a79-b2d3-c56d506b06d5`
    },
    secTree: {
      root: 'b4cb23ca-d8fd-41ea-8b1c-241ec098c633',
      roles: {
        root: 'd94846a8-d046-4617-9e3e-201c2ddc9dc0',
        create: 'eab56032-3de6-4455-858f-39dcd87810df',
        edit: id => `64174857-5bb2-4812-923f-fd95ea50fe65`,
        copy: id => `a8ffd131-7755-4566-9dd0-00179b9ee82e`
      },
      rights: {
        root: 'a4a4cabd-2c7b-4834-9501-d6765f555b41'
      }
    }
  },
  account: section => `/account/${section || ''}`
};

export { paths, guids };
