import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MtsBadge } from '@mts-ds/components-react';

import { INotificationSetting } from '../../../../models/types';
import { IProps as IListProps } from './';

import Table from '../../../../components_/Table';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../components_/Table/TableActionButton';
import Typography from '../../../../components_/Typography';

import NotificationIcon from '../../../../components_/Notification/NotificationIcon';
import NotificationTarget from '../../../../components_/Notification/NotificationTarget';

import StrTemplate from './../StrTemplate';

const useStyles = makeStyles(({ spacing }) => ({
  info: {
    whiteSpace: 'normal',
    minWidth: spacing(66)
  }
}));

interface IProps {
  data: IListProps['data'];
  list: INotificationSetting[];
  onClickEdit: any;
  onClickDelete: any;
}

const List: FC<IProps> = ({ data, list, onClickEdit, onClickDelete }) => {
  const { notificationVariables } = data;

  const classes = useStyles();

  const rows = list.map(item => {
    const {
      notificationClass,
      notificationClassName,
      name,
      template,
      targetName,
      targetSimple
    } = item;

    return {
      items: {
        icon: <NotificationIcon notificationClass={notificationClass} />,
        info: (
          <div className={classes.info}>
            <MtsBadge size="s">{notificationClassName}</MtsBadge>
            <Typography variant="p1Medium">{name}</Typography>
            <Typography variant="p2Regular">
              <StrTemplate text={template} notificationVariables={notificationVariables} />
            </Typography>
          </div>
        ),
        target: <MtsBadge size="s">{targetName}</MtsBadge>,
        targetIcon: <NotificationTarget targetSimple={targetSimple} />,
        actions: (
          <TableActionGroup>
            <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
            <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
          </TableActionGroup>
        )
      }
    };
  });

  const columns = [
    {
      title: '',
      field: 'icon'
    },
    {
      title: '',
      field: 'info'
    },
    {
      title: '',
      field: 'target'
    },
    {
      title: '',
      field: 'targetIcon'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={columns} rows={rows} hideHead />;
};

export default List;
