import React, { FC } from 'react';

import DrawerStickyActions from '../../../../../../components_/Drawer/DrawerStickyActions';

interface IProps {
  onGoBack: () => void;
}

const FormActions: FC<IProps> = ({ onGoBack }) => {
  return <DrawerStickyActions cancel="Отмена" confirm="Сохранить" onClickCancel={onGoBack} />;
};

export default FormActions;
