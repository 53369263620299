import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import Icon, { IProps as IIconProps } from '../../../components_/Icon';
import Tooltip, { IProps as ITooltipProps } from '../../../components_/Tooltip';

type IProps = {
  icon: IIconProps['name'];
  tooltip: ITooltipProps['label'];
} & typeof MtsButton.defaultProps;

const TableActionButton: FC<IProps> = ({ icon, tooltip, ...props }) => {
  return (
    <Tooltip size="s" label={tooltip}>
      <MtsButton
        size={'s' as ButtonSizes}
        format={'icon' as ButtonFormats}
        color={'outline' as ButtonColors}
        {...props}
      >
        <Grid container alignItems="center">
          <Icon name={icon} size="m" />
        </Grid>
      </MtsButton>
    </Tooltip>
  );
};

export default TableActionButton;
