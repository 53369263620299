import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IUpdateOrCreateBrigadeBody } from '../../../../services/UsersService';
import { required } from '../../../../utils/validation';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';
import FormButton from '../../../../components_/Form/FormButton';
import FormUserList from '../../../../components_/Form/FormUserList';
import FormUserListChip from '../../../../components_/Form/FormUserList/FormUserListChip';

import { IProps as IFormProps } from './';

import FormController from './FormController';
import { IDepartament } from '../../../../models/types';

const useStyles = makeStyles(({ spacing }) => ({
  rows: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(4)
    }
  },
  tooltip: {
    width: 365
  },
  switch: {
    marginBottom: 10
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IValues = IDepartament;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IValues | null;
  isCreate: boolean;
  isEdit: boolean;
  getDataUsers: any;
  onSubmitAction: any;
  onCancel: any;
}

export const defaultValues: IValues = {
  id: 0,
  name: '',
  parentId: '',
  // leadId: '',
  employees: []
};

const Form: FC<IProps> = ({
  data,
  item,
  isCreate,
  isEdit,
  getDataUsers,
  onSubmitAction,
  onCancel
}) => {
  const { users, shortUsers } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item)
  };

  const shortUserOptions = shortUsers.map(({ userId, fio }) => ({
    id: userId,
    name: fio
  }));

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const error = await onSubmitAction(values);

    requestHandler({
      error,
      entity: 'departament',
      onSuccess: onCancel,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <ContentForm>
          <Grid className={classes.rows}>
            <FormRow>
              <FormCol xs={4}>
                <FormInput
                  name="name"
                  label="Название подразделения"
                  placeholder="Введите название"
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
              <FormCol xs={4}>
                <FormDropdown
                  name="leadId"
                  label="Руководитель"
                  placeholder="Выберите руководителя"
                  options={shortUserOptions}
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol xs={4}>
                <FormUserList
                  title="Добавьте сотрудников в подразделение (необязательно)"
                  users={users}
                  getUsers={getDataUsers}
                />
              </FormCol>
              <FormCol>
                <FormUserListChip shortUsers={shortUsers} />
              </FormCol>
            </FormRow>
          </Grid>
          <Grid container justify="center" className={classes.buttons}>
            <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
              Отмена
            </MtsButton>
            <FormButton>{submitButtonTitle}</FormButton>
          </Grid>
        </ContentForm>
        <FormController />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
