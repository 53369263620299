import React, { FC } from 'react';

import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';

import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormInput from '../../../../../../components_/Form/FormInput';

import { required, phone, email } from '../../../../../../utils/validation';
import FormDatePicker from '../../../../../../components_/Form/FormDatePicker';

const FormPersonal: FC = () => {
  return (
    <>
      <div style={{ marginTop: '40px' }} />
      <FormGroup title="Персональная информация" size="s">
        <FormRow>
          <FormCol>
            <FormInput
              name="lastname"
              label="Фамилия"
              placeholder="Введите фамилию"
              size={'s' as InputSizes}
              useFieldConfig={{ validate: required }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput
              name="name"
              label="Имя"
              placeholder="Введите имя"
              size={'s' as InputSizes}
              useFieldConfig={{ validate: required }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput
              name="secondname"
              label="Отчество (необязательно)"
              placeholder="Введите отчество"
              size={'s' as InputSizes}
            />
          </FormCol>
        </FormRow>
        <FormRow alignItems="flex-end">
          <FormCol>
            <FormDatePicker
              name="birthday"
              label="Дата рождения (необязательно)"
              size="s"
              maxDate={new Date()}
            />
            {/*<FormBaseInput*/}
            {/*  name="birthday"*/}
            {/*  label="Дата рождения (необязательно)"*/}
            {/*  type="date"*/}
            {/*  size="s"*/}
            {/*/>*/}
          </FormCol>
          <FormCol>
            <FormInput
              name="username"
              label="Логин"
              placeholder="Введите логин"
              size={'s' as InputSizes}
              disabled
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput
              name="phone"
              label="Контактный номер телефона"
              placeholder="+7"
              size={'s' as InputSizes}
              useFieldConfig={{ validate: value => required(value) || phone(value) }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput
              name="email"
              label="Email (необязательно)"
              placeholder="user@company.ru"
              size={'s' as InputSizes}
              useFieldConfig={{ validate: email }}
            />
          </FormCol>
        </FormRow>
      </FormGroup>
    </>
  );
};

export default FormPersonal;
