import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IUpdateOrCreateTemplate } from '../../../../services/NotificationsService';
import { required } from '../../../../utils/validation';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';
import FormButton from '../../../../components_/Form/FormButton';
import FormUserList from '../../../../components_/Form/FormUserList';
import FormUserListChip from '../../../../components_/Form/FormUserList/FormUserListChip';

import { IProps as IFormProps } from './';
import FormCard from './FormCard';
import FormTemplate from './FormTemplate';
import FormConformation from './FormConfirmation';
import FormSound from './FormSound';

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    width: 365
  },
  switch: {
    marginBottom: 10
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IValues = IUpdateOrCreateTemplate;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IValues | null;
  isCreate: boolean;
  isEdit: boolean;
  getDataUsers: IFormProps['getDataUsers'];
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues: IValues = {
  id: 0,
  notificationClass: '',
  name: '',
  target: '',
  channelType: '',
  template: '',
  employeesList: [],
  isNeedConfirmation: true,
  isNeedSound: true,
  isNeedVibro: true
};

const Form: FC<IProps> = ({
  data,
  item,
  isCreate,
  isEdit,
  getDataUsers,
  onSubmitAction,
  onCancel
}) => {
  const { notificationTypes, users, shortUsers } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item)
  };

  const notificationTypeOptions = notificationTypes.map(({ class: id, descr }) => ({
    id,
    name: descr
  }));

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const error = await onSubmitAction(values);

    requestHandler({
      error,
      entity: 'notification',
      onSuccess: onCancel,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit, values: { notificationClass, target } }) => {
    // tslint:disable-next-line: no-shadowed-variable
    const { targets = [] } = notificationTypes.find(item => item.class === notificationClass) || {};
    // tslint:disable-next-line: no-shadowed-variable
    const { isList, isReadConfirm, isSound, channels = [] } =
      // tslint:disable-next-line: no-shadowed-variable
      targets.find(item => item.target === target) || {};

    // tslint:disable-next-line: no-shadowed-variable
    const notificationTargetOptions = targets.map(item => ({
      id: item.target,
      name: item.descr
    }));

    // tslint:disable-next-line: no-shadowed-variable
    const notificationChannelOptions = channels.map(item => ({
      id: item.channelType,
      name: item.friendlyName
    }));

    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <ContentForm>
          <Grid>
            <FormCard data={data} />
            <FormRow>
              <FormCol>
                <FormDropdown
                  name="notificationClass"
                  label="Тип события"
                  placeholder="Выберите тип события"
                  options={notificationTypeOptions}
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
              <FormCol>
                <FormInput
                  name="name"
                  label="Название шаблона"
                  placeholder="Введите название шаблона"
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
              <FormCol>
                <FormDropdown
                  name="target"
                  label="Получатели уведомления"
                  placeholder="Выберите получателя"
                  disabled={!Boolean(notificationTargetOptions.length)}
                  options={notificationTargetOptions}
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
              <FormCol>
                <FormDropdown
                  name="channelType"
                  label="Канал уведомления"
                  placeholder="Выберите канал"
                  disabled={!Boolean(notificationChannelOptions.length)}
                  options={notificationChannelOptions}
                  useFieldConfig={{ validate: required }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <FormTemplate data={data} />
              </FormCol>
            </FormRow>
            {isList && (
              <FormRow>
                <FormCol xs={4}>
                  <FormUserList
                    title="Добавьте сотрудников, которые получат уведомление"
                    users={users}
                    getUsers={getDataUsers}
                  />
                </FormCol>
                <FormCol>
                  <FormUserListChip shortUsers={shortUsers} />
                </FormCol>
              </FormRow>
            )}
            {isReadConfirm && (
              <FormRow>
                <FormCol>
                  <FormConformation />
                </FormCol>
              </FormRow>
            )}
            {isSound && (
              <FormRow>
                <FormCol>
                  <FormSound />
                </FormCol>
              </FormRow>
            )}
          </Grid>
          <Grid container justify="center" className={classes.buttons}>
            <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
              Отмена
            </MtsButton>
            <FormButton>{submitButtonTitle}</FormButton>
          </Grid>
        </ContentForm>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
