const prepareIsGroup = () => {
  let group: string;

  return (time: string) => {
    const result = group === time;

    group = time;

    return !result;
  };
};

export { prepareIsGroup };
