import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { ICamera } from '../../../../../../models/types';
import { paths } from '../../../../../Root/paths';

import Tooltip from '../../../../../../components_/Tooltip';
import Icon from '../../../../../../components_/Icon';
import DrawerContentItemCamera from '../../../../../../components_/Drawer/DrawerContentItemCamera';

interface IProps {
  item: ICamera;
  setMarkerEditMode: () => void;
}

const Card: FC<IProps> = ({ item, setMarkerEditMode }) => {
  const { id } = item || {};

  const history = useHistory();

  const handleEditClick = () => {
    history.push(paths.map.office.cameras.edit(id));
    setMarkerEditMode();
  };

  return (
    <DrawerContentItemCamera
      camera={item}
      variant="card"
      action={
        <Tooltip id="tooltipEdit" size="s" label="Редактировать">
          <MtsButton
            id="buttonEdit"
            size={'s' as ButtonSizes}
            format={'icon' as ButtonFormats}
            color={'secondary' as ButtonColors}
            onClick={handleEditClick}
          >
            <Grid container alignItems="center">
              <Icon name="edit" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      }
    />
  );
};

export default Card;
