/**
 * Модель для работы с камерами
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import { Dispatch } from '../store';
import { IId, ICoordXY, ICamera } from './types';
import CamerasService, { IUpdateOrCreateBody } from '../services/CamerasService';

interface IState {
  item: ICamera | null;
  coordinates: ICoordXY | null;
  cameraEdit: ICamera | null;
}

const initialState: IState = {
  item: null,
  coordinates: null,
  cameraEdit: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setItem(state: IState, payload: ICamera): IState {
      // console.log(payload);
      return { ...state, item: payload };
    },
    setItemEdit(state: IState, payload: ICamera): IState {
      // console.log(payload);
      return { ...state, cameraEdit: payload };
    },
    setCoordinates(state: IState, payload: ICoordXY): IState {
      return { ...state, coordinates: payload };
    },
    reset(): IState {
      return initialState;
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async getItem(id: IId) {
        const { data } = await CamerasService.get(id);

        this.setItem(data);
      },
      async updateOrCreate(body: IUpdateOrCreateBody) {
        try {
          await CamerasService.updateOrCreate(body);
        } catch (error) {
          return error;
        }
      },
      async remove(id: IId) {
        try {
          await CamerasService.remove(id);
        } catch (error) {
          return error;
        }
      },
      focus(center: ICoordXY) {
        // const payload = { zoom: 20, center };

        const payload = { center };

        d.infrastructureMap.setFocus(payload);
      }
    };
  }
};

export const infrastructureCameras: typeof model = createModel(model);
