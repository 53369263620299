import React, { useState } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import Input from '../../../../components_/Fields/Input';

import TableActionButton from '../../../../components_/Table/TableActionButton';
import { Divider } from '@material-ui/core';
import { ITreeNode } from './FromTree';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ spacing }) => ({
  typography: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: 5
  },
  iconContainer: {
    width: '24px'
  },
  '@global': {
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      border: '2px solid rgba(0, 124, 255, 1)',
      borderRadius: '0.5rem;',
      backgroundColor: 'white'
    },
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      border: '2px solid rgba(0, 124, 255, 1)',
      borderRadius: '0.5rem;',
      backgroundColor: 'white'
    }
  }
}));

interface ITreeNodeProps {
  node: ITreeNode;
  onClickUpdateOnCrate: any;
  onClickDelete: any;
}

const TreeNodeComponent: React.FC<ITreeNodeProps> = ({
  node,
  onClickUpdateOnCrate,
  onClickDelete
}) => {
  const classes = useStyles();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newNodeName, setNewNodeName] = useState('');
  const [newName, setNodeName] = useState('');

  const handleAddClick = () => {
    if (newNodeName) {
      onClickUpdateOnCrate({ id: null, parentId: node.id, name: newNodeName });
      setNewNodeName('');
    }
    setIsAdd(!isAdd);
  };

  const handleEditClick = () => {
    if (newName) {
      onClickUpdateOnCrate({ id: node.id, parentId: node.parentId, name: newName });
    }
    setIsEdit(!isEdit);
  };

  return (
    <TreeItem
      nodeId={node.id}
      classes={{
        iconContainer: classes.iconContainer
      }}
      onLabelClick={event => event.preventDefault()}
      label={
        <>
          <Grid container wrap="nowrap" alignItems="center" justify="space-between" spacing={1}>
            <Grid item xs={8}>
              {isEdit ? (
                <Input
                  name="name"
                  label="Название подразделения"
                  value={node.name}
                  placeholder="Введите название"
                  onMtsChange={e => setNodeName(e.detail.value)}
                />
              ) : (
                <div className={classes.typography}>{node.name}</div>
              )}
            </Grid>
            <Grid item xs={4}>
              <Grid container wrap="nowrap" alignItems="center" justify="flex-end" spacing={1}>
                {isEdit ? (
                  <>
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="floppy"
                        disabled={node.name === newName}
                        tooltip="Сохранить"
                        onClick={handleEditClick}
                      />
                    </Grid>
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="shape"
                        tooltip="Отмена"
                        onClick={() => setIsEdit(!isEdit)}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="edit"
                        tooltip="Редактировать"
                        onClick={() => {
                          setIsEdit(!isEdit);
                          setNodeName(node.name);
                          // setIsAdd(false);
                        }}
                      />
                    </Grid>
                    {!isAdd && (
                      <Grid item xs="auto" zeroMinWidth={true}>
                        <TableActionButton
                          style={{ float: 'right' }}
                          icon="plus"
                          tooltip="Добавить"
                          onClick={() => {
                            // setIsEdit(false);
                            setIsAdd(!isAdd);
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="bin"
                        tooltip="Удалить"
                        onClick={onClickDelete(node)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          {isAdd && (
            <div style={{ marginLeft: '20px', height: 'auto' }}>
              <Grid container wrap="nowrap" alignItems="center" justify="space-between" spacing={1}>
                <Grid item xs="auto">
                  <Input
                    name="name"
                    label="Название нового подразделения"
                    value={newNodeName}
                    onMtsChange={e => setNewNodeName(e.detail.value)}
                    placeholder="Введите название"
                    // useFieldConfig={{ validate: required }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Grid container wrap="nowrap" alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="floppy"
                        disabled={!newNodeName}
                        tooltip="Сохранить"
                        onClick={handleAddClick}
                      />
                    </Grid>
                    <Grid item xs="auto" zeroMinWidth={true}>
                      <TableActionButton
                        style={{ float: 'right' }}
                        icon="shape"
                        tooltip="Отмена"
                        onClick={() => setIsAdd(!isAdd)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </div>
          )}
        </>
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((children: any) => (
            <TreeNodeComponent
              key={children.id}
              node={children}
              onClickUpdateOnCrate={onClickUpdateOnCrate}
              onClickDelete={onClickDelete}
            />
          ))
        : null}
    </TreeItem>
  );
};

export default TreeNodeComponent;
