import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';

import { Dispatch, iRootState } from '../../../store';
import { IOffice, ICoordXY } from '../../../models/types';
import { paths } from '../../Root/paths';

import OfficeMarkerIcon from './OfficeMarkerIcon';
import { ReactComponent as InfrastructureSvg } from '../../../assets_/icons/infrastructure.svg';
import { makeStyles } from '@material-ui/core/styles';
import { getColor } from '../../../theme';

const useStyles = makeStyles(() => ({
  icon: ({ hidden }: IStyleProps) => ({
    color: hidden ? getColor('constant-greyscale-500') : getColor('constant-blueberry-light'),
    fontSize: 32,
    width: '1em',
    height: '1em'
  })
}));

const mapState = (state: iRootState) => ({
  activeItem: state.infrastructureOffices.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setOffice: d.infrastructureOffices.setOffice,
    focus: d.infrastructureOffices.focus
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
interface IStyleProps {
  edit?: boolean;
  hidden?: boolean;
}

type IProps = {
  office: IOffice | null | undefined;
  index: number;
} & IStyleProps &
  connectedProps;

const OfficeMarkerListItem: FC<IProps> = ({
  setOffice,
  activeItem,
  focus,
  office,
  index,
  edit
}) => {
  const { id, location = { lat: 0, lon: 0 } } = office || {};
  const classes = useStyles();
  const history = useHistory();

  const position: ICoordXY = [location.lat, location.lon];

  useEffect(() => {
    if (activeItem && activeItem.id === id) {
      focus(position);
    }
  }, [activeItem]);

  const renderIcon = edit ? (
    <InfrastructureSvg className={classes.icon} />
  ) : (
    <OfficeMarkerIcon size="m" index={index} />
  );

  const icon = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: edit ? [15, 15] : [32.5, 80],
    html: renderToString(renderIcon)
  });

  const handleClick = () => {
    localStorage.removeItem('mapId');

    setOffice(office);

    history.push(paths.map.office.root);
  };

  return <Marker position={position} icon={icon} onclick={handleClick} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(OfficeMarkerListItem);
