import React, { FC, useState, useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonTypes } from '@mts-ds/components/dist/types/components/button/enums';

type IProps = typeof MtsButton.defaultProps;

const FormButton: FC<IProps> = ({ children, ...props }) => {
  const { submitting, pristine, hasValidationErrors } = useFormState();
  const [isLoading, setIsLoading] = useState(false);

  // Задержка для состояния загрузки
  useEffect(() => {
    let timer: number;

    if (submitting) {
      timer = window.setTimeout(() => {
        setIsLoading(true);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
      setIsLoading(false);
    };
  }, [submitting]);

  const isDisabled = submitting || pristine || hasValidationErrors;

  return (
    <MtsButton
      id="buttonSubmit"
      type={'submit' as ButtonTypes}
      loading={isLoading}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </MtsButton>
  );
};

export default FormButton;
