import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../../store';
import { useAsync, useRequestHandler } from '../../../../../hooks';
import { paths } from '../../../../Root/paths';

import Spinner from '../../../../../components_/Spinner';
import Modal from '../../../../../components_/Modal';

import Form from './Form';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  office: state.infrastructureOffices.item,
  coordinates: state.infrastructureMap.coordinates
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getMap: d.infrastructureMap.getMap,
    setMap: d.infrastructureMap.setMap,
    updateOrCreate: d.infrastructureMap.updateOrCreate,
    remove: d.infrastructureMap.remove,
    resetMap: d.infrastructureMap.resetMap,
    setMarkerCreateMode: d.infrastructureMap.setMarkerCreateMode,
    setDefaultMode: d.infrastructureMap.setDefaultMode,
    setEditPlan: d.infrastructureMap.setEditPlan,
    setEditPlanMode: d.infrastructureMap.setEditPlanMode,
    resetCoordinates: d.infrastructureMap.resetCoordinates,
    setCoordinates: d.infrastructureMap.setCoordinates
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

interface IParams {
  id: string;
}

const PlanForm: FC<IProps> = ({
  map,
  office,
  coordinates,
  getMap,
  updateOrCreate,
  setMap,
  resetMap,
  remove,
  setEditPlan,
  setEditPlanMode,
  setCoordinates,
  resetCoordinates
}) => {
  const requestHandler = useRequestHandler();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getMap);

  const handleCancel = () => {
    resetCoordinates();
    resetMap();
    localStorage.removeItem('mapId');
    id ? getMap(id) : getMap();
    history.push(paths.map.office.root);
  };

  const handleSuccess = () => {
    resetCoordinates();
    resetMap();
    localStorage.removeItem('mapId');
    id ? getMap(id) : getMap();
    history.push(paths.map.office.root);
  };

  const handleRemove = () => {
    resetCoordinates();
    resetMap();
    localStorage.removeItem('mapId');
    getMap();
    history.push(paths.map.office.root);
  };

  const [openRemoveModal] = useMtsModal(Modal, {
    header: 'Удалить план',
    text:
      'Вы действительно хотите удалить план? С удалением плана будут удалены все связанные с ним элементы.',
    buttonTextConfirm: 'Удалить',
    ...(map && {
      onMtsModalConfirm: async () => {
        const error = await remove(map.id);

        requestHandler({
          error,
          entity: 'plan',
          onSuccess: handleRemove,
          isDelete: true
        });
      }
    })
  });

  useEffect(() => {
    setEditPlan(true);
    return () => {
      setEditPlan(false);
    };
  }, []);

  useEffect(() => {
    if (id) {
      runGetItem(id);
    }
  }, [id]);

  const isLoading = isLoadingGetItem;

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Form
          item={map}
          id={id}
          office={office}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          updateOrCreate={updateOrCreate}
          setMap={setMap}
          setEditPlanMode={setEditPlanMode}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
          onClickRemove={openRemoveModal}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(PlanForm);
