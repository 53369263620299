import React, { FC, useRef, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import L, { divIcon } from 'leaflet';
import { EditControl as EditControlLeaflet } from 'react-leaflet-draw';
import { makeStyles } from '@material-ui/core/styles';

import '../../../../node_modules/leaflet-draw/dist/leaflet.draw.css';

import { AreaModes, AreaTypes } from '../../../models/types';
import { ReactComponent as BeaconSvg } from '../../../assets_/icons/infrastructure.svg';
import { getColor } from '../../../theme';

L.drawLocal.draw.handlers.rectangle.tooltip.start = 'Кликните и перетащите, чтобы нарисовать.';
L.drawLocal.draw.handlers.circle.tooltip.start = 'Кликните и перетащите, чтобы нарисовать.';
L.drawLocal.draw.handlers.polygon.tooltip.start = 'Кликните, чтобы начать рисовать фигуру';
L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Кликните, чтобы продолжить рисование фигуры';
L.drawLocal.draw.handlers.polygon.tooltip.end =
  'Кликните на первую точку, чтобы завершить рисование фигуры';
L.drawLocal.draw.handlers.marker.tooltip.start = 'Кликните, чтобы поставить точку';
L.drawLocal.draw.handlers.simpleshape.tooltip.end =
  'Отпустите кнопку мыши, чтобы закончить рисование.';
L.drawLocal.draw.handlers.polyline.error =
  '<strong>Ошибка:</strong> линия не может самопересекаться!';
L.drawLocal.edit.handlers.edit.tooltip.text = 'Кликните и перетащите, чтобы редактировать.';
L.drawLocal.edit.handlers.edit.tooltip.subtext = '';

const useStyles = makeStyles(() => ({
  icon: {
    color: getColor('constant-blueberry-light'),
    fontSize: 32,
    width: '1em',
    height: '1em'
  }
}));

interface IProps {
  setAreaCoordinates: (coordinates: number[]) => void;
  setBeaconCoordinates: (coordinates: number[]) => void;
  setOfficeCoordinates: (coordinates: number[]) => void;
  setCameraCoordinates: (coordinates: number[]) => void;
  setMarkerEditMode: () => void;
  setBoxEditMode: () => void;
  setCircleEditMode: () => void;
  setPolygonEditMode: () => void;
  mode: AreaModes;
  type: AreaTypes;
}

const EditControl: FC<IProps> = ({
  mode,
  type,
  setAreaCoordinates,
  setBeaconCoordinates,
  setOfficeCoordinates,
  setCameraCoordinates,
  setMarkerEditMode,
  setBoxEditMode,
  setCircleEditMode,
  setPolygonEditMode
}) => {
  const classes = useStyles();
  const editControl = useRef<any>(null);

  useEffect(() => {
    // Переход в режим редактирования зоны
    if (mode === AreaModes.Edit) {
      editControl.current.leafletElement._toolbars.edit._modes.edit.handler.enable();
    }

    // Переход в режим рисования зоны
    if (mode === AreaModes.Create) {
      switch (type) {
        case AreaTypes.Box:
          editControl.current.leafletElement._toolbars.draw._modes.rectangle.handler.enable();
          break;
        case AreaTypes.Circle:
          editControl.current.leafletElement._toolbars.draw._modes.circle.handler.enable();
          break;
        case AreaTypes.Polygon:
          editControl.current.leafletElement._toolbars.draw._modes.polygon.handler.enable();
          break;
        case AreaTypes.Marker:
          editControl.current.leafletElement._toolbars.draw._modes.marker.handler.enable();
          break;
      }
    }

    if (mode === AreaModes.Default) {
      // Удаляем нарисованную при создании зону
      Object.values(
        editControl.current.leafletElement._toolbars.edit.options.featureGroup._layers
      ).forEach((item: any) => {
        if (!item.options.noRemove) {
          editControl.current.leafletElement._toolbars.edit.options.featureGroup.removeLayer(item);
        }
      });
    }

    return () => {
      // Выход из режима редактирования зоны
      if (mode === AreaModes.Edit) {
        if (editControl.current.leafletElement._toolbars.edit._modes.edit) {
          editControl.current.leafletElement._toolbars.edit._modes.edit.handler.disable();
        }
      }

      if (mode === AreaModes.Create) {
        // Выход из режима рисования зоны
        switch (type) {
          case AreaTypes.Box:
            if (editControl.current.leafletElement._toolbars.draw._modes.rectangle) {
              editControl.current.leafletElement._toolbars.draw._modes.rectangle.handler.disable();
            }
            break;
          case AreaTypes.Circle:
            if (editControl.current.leafletElement._toolbars.draw._modes.circle) {
              editControl.current.leafletElement._toolbars.draw._modes.circle.handler.disable();
            }
            break;
          case AreaTypes.Polygon:
            if (editControl.current.leafletElement._toolbars.draw._modes.polygon) {
              editControl.current.leafletElement._toolbars.draw._modes.polygon.handler.disable();
            }
            break;
          case AreaTypes.Marker:
            if (editControl.current.leafletElement._toolbars.draw._modes.marker) {
              editControl.current.leafletElement._toolbars.draw._modes.marker.handler.disable();
            }
            break;
        }
      }

      // if (mode === AreaModes.Create || mode === AreaModes.Edit) {
      //   // Удаляем нарисованную при создании зону
      //   Object.values(
      //     editControl.current.leafletElement._toolbars.edit.options.featureGroup._layers
      //   ).forEach((item: any) => {
      //     if (!item.options.noRemove) {
      //       editControl.current.leafletElement._toolbars.edit.options.featureGroup.removeLayer(
      //         item
      //       );
      //     }
      //   });
      // }
    };
  }, [mode, type]);

  useEffect(() => {
    if (mode === AreaModes.Create || mode === AreaModes.Edit) {
      // Удаляем нарисованную при создании зону
      Object.values(
        editControl.current.leafletElement._toolbars.edit.options.featureGroup._layers
      ).forEach((item: any) => {
        if (!item.options.noRemove) {
          editControl.current.leafletElement._toolbars.edit.options.featureGroup.removeLayer(item);
        }
      });
    }
  }, [type]);

  const handleDraw = (event: any) => {
    const editTypePolygon = event.poly && event.poly.options.type;
    const editType = event.layer && event.layer.options.type;

    const areaType = editTypePolygon || editType || type;
    let coordinates: number[] = [];

    switch (areaType) {
      case AreaTypes.Box:
        const { lat: southWestLat, lng: southWestLng } = event.layer._bounds._southWest;
        const { lat: northEastLat, lng: northEastLng } = event.layer._bounds._northEast;

        coordinates = [southWestLng, southWestLat, northEastLng, northEastLat];
        setBoxEditMode();
        break;
      case AreaTypes.Circle:
        const {
          _latlng: { lat, lng },
          _mRadius: radius
        } = event.layer;

        coordinates = [lng, lat, radius];
        setCircleEditMode();
        break;
      case AreaTypes.Polygon:
        const createLatLngs = event.layer && event.layer._latlngs[0];
        const editLatLngs = event.poly && event.poly.editing.latlngs[0][0];
        const latLngs = createLatLngs || editLatLngs;

        // tslint:disable-next-line: no-shadowed-variable
        coordinates = (latLngs as any[]).reduce((acc, { lat, lng }) => [...acc, lng, lat], []);
        setPolygonEditMode();
        break;
      case AreaTypes.Marker:
        const {
          _latlng: { lat: markerLat, lng: markerLng }
        } = event.layer;

        coordinates = [markerLng, markerLat];
        setMarkerEditMode();
        break;
    }

    if (areaType === AreaTypes.Marker) {
      setOfficeCoordinates(coordinates);
      setBeaconCoordinates(coordinates);
      setCameraCoordinates(coordinates);
      return;
    }

    setAreaCoordinates(coordinates);
  };

  const renderIcon = <BeaconSvg className={classes.icon} />;

  const icon = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [15, 15],
    html: renderToString(renderIcon)
  });

  return (
    <EditControlLeaflet
      draw={{
        polyline: false,
        circlemarker: false,
        circle: { showRadius: true },
        marker: {
          icon
        }
      }}
      edit={{
        remove: false
      }}
      ref={editControl}
      onCreated={handleDraw}
      onEditResize={handleDraw}
      onEditMove={handleDraw}
      onEditVertex={handleDraw}
    />
  );
};

export default EditControl;
