import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from '../../Root/paths';

import RolesList from './List/Roles';
import RightsFrom from './Form/Rights';
import ToggleButtons from './ToggleButtons';

const secTree: FC = () => {
  return (
    <Switch>
      {/* Списки */}
      <Route path={paths.settings.secTree.root}>
        <ToggleButtons />
        <Switch>
          <Route
            exact
            path={[paths.settings.secTree.roles.root, paths.settings.secTree.root]}
            component={RolesList}
          />
          <Route exact path={paths.settings.secTree.rights.root} component={RightsFrom} />
        </Switch>
      </Route>
    </Switch>
  );
};

export default secTree;
