/**
 * Модель для работы с настройками прав и ролей
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IId, IRoleType, IRole } from './types';

import UsersService from '../services/UsersService';
import adminService from '../services/AdminService';

interface IData {
  roles: IRoleType[];
  securableTypes: any;
  nodeACL: any;
}

interface IList {
  data: IRole[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  listRoles: IRole[] | null;
  secTreeHierarchy: {};
  roleItem: IRole | null;
}

const initialState: IState = {
  data: {
    roles: [],
    securableTypes: [],
    nodeACL: {}
  },
  list: {
    data: null,
    pagesCount: 0
  },
  listRoles: null,
  secTreeHierarchy: {},
  roleItem: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: { ...state.data, ...payload } };
    },
    setListRoles(state: IState, payload: IRole[]): IState {
      return { ...state, listRoles: payload };
    },
    // setList(state: IState, payload: IList): IState {
    //   return { ...state, list: payload };
    // },
    setHierarchy(state: IState, payload: IList): IState {
      return { ...state, secTreeHierarchy: payload };
    },
    setRoleItem(state: IState, payload: IRole): IState {
      return { ...state, roleItem: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [{ data: roles }] = await Promise.all([UsersService.getRoles()]);

        this.setData({ roles });
      } catch (error) {
        return error;
      }
    },
    async getListRoles() {
      try {
        const { data } = await UsersService.getRoles();

        this.setListRoles(data);
      } catch (error) {
        return error;
      }
    },
    // async getList(params, rootState: iRootState) {
    //   try {
    //     const { data } = await ProtectionsService.getTypeAll(params);
    //
    //     this.setList(data);
    //   } catch (error) {
    //     return error;
    //   }
    // },
    async getHierarchy(params, rootState: iRootState) {
      try {
        const { data } = await UsersService.getSecTreeHierarchy();

        this.setHierarchy(data);
      } catch (error) {
        return error;
      }
    },
    async getNodeACL(props) {
      try {
        const { data } = await UsersService.getNodeACL(props.root, props.roleId);
        const obj: any = {};
        data.forEach((item: { uid: IId; roleId: IId; action: IId; result: string }) => {
          if (!obj[item.uid]) {
            obj[item.uid] = {};
          }
          if (props.roleId) {
            obj[item.uid][item.action] = item.result === 'Grant';
          } else {
            if (!obj[item.uid][item.roleId + 'roleId']) {
              obj[item.uid][item.roleId + 'roleId'] = {};
            }
            obj[item.uid][item.roleId + 'roleId'][item.action] = item.result === 'Grant';
          }
        });

        this.setData({
          nodeACL: obj
        });
      } catch (error) {
        return error;
      }
    },
    async getSecurableTypes(id: IId) {
      try {
        const { data } = await adminService.getSecurableTypes(id);
        this.setData({
          securableTypes: Object.values(data)[0]
        });
      } catch (error) {
        return error;
      }
    },
    async getRoleItem(id: IId) {
      try {
        const { data } = await UsersService.getRole(id);

        this.setRoleItem(data);
      } catch (error) {
        return error;
      }
    },

    async updateOrCreate(body: any) {
      try {
        const roleBody = {
          id: body.id,
          name: body.name ? body.name : body.friendlyName,
          friendlyName: body.friendlyName
        };

        await UsersService.updateOrCreateRole(roleBody).then(({ data }) => {
          const output = Object.entries(body.actions).flatMap(([uid, actions]) => {
            // @ts-ignore
            return Object.entries(actions).map(([action, result]) => ({
              uid,
              action,
              roleId: data.id,
              result: result ? 'Grant' : 'Deny'
            }));
          });
          UsersService.updateOrCreateACL(output);
        });
      } catch (error) {
        return error;
      }
    },

    async updateOrCreateACL(body: any) {
      try {
        const { actions } = body;
        const resultArray = [];

        for (const uid of Object.keys(actions)) {
          for (const roleId of Object.keys(actions[uid])) {
            for (const action of Object.keys(actions[uid][roleId])) {
              resultArray.push({
                uid,
                roleId: parseInt(roleId, 10),
                action,
                result: actions[uid][roleId][action] ? 'Grant' : 'Deny'
              });
            }
          }
        }

        await UsersService.updateOrCreateACL(resultArray);
      } catch (error) {
        return error;
      }
    },

    async remove(id: IId) {
      try {
        await UsersService.deleteRole(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const settingSecTree: typeof model = createModel(model);
