import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import { IProtection } from '../../../../models/types';
import { useAsync, useRequestHandler, useParams } from '../../../../hooks';
import { IGetAllParams } from '../../../../services/ProtectionsService';

import Spinner from '../../../../components_/Spinner';
import Pagination from '../../../../components_/Pagination';
import EmptyState from '../../../../components_/EmptyState';
import Modal from '../../../../components_/Modal';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetAllParams, 'pageIndex'> = {
  fullTextSearch: ''
};

const mapState = (state: iRootState) => ({
  list: state.equipmentProtections.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getList: d.equipmentProtections.getList,
    unbind: d.equipmentProtections.unbind,
    remove: d.equipmentProtections.remove,
    reset: d.equipmentProtections.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({
  getList,
  unbind,
  remove,
  reset,
  list: { data: list, pagesCount }
}) => {
  const [active, setActive] = useState<IProtection | null>(null);

  const history = useHistory();
  const requestHandler = useRequestHandler();

  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const { pageIndex, filter, handlePageChange, handleFilterChange } = useParams(
    initialFilter,
    runGetList
  );

  const updateList = () => {
    getList({ ...filter, pageIndex });
  };

  const [openUnbindModal] = useMtsModal(Modal, {
    header: 'Открепление СИЗ',
    text: 'Вы действительно хотите открепить СИЗ?',
    buttonTextConfirm: 'Открепить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await unbind(active.id);

        requestHandler({
          error,
          entity: 'protection',
          isUnbind: true,
          onSuccess: updateList
        });
      }
    })
  });

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удаление СИЗ',
    text: 'Вы действительно хотите удалить СИЗ?',
    buttonTextConfirm: 'Удалить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await remove(active.id);

        requestHandler({
          error,
          entity: 'protection',
          isDelete: true,
          onSuccess: updateList
        });
      }
    })
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleClickCreate = () => {
    history.push(paths.equipment.protections.create);
  };

  const handleClickEdit = ({ id }: IProtection) => () => {
    history.push(paths.equipment.protections.edit(id));
  };

  const handleClickCopy = ({ id }: IProtection) => () => {
    history.push(paths.equipment.protections.copy(id));
  };

  const handleClickUnbind = (item: IProtection) => () => {
    setActive(item);
    openUnbindModal();
  };

  const handleClickDelete = (item: IProtection) => () => {
    setActive(item);
    openDeleteModal();
  };

  const isLoading = isLoadingGetList;
  const isLoadingError = isGetListLoadingError;

  return (
    <>
      <>
        <Filter filter={filter} onChange={handleFilterChange} onClickCreate={handleClickCreate} />
        {!isLoadingGetList && !isGetListLoadingError && (
          <>
            {!list && <EmptyState variant="generateReport" />}
            {list && (
              <>
                {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                {Boolean(list.length) && (
                  <>
                    <List
                      list={list}
                      onClickEdit={handleClickEdit}
                      onClickCopy={handleClickCopy}
                      onClickUnbind={handleClickUnbind}
                      onClickDelete={handleClickDelete}
                    />
                    <Pagination
                      pageIndex={pageIndex}
                      pagesCount={pagesCount}
                      onPageChange={handlePageChange}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
