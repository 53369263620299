import { IIcon } from '../../../assets_/icons';
import { paths } from '../../Root/paths';

interface IReport {
  icon: IIcon;
  title: string;
  description: string;
  url?: string;
}

const reports: IReport[] = [
  {
    icon: 'errorOutline',
    title: 'Нарушения',
    description:
      'Отчёт показывает нарушения пребывания в зоне с ограничением по времени, в зоне без доступа, в зоне без СИЗ, начало смены без СИЗ',
    url: paths.reports.violations
  },
  {
    icon: 'notifications',
    title: 'События',
    description:
      'Отчёт показывает события на производстве и в конкретных зонах - аварийные ситуации и нарушения',
    url: paths.reports.notifications
  },
  {
    icon: 'schedule',
    title: 'Рабочее время',
    description:
      'Отчёт показывает, в какое время сотрудник начинал и заканчивал смену, сколько длился перерыв, фактическое и общее время работы',
    url: paths.reports.workingTime
  },
  {
    icon: 'bubbleChart',
    title: 'Использование СИЗ',
    description:
      'Отчёт показывает, какой тип СИЗ использовал сотрудник за заданный промежуток времени и в каких зонах',
    url: paths.reports.useProtection
  },
  {
    icon: 'texture',
    title: 'Пребывание в зонах',
    description:
      'Отчёт показывает перемещение сотрудника по зонам (включая зоны особого доступа и с временными ограничениями)',
    url: paths.reports.stayInAreas
  },
  {
    icon: 'favoriteBorder',
    title: 'Критические состояния',
    description:
      'Отчёт показывает физические состояния сотрудников - обездвиженность, падение, превышение пульса',
    url: paths.reports.physicalState
  },
  {
    icon: 'intersectingBubbleChart',
    title: 'Тепловая карта',
    description: 'Частота пребывания сотрудников за определённый период времени',
    url: paths.reports.heatMap
  },
  {
    icon: 'pin',
    title: 'Треки',
    description: 'История перемещения сотрудников за выбранный период времени',
    url: paths.reports.tracks
  }
];

export { reports };
