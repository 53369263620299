import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MtsDivider } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../store';
import getSearchedData from '../../../../utils/getSearchedData';

import Typography from '../../../../components_/Typography';

import DrawerContent from '../../../../components_/Drawer/DrawerContent';
import DrawerContentCompany from '../../../../components_/Drawer/DrawerContentCompany';
import DrawerContentListSearch from '../../../../components_/Drawer/DrawerContentListSearch';
import EmptyState from '../../../../components_/EmptyState';

import Item from './Item';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import Button from '../../../../components_/Fields/Button';
import DrawerSticky from '../../../../components/Drawer/DrawerSticky';
import { guids, paths } from '../../../Root/paths';
import { useHistory } from 'react-router-dom';
import isSatisfied from '../../../../utils/isSatisfied';

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    marginTop: spacing(3)
  },
  title: {
    margin: spacing(3, 0, 1)
  },
  button: {
    width: '100%',
    padding: spacing(2, 4, 2)
  }
}));

const mapState = (state: iRootState) => ({
  offices: state.infrastructureOffices.list,
  rights: state.securable.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setMarkerCreateMode: d.infrastructureMap.setMarkerCreateMode,
    getOffices: d.infrastructureOffices.getOffices,
    resetSetOffice: d.infrastructureOffices.resetSetOffice,
    reset: d.infrastructureOffices.reset,
    resetMap: d.infrastructureMap.resetMap
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Offices: FC<IProps> = ({
  getOffices,
  resetSetOffice,
  reset,
  offices,
  setMarkerCreateMode,
  rights,
  resetMap
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    getOffices();

    return () => {
      reset();
    };
  }, []);

  const handleActionClick = async () => {
    resetSetOffice();
    localStorage.removeItem('mapId');
    resetMap();
    setMarkerCreateMode();
    history.push(paths.map.office.create);
  };

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const checkAccess = isSatisfied(
    { ...{ uid: guids.map.office.create }, ...{ action: 'Visibility' } },
    rights
  );

  const searchedOffices = getSearchedData(offices, ['name'], search);
  const searchedOfficesLength = (searchedOffices || []).length;
  return (
    <>
      <DrawerContent>
        <DrawerContentCompany />
        <MtsDivider className={classes.divider} />
        <Typography variant="p1Bold" className={classes.title}>
          Все места
        </Typography>
        <DrawerContentListSearch
          placeholder="Найти место"
          value={search}
          onMtsChange={handleChangeSearch}
        />
        {searchedOffices && (
          <>
            {searchedOffices.map((office, index) => (
              <Item key={office.id} office={office} index={index + 1} />
            ))}
            {!searchedOfficesLength && <EmptyState variant="emptyRequest" />}
          </>
        )}
        {checkAccess && (
          <DrawerSticky>
            <Button
              icon="plus"
              color={'secondary' as ButtonColors}
              className={classes.button}
              onClick={handleActionClick}
            >
              Добавить новое место
            </Button>
          </DrawerSticky>
        )}
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Offices);
