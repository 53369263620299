/**
 * Модель для работы с уведомлениями
 */

import { createModel, ModelConfig } from '@rematch/core';

import NotificationsService, { IBody } from '../services/NotificationsService';
import UsersService, { IShortUsersParams } from '../services/UsersService';
import { IShortUser } from './types';
import getSubmitError from '../utils/getSubmitError';

interface IState {
  users: IShortUser[];
}

const initialState: IState = {
  users: []
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUsers(state: IState, payload: IShortUser[]): IState {
      return {
        ...state,
        users: payload
      };
    },
    reset() {
      return initialState;
    }
  },
  effects: {
    async getUsers(filter: IShortUsersParams) {
      const { data } = await UsersService.getAllShort(filter);

      this.setUsers(data);
    },
    async sendNotification(body: IBody) {
      try {
        await NotificationsService.send(body);
      } catch (error) {
        return error;
      }
    }
  }
};

export const infrastructureSending: typeof model = createModel(model);
