import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../../store';
import { useAsync } from '../../../../../../hooks';

import Spinner from '../../../../../../components_/Spinner';

import Card from './Card';
import Attributes from './Attributes';

const mapState = (state: iRootState) => ({
  item: state.infrastructureCameras.item,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getItem: d.infrastructureCameras.getItem,
    reset: d.infrastructureCameras.reset,
    setMarkerEditMode: d.infrastructureMap.setMarkerEditMode
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IParams {
  id: string;
}

const CameraShow: FC<IProps> = ({ item, getItem, reset, setMarkerEditMode, isGeo }) => {
  const { id } = useParams<IParams>();
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    runGetItem(id);
  }, [id]);

  const isLoading = isLoadingGetItem;
  const isLoadingError = isGetItemLoadingError;
  // console.log(isGetItemLoadingError);
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && item && (
        <>
          <Card item={item} setMarkerEditMode={setMarkerEditMode} />
          <Attributes item={item} isGeo={isGeo} />
        </>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(CameraShow);
