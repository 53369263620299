import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { MtsButton, MtsCard, MtsCardBody } from '@mts-ds/components-react';
import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './index';
import useRequestHandler from '../../../../../hooks/useRequestHandler';
import { required } from '../../../../../utils/validation';

import Typography from '../../../../../components_/Typography';
import Icon from '../../../../../components_/Icon';
import FormBase from '../../../../../components_/Form';
import FormGroup from '../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../components_/Form/FormRow';
import FormCol from '../../../../../components_/Form/FormCol';
import FormInput from '../../../../../components_/Form/FormInput';
import DrawerStickyActions from '../../../../../components_/Drawer/DrawerStickyActions';
import { IUpdateOfficeBody } from '../../../../../services/MapsService';

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%'
  },
  card: {
    margin: spacing(3, 0)
  },
  cardBody: {
    display: 'flex',
    padding: spacing(2),
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  icon: {
    fontSize: 36
  }
}));

type IValues = IUpdateOfficeBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  // data: IFormProps['data'];
  item: any;
  coordinates: IFormProps['coordinates'];
  updateOrCreate: any;
  onCancel: any;
  onSuccess: any;
  onClickRemove: any;
}

const Form: FC<IProps> = ({
  // data,
  item,
  coordinates,
  updateOrCreate,
  onCancel,
  onSuccess,
  onClickRemove
}) => {
  // const { types } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const isCreate = !Boolean(item);
  const isEdit = Boolean(item);

  // const defaultValues: IValues = {
  //   id: 0,
  //   name: '',
  //   address: '',
  //   lat: '',
  //   lon: '',
  //   employeesCount: 0,
  //   maps: []
  // };

  const initialValues: IValues = {
    // ...defaultValues,
    ...(isEdit && item),
    ...(isEdit && item.location && { lon: item.location.lon, lat: item.location.lat })
  };

  const onSubmit = async (values: IValues) => {
    const body = {
      id: values.id,
      address: values.address,
      name: values.name,
      location: { lat: values.lat, lon: values.lon, alt: 0 }
    };
    const error = await updateOrCreate(body);

    requestHandler({
      error,
      entity: 'office',
      onSuccess,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit, form: { change } }) => {
    if (coordinates) {
      change('lat', coordinates[1] as any);
      change('lon', coordinates[0] as any);
    }

    return (
      <FormBase onSubmit={handleSubmit}>
        <Typography variant="h3Bold">{(item && item.name) || 'Добавить новое место'}</Typography>
        <MtsCard className={classes.card}>
          <MtsCardBody className={classes.cardBody}>
            <span style={{ width: '36px', height: '36px' }}>
              <Icon name="placeMarkerDrag" className={classes.icon} />
            </span>
            <Typography variant="p3Regular">Установите пин вручную в нужной точке</Typography>
          </MtsCardBody>
        </MtsCard>
        <FormGroup title="" size="s">
          <FormRow>
            <FormCol>
              <FormInput
                name="name"
                label="Название"
                // placeholder="00:00:00:00:00:00"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
                // disabled={isEdit}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="address"
                label="Адрес"
                description="Город, улица, номер дома"
                // placeholder="00:00:00:00:00:00"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
                // disabled={isEdit}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="lat"
                label="Широта"
                placeholder="Введите широту"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
                disabled
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="lon"
                label="Долгота"
                placeholder="Введите долготу"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
                disabled
              />
            </FormCol>
          </FormRow>
        </FormGroup>
        {isEdit && (
          <MtsButton
            size={'s' as ButtonSizes}
            color={'secondary' as ButtonColors}
            onClick={onClickRemove}
            className={classes.button}
          >
            Удалить место
          </MtsButton>
        )}
        <DrawerStickyActions cancel="Отмена" confirm="Сохранить" onClickCancel={onCancel} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
