import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import { ImageOverlay as ImageOverlayMap, useLeaflet } from 'react-leaflet';

import { Dispatch, iRootState } from '../../../store';
import getBlobImage from '../../../utils/getBlobImage';
import { EditPlanMode } from '../../../models/types';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  control: state.infrastructureMap.control,
  office: state.infrastructureOffices.item,
  isGeo: state.infrastructureMap.layers.isGeo,
  editPlan: state.infrastructureMap.editPlan,
  planImg: state.infrastructureMap.planImg
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getMap: d.infrastructureMap.getMap,
    resetMap: d.infrastructureMap.resetMap,
    setControl: d.infrastructureMap.setControl,
    setCoordinates: d.infrastructureMap.setCoordinates,
    getPlanImg: d.infrastructureMap.getPlanImg,
    resetPlanImg: d.infrastructureMap.resetPlanImg
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = { mode: EditPlanMode } & connectedProps;

const ImageOverlay: FC<IProps> = ({
  map,
  office,
  getMap,
  resetMap,
  isGeo,
  setControl,
  setCoordinates,
  control,
  editPlan,
  mode,
  getPlanImg,
  planImg,
  resetPlanImg
}) => {
  const { previewFormat, preview, gbox, bbox } = map;
  const { map: useMap } = useLeaflet();

  useEffect(() => {
    return () => {
      resetPlanImg();
      resetMap();
    };
  }, []);

  useEffect(() => {
    getMap();
  }, [office]);
  useEffect(() => {
    if (map.id) {
      resetPlanImg();
      getPlanImg(map.id);
    }
  }, [map.id]);

  // Устанавливаем zoom, чтобы был виден весь план

  useEffect(() => {
    if (useMap && map.id) {
      // console.log(map);
      useMap.fitBounds(
        isGeo
          ? [
              new LatLng(gbox[5], gbox[4]),
              new LatLng(gbox[7], gbox[6]),
              new LatLng(gbox[1], gbox[0]),
              new LatLng(gbox[3], gbox[2])
            ]
          : [new LatLng(bbox[1], bbox[0]), new LatLng(bbox[3], bbox[2])],
        {
          padding: [150, 150],
          maxZoom: 20
        }
      );

      setControl({
        ...control,
        zoom: useMap.getZoom(),
        center: useMap.getCenter()
      });
    } else if (office && !map.id) {
      setControl({
        zoom: 19.5,
        center: {
          lat: office.location.lat,
          lng: office.location.lon
        }
      });
    }
  }, [map, isGeo]);

  const url = getBlobImage(preview, previewFormat);

  const LatLng = L.latLng as any;
  const onCornersUpdated = (corners: any) => {
    const coordArray = [
      corners[2].lng,
      corners[2].lat,
      corners[3].lng,
      corners[3].lat,
      corners[0].lng,
      corners[0].lat,
      corners[1].lng,
      corners[1].lat
    ];
    setCoordinates(coordArray);
  };

  return (
    <>
      {isGeo ? (
        <ReactDistortableImageOverlay
          key={editPlan && preview ? url : map.contentId}
          url={editPlan && preview ? url : planImg}
          opacity={editPlan ? 0.4 : 1}
          onCornersUpdated={onCornersUpdated}
          editMode={editPlan ? mode : false}
          corners={[
            new LatLng(gbox[5], gbox[4]),
            new LatLng(gbox[7], gbox[6]),
            new LatLng(gbox[1], gbox[0]),
            new LatLng(gbox[3], gbox[2])
          ]}
        />
      ) : (
        <ImageOverlayMap
          key={map.contentId}
          url={planImg}
          bounds={[new LatLng(bbox[1], bbox[0]), new LatLng(bbox[3], bbox[2])]}
          zIndex={0}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(ImageOverlay);
