import React, { FC } from 'react';

import { IProps as ICamerasProps } from '../../Cameras/List';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Dropdown from '../../../../components_/Fields/Dropdown';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import Button from '../../../../components_/Fields/Button';
import checkFilter from '../../../../utils/checkFilter';
import Input from '../../../../components_/Fields/Input';

interface IProps {
  data: ICamerasProps['data'];
  filter: any;
  onChange: any;
  onReset: () => void;
}

const Filter: FC<IProps> = ({ data, filter, onChange, onReset }) => {
  const { offices } = data;
  const { plans, name } = filter;
  const { isShowReset } = checkFilter(filter);

  // tslint:disable-next-line:no-shadowed-variable
  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={2}>
          <Input
            name="name"
            type="search"
            placeholder="Название камеры"
            clear
            value={name}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={2}>
          <Dropdown
            name="plans"
            label="Место/План"
            placeholder="Выберите уровень"
            groupOptions={officeOptions}
            value={plans}
            onMtsChange={onChange}
          />
        </FormCol>
        {isShowReset && (
          <FormCol xs={3}>
            <Button color={'ghost' as ButtonColors} onClick={onReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
