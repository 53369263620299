import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { IColor } from '../../../theme';

type IVariant = 'square' | 'circle';

type IVariants = {
  [key in IVariant]: {
    borderRadius: string | number;
  };
};

const variants: IVariants = {
  square: {
    borderRadius: 8
  },
  circle: {
    borderRadius: '50%'
  }
};

type ISize = 's' | 'l' | 'xl' | 'xxl';

type ISizes = {
  [key in ISize]: {
    size: number;
  };
};

const sizes: ISizes = {
  s: {
    size: 36
  },
  l: {
    size: 44
  },
  xl: {
    size: 48
  },
  xxl: {
    size: 64
  }
};

const useStyles = makeStyles(({ getColor }) => ({
  root: ({ variant, size, color }: IStyleProps) => ({
    backgroundColor: color ? getColor(color) : getColor('constant-greyscale-0'),
    width: sizes[size].size,
    height: sizes[size].size,
    borderRadius: variants[variant].borderRadius
  })
}));

interface IStyleProps {
  variant: IVariant;
  size: ISize;
  color?: IColor;
  className?: string;
}

// tslint:disable-next-line: no-empty-interface
interface IProps extends IStyleProps {}

const IconWrapper: FC<IProps> = ({ children, className, ...props }) => {
  const classes = useStyles({ ...props });

  return (
    <Grid container justify="center" alignItems="center" className={clsx(classes.root, className)}>
      {children}
    </Grid>
  );
};

export default IconWrapper;
