/**
 *  Функция возвращает url
 */

const createUrl = (url: string, params: object): string => {
  const result = new URL(url);

  Object.entries(params).map(([key, value]) => result.searchParams.set(key, value));

  return result.toString();
};

export default createUrl;
