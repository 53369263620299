/**
 * Модель для работы с инфраструктурой
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IShortUser, INotificationType, INotificationTarget, INotification } from './types';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import NotificationsService from '../services/NotificationsService';
import UsersService from '../services/UsersService';

interface IData {
  users: IShortUser[];
  notificationTypes: INotificationType[];
  notificationTargets: INotificationTarget[];
}

interface IList {
  data: INotification[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    users: [],
    notificationTypes: [],
    notificationTargets: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          { data: users },
          { data: notificationTypes },
          { data: notificationTargets }
        ] = await Promise.all([
          UsersService.getAllShort(),
          NotificationsService.getTypesAll(),
          NotificationsService.getTargetsAll()
        ]);

        this.setData({ users, notificationTypes, notificationTargets });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      const cloneParams = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await NotificationsService.getAll(cloneParams);

        this.setList(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const notifications: typeof model = createModel(model);
