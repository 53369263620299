import React, { FC } from 'react';

import Table from '../../../../../components_/Table';
import TableColText from '../../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../../components_/Table/TableActionGroup';
import { securableTypes } from '../../../../../constants';
import FormSwitch from '../../../../../components_/Form/FormSwitch';

export interface IProps {
  treeItem: string;
  list: any;
}

const List: FC<IProps> = ({ list, treeItem }) => {
  const rows = list.map((item: string) => ({
    items: {
      friendlyName: <TableColText>{securableTypes[item].name}</TableColText>,
      actions: (
        <TableActionGroup>
          <FormSwitch key={treeItem + '.' + item} name={'actions.' + treeItem + '.' + item} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'Название',
      field: 'friendlyName'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
