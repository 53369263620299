import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import { IDevice } from '../../../../models/types';
import { useAsync, useRequestHandler, useParams } from '../../../../hooks';
import { IGetAllParams } from '../../../../services/DeviceService';

import Spinner from '../../../../components_/Spinner';
import Pagination from '../../../../components_/Pagination';
import EmptyState from '../../../../components_/EmptyState';
import Modal from '../../../../components_/Modal';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetAllParams, 'pageIndex'> = {
  fullTextSearch: ''
};

const mapState = (state: iRootState) => ({
  data: state.equipmentDevices.data,
  list: state.equipmentDevices.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getList: d.equipmentDevices.getList,
    getData: d.equipmentDevices.getData,
    unbind: d.equipmentDevices.unbind,
    remove: d.equipmentDevices.remove,
    reset: d.equipmentDevices.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({
  getData,
  getList,
  unbind,
  remove,
  reset,
  data,
  list: { data: list, pagesCount }
}) => {
  const [active, setActive] = useState<IDevice | null>(null);

  const history = useHistory();
  const requestHandler = useRequestHandler();

  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const { pageIndex, filter, handlePageChange, handleFilterChange, handleFilterReset } = useParams(
    initialFilter,
    runGetList
  );

  const updateList = () => {
    getList({ ...filter, pageIndex });
  };

  const [openUnbindModal] = useMtsModal(Modal, {
    header: 'Открепление носимого устройства',
    text: 'Вы действительно хотите открепить носимое устройство?',
    buttonTextConfirm: 'Открепить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await unbind(active.id);

        requestHandler({
          error,
          entity: 'device',
          isUnbind: true,
          onSuccess: updateList
        });
      }
    })
  });

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удаление носимого устройства',
    text: 'Вы действительно хотите удалить носимое устройство?',
    buttonTextConfirm: 'Удалить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await remove(active.id);

        requestHandler({
          error,
          entity: 'device',
          isDelete: true,
          onSuccess: updateList
        });
      }
    })
  });

  useEffect(() => {
    runGetData();
    return () => {
      reset();
    };
  }, []);

  const handleClickCreate = () => {
    history.push(paths.equipment.devices.create);
  };

  const handleClickEdit = ({ id }: IDevice) => () => {
    history.push(paths.equipment.devices.edit(id));
  };

  const handleClickCopy = ({ id }: IDevice) => () => {
    history.push(paths.equipment.devices.copy(id));
  };

  const handleClickUnbind = (device: IDevice) => () => {
    setActive(device);
    openUnbindModal();
  };

  const handleClickDelete = (device: IDevice) => () => {
    setActive(device);
    openDeleteModal();
  };

  const isLoading = isLoadingGetData || isLoadingGetList;
  const isLoadingError = isGetDataLoadingError || isGetListLoadingError;

  return (
    <>
      {!isLoadingGetData && !isGetDataLoadingError && (
        <>
          <Filter
            data={data}
            filter={filter}
            onChange={handleFilterChange}
            onReset={handleFilterReset}
            onClickCreate={handleClickCreate}
          />
          {!isLoadingGetList && !isGetListLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                  {Boolean(list.length) && (
                    <>
                      <List
                        list={list}
                        onClickEdit={handleClickEdit}
                        onClickCopy={handleClickCopy}
                        onClickUnbind={handleClickUnbind}
                        onClickDelete={handleClickDelete}
                      />
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
