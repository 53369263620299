import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import icons, { IIcon } from '../../assets_/icons';
import { IColor } from '../../theme';

enum Sizes {
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl'
}

type ISizes = {
  [key in Sizes]: number;
};

const sizes: ISizes = {
  s: 16,
  m: 24,
  l: 32,
  xl: 44,
  xxl: 64
};

const useStyles = makeStyles(({ getColor }) => ({
  root: ({ size, color }: IStyleProps) => ({
    fontSize: sizes[size],
    ...(color && { color: getColor(color) }),
    width: '1em',
    height: '1em'
  })
}));

interface IStyleProps {
  size: keyof typeof Sizes;
  color?: IColor;
}

export interface IProps {
  name: IIcon;
  size?: keyof typeof Sizes;
  color?: IColor;
  className?: string;
}

const Icon: FC<IProps> = ({ name, size = Sizes.m, color, className }) => {
  const classes = useStyles({ size, color });

  const IconEl = icons[name];

  return <IconEl className={clsx(classes.root, className)} />;
};

export default Icon;
