import React, { ChangeEvent, FC, useState } from 'react';
import { useField } from 'react-final-form';

import FormGroup from '../FormGroup';

import FileInput, { IProps as IFileInputProps } from '../../Fields/FileInput';

type IProps = {
  name: string;
  label: string;
  placeholder?: string;
} & IFileInputProps;

const FormFileInput: FC<IProps> = ({ name, label, placeholder, ...props }) => {
  const { input } = useField(name);
  // const [img, setImg] = useState<string | null>(null);
  // const handleChange = (delegate: IDelegate) => (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //
  //   delegate(file);
  //   if (file) {
  //     const reader = new FileReader();
  //
  //     reader.onload = event => {
  //       if (event.target) {
  //         setImg(event.target.result as string);
  //       }
  //     };
  //
  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    // <div>
    //   {/*<label htmlFor="file">{label}</label>*/}
    //   {/*<FormGroup label={label} description={placeholder}>*/}
    //   <input
    //     id="file"
    //     type="file"
    //     placeholder={placeholder}
    //     onChange={handleChange(input.onChange)}
    //   />
    //   {/*</FormGroup>*/}
    //   {/* <p>placeholder</p>*/}
    // </div>
    <FileInput {...props} label={label} placeholder={placeholder} onChange={input.onChange} />
  );
};

export default FormFileInput;
