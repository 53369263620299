import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { iRootState } from '../../../store';
import { paths } from '../../Root/paths';

import BeaconMarkerListItem from './BeaconMarkerListItem';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  layers: state.infrastructureMap.layers
});

type connectedProps = ReturnType<typeof mapState>;

type IProps = connectedProps;

interface IBeaconParams {
  mac: string;
}

const UserMarkerList: FC<IProps> = ({ map, layers }) => {
  const { beacons } = map;
  const { isInfrastructure } = layers;

  const beaconEditMatch = useRouteMatch<IBeaconParams>(paths.map.office.infrastructure.edit());

  const beaconParamMac = beaconEditMatch ? beaconEditMatch.params.mac : null;
  const beaconList = beaconParamMac
    ? beacons.filter(item => item.macAddress !== beaconParamMac)
    : beacons;

  return (
    <>
      {isInfrastructure &&
        beaconList.map(beacon => (
          <BeaconMarkerListItem
            key={beacon.macAddress}
            item={beacon}
            hidden={Boolean(beaconParamMac)}
          />
        ))}
    </>
  );
};

const withConnect = connect(mapState);

export default withConnect(UserMarkerList);
