import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { MtsAvatar } from '@mts-ds/components-react';

import { Dispatch } from '../../../store';
import { IUser } from '../../../models/types';
import { paths } from '../../../routes/Root/paths';
import getUserBlobImage from '../../../utils/getUserBlobImage';
import getFullName from '../../../utils/getFullName';
import { getUserStatuses } from '../../../routes/Infrastructure/Users/utils';

import Typography, { IProps as ITypographyProps } from '../../Typography';
import Icon from '../../Icon';

import DrawerContentItem from '../DrawerContentItem';

const useStyles = makeStyles(({ spacing }) => ({
  error: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  }
}));

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    focusOn: d.infrastructureUsers.focusOn
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

enum Variants {
  default = 'default',
  card = 'card',
  form = 'form'
}

type IVariants = {
  [key in Variants]: {
    typographyTitleVariant: ITypographyProps['variant'];
    fullNameShort: boolean;
    isDescription: boolean;
    isError: boolean;
  };
};

type IProps = {
  isLoading?: boolean;
  user: IUser | null;
  variant?: keyof typeof Variants;
  isClickable?: boolean;
  action?: ReactNode;
} & connectedProps;

const DrawerContentItemUser: FC<IProps> = ({
  focusOn,
  isLoading,
  user,
  variant = Variants.default,
  isClickable = false,
  action
}) => {
  const { username, title, brigadeName, photoContent = '', photoContentType = '', trackInfo } =
    user || {};

  const classes = useStyles();
  const history = useHistory();

  const variants: IVariants = {
    default: {
      typographyTitleVariant: 'p2Bold',
      fullNameShort: true,
      isDescription: true,
      isError: true
    },
    card: {
      typographyTitleVariant: 'p1Bold',
      fullNameShort: false,
      isDescription: true,
      isError: false
    },
    form: {
      typographyTitleVariant: 'h3Bold',
      fullNameShort: false,
      isDescription: false,
      isError: false
    }
  };

  const handleClick = () => {
    focusOn();
    history.push(paths.map.office.users.show(username));
  };

  const isOnline = trackInfo && trackInfo.online;
  const isStates =
    Boolean(trackInfo && trackInfo.states && getUserStatuses(trackInfo.states).length) &&
    variants[variant].isError;

  const icon = isLoading ? (
    <Skeleton variant="circle" width={48} height={48} />
  ) : (
    <>{user && <MtsAvatar size={32} image={getUserBlobImage(user)} />}</>
  );

  const fullName = user ? getFullName(user, variants[variant].fullNameShort) : '';

  const description = [title, brigadeName].filter(item => item).join(', ');

  const content = (
    <>
      <Typography variant={variants[variant].typographyTitleVariant}>
        {isLoading ? <Skeleton /> : fullName}
      </Typography>
      {variants[variant].isDescription && (
        <Typography variant="p2Regular" color="text-secondary">
          {isLoading ? <Skeleton /> : description}
        </Typography>
      )}
      {isStates && (
        <Grid container alignItems="center" className={classes.error}>
          <Icon name="error" size="s" />
          <Typography variant="p3Regular" color="text-secondary">
            У сотрудника проблема
          </Typography>
        </Grid>
      )}
    </>
  );

  return (
    <DrawerContentItem
      variant={variant}
      icon={icon}
      content={content}
      action={isLoading ? undefined : action}
      {...(isClickable && { onClick: handleClick })}
    />
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(DrawerContentItemUser);
