import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge, MtsDivider, MtsButton } from '@mts-ds/components-react';
import {
  ButtonFormats,
  ButtonSizes,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import Typography from '../../../../../../components_/Typography';
import Icon from '../../../../../../components_/Icon';

import { getTimeFromMinutes } from '../../../../../../utils/convertTime';
import { IArea } from '../../../../../../models/types';
import { IData } from '../../../../../../models/infrastructureAreas';
import { getColor } from '../../../../../../theme';

import AttributesUserList from './AttributesUserList';

const useStyles = makeStyles(({ spacing }) => ({
  list: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  danger: {
    '--color-text-primary': getColor('constant-greyscale-0'),
    '--color-background-secondary': getColor('accent-negative')
  },
  protections: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  divider: {
    margin: spacing(2, 0)
  }
}));

interface IProps {
  area: IArea;
  data: IData;
}

const Attributes: FC<IProps> = ({ area, data }) => {
  const {
    dangerClassId,
    workStart,
    workEnd,
    workDurationMinutes,
    protectionsList: areaProtectionIds,
    employees
  } = area;

  const { dangers, protections: dataProtections } = data;

  const classes = useStyles({ dangerClassId });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenUsers = () => {
    setIsOpen(true);
  };

  const handleCloseUsers = () => {
    setIsOpen(false);
  };

  const { name: dangerName = '' } = dangers.find(({ id }) => id === dangerClassId) || {};

  const [allowedHours, allowedMinutes] = getTimeFromMinutes(workDurationMinutes);

  const isDangerName = Boolean(dangerName);
  const isWorkTime = Boolean(workStart && workEnd);
  const isAllowedTime = Boolean(allowedHours || allowedMinutes);

  const protections = dataProtections.filter(dataProtection =>
    areaProtectionIds.includes(dataProtection.id)
  );

  return (
    <>
      <MtsDivider className={classes.divider} />
      <Grid className={classes.list}>
        {isDangerName && <MtsBadge className={classes.danger}>{dangerName}</MtsBadge>}

        {isWorkTime && (
          <Grid className={classes.label} container>
            <Typography variant="p2Medium">Рабочее время зоны:</Typography>
            <Typography variant="p2Regular">{`${workStart}-${workEnd}`}</Typography>
          </Grid>
        )}

        {isAllowedTime && (
          <Grid className={classes.label} container>
            <Typography variant="p2Medium">Допустимое время нахождения в зоне:</Typography>
            <Typography variant="p2Regular">{`${allowedHours} ч ${allowedMinutes} мин`}</Typography>
          </Grid>
        )}
      </Grid>

      {(isDangerName || isWorkTime || isAllowedTime) && <MtsDivider className={classes.divider} />}

      <Grid className={classes.protections}>
        <Grid container alignItems="center" className={classes.label}>
          <Typography variant="p1Bold">Обязательные СИЗ</Typography>
          <MtsBadge type="counter" size="s" counterValue={protections.length} />
        </Grid>
        {Boolean(protections.length) && (
          <Grid container spacing={1}>
            {protections.map(({ id, name }) => (
              <Grid item key={id}>
                <MtsBadge>{name}</MtsBadge>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <MtsDivider className={classes.divider} />

      <Grid container justify="space-between">
        <Grid item xs container alignItems="center" className={classes.label}>
          <Typography variant="p1Bold">Доступ сотрудникам</Typography>
          <MtsBadge type="counter" size="s" counterValue={employees.length} />
        </Grid>
        {Boolean(employees.length) && (
          <Grid item>
            <MtsButton
              format={'icon-round' as ButtonFormats}
              size={'s' as ButtonSizes}
              color={'ghost' as ButtonColors}
              onClick={handleOpenUsers}
            >
              <Grid container justify="center" alignItems="center">
                <Icon name="arrowRight" size="s" />
              </Grid>
            </MtsButton>
          </Grid>
        )}
      </Grid>

      <MtsDivider className={classes.divider} />

      {isOpen && <AttributesUserList area={area} onClose={handleCloseUsers} />}
    </>
  );
};

export default Attributes;
