import React, { FC } from 'react';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody, MtsBadge } from '@mts-ds/components-react';

import NotificationIcon from '../../../../components_/Notification/NotificationIcon';
import NotificationTarget from '../../../../components_/Notification/NotificationTarget';
import Typography from '../../../../components_/Typography';

import { IProps as IFormProps } from './';
import StrTemplate from '../StrTemplate';

const useStyles = makeStyles(({ spacing, getColor }) => ({
  root: {
    marginBottom: spacing(5)
  },
  body: {
    color: getColor('text-primary')
  }
}));

interface IProps {
  data: IFormProps['data'];
}

const FormCard: FC<IProps> = ({ data }) => {
  const { notificationTypes, notificationVariables } = data;

  const classes = useStyles();
  const {
    values: { notificationClass, name, template, target }
  } = useFormState();

  const { descr: notificationClassDescr, targets = [] } =
    notificationTypes.find(item => item.class === notificationClass) || {};

  const { descr: targetDescr, targetSimple } = targets.find(item => item.target === target) || {};

  return (
    <MtsCard className={classes.root}>
      <MtsCardBody className={classes.body}>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <NotificationIcon notificationClass={notificationClass} />
          </Grid>
          <Grid item xs>
            <MtsBadge size="s">{notificationClassDescr || 'Тип события'}</MtsBadge>
            <Typography variant="p1Medium">{name || 'Название шаблона'}</Typography>
            <Typography variant="p2Regular">
              <StrTemplate
                text={template || '{default}'}
                notificationVariables={
                  template
                    ? notificationVariables
                    : [{ placeholder: 'default', descr: 'Текст уведомления' }]
                }
              />
            </Typography>
          </Grid>
          <Grid item xs={3} container>
            <MtsBadge size="s">{targetDescr || 'Получатели уведомления'}</MtsBadge>
          </Grid>
          <Grid item xs={1}>
            {targetSimple && <NotificationTarget targetSimple={targetSimple} />}
          </Grid>
        </Grid>
      </MtsCardBody>
    </MtsCard>
  );
};

export default FormCard;
