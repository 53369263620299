import localStorageConstants from '../constants/localStorageConstants';

interface ISetTokenParams {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

const LocalStorageService = {
  setToken: ({ access_token, refresh_token, token_type }: ISetTokenParams) => {
    localStorage.setItem(localStorageConstants.accessToken, access_token);
    localStorage.setItem(localStorageConstants.refreshToken, refresh_token);
    localStorage.setItem(localStorageConstants.tokenType, token_type);
  },
  getToken: () => ({
    accessToken: localStorage.getItem(localStorageConstants.accessToken),
    refreshToken: localStorage.getItem(localStorageConstants.refreshToken),
    tokenType: localStorage.getItem(localStorageConstants.tokenType)
  }),
  clearToken: () => {
    localStorage.removeItem(localStorageConstants.accessToken);
    localStorage.removeItem(localStorageConstants.refreshToken);
    localStorage.removeItem(localStorageConstants.tokenType);
  }
};

export default LocalStorageService;
