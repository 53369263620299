import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ArrowDown } from '../../../assets_/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../assets_/icons/arrowUp.svg';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../../theme';
import TreeItem from '@material-ui/lab/TreeItem';
import { Divider } from '@material-ui/core';
import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
import { MtsDropdown, MtsDropdownOption } from '@mts-ds/components-react';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: 10,
    // height: '90vh',
    flexGrow: 1,
    // width: 400,
    overflow: 'auto',
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    }
    // '&:focus > $content, &$selected > $content': {
    //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    //   color: 'var(--tree-view-color)'
    // },
  },
  itemRoot: {
    '&:hover > $content': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    }
  },
  typography: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: 5
  },
  iconContainer: {
    width: '24px'
  },
  gridRoot: {
    marginTop: spacing(4)
  }
}));

interface ITreeOptions {
  id: string;
  name: string;
  children: ITreeOptions[];
}

export type IDropdownTreeProps = {
  treeHierarchy: ITreeOptions[];
} & IFormGroupTextProps &
  typeof MtsDropdown.defaultProps;

const DropdownTree: FC<IDropdownTreeProps> = ({
  treeHierarchy,
  label,
  description,
  valid,
  onMtsChange,
  ...props
}: IDropdownTreeProps) => {
  const classes = useStyles();

  const [resetCounter, setResetCounter] = useState(0);

  useEffect(() => {
    /*
     * Хак - ререндер компонента MtsDropdownOptgroup с помощью key
     * При ресете фильтров галочка у MtsDropdownOptgroupLabel не сбрасывается,
     * хотя MtsDropdownOption-элементы внутри MtsDropdownOptgroup сбрасываются
     * Проверить исправление при следующем релизе
     */
    if (Array.isArray(props.value) && !props.value.length) {
      setResetCounter(resetCounter + 1);
    }
  }, [props.value]);

  const handleMtsChange = (onChange: any) => (event: any) => {
    /*
     * Хак - передаем [] изменяет на [""]
     * Проверить исправление при следующем релизе
     */
    if (
      (Array.isArray(event.target.value) && event.target.value.every((item: any) => item)) ||
      !Array.isArray(event.target.value)
    ) {
      /*
       * Хак - передаем [1, 2] изменяет на ["1,2"]
       * Проверить исправление при следующем релизе
       */
      if (
        Array.isArray(event.target.value) &&
        event.target.value.length === 1 &&
        event.target.value[0].includes(',')
      ) {
        event.target.value = event.target.value[0].split(',');
      }

      onChange(event);
    }
  };

  const renderTree = (nodes: ITreeOptions[]) => {
    const renderNode = (node: ITreeOptions) => (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        classes={{
          root: classes.itemRoot,
          iconContainer: classes.iconContainer
        }}
        label={
          <>
            <MtsDropdownOption key={node.id} value={node.id as string}>
              {node.name}
            </MtsDropdownOption>
            <Divider />
          </>
        }
      >
        {Array.isArray(node.children) ? renderTree(node.children) : null}
      </TreeItem>
    );

    return nodes.map(node => renderNode(node));
  };

  return (
    <FormGroup label={label} description={description} valid={valid}>
      <MtsDropdown
        buttonCancel="Отмена"
        buttonConfirm="Готово"
        {...(onMtsChange && { onMtsChange: handleMtsChange(onMtsChange) })}
        {...props}
      >
        <TreeView
          className={classes.root}
          defaultCollapseIcon={
            <ArrowUp style={{ width: 24, height: 24 }} className={classes.iconContainer} />
          }
          defaultExpandIcon={
            <ArrowDown style={{ width: 24, height: 24 }} className={classes.iconContainer} />
          }
          // onNodeSelect={handleSelect}
        >
          {renderTree(treeHierarchy)}
        </TreeView>
      </MtsDropdown>
    </FormGroup>
  );
};

export default DropdownTree;
