import React, { FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Rectangle, Circle, Polygon } from 'react-leaflet';

import { IArea } from '../../../models/types';
import { paths } from '../../Root/paths';
import { AreaTypes, ICoordXY } from '../../../models/types';
import { iRootState, Dispatch } from '../../../store';
import {
  getBoxCenter,
  getBoxBounds,
  getCircleCenter,
  getCircleRadius,
  getPolygonPositions
} from '../utils';
import { mapColor as colors } from '../../../theme';

import AreaListItemTooltip from './AreaListItemTooltip';

const mapState = (state: iRootState) => ({
  activeArea: state.infrastructureAreas.item,
  hoverArea: state.infrastructureAreas.hover,
  isGeo: state.infrastructureMap.layers.isGeo,
  office: state.infrastructureOffices.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    focusArea: d.infrastructureAreas.focusArea
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = {
  area: IArea;
} & connectedProps;

const AreaListItem: FC<IProps> = ({ area, activeArea, hoverArea, focusArea, office, isGeo }) => {
  const { id, mapColor, geomType, geomGlobalCoords, geomCoords } = area;

  const position = isGeo ? geomGlobalCoords : geomCoords;

  const history = useHistory();
  const areaEl = useRef<any>();

  useEffect(() => {
    if (activeArea && activeArea.id === id) {
      let center!: ICoordXY;

      switch (geomType) {
        case AreaTypes.Box:
          center = getBoxCenter(position);

          break;
        case AreaTypes.Circle:
          center = getCircleCenter(position);

          break;
        case AreaTypes.Polygon:
          const {
            _southWest: { lat: southWestLat, lng: southWestLng },
            _northEast: { lat: northEastLat, lng: northEastLng }
          } = areaEl.current.leafletElement._bounds;

          const polygonBoxCoordinates = [southWestLng, southWestLat, northEastLng, northEastLat];

          center = getBoxCenter(polygonBoxCoordinates);

          break;
      }

      focusArea(center);
    }
  }, [activeArea]);

  const handleClick = () => {
    history.push(paths.map.office.areas.show(id));
  };

  const isBox = geomType === AreaTypes.Box;
  const isCircle = geomType === AreaTypes.Circle;
  const isPolygon = geomType === AreaTypes.Polygon;
  const isHover = Boolean(hoverArea && id === hoverArea.id);

  const color = isHover ? colors.darkBlueberry : mapColor;
  const opacity = isHover ? 0.5 : 0.3;

  return (
    <>
      {isBox && (
        <Rectangle
          type={AreaTypes.Box}
          bounds={getBoxBounds(position)}
          weight={2}
          color={color}
          fillOpacity={opacity}
          onClick={handleClick}
        >
          <AreaListItemTooltip area={area} />
        </Rectangle>
      )}
      {isCircle && (
        <Circle
          type={AreaTypes.Circle}
          center={getCircleCenter(position)}
          radius={getCircleRadius(position)}
          weight={2}
          color={color}
          fillOpacity={opacity}
          onClick={handleClick}
        >
          <AreaListItemTooltip area={area} />
        </Circle>
      )}
      {isPolygon && (
        <Polygon
          type={AreaTypes.Polygon}
          positions={getPolygonPositions(position)}
          weight={2}
          color={color}
          fillOpacity={opacity}
          onClick={handleClick}
          ref={areaEl}
        >
          <AreaListItemTooltip area={area} />
        </Polygon>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(AreaListItem);
