import React, { FC, useRef, useState, useEffect, createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MtsToast } from '@mts-ds/components-react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 24,
    right: 24
  }
}));

export const ToastContext = createContext<any>(null);
let isShow = false;

type IParams = { text: string } & typeof MtsToast.defaultProps;

const Toast: FC = ({ children }) => {
  const classes = useStyles();
  const rootRef = useRef<any>();
  const [toast, setToast] = useState<null | IParams>(null);

  const { text = '', ...props } = toast || {};

  useEffect(() => {
    if (toast && !isShow) {
      rootRef.current.show();
      isShow = true;
    }

    if (isShow) {
      const timeout = setTimeout(() => {
        rootRef.current.hide();
        setToast(null);
        isShow = false;
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [toast]);

  return (
    <>
      <ToastContext.Provider value={setToast}>{children}</ToastContext.Provider>
      {
        <MtsToast ref={rootRef} className={classes.root} {...props}>
          {text}
        </MtsToast>
      }
    </>
  );
};

export default Toast;
