import React, { FC } from 'react';
import { useField } from 'react-final-form';

import TimePicker, { IProps as IColorPickerProps } from '../../Fields/TimePicker';

type IProps = {
  name: string;
} & IColorPickerProps;

const FormTimePicker: FC<IProps> = ({ name, ...props }) => {
  const { input } = useField(name);

  return <TimePicker {...input} {...props} />;
};

export default FormTimePicker;
