import React, { FC } from 'react';

import Table from '../../../../../components_/Table';
import TableColText from '../../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../../components_/Table/TableActionGroup';
import { securableTypes } from '../../../../../constants';
import FormSwitch from '../../../../../components_/Form/FormSwitch';
import { IRoleType } from '../../../../../models/types';

export interface IProps {
  treeItem: string;
  list: any;
  roles: IRoleType[];
}

const List: FC<IProps> = ({ list, treeItem, roles }) => {
  const rows = roles.map(role => {
    const obj = {
      items: {
        friendlyName: <TableColText>{role.friendlyName}</TableColText>
      }
    };
    list.forEach((item: any) => {
      // @ts-ignore
      obj.items[item] = (
        <TableActionGroup>
          <FormSwitch
            key={`${treeItem}.${role.id}roleId.${item}`}
            name={`actions.${treeItem}.${role.id}roleId.${item}`}
          />
        </TableActionGroup>
      );
    });
    return obj;
  });

  const cols = [
    {
      title: 'Роль',
      field: 'friendlyName'
    },
    ...list.map((item: string) => ({
      title: securableTypes[item].name,
      field: item
    }))
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
