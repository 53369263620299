import React, { FC } from 'react';
import { Tooltip } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, getShadow }) => ({
  root: {
    padding: spacing(2),
    borderRadius: 8,
    boxShadow: getShadow('low'),
    minWidth: 220
  }
}));

const MarkerTooltip: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Tooltip direction="bottom" offset={[0, 20]} opacity={1} className={classes.root}>
      {children}
    </Tooltip>
  );
};

export default MarkerTooltip;
