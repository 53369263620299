import moment from 'moment';

const setStartEndOfDay = <T>(
  object: T,
  keys: [keyof T, keyof T] | [keyof T, keyof T, keyof T, keyof T]
): T => {
  const result = { ...object };
  const [start, end, timeStart, timeEnd] = keys;

  if ((result[start] && !timeStart) || (result[start] && timeStart && !result[timeStart])) {
    result[start] = moment(result[start])
      .startOf('day')
      .format() as any;
  }

  if ((result[end] && !timeEnd) || (result[end] && timeEnd && !result[timeEnd])) {
    result[end] = moment(result[end])
      .endOf('day')
      .format() as any;
  }

  if (result[start] && timeStart && result[timeStart]) {
    result[start] = moment(result[start])
      .add(moment.duration(result[timeStart]))
      .format() as any;
  }

  if (result[end] && timeEnd && result[timeEnd]) {
    result[end] = moment(result[end])
      .add(moment.duration(result[timeEnd]))
      .format() as any;
  }

  return result;
};

export default setStartEndOfDay;
