import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { guids, paths } from '../../../Root/paths';
import getSearchedData from '../../../../utils/getSearchedData';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../components_/Drawer/DrawerContentListSearch';
import DrawerContentItemArea from '../../../../components_/Drawer/DrawerContentItemArea';
import EmptyState from '../../../../components_/EmptyState';
import Button from '../../../../components_/Fields/Button';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import DrawerSticky from '../../../../components/Drawer/DrawerSticky';
import { makeStyles } from '@material-ui/core/styles';
import isSatisfied from '../../../../utils/isSatisfied';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  rights: state.securable.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setPolygonCreateMode: d.infrastructureMap.setPolygonCreateMode,
    resetHover: d.infrastructureAreas.resetHover
  };
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%',
    padding: spacing(2, 4, 2)
  }
}));

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Areas: FC<IProps> = ({ setPolygonCreateMode, resetHover, map, rights }) => {
  const { name: mapName, areas } = map;
  const classes = useStyles();

  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    return () => {
      resetHover();
    };
  }, []);

  const handleGoAdd = () => {
    history.push(paths.map.office.areas.create);
    setPolygonCreateMode();
  };

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const checkAccess = isSatisfied(
    { ...{ uid: guids.map.office.areas.create }, ...{ action: 'Visibility' } },
    rights
  );

  const searchedAreas = getSearchedData(areas, ['name', 'descr'], search);
  const searchedAreasLength = (searchedAreas || []).length;

  return (
    <>
      <DrawerAction toUrl={paths.map.office.root} />
      <DrawerContent title={mapName}>
        <DrawerContentList
          title="Зоны"
          count={searchedAreasLength}
          // actionName="Добавить зону"
          // actionOnClick={handleGoAdd}
        >
          <DrawerContentListSearch
            placeholder="Найти зону"
            value={search}
            onMtsChange={handleChangeSearch}
          />
          {searchedAreas && (
            <>
              {searchedAreas.map(area => (
                <DrawerContentItemArea key={area.id} area={area} />
              ))}
              {!searchedAreasLength && <EmptyState variant="emptyRequest" />}
            </>
          )}
        </DrawerContentList>
        {checkAccess && (
          <DrawerSticky>
            <Button
              icon="plus"
              color={'secondary' as ButtonColors}
              className={classes.button}
              onClick={handleGoAdd}
            >
              Добавить зону
            </Button>
          </DrawerSticky>
        )}
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Areas);
