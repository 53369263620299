import React  from 'react';
import { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format, formatISO, getYear } from 'date-fns';
import ru from 'date-fns/locale/ru';
import clsx from 'clsx';

import { ReactComponent as CalendarIcon } from '../../../../components_/Fields/DatePicker/icons/calendar.svg';
import Button from '../../../../components_/Fields/Button';
import Input, { ICustomInputProps } from '../../../../components_/Fields/DatePicker/input/Input';
import { ReactComponent as CloseIcon } from '../../../../components_/Fields/DatePicker/icons/close-icon-gray-small.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as ChevronIcon } from '../../../../components_/Fields/DatePicker/icons/chevron.svg';

import styles from './inputWithDate.module.css';

export interface IInputWithDate
  extends Omit<ICustomInputProps, 'value' | 'onChange'> {
  onChange?: (value: {}) => void;
  error?: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  label?: string;
  value?: string;
  name?:string;
  placeholder?: string;
}


export default function InputWithDate({
  value,
  error,
  maxDate,
  minDate = new Date('1950-01-01'),
  disabled = false,
  onChange,
  name,
  placeholder='Выберите дату',
  ...props
}: IInputWithDate) {
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);
  const [isMonthsOpened, setMonthsOpened] = useState(false);
  const [isYearsOpened, setYearsOpened] = useState(false);
  const defaultValue = value;
  registerLocale('ru', ru);

  const datePickerRef = useRef<DatePicker>(null);

  const handleClose = (reset: boolean) => {
    // tslint:disable-next-line:no-unused-expression
    reset && resetValue();
    // tslint:disable-next-line:no-unused-expression prettier
    datePickerRef && datePickerRef.current?.setOpen(false);
  };

  const handleOpen = () => {
    // tslint:disable-next-line:no-unused-expression
    datePickerRef && datePickerRef.current?.setOpen(true);
  };

  const resetValue = () => {
    setStartDate(defaultValue ? new Date(defaultValue) : null);
  };

  const handleConfirmation = () => {
    // tslint:disable-next-line:no-unused-expression
    !isYearsOpened && !isMonthsOpened && handleClose(false);
  };

  const handleChange = (date:Date) => {
     setStartDate(date);
    if (isYearsOpened || isMonthsOpened) {
      setYearsOpened(false);
      setMonthsOpened(false);
    }
  };

  useEffect(() => {
    if(value){
      setStartDate(new Date(value));
    } else {
      setStartDate(null);
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      if (startDate) {
        onChange({target:{name, value:formatISO(startDate, { representation: 'date' })}});
      }
    }
  }, [startDate]);

  const getYearsPlaceholder = (date: Date) => {
    const endPeriod =
      Math.ceil(
        getYear(date ? date : startDate ? startDate : new Date()) / 12
      ) * 12;
    const startPeriod = endPeriod - (12 - 1);
    return `${startPeriod} - ${endPeriod}`;
  };

  const getMonthName = (date: Date) => {
    const selectedDate = new Date(date);
    return format(selectedDate, 'LLLL', { locale: ru });
  };


  return (
    <div data-testid="datepicker-wrapper" className={styles.datePickerWrapper}>
      <DatePicker
        placeholderText={placeholder}
        popperClassName={styles.popperCustomClass}
        ref={datePickerRef}
        selected={startDate}
        disabled={disabled}
        dateFormat={'dd.MM.yyyy'}
        locale={'ru'}
        onChange={handleChange}
        minDate={minDate}
        customInput={
          <Input
            {...props}
            customIcons={
              <CalendarIcon
                onClick={handleOpen}
                className={styles.calendarIcon}
              />
            }
            disabled={disabled}
            error={error}
            value={value ?? ''}
          />
        }
        maxDate={maxDate}
        showPopperArrow={false}
        shouldCloseOnSelect={
          isYearsOpened || isMonthsOpened ? false : true
        }
        calendarClassName={styles.datePicker}
        showMonthYearPicker={isMonthsOpened}
        showYearPicker={isYearsOpened}
        yearItemNumber={12}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          nextYearButtonDisabled,
          prevYearButtonDisabled,
        }) => (
          <div className={styles.header}>
            <div className={styles.heading}>
              <p className={styles.text}> {'Выбор даты'}</p>
              <CloseIcon
                onClick={() => {
                  handleClose(true);
                }}
                className={styles.close}
              />
            </div>
            <div className={styles.controls}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  isYearsOpened ? decreaseYear() : decreaseMonth();
                }}
                disabled={
                  isYearsOpened
                    ? prevYearButtonDisabled
                    : prevMonthButtonDisabled
                }
                className={clsx(styles.button, styles.previous)}
              >
                <ChevronIcon
                    className={styles.icon}
                />
              </button>
              <div className={styles.type}>
                {isYearsOpened ? (
                  <></>
                ) : (
                  <div
                    className={styles.headingSelector}
                    onClick={() => {
                      setMonthsOpened((prev) => !prev);
                      setYearsOpened(false);
                    }}
                  >
                    {getMonthName(date)}
                  </div>
                )}
                <div
                  className={styles.headingSelector}
                  onClick={() => {
                    setYearsOpened((prev) => !prev);
                    setMonthsOpened(false);
                  }}
                >
                  {isYearsOpened ? getYearsPlaceholder(date) : getYear(date)}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  isYearsOpened ? increaseYear() : increaseMonth();
                }}
                className={clsx(styles.button, styles.next)}
                disabled={
                  isYearsOpened
                    ? nextYearButtonDisabled
                    : nextMonthButtonDisabled
                }
              >
                <ChevronIcon />
              </button>
            </div>
            <div className={styles.buttonsWrapper}>
              <Button
                // buttonStyle={'secondary'}
                // size={'medium'}
                className={styles.mobileButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose(true);
                }}
              >
                {'Сброс'}
              </Button>
              <Button
                // size={'medium'}
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmation();
                }}
                className={styles.mobileButton}
              >
                {'Ок'}
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
