import { useEffect, useState } from 'react';

import getInitialFilter from '../utils/getInitialFilter';
import { useQueryParams, useDebounce } from '../hooks';

/**
 * Хук для работы с пагинацией и фильтрами
 */

function useParams<T>(
  initialFilter: T,
  callback: (params: T & { pageIndex: number }) => void,
  sync: boolean = true
) {
  const { queryParams, setQueryParams } = useQueryParams();
  const [pageIndex, setPageIndex] = useState(Number(queryParams.get('pageIndex')) || 0);
  const [filter, setFilter] = useState(getInitialFilter<T>(initialFilter));
  const debounce = useDebounce(filter, 500);

  useEffect(() => {
    if (sync) {
      const params = { ...filter, pageIndex };

      callback(params);

      setQueryParams(params);
    }
  }, [debounce]);

  // tslint:disable-next-line: no-shadowed-variable
  const handlePageChange = (pageIndex: number) => {
    setPageIndex(pageIndex);

    const params = { ...filter, pageIndex };

    callback(params);

    setQueryParams(params);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;

    // tslint:disable-next-line: no-shadowed-variable
    setFilter(filter => ({ ...filter, [name]: value }));

    setPageIndex(0);
  };

  const handleFilterApply = () => {
    const params = { ...filter, pageIndex };

    callback(params);

    setQueryParams(params);
  };

  const handleFilterReset = (resetQuery?: boolean) => {
    setFilter(initialFilter);

    setPageIndex(0);

    if (resetQuery) {
      setQueryParams({});
    }
  };

  return {
    pageIndex,
    filter,
    handlePageChange,
    handleFilterChange,
    handleFilterApply,
    handleFilterReset
  };
}

export default useParams;
