import { ReactComponent as Add } from './add.svg';
import { ReactComponent as AddButton } from './addButton.svg';
import { ReactComponent as Area } from './area.svg';
import { ReactComponent as Areas } from './areas.svg';
import { ReactComponent as ArrowBack } from './arrowBack.svg';
import { ReactComponent as Avatar } from './avatar.svg';
import { ReactComponent as Beacon } from './beacon.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as Charging } from './charging.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as Company } from './company.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DeleteButton } from './deleteButton.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as EditButton } from './editButton.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as ExitToApp } from './exitToApp.svg';
import { ReactComponent as Fire } from './fire.svg';
import { ReactComponent as Go } from './go.svg';
import { ReactComponent as HeatMap } from './heatMap.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Infrastructure } from './infrastructure.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as Navigate } from './navigate.svg';
import { ReactComponent as NotFound } from './notFound.svg';
import { ReactComponent as NotFoundReport } from './notFoundReport.svg';
import { ReactComponent as NotificationAreaAccessViolation } from './notificationAreaAccessViolation.svg';
import { ReactComponent as NotFoundNotifications } from './notFoundNotifications.svg';
import { ReactComponent as NotificationAreaExceedingTime } from './notificationAreaExceedingTime.svg';
import { ReactComponent as NotificationAreaOfftime } from './notificationAreaOfftime.svg';
import { ReactComponent as NotificationEmergency } from './notificationEmergency.svg';
import { ReactComponent as NotificationEnteringDangerZone } from './notificationEnteringDangerZone.svg';
import { ReactComponent as NotificationLeaveDangerZone } from './notificationLeaveDangerZone.svg';
import { ReactComponent as NotificationFall } from './notificationFall.svg';
import { ReactComponent as NotificationForgottenProtection } from './notificationForgottenProtection.svg';
import { ReactComponent as NotificationHealthPulseAbnormal } from './notificationHealthPulseAbnormal.svg';
import { ReactComponent as NotificationImmobilized } from './notificationImmobilized.svg';
import { ReactComponent as NotificationInformational } from './notificationInformational.svg';
import { ReactComponent as NotificationProtectionProblem } from './notificationProtectionProblem.svg';
import { ReactComponent as NotificationSos } from './notificationSos.svg';
import { ReactComponent as NotificationWorkEnd } from './notificationWorkEnd.svg';
import { ReactComponent as NotificationWorkStart } from './notificationWorkStart.svg';
import { ReactComponent as NotMap } from './notMap.svg';
import { ReactComponent as Office } from './office.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Polygon } from './polygon.svg';
import { ReactComponent as Products } from './products.svg';
import { ReactComponent as Pulse } from './pulse.svg';
import { ReactComponent as SaveButton } from './saveButton.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Unbind } from './unbind.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as UsersOffice } from './usersOffice.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Web } from './web.svg';

const icons = {
  add: Add,
  addButton: AddButton,
  area: Area,
  areas: Areas,
  avatar: Avatar,
  back: ArrowBack,
  beacon: Beacon,
  bell: Bell,
  box: Box,
  camera: Camera,
  calendar: Calendar,
  charging: Charging,
  circle: Circle,
  clear: Clear,
  company: Company,
  copy: Copy,
  delete: Delete,
  deleteButton: DeleteButton,
  edit: Edit,
  editButton: EditButton,
  error: Error,
  exitToApp: ExitToApp,
  fire: Fire,
  go: Go,
  heatMap: HeatMap,
  help: Help,
  infrastructure: Infrastructure,
  logo: Logo,
  tag: Tag,
  menu: Menu,
  mobile: Mobile,
  navigate: Navigate,
  notFound: NotFound,
  notFoundReport: NotFoundReport,
  notificationAreaAccessViolation: NotificationAreaAccessViolation,
  notFoundNotifications: NotFoundNotifications,
  notificationAreaExceedingTime: NotificationAreaExceedingTime,
  notificationAreaOfftime: NotificationAreaOfftime,
  notificationEmergency: NotificationEmergency,
  notificationEnteringDangerZone: NotificationEnteringDangerZone,
  notificationLeaveDangerZone: NotificationLeaveDangerZone,
  notificationFall: NotificationFall,
  notificationForgottenProtection: NotificationForgottenProtection,
  notificationHealthPulseAbnormal: NotificationHealthPulseAbnormal,
  notificationImmobilized: NotificationImmobilized,
  notificationInformational: NotificationInformational,
  notificationProtectionProblem: NotificationProtectionProblem,
  notificationSos: NotificationSos,
  notificationWorkEnd: NotificationWorkEnd,
  notificationWorkStart: NotificationWorkStart,
  notMap: NotMap,
  office: Office,
  person: Person,
  phone: Phone,
  polygon: Polygon,
  products: Products,
  pulse: Pulse,
  saveButton: SaveButton,
  search: Search,
  unbind: Unbind,
  users: Users,
  usersOffice: UsersOffice,
  warning: Warning,
  web: Web
};

export default icons;
