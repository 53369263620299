/**
 * Функция возвращающая BLOB изображения
 * @param blob BLOB
 * @param format Формат
 */
const getBlobImage = (blob: string, format: string) => {
  return blob && format ? `data:${format};base64,${blob}` : '';
};

export default getBlobImage;
