import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../../routes/Root/paths';
import { useAsync } from '../../../../hooks';

import Content from '../../../../components_/Content';
import Spinner from '../../../../components_/Spinner';
import EmptyState from '../../../../components_/EmptyState';

import Form from './Form';
import FormAdd from './FormAdd';
import FormActions from './FormActions';

interface IParams {
  id: string;
}

const mapState = (state: iRootState) => ({
  data: state.equipmentDevices.data,
  forms: state.equipmentDevices.forms,
  item: state.equipmentDevices.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.equipmentDevices.getData,
    getItem: d.equipmentDevices.getItem,
    addForm: d.equipmentDevices.addForm,
    deleteForm: d.equipmentDevices.deleteForm,
    editForm: d.equipmentDevices.editForm,
    setSubmitForms: d.equipmentDevices.setSubmitForms,
    updateOrCreate: d.equipmentDevices.updateOrCreate
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({
  data,
  item,
  forms,
  getData,
  getItem,
  addForm,
  deleteForm,
  editForm,
  setSubmitForms,
  updateOrCreate
}) => {
  const { list: formList, isSubmit } = forms;

  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);
  const [runUpdateOrCreate, isLoadingUpdateOrCreate, isUpdateOrCreateLoadingError] = useAsync(
    updateOrCreate
  );
  const { id } = useParams<IParams>();
  const history = useHistory();
  const isCreate = Boolean(useRouteMatch(paths.equipment.devices.create));
  const isEdit = Boolean(useRouteMatch(paths.equipment.devices.edit()));
  const isCopy = Boolean(useRouteMatch(paths.equipment.devices.copy()));

  useEffect(() => {
    runGetData();

    if (isEdit || isCopy) {
      runGetItem(id);
    }
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const isLoading = isLoadingGetData || isLoadingGetItem;
  const isLoadingError = isGetDataLoadingError || isGetItemLoadingError;

  const title = isCreate
    ? 'Добавить носимое устройство'
    : isCopy
    ? 'Копировать носимое устройство'
    : isEdit && item
    ? item.number
    : '';

  return (
    <Content
      breadcrumb={[{ name: 'Оборудование', url: paths.equipment.devices.root }, { name: title }]}
      title={title}
    >
      {!isLoading && !isLoadingError && (
        <Grid item xs container direction="column" justify="space-between">
          <Grid>
            {formList.map((formItem, index) => (
              <Form
                key={formItem.uid}
                index={index}
                form={formItem}
                data={data}
                item={item}
                isCreate={isCreate}
                isEdit={isEdit}
                isCopy={isCopy}
                isSubmit={isSubmit}
                deleteForm={deleteForm}
                editForm={editForm}
                setSubmitForms={setSubmitForms}
                onSubmitAction={runUpdateOrCreate}
                onCancel={handleCancel}
              />
            ))}
            {isCreate && <FormAdd addForm={addForm} />}
          </Grid>
          <FormActions
            forms={forms}
            isEdit={isEdit}
            setSubmitForms={setSubmitForms}
            onCancel={handleCancel}
          />
        </Grid>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
