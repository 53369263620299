import React, { FC, FormHTMLAttributes } from 'react';

type IProps = FormHTMLAttributes<HTMLFormElement>;

const Form: FC<IProps> = ({ children, ...props }) => {
  return (
    <form autoComplete="off" {...props}>
      {children}
    </form>
  );
};

export default Form;
