import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../store';
import { paths } from '../../../../Root/paths';
import { useAsync } from '../../../../../hooks';

import Content from '../../../../../components_/Content';
import Spinner from '../../../../../components_/Spinner';
import EmptyState from '../../../../../components_/EmptyState';

import Form from './Form';

interface IParams {
  id: string;
}

const mapState = (state: iRootState) => ({
  data: state.settingSecTree.data,
  roleItem: state.settingSecTree.roleItem,
  secTreeHierarchy: state.settingSecTree.secTreeHierarchy
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getHierarchy: d.settingSecTree.getHierarchy,
    getRoleItem: d.settingSecTree.getRoleItem,
    getSecurableTypes: d.settingSecTree.getSecurableTypes,
    getNodeACL: d.settingSecTree.getNodeACL,
    updateOrCreate: d.settingSecTree.updateOrCreate,
    reset: d.settingSecTree.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Index: FC<IProps> = ({
  data,
  roleItem,
  getRoleItem,
  updateOrCreate,
  getHierarchy,
  secTreeHierarchy,
  getSecurableTypes,
  getNodeACL,
  reset
}) => {
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getRoleItem);
  const [runGetHierarchy, isLoadingGetHierarchy, isGetHierarchyError] = useAsync(getHierarchy);
  const [runGetNodeACL, isLoadingGetNodeACL, isGetHNodeACLError] = useAsync(getNodeACL);
  const [runGetSecurableTypes, isLoadingGetSecurableTypes, isGetSecurableTypesError] = useAsync(
    getSecurableTypes
  );

  const [runUpdateOrCreate, isLoadingUpdateOrCreate, isUpdateOrCreateLoadingError] = useAsync(
    updateOrCreate
  );
  const { id } = useParams<IParams>();
  const history = useHistory();
  const isCreate = Boolean(useRouteMatch(paths.settings.secTree.roles.create));
  const isEdit = Boolean(useRouteMatch(paths.settings.secTree.roles.edit()));
  const isCopy = Boolean(useRouteMatch(paths.settings.secTree.roles.copy()));

  const query = useQuery();
  const treeItem = query.get('root') ? query.get('root') : '20b3bb0a-6226-4cc7-9bcc-c9cb5c458f47';

  useEffect(() => {
    runGetHierarchy();
    if (isEdit || isCopy) {
      runGetItem(id);
      runGetNodeACL({ root: '', roleId: id });
    }
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (treeItem) {
      runGetSecurableTypes(treeItem);
    }
  }, [query.get('root')]);

  const handleCancel = () => {
    history.push(paths.settings.secTree.roles.root);
  };

  const isLoading = isLoadingGetItem || isLoadingGetHierarchy || isLoadingGetNodeACL;
  const isLoadingError = isGetItemLoadingError || isGetHierarchyError || isGetHNodeACLError;

  const title = isCreate
    ? 'Добавить роль'
    : isCopy
    ? 'Копировать роль'
    : isEdit && roleItem
    ? roleItem.friendlyName
    : '';

  return (
    <Content
      breadcrumb={[{ name: 'Настройки', url: paths.settings.secTree.roles.root }, { name: title }]}
      title={title}
    >
      {!isLoading && !isLoadingError && (
        <>
          <Form
            data={data}
            secTreeHierarchy={secTreeHierarchy}
            item={roleItem}
            isCreate={isCreate}
            isEdit={isEdit}
            isCopy={isCopy}
            treeItem={treeItem}
            onSubmitAction={runUpdateOrCreate}
            getNodeACL={runGetNodeACL}
            onCancel={handleCancel}
          />
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
