/**
 * Возвращает полное имя пользователя
 */

import { IUser } from '../models/types';

const getFullName = (data: string | IUser, short = false) => {
  if (!data) {
    return '';
  }

  const arrayFullName =
    typeof data === 'string'
      ? data.split(' ')
      : [data.lastname, data.name, data.secondname].filter(item => item);

  const result = arrayFullName.reduce((previousValue, currentValue, index) => {
    if (!index) {
      return currentValue;
    }

    return `${previousValue} ${
      short ? `${currentValue && currentValue[0].toUpperCase()}.` : currentValue
    }`;
  }, '');

  return result;
};

export default getFullName;
