import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TopTypography from '@top-ui/core/lib/TopTypography';

import { ReactComponent as Error } from '../../assets/images/error.svg';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

const useStyles = makeStyles(({ spacing }) => ({
  error: {
    position: 'relative'
  },
  info: {
    position: 'absolute',
    // top: -50,
    // left: 250,
    padding: 20,
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  image: {
    width: '100%'
  },
  button: {
    padding: 10
  }
}));

interface IProps {
  code?: number;
  text?: string;
  description?: string;
  action?: string;
  actionOnClick?: () => void;
}

const ErrorPage: FC<IProps> = ({ code, text, description, action, actionOnClick }) => {
  const classes = useStyles();

  return (
    <Grid item xs container justify="center" alignItems="center">
      <Grid className={classes.error}>
        {/*<Error className={classes.image} />*/}
        <Grid className={classes.info}>
          {code && <TopTypography variant="h1">{code}</TopTypography>}
          {text && <TopTypography variant="h5">{text}</TopTypography>}
          {description && (
            <TopTypography variant="subtitle1" color="textSecondary">
              {description}
            </TopTypography>
          )}
          <MtsButton
            color={'secondary' as ButtonColors}
            onClick={() => document.location.replace('/')}
          >
            На главную
          </MtsButton>
          {action && (
            <MtsButton className={classes.button} onClick={actionOnClick}>
              {action}
            </MtsButton>
          )}
        </Grid>
        <Error className={classes.image} />
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
