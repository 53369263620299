/**
 * Модель для поиска сотрудников на карте
 */

import { createModel, ModelConfig } from '@rematch/core';

import { IUserPosition } from './types';

import PositionsService from '../services/PositionsService';

interface IState {
  users: IUserPosition[];
}

const initialState: IState = {
  users: []
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUsers(state: IState, payload: IUserPosition[]): IState {
      return {
        ...state,
        users: payload
      };
    }
  },
  effects: {
    async getUsers(fullTextSearch) {
      const { data } = await PositionsService.getUsers({ fullTextSearch });

      this.setUsers(data);
    }
  }
};

export const infrastructureSearch: typeof model = createModel(model);
