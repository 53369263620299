import React, { FC } from 'react';
import { MtsTextarea } from '@mts-ds/components-react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';

export type IProps = IFormGroupTextProps & typeof MtsTextarea.defaultProps;

const Textarea: FC<IProps> = ({ label, description, valid, ...props }) => {
  return (
    <FormGroup label={label} description={description} valid={valid}>
      <MtsTextarea {...props} />
    </FormGroup>
  );
};

export default Textarea;
