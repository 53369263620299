import getBlobImage from './getBlobImage';
import { IUser } from '../models/types';

/**
 * Функция возвращает BLOB изображения пользователя
 */
const getUserBlobImage = ({ photoContent, photoContentType }: IUser) => {
  return getBlobImage(photoContent, photoContentType);
};

export default getUserBlobImage;
