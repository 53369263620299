import React, { FC } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import DropdownTree, { IDropdownTreeProps } from '../../Fields/Tree';

type IProps = {
  name: string;
  useFieldConfig?: UseFieldConfig<string>;
} & IDropdownTreeProps;

const FormDropdownTree: FC<IProps> = ({ name, useFieldConfig, ...props }) => {
  const {
    input,
    meta: { touched, error }
  } = useField(name, useFieldConfig);

  // Адаптер под mts-ds
  input.onMtsChange = input.onChange;
  input.onMtsFocus = input.onFocus;
  input.onMtsBlur = input.onBlur;

  const message = touched && error;

  const isError = Boolean(message);
  return <DropdownTree description={message} valid={!isError} {...input} {...props} />;
};

export default FormDropdownTree;
