/**
 * Модель для работы с отчётом "Использование СИЗ"
 */

import { createModel, ModelConfig } from '@rematch/core';
import { saveAs } from 'file-saver';

import { iRootState } from '../store';
import { IDepartament, IReportUseProtection } from './types';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import UsersService from '../services/UsersService';
import ReportService from '../services/ReportService';

interface IData {
  brigades: IDepartament[];
}

interface IList {
  data: IReportUseProtection[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    brigades: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    resetList(state: IState): IState {
      return {
        ...state,
        list: {
          data: null,
          pagesCount: 0
        }
      };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: brigades }
          }
        ] = await Promise.all([UsersService.getBrigadeAll({ includeOther: true, pageSize: 100 })]);

        this.setData({ brigades });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      const cloneParams = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getUseProtection(cloneParams);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async exportList(params, rootState: iRootState) {
      const { from, to } = params;

      const cloneFilter = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getCsvUseProtection(cloneFilter);

        saveAs(data, `Отчет по использованию СИЗ за период ${from} - ${to}.csv`);
      } catch (error) {
        return error;
      }
    }
  }
};

export const reportUseProtection: typeof model = createModel(model);
