import { INotification, IAlert } from '../../models/types';
import { notificationTypesData } from '../../constants';

const convertNotificationToAlert = (notification: INotification): IAlert => {
  const defaultNotificationClassIcon = { color: '' };
  const { color } = notificationTypesData[notification.class] || defaultNotificationClassIcon;

  return {
    uid: notification.uid,
    userId: notification.userId,
    type: notification.class,
    title: notification.classFriendly,
    description: notification.text,
    color,
    isConfirm: notification.isNeedConfirmation,
    date: notification.raisingTime,
    severity: notification.severity,
    targetSimple: notification.targetSimple,
    trackInfo: notification.trackInfo
  };
};

export { convertNotificationToAlert };
