import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import getSearchedData from '../../../../utils/getSearchedData';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../components_/Drawer/DrawerContentListSearch';
import EmptyState from '../../../../components_/EmptyState';
import Button from '../../../../components_/Fields/Button';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import DrawerSticky from '../../../../components/Drawer/DrawerSticky';
import { makeStyles } from '@material-ui/core/styles';
import DrawerContentItemCamera from '../../../../components_/Drawer/DrawerContentItemCamera';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setMarkerCreateMode: d.infrastructureMap.setMarkerCreateMode
  };
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%',
    padding: spacing(2, 4, 2)
  }
}));

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Cameras: FC<IProps> = ({ map, setMarkerCreateMode }) => {
  const { name: mapName, cameras } = map;
  const classes = useStyles();

  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  const handleActionClick = () => {
    history.push(paths.map.office.cameras.create);
    setMarkerCreateMode();
  };

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const searchedCameras = getSearchedData(cameras, ['name'], search);
  const searchedCamerasLength = (searchedCameras || []).length;

  return (
    <>
      <DrawerAction toUrl={paths.map.office.root} />
      <DrawerContent title={mapName}>
        <DrawerContentList
          title="Камеры"
          count={searchedCamerasLength}
          // actionName="Добавить анкер"
          // actionOnClick={handleActionClick}
        >
          <DrawerContentListSearch
            placeholder="Найти камеру"
            value={search}
            onMtsChange={handleChangeSearch}
          />
          {searchedCameras && (
            <>
              {searchedCameras.map(camera => (
                <DrawerContentItemCamera key={camera.id} camera={camera} />
              ))}
              {!searchedCamerasLength && <EmptyState variant="emptyRequest" />}
            </>
          )}
        </DrawerContentList>
        <DrawerSticky>
          <Button
            id="cameraButtonAdd"
            key="cameraButtonAdd"
            icon="plus"
            color={'secondary' as ButtonColors}
            className={classes.button}
            onClick={handleActionClick}
          >
            Добавить камеру
          </Button>
        </DrawerSticky>
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Cameras);
