import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { IUser } from '../../../../../../models/types';
import { paths } from '../../../../../Root/paths';

import Tooltip from '../../../../../../components_/Tooltip';
import Icon from '../../../../../../components_/Icon';
import DrawerContentItemUser from '../../../../../../components_/Drawer/DrawerContentItemUser';

interface IProps {
  isLoading: boolean;
  user: IUser | null;
}

const Card: FC<IProps> = ({ isLoading, user }) => {
  const { username } = user || {};

  const history = useHistory();

  const handleEditClick = () => {
    history.push(paths.map.office.users.edit(username));
  };

  return (
    <DrawerContentItemUser
      isLoading={isLoading}
      user={user}
      variant="card"
      // action={
      //   <Tooltip size="s" label="Редактировать">
      //     <MtsButton
      //       size={'s' as ButtonSizes}
      //       format={'icon' as ButtonFormats}
      //       color={'secondary' as ButtonColors}
      //       onClick={handleEditClick}
      //     >
      //       <Grid container alignItems="center">
      //         <Icon name="edit" size="m" />
      //       </Grid>
      //     </MtsButton>
      //   </Tooltip>
      // }
    />
  );
};

export default Card;
