type IList = object[] | null;

interface IFilter {
  [key: string]: any;
}

const checkReset = (filter: IFilter): boolean => {
  // если хоть один из фильтров установлен - показываем кнопку "Сбросить"
  return Object.entries(filter).some(item => {
    const val = item[1];

    if (Array.isArray(val)) {
      return val.length > 0;
    }

    return val;
  });
};

const checkFilter = (filter: IFilter, required: string[] = [], list: IList = null) => {
  const isApply = !required.every(item => filter[item]);
  const isDownload = !Boolean(list && list.length);
  const isShowReset = checkReset(filter);

  return { isApply, isDownload, isShowReset };
};

export default checkFilter;
