import React, { Component } from 'react';

import ErrorPage from '../ErrorPage';

interface IState {
  isError: boolean;
}

class ErrorBoundary extends Component<{}, IState> {
  public state = { isError: false };

  public componentDidCatch() {
    this.setState({ isError: true });
  }

  public render() {
    const { isError } = this.state;
    const { children } = this.props;

    const handleActionClick = () => {
      document.location.reload();
    };

    return (
      <>
        {isError ? (
          <ErrorPage
            text="Что-то пошло не так…"
            description="Не переживайте, мы знаем об этом и скоро всё поправим!"
            action="Попробовать ещё раз"
            actionOnClick={handleActionClick}
          />
        ) : (
          children
        )}
      </>
    );
  }
}

export default ErrorBoundary;
