import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { IOffice } from '../../../models/types';

import Icon from '../../Icon';
import IconWrapper from '../../Icon/IconWrapper';
import Typography from '../../Typography';

import DrawerContentItem from '../DrawerContentItem';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { paths } from '../../../routes/Root/paths';
import { useHistory } from 'react-router-dom';

interface IProps {
  isLoading: boolean;
  office: IOffice | null;
}

const DrawerContentOffice: FC<IProps> = ({ isLoading, office }) => {
  const defaultOffice = { name: '', address: '', id: 0 };
  const { name, address, id } = office || defaultOffice;
  const history = useHistory();
  const handleClick = () => () => {
    history.push(paths.map.office.edit(id));
  };
  const icon = (
    <IconWrapper variant="square" size="xl">
      <Icon name="business" size="m" />
    </IconWrapper>
  );

  const content = (
    <>
      <Typography variant="p1Medium">{isLoading ? <Skeleton /> : name}</Typography>
      <Typography variant="p2Regular" color="text-secondary">
        {isLoading ? <Skeleton /> : address}
      </Typography>
    </>
  );
  const action = (
    <MtsButton
      format={'icon' as ButtonFormats}
      color={'secondary' as ButtonColors}
      key={'editPlace'}
      onClick={handleClick()}
    >
      <Grid container justify="center" alignItems="center" wrap="nowrap">
        <Icon name="edit" size="m" />
      </Grid>
    </MtsButton>
  );

  return <DrawerContentItem variant="current" icon={icon} content={content} action={action} />;
};

export default DrawerContentOffice;
