import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { Dispatch } from '../../../../../store';
import { guids, paths } from '../../../../Root/paths';

import DrawerAction from '../../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../../components/Drawer/DrawerContent';

import Form from './Form';
import Show from './Show';
import PrivateRoute from '../../../../Root/PrivateRoute';

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    resetFocus: d.infrastructureMap.resetFocus
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Beacon: FC<IProps> = ({ resetFocus }) => {
  return (
    <>
      <DrawerAction toUrl={paths.map.office.infrastructure.root} onClose={resetFocus} />
      <DrawerContent>
        <Switch>
          {/*<Route path={paths.map.office.infrastructure.create} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.infrastructure.create
            }}
            path={paths.map.office.infrastructure.create}
            component={Form}
          />
          {/*<Route path={paths.map.office.infrastructure.edit()} component={Form} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.infrastructure.edit()
            }}
            path={paths.map.office.infrastructure.edit()}
            component={Form}
          />
          {/*<Route path={paths.map.office.infrastructure.show()} component={Show} />*/}
          <PrivateRoute
            requirement={{
              uid: guids.map.office.infrastructure.show()
            }}
            path={paths.map.office.infrastructure.show()}
            component={Show}
          />
        </Switch>
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(Beacon);
