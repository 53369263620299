import React, { FC, useEffect } from 'react';
import { useField } from 'react-final-form';

interface IProps {
  isCreate: boolean;
}

const FormController: FC<IProps> = ({ isCreate }) => {
  const {
    input: { value: username, onChange: onChangeUsername }
  } = useField('username');

  const {
    input: { value: phone, onChangePhone }
  } = useField('phone');

  useEffect(() => {
    if (isCreate) {
      onChangeUsername(phone);
    }
  }, [phone]);

  return null;
};

export default FormController;
