import React, { FC } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { getCalendarTime } from '../../../utils/convertTime';
import { INotification } from '../../../models/types';

import Typography from '../../Typography';

import DrawerContentItem from '../DrawerContentItem';

import NotificationIcon from '../../Notification/NotificationIcon';
import NotificationTarget from '../../Notification/NotificationTarget';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(0.5)
    }
  },
  target: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  }
}));

interface IProps {
  notification: INotification;
}

const DrawerContentItemNotification: FC<IProps> = ({ notification }) => {
  const classes = useStyles();

  const { class: classId, classFriendly, text, targetSimple, raisingTime } = notification;

  const icon = <NotificationIcon notificationClass={classId} size="m" />;

  const content = (
    <Grid className={classes.root}>
      <Typography variant="p2Bold">{classFriendly}</Typography>
      <Typography variant="p2Regular">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
      <Grid container alignItems="center" className={classes.target}>
        <NotificationTarget targetSimple={targetSimple} size="s" />
        <Typography variant="p3Regular" color="text-secondary">
          {`${getCalendarTime(raisingTime, 'DD.MM.YY')}, ${moment(raisingTime).format('HH:mm')}`}
        </Typography>
      </Grid>
    </Grid>
  );

  return <DrawerContentItem icon={icon} content={content} align="stretch" />;
};

export default DrawerContentItemNotification;
