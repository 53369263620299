/**
 * Сервис для работы с инфраструктурой
 */

import axios from 'axios';

import { pageSize } from '../constants/pagination';
import { IId } from '../models/types';

export interface IGetAllParams {
  mapId: string;
  hardwareType: string;
  search: string;
  showInactive: boolean | undefined;

  pageIndex: number;
}

export interface IUpdateOrCreateBody {
  macAddress: IId;
  name: string;
  hardwareType: string;
  calibratedRssi: string;
  calibratedDistance: string;
  isActive: boolean;
  lat?: number | null;
  lon?: number | null;
  x?: number | null;
  y?: number | null;
  z: string;
  mapId: IId;
  instanceId: number | null;
}

export interface IGetTypeAllParams {
  hideSpecial?: boolean;
}

const url = '/beacon';

const BeaconsService = {
  get: (mac: IId) => axios.get(`${url}/${mac}`),
  getAll: (params: IGetAllParams) => axios.get(`${url}`, { params: { pageSize, ...params } }),
  updateOrCreate: (body: IUpdateOrCreateBody) => axios.post(`${url}`, body),
  remove: (mac: IId) => axios.delete(`${url}/${mac}`),
  getTypeAll: (params: IGetTypeAllParams = {}) => axios.get(`${url}/hard_types`, { params })
};

export default BeaconsService;
