import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody, MtsBadge } from '@mts-ds/components-react';

import { Dispatch } from '../../../../store';
import { paths } from '../../../Root/paths';
import { IOffice } from '../../../../models/types';

import Typography from '../../../../components_/Typography';

import OfficeMarkerIcon from '../../Map/OfficeMarkerIcon';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: `${spacing(1)}px 0`,
    cursor: 'pointer'
  },
  body: {
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  },
  count: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  info: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setOffice: d.infrastructureOffices.setOffice,
    resetMap: d.infrastructureMap.resetMap
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

type IProps = {
  office: IOffice;
  index: number;
} & connectedProps;

const Item: FC<IProps> = ({ setOffice, office, index, resetMap }) => {
  const { name, employeesCount } = office;

  const history = useHistory();
  const classes = useStyles();

  const handleClick = async () => {
    localStorage.removeItem('mapId');
    await resetMap();
    await setOffice(office);

    history.push(paths.map.office.root);
  };

  return (
    <MtsCard onClick={handleClick} className={classes.root}>
      <MtsCardBody>
        <Grid container className={classes.body}>
          <Grid item>
            <OfficeMarkerIcon size="s" index={index} />
          </Grid>
          <Grid item className={classes.info}>
            <Typography variant="p1Medium" color="text-headline">
              {name}
            </Typography>
            <Grid container alignItems="center" className={classes.count}>
              <Typography variant="p3Regular">Активных сотрудников</Typography>
              <MtsBadge>{employeesCount ? employeesCount : '-'}</MtsBadge>
            </Grid>
          </Grid>
        </Grid>
      </MtsCardBody>
    </MtsCard>
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(Item);
