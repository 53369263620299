/**
 * Функция, возвращающая form data
 */

interface IData {
  [key: string]: any;
}

const getFormData = (data: IData) => {
  const Form = new FormData();

  Object.keys(data).forEach(key => {
    const value = data[key];

    if (Array.isArray(value)) {
      value.forEach(item => {
        Form.append(`${key}[]`, item);
      });

      return;
    }

    Form.append(key, data[key]);
  });

  return Form;
};

export default getFormData;
