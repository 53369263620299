import React, { FC } from 'react';
import { MtsCheckbox } from '@mts-ds/components-react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';

export type IProps = IFormGroupTextProps & typeof MtsCheckbox.defaultProps;

const Checkbox: FC<IProps> = ({ label, description, valid, onMtsChange, ...props }) => {
  const handleMtsChange = (onChange: any) => (event: any) => {
    const { checked } = event.target;
    event.target.value = checked;
    onChange(event);
  };
  return (
    <FormGroup label={label} description={description} valid={valid}>
      <MtsCheckbox {...(onMtsChange && { onMtsChange: handleMtsChange(onMtsChange) })} {...props} />
    </FormGroup>
  );
};

export default Checkbox;
