import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../store';
import { useAsync } from '../../../../../hooks';

import Spinner from '../../../../../components_/Spinner';
import EmptyState from '../../../../../components_/EmptyState';

import Form from './Form';

const mapState = (state: iRootState) => ({
  data: state.settingSecTree.data,
  secTreeHierarchy: state.settingSecTree.secTreeHierarchy
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getHierarchy: d.settingSecTree.getHierarchy,
    getData: d.settingSecTree.getData,
    getSecurableTypes: d.settingSecTree.getSecurableTypes,
    getNodeACL: d.settingSecTree.getNodeACL,
    updateOrCreateACL: d.settingSecTree.updateOrCreateACL,
    reset: d.settingSecTree.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Index: FC<IProps> = ({
  data,
  getData,
  updateOrCreateACL,
  getHierarchy,
  secTreeHierarchy,
  getSecurableTypes,
  getNodeACL,
  reset
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetHierarchy, isLoadingGetHierarchy, isGetHierarchyError] = useAsync(getHierarchy);
  const [runGetNodeACL, isLoadingGetNodeACL, isGetHNodeACLError] = useAsync(getNodeACL);
  const [runGetSecurableTypes, isLoadingGetSecurableTypes, isGetSecurableTypesError] = useAsync(
    getSecurableTypes
  );
  const query = useQuery();
  const treeItem = query.get('root') ? query.get('root') : '20b3bb0a-6226-4cc7-9bcc-c9cb5c458f47';

  const [runUpdateOrCreate, isLoadingUpdateOrCreate, isUpdateOrCreateLoadingError] = useAsync(
    updateOrCreateACL
  );

  useEffect(() => {
    runGetData();
    runGetHierarchy();
    runGetNodeACL({ root: '', roleId: '' });
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (treeItem) {
      runGetSecurableTypes(treeItem);
    }
  }, [query.get('root')]);

  const handleCancel = () => {
    runGetNodeACL({ root: '', roleId: '' });
  };

  const isLoading =
    isLoadingGetData || isLoadingGetHierarchy || isLoadingGetNodeACL || isLoadingUpdateOrCreate;
  const isLoadingError =
    isGetDataLoadingError || isGetHierarchyError || isGetHNodeACLError || isGetSecurableTypesError;

  return (
    <>
      {!isLoading && !isLoadingError && (
        <Form
          data={data}
          secTreeHierarchy={secTreeHierarchy}
          treeItem={treeItem}
          onSubmitAction={runUpdateOrCreate}
          onCancel={handleCancel}
        />
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
