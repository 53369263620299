import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { Form, FormRenderProps } from 'react-final-form';
import { Grid, makeStyles } from '@material-ui/core';

import FormInput from '../../components_/Form/FormInput';

import { Dispatch, iRootState } from '../../store';
import { required } from '../../utils/validation';
import { paths } from '../Root/paths';
import useRequestHandler from '../../hooks/useRequestHandler';
import FormRow from '../../components_/Form/FormRow';
import FormCol from '../../components_/Form/FormCol';
import FormBase from '../../components_/Form';
import { MtsButton, MtsCard, MtsCardBody, MtsCardTitle } from '@mts-ds/components-react';
import Typography from '../../components_/Typography';
import FormButton from '../../components_/Form/FormButton';

const useStyles = makeStyles(({ colors }) => ({
  root: {
    height: '100%'
  },
  title: {
    color: colors.deepBlue
  },
  card: {
    boxShadow: 'none',
    width: 400,
    padding: 30
  },
  submitButton: {
    marginTop: 25,
    width: '100%'
  }
}));

const mapState = (state: iRootState) => ({
  isAuth: state.auth.isAuth // todo убрать если не будет использоваться
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    submitLogin: d.auth.submitLogin
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IFormValues {
  username: string;
  password: string;
}

const initialValues: IFormValues = {
  username: '',
  password: ''
};

type IRenderForm = (props: FormRenderProps<IFormValues>) => ReactNode;

interface ILocationState {
  from: {
    pathname: string;
  };
}

const Login: FC<IProps> = ({ submitLogin }) => {
  const classes = useStyles();
  const location = useLocation<ILocationState>();
  const requestHandler = useRequestHandler();

  const isCreate = true;
  const onSuccess = () => {
    const { from } = location.state || { from: { pathname: paths.root } };

    return <Redirect to={from} />;
  };

  const onSubmit = async (values: IFormValues) => {
    const error = await submitLogin(values);
    requestHandler({
      error,
      entity: 'login',
      onSuccess,
      isCreate
    });
  };

  const renderForm: IRenderForm = ({ handleSubmit, values }) => {
    return (
      <Grid className={classes.root} container justify="center" alignItems="center">
        <FormBase onSubmit={handleSubmit} autoComplete="off">
          <MtsCard className={classes.card}>
            <MtsCardTitle className={classes.title} title="Вход" />
            <MtsCardBody>
              <Typography className={classes.title} variant="h3Bold">
                Вход
              </Typography>
              <Typography className={classes.title} variant="p3Regular">
                Для регистрации оставьте заявку
              </Typography>
              <FormRow>
                <FormCol>
                  <FormInput
                    label="Логин"
                    id="login"
                    name="username"
                    useFieldConfig={{
                      validate: required
                    }}
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    label="Пароль"
                    name="password"
                    type="password"
                    useFieldConfig={{
                      validate: required
                    }}
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormButton className={classes.submitButton}>Войти</FormButton>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <MtsButton
                    className={classes.submitButton}
                    disabled={!values.username}
                    onClick={() => {
                      if (values.username) {
                        window.location.replace('/api/v0/auth/sso_redirect/' + values.username);
                      }
                    }}
                  >
                    SSO
                  </MtsButton>
                </FormCol>
              </FormRow>
            </MtsCardBody>
          </MtsCard>
        </FormBase>
      </Grid>
    );
  };

  return <Form initialValues={initialValues} onSubmit={onSubmit} render={renderForm} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Login);
