import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { iRootState } from '../../store';
import { paths } from '../Root/paths';

const mapState = (state: iRootState) => ({
  office: state.infrastructureOffices.item
});

type connectedProps = ReturnType<typeof mapState>;

type IProps = connectedProps & RouteProps;

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const OfficeRoute: FC<IProps> = ({ office, children, ...rest }) => {
  const query = useQuery();
  if (query.get('mapId')) {
    localStorage.setItem('mapId', String(query.get('mapId')));
  }
  const mapId = localStorage.getItem('mapId');

  const isAccess = Boolean(office || mapId);
  // console.log(isAccess);

  return (
    <Route {...rest} render={() => (isAccess ? children : <Redirect to={paths.map.root} />)} />
  );
};

const withConnect = connect(mapState);

export default withConnect(OfficeRoute);
