/**
 * Модель для работы с реестром СИЗ
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import {
  IId,
  IUser,
  IShortUser,
  INotificationType,
  INotificationTarget,
  INotificationVariable,
  INotificationSetting
} from './types';

import NotificationsService, {
  IUpdateOrCreateTemplate as IUpdateOrCreate
} from '../services/NotificationsService';
import UsersService from '../services/UsersService';

interface IDataUsers {
  data: IUser[];
  pageIndex: number;
  total: number;
}

interface IData {
  users: IDataUsers;
  shortUsers: IShortUser[];
  notificationTypes: INotificationType[];
  notificationTargets: INotificationTarget[];
  notificationVariables: INotificationVariable[];
}

interface IList {
  data: INotificationSetting[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  item: IUpdateOrCreate | null;
}

const initialState: IState = {
  data: {
    users: {
      data: [],
      pageIndex: 0,
      total: 0
    },
    shortUsers: [],
    notificationTypes: [],
    notificationTargets: [],
    notificationVariables: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: { ...state.data, ...payload } };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IUpdateOrCreate): IState {
      return { ...state, item: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          { data: shortUsers },
          { data: notificationTypes },
          { data: notificationTargets },
          { data: notificationVariables }
        ] = await Promise.all([
          UsersService.getAllShort(),
          NotificationsService.getTypesAll(),
          NotificationsService.getTargetsAll(),
          NotificationsService.getVariablesAll()
        ]);

        this.setData({ shortUsers, notificationTypes, notificationTargets, notificationVariables });
      } catch (error) {
        return error;
      }
    },
    async getDataUsers(params, rootState: iRootState) {
      const { users } = rootState.settingNotifications.data;

      const {
        data: { data, total, pageIndex: page }
      } = await UsersService.getAll(params);

      this.setData({
        users: { data: [...(params.pageIndex ? users.data : []), ...data], total, pageIndex: page }
      });
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await NotificationsService.getTemplateAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async getItem(id: IId) {
      try {
        const { data } = await NotificationsService.getTemplate(id);

        this.setItem(data);
      } catch (error) {
        return error;
      }
    },
    async updateOrCreate(body: IUpdateOrCreate) {
      try {
        await NotificationsService.updateOrCreateTemplate(body);
      } catch (error) {
        return error;
      }
    },
    async remove(id: IId) {
      try {
        await NotificationsService.deleteTemplate(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const settingNotifications: typeof model = createModel(model);
