import React, { FC, Fragment, useEffect, useState } from 'react';
import { MtsDropdown, MtsDropdownOptgroup, MtsDropdownOption } from '@mts-ds/components-react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';

import { IId } from '../../../models/types';

interface IOption {
  id: IId;
  name: string;
}

interface IGroupOptions {
  [key: string]: IOption[];
}

export type IProps = {
  options?: IOption[];
  groupOptions?: IGroupOptions[];
} & IFormGroupTextProps &
  typeof MtsDropdown.defaultProps;

const Dropdown: FC<IProps> = ({
  options,
  groupOptions,
  label,
  description,
  valid,
  onMtsChange,
  ...props
}) => {
  const [resetCounter, setResetCounter] = useState(0);

  useEffect(() => {
    /*
     * Хак - ререндер компонента MtsDropdownOptgroup с помощью key
     * При ресете фильтров галочка у MtsDropdownOptgroupLabel не сбрасывается,
     * хотя MtsDropdownOption-элементы внутри MtsDropdownOptgroup сбрасываются
     * Проверить исправление при следующем релизе
     */
    if (Array.isArray(props.value) && !props.value.length) {
      setResetCounter(resetCounter + 1);
    }
  }, [props.value]);

  // tslint:disable-next-line: no-shadowed-variable
  const handleMtsChange = (onMtsChange: any) => (event: any) => {
    /*
     * Хак - передаем [] изменяет на [""]
     * Проверить исправление при следующем релизе
     */
    if (
      (Array.isArray(event.target.value) && event.target.value.every((item: any) => item)) ||
      !Array.isArray(event.target.value)
    ) {
      /*
       * Хак - передаем [1, 2] изменяет на ["1,2"]
       * Проверить исправление при следующем релизе
       */
      if (
        Array.isArray(event.target.value) &&
        event.target.value.length === 1 &&
        event.target.value[0].includes(',')
      ) {
        event.target.value = event.target.value[0].split(',');
      }

      onMtsChange(event);
    }
  };

  // tslint:disable-next-line: no-shadowed-variable
  const renderOptions = (options: IOption[]) =>
    options.map(({ id, name }) => (
      <MtsDropdownOption key={id} value={id.toString()}>
        {name}
      </MtsDropdownOption>
    ));

  return (
    <FormGroup label={label} description={description} valid={valid}>
      <MtsDropdown
        buttonCancel="Отмена"
        buttonConfirm="Готово"
        {...(onMtsChange && { onMtsChange: handleMtsChange(onMtsChange) })}
        {...props}
      >
        {options && renderOptions(options)}
        {groupOptions &&
          groupOptions.map(groupOption =>
            // tslint:disable-next-line: no-shadowed-variable
            Object.entries(groupOption).map(([group, groupOptions]) => (
              <Fragment key={group}>
                {Boolean(groupOptions.length) && (
                  <MtsDropdownOptgroup label={group} key={`${group}_${resetCounter}`}>
                    {renderOptions(groupOptions)}
                  </MtsDropdownOptgroup>
                )}
              </Fragment>
            ))
          )}
      </MtsDropdown>
    </FormGroup>
  );
};

export default Dropdown;
