import * as L from 'leaflet';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Children, MapLayer, withLeaflet } from 'react-leaflet';

declare module 'react-leaflet' {
  // @ts-ignore
  const withLeaflet: <T>(component: T) => T;
}

export interface IMapboxGLProps extends L.MapboxGLOptions {
  children?: Children;
}

// @ts-ignore
class MapBoxGLLayer extends MapLayer<IMapboxGLProps, {}> {
  public static defaultProps = {
    style: 'mapbox://styles/mapbox/satellite-streets-v12'
  };

  public createLeafletElement(props: IMapboxGLProps) {
    return L.mapboxGL(props);
  }

  public render() {
    return null;
  }
}

// @ts-ignore
export default withLeaflet(MapBoxGLLayer);
