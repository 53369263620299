import React, { FC } from 'react';

import Typography, { IProps as ITypographyProps } from '../../Typography';

type IProps = Omit<ITypographyProps, 'variant'>;

const TableColText: FC<IProps> = ({ children, ...props }) => {
  return (
    <Typography variant="p2Regular" {...props}>
      {children || '-'}
    </Typography>
  );
};

export default TableColText;
