import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge } from '@mts-ds/components-react';
import { BadgeSizes } from '@mts-ds/components/dist/types/components/badge/enums/sizes.enum';

import { IBeacon } from '../../../models/types';
import { paths } from '../../../routes/Root/paths';

import Typography, { IProps as ITypographyProps } from '../../Typography';
import Icon from '../../Icon';
import BeaconIcon, { IProps as BeaconIconProps } from '../../Icon/icons/BeaconIcon';
import { ReactComponent as BeaconSvg } from '../../../assets_/icons/bleBeacon.svg';
import { ReactComponent as GatewaySvg } from '../../../assets_/icons/bleGateway.svg';
import { ReactComponent as UwbSvg } from '../../../assets_/icons/uwbAnchor.svg';
import { ReactComponent as InfrastructureSvg } from '../../../assets_/icons/infrastructure.svg';
import BatteryIcon from '../../Icon/icons/BatteryIcon';

import DrawerContentItem from '../DrawerContentItem';
import { getColor } from '../../../theme';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  error: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  active: {
    marginLeft: 'auto'
  }
}));

enum Variants {
  default = 'default',
  card = 'card'
}

type IVariants = {
  [key in Variants]: {
    iconSize: BeaconIconProps['size'];
    typographyMacAddressVariant: ITypographyProps['variant'];
    typographyNameVariant: ITypographyProps['variant'];
    badgeHardwareTypeSimpleSize: keyof typeof BadgeSizes;
    isVertical: boolean;
    isQueryTime: boolean;
    isClick: boolean;
  };
};

interface IProps {
  beacon: IBeacon;
  variant?: keyof typeof Variants;
  action?: ReactNode;
}

const DrawerContentItemBeacon: FC<IProps> = ({ beacon, variant = Variants.default, action }) => {
  const { macAddress, name, hardwareTypeFriendly, charge, queryTime, isActive } = beacon;

  const classes = useStyles();
  const history = useHistory();

  const variants: IVariants = {
    default: {
      iconSize: 's',
      typographyMacAddressVariant: 'p2Regular',
      typographyNameVariant: 'p2Bold',
      badgeHardwareTypeSimpleSize: 's',
      isVertical: false,
      isQueryTime: true,
      isClick: true
    },
    card: {
      iconSize: 'm',
      typographyMacAddressVariant: 'h3Regular',
      typographyNameVariant: 'h3Bold',
      badgeHardwareTypeSimpleSize: 'm',
      isVertical: true,
      isQueryTime: false,
      isClick: false
    }
  };

  const handleClick = () => {
    history.push(paths.map.office.infrastructure.show(macAddress));
  };

  // const icon = <BeaconIcon size={variants[variant].iconSize} />;
  const icon = beacon ? (
    beacon.hardwareType === 'BLE_BEACON' ? (
      <BeaconSvg style={{ color: getColor('constant-blueberry-dark') }} />
    ) : beacon.hardwareType === 'BLE_GATEWAY' ? (
      <GatewaySvg style={{ color: getColor('constant-blueberry-light') }} />
    ) : beacon.hardwareType === 'UWB_ANCHOR' ? (
      <UwbSvg style={{ color: getColor('constant-apple-light') }} />
    ) : (
      <InfrastructureSvg />
    )
  ) : (
    <InfrastructureSvg />
  );

  const content = (
    <Grid className={classes.root}>
      <Grid
        container
        justify="space-between"
        {...(variants[variant].isVertical && { direction: 'column' })}
      >
        <Grid>
          <Typography variant={variants[variant].typographyNameVariant}>{name}</Typography>
          <Typography variant={variants[variant].typographyMacAddressVariant}>
            {macAddress}
          </Typography>
        </Grid>
        <Grid>
          <MtsBadge size={variants[variant].badgeHardwareTypeSimpleSize}>
            {hardwareTypeFriendly}
          </MtsBadge>
        </Grid>
      </Grid>

      {/*<Grid container alignItems="center" className={classes.error}>*/}
      {/*  <Icon name="error" size="s" />*/}
      {/*  <Typography variant="p3Regular" color="text-secondary">*/}
      {/*    Устройство недоступно*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}

      <Grid container alignItems="center" className={classes.error}>
        <BatteryIcon charge={charge} />
        <Typography variant="p3Medium">{charge} %</Typography>
        {Number(charge) <= 25 && (
          <Typography variant="p3Regular" color="text-secondary">
            Низкий заряд батареи
          </Typography>
        )}
        {isActive ? (
          <>
            <Icon className={classes.active} name="checkMark" size="s" />
            <Typography variant="p3Medium">Активен</Typography>
          </>
        ) : (
          <>
            <Icon className={classes.active} name="errorGray" size="s" />
            <Typography variant="p3Medium">Неактивен</Typography>
          </>
        )}
      </Grid>

      {variants[variant].isQueryTime && queryTime && (
        <Typography variant="p3Regular" color="text-secondary">
          Данные получены{' '}
          {moment(queryTime)
            .startOf('hour')
            .fromNow()}
        </Typography>
      )}
    </Grid>
  );

  return (
    <DrawerContentItem
      variant={variant}
      icon={icon}
      content={content}
      action={action}
      align="stretch"
      {...(variants[variant].isClick && { onClick: handleClick })}
    />
  );
};

export default DrawerContentItemBeacon;
