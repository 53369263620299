import React, { FC } from 'react';
import Paginate from 'react-paginate';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

interface IProps {
  pageIndex: number;
  pagesCount: number;
  onPageChange: (pageIndex: number) => void;
}

const useStyles = makeStyles(({ spacing, getColor, getShadow }) => ({
  root: {
    margin: spacing(2, 0),
    padding: 0,
    '& li': {
      display: 'inline-block',
      margin: spacing(0, 1)
    },
    '& a': {
      textDecoration: 'none',
      fontSize: 17,
      fontWeight: 500,
      color: getColor('text-primary'),
      display: 'inline-block',
      cursor: 'pointer',
      outline: 'none',
      padding: spacing(0.5, 1.5)
    }
  },
  active: {
    borderRadius: spacing(0.75),
    backgroundColor: getColor('constant-greyscale-0'),
    boxShadow: getShadow('low')
  },
  activeLink: {
    cursor: 'default !important'
  },
  disabled: {
    visibility: 'hidden'
  }
}));

const Pagination: FC<IProps> = ({ pageIndex, pagesCount, onPageChange }) => {
  const classes = useStyles();

  if (pagesCount < 2) {
    return null;
  }

  return (
    <Grid container justify="center">
      <Paginate
        forcePage={pageIndex}
        pageCount={pagesCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        {...(onPageChange && { onPageChange: ({ selected }) => onPageChange(selected) })}
        previousLabel={'<'}
        nextLabel={'>'}
        containerClassName={classes.root}
        activeClassName={classes.active}
        activeLinkClassName={classes.activeLink}
        disabledClassName={classes.disabled}
      />
    </Grid>
  );
};

export default Pagination;
