import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './';

import Button from '../../../../components_/Fields/Button';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(6)
  }
}));

interface IProps {
  addForm: IFormProps['addForm'];
}

const FormAdd: FC<IProps> = ({ addForm }) => {
  const classes = useStyles();

  return (
    <Button icon="plus" color={'ghost' as ButtonColors} className={classes.root} onClick={addForm}>
      Ещё носимое устройство
    </Button>
  );
};

export default FormAdd;
