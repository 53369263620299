/**
 * Сервис для работы с пользователями
 */

import axios from 'axios';
import { IId } from '../models/types';

export interface IFilterParams {
  mapId?: IId;
  fullTextSearch?: string;
  includeOffline?: boolean;
}

const url = '/position';

const PositionsService = {
  getUsers: (params: IFilterParams = {}) => axios.get(`${url}/finduser`, { params })
};

export default PositionsService;
