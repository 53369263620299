import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { guids, paths } from '../Root/paths';

import Drawer from '../../components/Drawer';

import OfficeRoute from '../Infrastructure/OfficeRoute';
import GeoMap from '../Infrastructure/GeoMap';
import Map from '../Main/Map';

import Main from './Sidebar/Office/Show';
import Offices from './Sidebar/Main';
import Office from './Sidebar/Office';
import Plan from './Sidebar/Plan';
import Users from './Sidebar/Users';
import User from './Sidebar/Users/User';
import Areas from './Sidebar/Areas';
import Area from './Sidebar/Areas/Area';
import Beacons from './Sidebar/Beacons';
import Beacon from './Sidebar/Beacons/Beacon';
import Cameras from './Sidebar/Cameras';
import Camera from './Sidebar/Cameras/Camera';

import PrivateRoute from '../Root/PrivateRoute';

const Infrastructure: FC = () => {
  return (
    <>
      <Map />
      <Drawer>
        <Switch>
          <Route exact path={paths.map.root} component={Offices} />
          <Route path={paths.map.office.create} component={Office} />
          <OfficeRoute path={paths.map.office.root}>
            <Switch>
              {/*<Route exact path={paths.map.office.root} component={Office} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.root
                }}
                path={paths.map.office.root}
                component={Office}
              />
              {/*<Route exact path={paths.map.office.edit()} component={Office} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.edit()
                }}
                path={paths.map.office.edit()}
                component={Office}
              />
              {/*<Route exact path={paths.map.office.plan.create} component={Plan} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.plan.create
                }}
                path={paths.map.office.plan.create}
                component={Plan}
              />
              {/*<Route exact path={paths.map.office.plan.edit()} component={Plan} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.plan.edit()
                }}
                path={paths.map.office.plan.edit()}
                component={Plan}
              />
              {/*<Route exact path={paths.map.office.users.root} component={Users} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.users.root
                }}
                path={paths.map.office.users.root}
                component={Users}
              />
              {/*<Route path={paths.map.office.users.root} component={User} />*/}
              <PrivateRoute
                requirement={{
                  uid: guids.map.office.users.root
                }}
                path={paths.map.office.users.root}
                component={User}
              />
              {/*<Route exact path={paths.map.office.areas.root} component={Areas} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.areas.root
                }}
                path={paths.map.office.areas.root}
                component={Areas}
              />
              {/*<Route path={paths.map.office.areas.root} component={Area} />*/}
              <PrivateRoute
                requirement={{
                  uid: guids.map.office.areas.root
                }}
                path={paths.map.office.areas.root}
                component={Area}
              />
              {/*<Route exact path={paths.map.office.infrastructure.root} component={Beacons} />*/}
              <PrivateRoute
                exact
                requirement={{
                  uid: guids.map.office.infrastructure.root
                }}
                path={paths.map.office.infrastructure.root}
                component={Beacons}
              />
              {/*<Route path={paths.map.office.infrastructure.root} component={Beacon} />*/}
              <PrivateRoute
                requirement={{
                  uid: guids.map.office.infrastructure.root
                }}
                path={paths.map.office.infrastructure.root}
                component={Beacon}
              />
              <Route exact path={paths.map.office.cameras.root} component={Cameras} />
              <Route path={paths.map.office.cameras.root} component={Camera} />
            </Switch>
          </OfficeRoute>
        </Switch>
      </Drawer>
    </>
  );
};

export default Infrastructure;
