import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(4)
  }
}));

const ContentFilter: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      {children}
    </Grid>
  );
};

export default ContentFilter;
