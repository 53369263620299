import React, { FC } from 'react';
import { Popover } from '@material-ui/core';
import { IHeaderMenuPropsDefault } from './types';

const Default: FC<IHeaderMenuPropsDefault> = ({ children, ...restOfProps }) => {
  return (
    <Popover
      {...restOfProps}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {children}
    </Popover>
  );
};

export default Default;
