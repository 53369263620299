import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../store';
import { useAsync, useParams } from '../../hooks';
import { IGetAllParams } from '../../services/NotificationsService';

import Content from '../../components_/Content';
import Spinner from '../../components_/Spinner';
import Pagination from '../../components_/Pagination';
import EmptyState from '../../components_/EmptyState';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetAllParams, 'pageIndex'> = {
  notificationType: '',
  notificationTarget: '',
  employeesList: undefined,
  from: '',
  to: ''
};

const mapState = (state: iRootState) => ({
  data: state.notifications.data,
  list: state.notifications.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.notifications.getData,
    getList: d.notifications.getList,
    reset: d.notifications.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Notifications: FC<IProps> = ({
  data,
  list: { data: list, pagesCount },
  getData,
  getList,
  reset
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const { pageIndex, filter, handlePageChange, handleFilterChange, handleFilterReset } = useParams(
    initialFilter,
    runGetList
  );

  useEffect(() => {
    runGetData();

    return () => {
      reset();
    };
  }, []);

  const isLoading = isLoadingGetData || isLoadingGetList;
  const isLoadingError = isGetDataLoadingError || isGetListLoadingError;

  return (
    <Content title="Уведомления">
      {!isLoadingGetData && !isGetDataLoadingError && (
        <>
          <Filter
            data={data}
            filter={filter}
            onChange={handleFilterChange}
            onReset={handleFilterReset}
            disabled={isLoadingGetList}
          />
          {!isLoadingGetList && !isGetListLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                  {Boolean(list.length) && (
                    <>
                      <List list={list} />
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Notifications);
