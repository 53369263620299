/**
 * Модель для работы с картами
 */

import { createModel, ModelConfig } from '@rematch/core';

import MapsService from '../services/MapsService';
import { IId, IMaps } from './types';

interface IState {
  list: IMaps[];
}

const initialState: IState = {
  list: []
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setMaps(state: IState, payload: IMaps[]): IState {
      return {
        ...state,
        list: payload
      };
    }
  },
  effects: {
    async getMaps(officeId: IId) {
      const { data } = await MapsService.getAll({ officeId });

      // MOCK
      this.setMaps(data.reverse());
    },
    resetMaps(officeId: IId) {
      this.setMaps(initialState.list);
    }
  }
};

export const infrastructureMaps: typeof model = createModel(model);
