import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TopTypography from '@top-ui/core/lib/TopTypography';
import { TopChip } from '@top-ui/core';

const useStyles = makeStyles(({ spacing, colors }) => ({
  root: {
    padding: spacing(4, 3),
    marginBottom: spacing(6.5)
  },
  header: {
    marginBottom: spacing(2)
  },
  description: {
    fontSize: 12,
    fontWeight: 400,
    color: colors.greySlate,
    backgroundColor: colors.whiteSmoke
  },
  spacing: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  }
}));

interface IProps {
  title?: string;
  description?: string;
}

const DrawerContent: FC<IProps> = ({ title, description, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid container className={clsx(classes.header, classes.spacing)}>
        <TopTypography variant="h5">{title}</TopTypography>
        {description && (
          <TopChip size="small" label={description} className={classes.description} />
        )}
      </Grid>
      {children}
    </Grid>
  );
};

export default DrawerContent;
