import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import TopDrawer from '@top-ui/core/lib/TopDrawer';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TopIcon from '@top-ui/core/lib/TopIcon';
import TopIconButton from '@top-ui/core/lib/TopIconButton';

import { Dispatch, iRootState } from '../../store';

import Scrollbar from '../../components_/Scrollbar';

const width = 380;

const useStyles = makeStyles(({ spacing, colors }) => ({
  paper: ({ isExpand, isSub }: IStyleProps) => ({
    width: isExpand ? width : 50,
    left: isSub ? width : 0,
    paddingTop: spacing(6.5),
    paddingBottom: spacing(9),
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
    zIndex: 0,
    transition: '0.1s'
  }),
  back: {
    margin: `${spacing(1.5)}px auto`,
    transform: 'rotate(180deg)',
    color: colors.greySteel
  },
  iconButton: {
    padding: 0
  }
}));

const mapState = (state: iRootState) => ({
  isExpand: state.drawer.isExpand
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    expendDrawer: d.drawer.expandDrawer,
    getMap: d.infrastructureMap.getMap
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

interface IStyleProps {
  isExpand?: boolean;
  isSub?: boolean;
}

type IProps = {
  isSub?: boolean;
  children: ReactNode;
} & connectedProps;

const Drawer: FC<IProps> = ({ isSub, isExpand, children, expendDrawer, getMap }) => {
  const classes = useStyles({ isSub, isExpand });

  const handleClick = () => {
    expendDrawer();
    getMap();
  };

  return (
    <TopDrawer classes={{ paper: classes.paper }} variant="permanent">
      {isExpand ? (
        <Scrollbar>{children}</Scrollbar>
      ) : (
        <Grid className={classes.back}>
          <TopIconButton onClick={handleClick} className={classes.iconButton}>
            <TopIcon name="back" />
          </TopIconButton>
        </Grid>
      )}
    </TopDrawer>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Drawer);
