import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import BatteryIcon from '../../../../components_/Icon/icons/BatteryIcon';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginRight: spacing(1.5)
  }
}));

interface IProps {
  charge: number;
}

const ListItemCharge: FC<IProps> = ({ charge }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <BatteryIcon charge={charge} className={classes.root} /> {charge} %
    </Grid>
  );
};

export default ListItemCharge;
