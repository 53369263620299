import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useField } from 'react-final-form';
import { MtsChip } from '@mts-ds/components-react';

import { IId, IShortUser } from '../../../../models/types';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 112
  }
}));

interface IProps {
  shortUsers: IShortUser[];
}

const FormUserListChip: FC<IProps> = ({ shortUsers }) => {
  const classes = useStyles();

  const {
    input: { value, onChange }
  } = useField<[]>('employees');

  const handleClick = (id: IId) => () => {
    const data = value.filter(item => item !== id);

    onChange(data);
  };

  const getFio = (id: IId) => {
    // Хак dropdown отдаёт строку
    const { fio = '' } = shortUsers.find(item => item.userId === id) || {};
    return fio;
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      {value.map(id => {
        return (
          <Grid item key={id}>
            <MtsChip value={getFio(id)} onClick={handleClick(id)} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormUserListChip;
