import Hls from 'hls.js';
import React, { FC, useEffect, useRef } from 'react';

interface IVideoPlayerProps {
  src: string;
  className?: string;
}

const VideoPlayer: FC<IVideoPlayerProps> = ({ src, className = '' }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const hls = new Hls({
      debug: true,
      lowLatencyMode: true
      // autoStartLoad: false
    });

    if (Hls.isSupported()) {
      hls.loadSource(src);
      hls.attachMedia(videoRef.current as HTMLVideoElement);
      hls.on(Hls.Events.ERROR, (err: any, data: any) => {
        // tslint:disable-next-line:no-console
        console.log(err, data);
      });
    } else {
      // tslint:disable-next-line:no-console
      console.log('HLS is not supported');
    }
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      controls
      width="100%"
      height="auto"
      className={className}
    />
  );
};

export default VideoPlayer;
