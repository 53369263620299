import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { paths } from './paths';

import Wrapper from '../../components_/Wrapper';
import Header from '../../components_/Header';
import Main from '../../components_/Main';
import Footer from '../../components_/Footer';
import ErrorBoundary from '../../components/ErrorBoundaryPage';
import MinWidth from '../../components/MinWidth';
import Alerts from '../../containers/Alerts';
import Toast from '../../components_/Toast';
import SecurableTree from '../../containers/SecurableTree';

import Authorization from './Authorization';
import MainMap from '../Main';
import Staff from '../Staff';
import Equipment from '../Equipment/index';
import Reports from '../Reports';
import Notifications from '../Notifications';
import Settings from '../Settings/index';
import NotFound from '../NotFound';
import SoundEnablerModal from '../../components/Sound';
import PrivateRoute from './PrivateRoute';

const Root: FC = () => {
  return (
    <Wrapper>
      <ErrorBoundary>
        <Toast>
          <Authorization>
            <SecurableTree />
            <Header />
            <Main>
              <Switch>
                <Redirect from={paths.root} to={paths.map.root} exact />
                <Redirect from={paths.websso} to={paths.map.root} exact />
                <PrivateRoute
                  requirement={{
                    uid: 'b39d78c0-ec94-4ddf-8bc6-ad9cb738e3a6'
                  }}
                  path={paths.map.root}
                  component={MainMap}
                />
                <PrivateRoute
                  requirement={{
                    uid: 'c94e1c86-0196-4b5a-8725-20b44f58c501'
                  }}
                  path={paths.staff.root}
                  component={Staff}
                />
                <PrivateRoute
                  requirement={{
                    uid: '1a120c2c-b46c-4e57-ab22-3193a949e8c3'
                  }}
                  path={paths.equipment.root}
                  component={Equipment}
                />
                <PrivateRoute
                  requirement={{
                    uid: '3c200fbf-b6a7-4897-ba15-e971c56fb022'
                  }}
                  path={paths.reports.root}
                  component={Reports}
                />
                <PrivateRoute
                  requirement={{
                    uid: '22917d31-07c5-4687-9df3-de62146bd413'
                  }}
                  path={paths.notifications}
                  component={Notifications}
                />
                <PrivateRoute
                  requirement={{
                    uid: '8d15c93a-e534-4f1c-80bd-837550c4395a'
                  }}
                  path={paths.settings.root}
                  component={Settings}
                />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <Footer />
            <MinWidth />
            <SoundEnablerModal />
            <Alerts />
          </Authorization>
        </Toast>
      </ErrorBoundary>
    </Wrapper>
  );
};

export default Root;
