import React, { FC } from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

const FormRow: FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid container spacing={3} alignItems="flex-start" {...props}>
      {children}
    </Grid>
  );
};

export default FormRow;
