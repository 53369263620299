/**
 * Конфиг с настройками Axios обращения к API сервера
 */

import axios from 'axios';
import qs from 'qs';

import getClearParams from './utils/getClearParams';
import apiConstants from './constants/apiConstants';
import LocalStorageService from './services/LocalStorageService';
import UsersService from './services/UsersService';

/**
 * Base url для проксирования запросов на сервер
 */
axios.defaults.baseURL = apiConstants.prefix;

axios.defaults.paramsSerializer = (params: any) => {
  const clearParams = getClearParams(params);

  return qs.stringify(clearParams, { arrayFormat: 'repeat' });
};

axios.interceptors.request.use(config => {
  const { accessToken, tokenType = 'Bearer' } = LocalStorageService.getToken();

  config.headers.Authorization = `${tokenType} ${accessToken}`;

  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      originalRequest.baseURL = '';

      const { refreshToken } = LocalStorageService.getToken();

      if (
        refreshToken &&
        error.response.config.url !== '/api/user/refreshToken?refreshToken=' + refreshToken
      ) {
        await UsersService.getRefreshedToken(refreshToken)
          .then(({ data }) => {
            LocalStorageService.setToken(data);
          })
          .catch(() => {
            LocalStorageService.clearToken();
            window.location.reload();
          });
        return axios(originalRequest);
      }
    }
    if (error.response.status === 402) {
      LocalStorageService.clearToken();
    }

    return Promise.reject(error);
  }
);
