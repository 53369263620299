import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../../store';

import Form from './Form';

const mapState = (state: iRootState) => ({
  area: state.infrastructureAreas.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.infrastructureAreas.getData,
    getArea: d.infrastructureAreas.getArea,
    setDefaultMode: d.infrastructureMap.setDefaultMode,
    resetCoordinates: d.infrastructureAreas.resetCoordinates
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IParams {
  id: string;
}

const Area: FC<IProps> = ({ area, getData, getArea, setDefaultMode, resetCoordinates }) => {
  const { id } = useParams<IParams>();

  useEffect(() => {
    getData();

    if (id && !area) {
      getArea(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      setDefaultMode();
      resetCoordinates();
    };
  }, []);

  return (
    <>
      <Form />
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Area);
