import React, { FC, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import { prepareTemplate } from '../utils';

import Typography from '../../../../components_/Typography';

import StrTemplate from './../StrTemplate';
import FormTemplateChip from './FormTemplateChip';

import { IProps as IFormProps } from './';

const useStyles = makeStyles(({ spacing, getColor }) => ({
  root: {
    height: 88,
    width: '100%',
    border: `2px solid ${getColor('constant-greyscale-200')}`,
    borderRadius: 7,
    fontFamily: 'MTS Sans, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    padding: spacing(1),
    '&:focus': {
      outline: 'none',
      borderColor: getColor('constant-blueberry-dark')
    },
    '&:empty:before': {
      content: 'attr(placeholder)',
      fontSize: 14,
      color: getColor('text-tertiary')
    }
  },
  label: {
    marginBottom: 4
  },
  placeholder: {
    color: getColor('text-tertiary')
  }
}));

interface IProps {
  data: IFormProps['data'];
}

const FormTemplate: FC<IProps> = ({ data }) => {
  const { notificationVariables } = data;

  const classes = useStyles();

  const {
    input: { value, onChange }
  } = useField('template');

  const handleChange = (event: ContentEditableEvent) => {
    const templateSanitizeHtml = sanitizeHtml(event.target.value, {
      allowedTags: [],
      allowedAttributes: {}
    });

    const template = prepareTemplate(templateSanitizeHtml, notificationVariables);

    onChange(template);
  };

  const html = renderToString(
    <StrTemplate text={value as string} notificationVariables={notificationVariables} />
  );

  return (
    <>
      <Typography variant="p2Regular" color="text-secondary" className={classes.label}>
        Текст уведомления
      </Typography>
      <ContentEditable
        placeholder="Введите текст уведомления"
        html={html}
        onChange={handleChange}
        className={classes.root}
      />
      <FormTemplateChip data={data} />
    </>
  );
};

export default FormTemplate;
