/**
 * Фильтрует массив
 */

const getSearchedData = (data: any[], fields: string[], search: string) => {
  if (!search && !data) {
    return null;
  }

  return data.filter(item => {
    const searchStr = fields.map(field => item[field]).join(' ');

    const regExp = new RegExp(search, 'i');

    return regExp.test(searchStr);
  });
};

export default getSearchedData;
