import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Typography, { IProps as ITypographyProps } from '../../Typography';

type ISize = 's' | 'm';
interface ISizeParams {
  typography: ITypographyProps['variant'];
  margin: number;
}

type ISizes = Record<ISize, ISizeParams>;

const sizes: ISizes = {
  s: {
    typography: 'p1Bold',
    margin: 2
  },
  m: {
    typography: 'h3Medium',
    margin: 5
  }
};

const useStyles = makeStyles(({ spacing }) => ({
  root: ({ size }: IStyleProps) => ({
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    },
    '&:not(:last-child)': {
      marginBottom: spacing(sizes[size].margin)
    }
  })
}));

interface IStyleProps {
  size: ISize;
}

interface IProps extends IStyleProps {
  title: string;
}

const FormGroup: FC<IProps> = ({ children, size, title }) => {
  const classes = useStyles({ size });

  const variant = sizes[size].typography;

  return (
    <Grid className={classes.root}>
      <Typography variant={variant}>{title}</Typography>
      <Grid>{children}</Grid>
    </Grid>
  );
};

export default FormGroup;
