import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody, MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { Dispatch, iRootState } from '../../store';
import { IAlert, INotificationClass, NotificationSeverity } from '../../models/types';
import { getCalendarTime } from '../../utils/convertTime';

import NotificationIcon from '../../components_/Notification/NotificationIcon';

import Typography from '../../components_/Typography';
import Icon from '../../components_/Icon';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  body: {
    color: getColor('text-primary'),
    padding: spacing(2),
    position: 'relative'
  },
  info: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(0.5)
    },
    hyphens: 'auto'
  },
  close: {
    position: 'absolute',
    right: 4,
    top: 4
  }
}));

const mapState = (state: iRootState) => ({
  alerts: state.alerts.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    confirmAlert: d.alerts.confirmAlert,
    deleteAlert: d.alerts.deleteAlert,
    addConfirmation: d.alerts.addConfirmation
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = {
  alert: IAlert;
  play: any;
  stop: any;
} & connectedProps;

const Item: FC<IProps> = ({ alert, confirmAlert, deleteAlert, addConfirmation, play, stop }) => {
  const { uid, type, title, description, isConfirm, severity, date } = alert;

  const classes = useStyles();

  const handleConfirm = async () => {
    let error;

    if (isConfirm) {
      error = await confirmAlert(uid);
      stop();
    }

    if (!error) {
      deleteAlert(uid);
    }
  };

  const soundStart = async () => {
    if (severity === NotificationSeverity.Critical && isConfirm) {
      play(uid);
    }
  };

  useEffect(() => {
    soundStart();

    switch (severity) {
      case NotificationSeverity.Med:
        deleteAlert(uid);
        break;
      case NotificationSeverity.Low:
        deleteAlert(uid);
        break;
      case NotificationSeverity.High:
        deleteAlert(uid);
        break;
      case NotificationSeverity.Ignored:
        deleteAlert(uid);
        break;
    }

    const handler = setTimeout(() => {
      switch (severity) {
        case NotificationSeverity.Critical:
          addConfirmation(alert);
          break;
        default:
          deleteAlert(uid);
          break;
      }
    }, 10000);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  // const dateMap = [getCalendarTime(date, 'DD.MM.YYYY', true), moment(date).format('HH:mm')];

  return (
    <MtsCard>
      <MtsCardBody className={classes.body}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <NotificationIcon notificationClass={type as INotificationClass} />
          </Grid>
          <Grid item xs={8} className={classes.info}>
            <Typography variant="p2Medium">{title}</Typography>
            <Typography variant="p2Regular">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          </Grid>
          <MtsButton
            size={'s' as ButtonSizes}
            format={'icon-round' as ButtonFormats}
            color={'secondary-inverted' as ButtonColors}
            onClick={handleConfirm}
            className={classes.close}
          >
            <Grid container alignItems="center">
              <Icon name="shape" />
            </Grid>
          </MtsButton>
          {/* {dateMap.map((stamp, index) => (
              <Grid key={index} container justify="flex-end">
                <Typography variant="p3Regular">{stamp}</Typography>
              </Grid>
            ))} */}
        </Grid>
      </MtsCardBody>
    </MtsCard>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Item);
