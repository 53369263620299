import React, { FC, useEffect } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Input from '../../../../components_/Fields/Input';
import Button from '../../../../components_/Fields/Button';

interface IProps {
  filter: any;
  onChange: any;
  onClickCreate: () => void;
}

const Filter: FC<IProps> = ({ filter, onChange, onClickCreate }) => {
  const { fullTextSearch } = filter;

  return (
    <ContentFilter>
      <FormRow justify="space-between">
        <FormCol xs={4}>
          <Input
            name="fullTextSearch"
            type="search"
            placeholder="Найти СИЗ"
            clear
            value={fullTextSearch}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs="auto">
          <Button icon="plus" color={'secondary' as ButtonColors} onClick={onClickCreate}>
            Добавить СИЗ
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
