import React, { FC } from 'react';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsFormGroup, MtsCheckbox } from '@mts-ds/components-react';
import { CheckboxSizes } from '@mts-ds/components/dist/types/components/checkbox/enums/sizes.enum';

import Typography from '../../../../components_/Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  field: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  }
}));

const FormSound: FC = () => {
  const classes = useStyles();
  const { input: isNeedSoundInput } = useField('isNeedSound', { type: 'checkbox' });
  const { input: isNeedVibroInput } = useField('isNeedVibro', { type: 'checkbox' });

  // Адаптер под mts-ds
  isNeedSoundInput.onMtsChange = isNeedSoundInput.onChange;
  isNeedSoundInput.onMtsFocus = isNeedSoundInput.onFocus;
  isNeedSoundInput.onMtsBlur = isNeedSoundInput.onBlur;

  // Адаптер под mts-ds
  isNeedVibroInput.onMtsChange = isNeedVibroInput.onChange;
  isNeedVibroInput.onMtsFocus = isNeedVibroInput.onFocus;
  isNeedVibroInput.onMtsBlur = isNeedVibroInput.onBlur;

  return (
    <Grid className={classes.root}>
      <Typography variant="p1Bold">Сигнал для оповещения в мобильном приложении</Typography>
      <MtsFormGroup>
        <Grid container alignItems="center" className={classes.field}>
          <MtsCheckbox size={'s' as CheckboxSizes} {...isNeedSoundInput} />
          <Typography variant="p1Regular">Звуковой сигнал</Typography>
        </Grid>
      </MtsFormGroup>
    </Grid>
  );
};

export default FormSound;
