import { FC, useEffect } from 'react';

import { useField } from 'react-final-form';

import { ICoordXYZW } from '../../../../../../models/types';
import { required } from '../../../../../../utils/validation';

interface IProps {
  coordinates: ICoordXYZW | null;
}

const FormCoordinates: FC<IProps> = ({ coordinates }) => {
  const {
    input: { onChange }
  } = useField('position', {
    validate: required
  });

  useEffect(() => {
    if (coordinates) {
      onChange(coordinates);
    }
  }, [coordinates]);

  return null;
};

export default FormCoordinates;
