import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IUpdateOrCreateType } from '../../../../services/ProtectionsService';
import { required } from '../../../../utils/validation';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import Icon from '../../../../components_/Icon';
import Tooltip from '../../../../components_/Tooltip';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';
import FormSwitch from '../../../../components_/Form/FormSwitch';
import FormButton from '../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './';

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    width: 365
  },
  switch: {
    marginBottom: 10,
    marginTop: spacing(4),
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  },
  labelSwitch: {
    marginBottom: 10,
    marginTop: spacing(4),
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    },
    marginLeft: 10
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IValues = IUpdateOrCreateType;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IValues | null;
  isCreate: boolean;
  isEdit: boolean;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues: IValues = {
  id: 0,
  name: '',
  classType: '',
  multiUse: false
};

const Form: FC<IProps> = ({ data, item, isCreate, isEdit, onSubmitAction, onCancel }) => {
  const { types } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item)
  };

  const typeOptions = types.map(({ systemName, friendlyName }) => ({
    id: systemName,
    name: friendlyName
  }));

  const multiUseLabel = (
    <Grid container className={classes.labelSwitch} alignItems="center">
      Использовать несколько экземпляров
      <Tooltip
        label="При повторном сканировании другого экземляра экипировки в рамках смены, предыдущий экземляр не отвязывается"
        className={classes.tooltip}
      >
        <Grid container alignItems="center">
          <Icon name="question" size="s" />
        </Grid>
      </Tooltip>
    </Grid>
  );

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const error = await onSubmitAction(values);

    requestHandler({
      error,
      entity: 'protectionType',
      onSuccess: onCancel,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <ContentForm>
          <FormRow>
            <FormCol>
              <FormInput
                name="name"
                label="Название"
                placeholder="Введите название"
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormDropdown
                name="classType"
                label="Тип"
                placeholder="Выберите значение"
                options={typeOptions}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormSwitch className={classes.switch} name="multiUse" label={multiUseLabel} />
            </FormCol>
          </FormRow>
          <Grid container justify="center" className={classes.buttons}>
            <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
              Отмена
            </MtsButton>
            <FormButton>{submitButtonTitle}</FormButton>
          </Grid>
        </ContentForm>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
