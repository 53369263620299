import React, { FC } from 'react';
import moment from 'moment';

import { IReportNotifications } from '../../../models/types';

import Table from '../../../components_/Table';
import TableColText from '../../../components_/Table/TableColText';

interface IProps {
  list: IReportNotifications[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      time: (
        <TableColText>{item.time && moment(item.time).format('DD.MM.YYYY HH:mm')}</TableColText>
      ),
      areaName: <TableColText>{item.areaName}</TableColText>,
      mapName: <TableColText>{item.mapName}</TableColText>,
      officeName: <TableColText>{item.officeName}</TableColText>,
      eventType: <TableColText>{item.eventType}</TableColText>,
      eventText: <TableColText>{item.eventText}</TableColText>,
      employeeName: <TableColText>{item.employeeName}</TableColText>
    }
  }));

  const cols = [
    {
      title: 'Дата/Время',
      field: 'time'
    },
    {
      title: 'Название зоны',
      field: 'areaName'
    },
    {
      title: 'План',
      field: 'mapName'
    },
    {
      title: 'Место',
      field: 'officeName'
    },
    {
      title: 'Тип события',
      field: 'eventType'
    },
    {
      title: 'Событие',
      field: 'eventText'
    },
    {
      title: 'ФИО сотрудника',
      field: 'employeeName'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
