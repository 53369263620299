import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { MtsLink } from '@mts-ds/components-react';

import { INotification } from '../../../models/types';
import { paths } from '../../../routes/Root/paths';

import Typography from '../../Typography';
import EmptyState from '../../EmptyState';

import DrawerContentList from '../../Drawer/DrawerContentList';
import DrawerContentItemNotification from '../../Drawer/DrawerContentItemNotification';

interface IProps {
  notifications: INotification[];
}

const NotificationDrawerList: FC<IProps> = ({ notifications }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(paths.notifications);
  };

  return (
    <>
      <DrawerContentList title="Уведомления">
        {notifications.map(notification => (
          <DrawerContentItemNotification key={notification.uid} notification={notification} />
        ))}
        {!Boolean(notifications.length) && <EmptyState variant="emptyNotifications" />}
      </DrawerContentList>
      {Boolean(notifications.length) && (
        <Typography variant="p2Regular">
          <MtsLink onClick={handleClick}>Все уведомления</MtsLink>
        </Typography>
      )}
    </>
  );
};

export default NotificationDrawerList;
