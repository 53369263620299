import React, { FC, ChangeEvent } from 'react';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsAvatar } from '@mts-ds/components-react';
import { CheckboxSizes } from '@mts-ds/components/dist/types/components/checkbox/enums/sizes.enum';

import { IId, IUser } from '../../../models/types';
import getFullName from '../../../utils/getFullName';
import getUserBlobImage from '../../../utils/getUserBlobImage';

import Typography from '../../Typography';
import Checkbox from '../../Fields/Checkbox';

const useStyles = makeStyles(({ spacing }) => ({
  info: {
    '& > *:not(:last-child)': {
      paddingRight: spacing(2)
    }
  },
  checkbox: {
    marginTop: spacing(1)
  }
}));

interface IProps {
  user: IUser;
}

const FormUsersSelect: FC<IProps> = ({ user }) => {
  const { id } = user;

  const classes = useStyles();

  const {
    input: { value, onChange }
  } = useField<IId[]>('employees');

  const handleClick = (e: any) => {
    const { checked } = e.target;

    const data = checked ? [...value, id] : value.filter(item => item !== id);

    onChange(data);
  };

  // Хак dropdown отдаёт строку
  const selected = value.map(Number).includes(Number(id));

  return (
    <Grid container justify="space-between">
      <Grid item xs container alignItems="center" className={classes.info}>
        <MtsAvatar size={32} image={getUserBlobImage(user)} />
        <Typography variant="p2Medium">{user.fullName}</Typography>
      </Grid>
      <Checkbox
        size={'s' as CheckboxSizes}
        checked={selected}
        onClick={handleClick}
        className={classes.checkbox}
      />
    </Grid>
  );
};

export default FormUsersSelect;
