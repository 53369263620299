import { IProps as IIconProps } from '../components_/Icon';
import { IColor } from '../theme';
import { INotificationClass } from '../models/types';

export interface INotificationClassOptions {
  icon: IIconProps['name'];
  color: IColor;
  send: boolean;
}

type INotificationClasses = Record<INotificationClass, INotificationClassOptions>;

export const notificationClasses: INotificationClasses = {
  // Аварийная ситуация
  NotificationSos: {
    icon: 'sos',
    color: 'constant-orange-darkest',
    send: true
  },
  // Вхождение в опасную зону
  NotificationEnteringDangerZone: {
    icon: 'enteringDangerZone',
    color: 'constant-banana-darkest',
    send: false
  },
  // Выход из опасной зоны
  NotificationLeaveDangerZone: {
    icon: 'leaveDangerZone',
    color: 'constant-banana-darkest',
    send: false
  },
  // Отсутсвует СИЗ
  NotificationForgottenProtection: {
    icon: 'shieldQuestion',
    color: 'constant-banana-dark',
    send: false
  },
  // Силуэт без СИЗ
  NotificationSilhouetteWoProtection: {
    icon: 'shieldCrossedOut',
    color: 'constant-banana-dark',
    send: false
  },
  // Обездвижен
  NotificationImmobilized: {
    icon: 'immobilizedFall',
    color: 'constant-orange-darkest',
    send: false
  },
  // Падение с высоты
  NotificationFall: {
    icon: 'fall',
    color: 'constant-orange-darkest',
    send: false
  },
  // Информационное сообщени
  NotificationInformational: {
    icon: 'errorOutline',
    color: 'constant-blueberry-normal',
    send: false
  },
  // Начало рабочей смены
  NotificationWorkStart: {
    icon: 'powerSettings',
    color: 'constant-blueberry-normal',
    send: false
  },
  // Окончание рабочей смены
  NotificationWorkEnd: {
    icon: 'shortcut',
    color: 'constant-blueberry-normal',
    send: false
  },
  // Авария на производстве
  NotificationEmergency: {
    icon: 'whatshot',
    color: 'constant-orange-darkest',
    send: true
  },
  // Превышение пульса
  NotificationHealthPulseAbnormal: {
    icon: 'favoriteBorder',
    color: 'constant-banana-darkest',
    send: false
  },
  // Превышение допустимого времени нахождения в зоне
  NotificationAreaExceedingTime: {
    icon: 'timer',
    color: 'constant-banana-dark',
    send: false
  },
  // Нахождение в зоне в нерабочее время
  NotificationAreaOfftime: {
    icon: 'schedule',
    color: 'constant-banana-dark',
    send: false
  },
  // Проблема с экипировкой
  NotificationProtectionProblem: {
    icon: 'shield',
    color: 'constant-banana-dark',
    send: false
  },
  // Нарушение доступа в зону
  NotificationAreaAccessViolation: {
    icon: 'cropFree',
    color: 'constant-banana-darkest',
    send: false
  },
  // Трекер поставлен на зарядку
  NotificationTrackerCharging: {
    icon: 'charging',
    color: 'constant-blueberry-normal',
    send: false
  },
  // Устройство сняли c зарядки
  NotificationTrackerUncharged: {
    icon: 'battery',
    color: 'constant-blueberry-normal',
    send: false
  },
  // Низкий заряд устройства
  NotificationTrackerLowCharge: {
    icon: 'batteryLowAlert',
    color: 'constant-blueberry-normal',
    send: false
  }
};
