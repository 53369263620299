import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge, MtsLink } from '@mts-ds/components-react';

import Typography from '../../Typography';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  header: {
    marginBottom: spacing(2.5)
  },
  title: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  link: {
    '--link-color': getColor('text-primary')
  },
  children: {
    '& > *': {
      marginBottom: spacing(1)
    }
  }
}));

interface IProps {
  title: string;
  count?: number;
  actionName?: string;
  actionOnClick?: () => void;
}

const DrawerContentList: FC<IProps> = ({ title, count, actionName, actionOnClick, children }) => {
  const classes = useStyles();

  const isAction = Boolean(actionName && actionOnClick);
  const isCount = count !== undefined;

  return (
    <Grid item xs container direction="column">
      <Grid container justify="space-between" className={classes.header}>
        <Grid item>
          <Grid container alignItems="center" className={classes.title}>
            <Typography id="typographyTitle" variant="p1Bold">
              {title}
            </Typography>
            {isCount && (
              <MtsBadge id="badgeCount" size="s">
                {count}
              </MtsBadge>
            )}
          </Grid>
        </Grid>
        {isAction && (
          <Grid item>
            <Typography variant="p2Medium">
              <MtsLink className={classes.link} onClick={actionOnClick}>
                {actionName}
              </MtsLink>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs container direction="column" className={classes.children}>
        {children}
      </Grid>
    </Grid>
  );
};

export default DrawerContentList;
