/**
 * Модель для работы с уведомлениями
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { v4 as uuidv4 } from 'uuid';

import { Dispatch, iRootState } from '../store';
import NotificationsService from '../services/NotificationsService';
import { IAlert, IId, INotification, NotificationSeverity } from './types';
import { convertNotificationToAlert } from '../containers/Alerts/utils';

interface IState {
  list: IAlert[];
  confirmations: IAlert[];
  soundEnabled: boolean;
}

const initialState: IState = {
  list: [],
  confirmations: [],
  soundEnabled: false
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setAlerts(state: IState, payload: IAlert[]): IState {
      return {
        ...state,
        list: payload
      };
    },
    setSoundEnabled(state: IState, payload: boolean): IState {
      return {
        ...state,
        soundEnabled: payload
      };
    },
    setConfirmations(state: IState, payload: IAlert[]): IState {
      return {
        ...state,
        confirmations: payload
      };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;
    return {
      async getAlerts(_, rootState: iRootState) {
        const { list } = initialState;

        const {
          data: { data }
        } = await NotificationsService.getFullAll({
          pageSize: 100,
          needConfirmRead: true,
          notificationTarget: 'Platform'
        });

        const notifications = (data as INotification[]).map(notification =>
          convertNotificationToAlert(notification)
        );

        const payload = [...list, ...notifications];

        this.setAlerts(payload);
      },
      async confirmAlert(uid: IId) {
        try {
          await NotificationsService.confirm(uid);
        } catch (error) {
          return error;
        }
      },
      addAlert(payload: IAlert, rootState: iRootState) {
        const { list } = rootState.alerts;

        if (!payload.uid) {
          payload.uid = uuidv4();
        }
        if (
          payload.trackInfo &&
          payload.userId &&
          payload.severity === NotificationSeverity.Critical
        ) {
          d.infrastructureUsers.alertUser({ userId: payload.userId, trackInfo: payload.trackInfo });
        }

        const data = [...list, payload];

        this.setAlerts(data);
      },
      deleteAlert(id: IId, rootState: iRootState) {
        const { list } = rootState.alerts;

        const data = list.filter(item => item.uid !== id);

        this.setAlerts(data);
      },
      addConfirmation(payload: IAlert, rootState: iRootState) {
        const { confirmations } = rootState.alerts;

        const data = [...confirmations, payload];

        this.setConfirmations(data);
      },
      checkConfirmation(id: IId, rootState: iRootState) {
        const { confirmations } = rootState.alerts;

        const data = confirmations.filter(item => item.uid !== id);

        this.setConfirmations(data);
      }
    };
  }
};

export const alerts: typeof model = createModel(model);
