import React, { FC, useState } from 'react';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsDivider, MtsBadge, MtsButton, MtsLink } from '@mts-ds/components-react';
import {
  ButtonFormats,
  ButtonSizes,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import Typography from '../../../../../../components_/Typography';
import Icon from '../../../../../../components_/Icon';

import { IUser } from '../../../../../../models/types';
import { IProps as IFormProps } from './Form';
import FormAccessUsers from './FormAccessUsers';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  root: {
    padding: spacing(2, 0)
  },
  body: {
    height: 64,
    padding: spacing(1, 0)
  },
  title: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  link: {
    '--link-color': getColor('text-primary')
  }
}));

interface IProps {
  getDataUsers: IFormProps['getDataUsers'];
  resetDataUsers: IFormProps['resetDataUsers'];
  data: IFormProps['data'];
}

const FormAccess: FC<IProps> = ({ getDataUsers, resetDataUsers, data }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const {
    input: { value: employees, onChange }
  } = useField<IUser[]>('employees');

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isEmployees = Boolean(employees.length);

  return (
    <>
      <Grid className={classes.root}>
        <MtsDivider />
        <Grid container justify="space-between" alignItems="center" className={classes.body}>
          <Grid item>
            <Grid container alignItems="center" className={classes.title}>
              <Typography variant="p1Bold">Доступ сотрудникам</Typography>
              {isEmployees && <MtsBadge size="s">{employees.length}</MtsBadge>}
            </Grid>
          </Grid>
          <Grid item>
            {isEmployees ? (
              <MtsButton
                format={'icon-round' as ButtonFormats}
                size={'s' as ButtonSizes}
                color={'ghost' as ButtonColors}
                onClick={handleOpen}
              >
                <Grid container justify="center" alignItems="center">
                  <Icon name="arrowRight" size="s" />
                </Grid>
              </MtsButton>
            ) : (
              <Typography variant="p2Medium">
                <MtsLink onClick={handleOpen} className={classes.link}>
                  Добавить
                </MtsLink>
              </Typography>
            )}
          </Grid>
        </Grid>
        <MtsDivider />
      </Grid>
      {isOpen && (
        <FormAccessUsers
          onClose={handleClose}
          getDataUsers={getDataUsers}
          resetDataUsers={resetDataUsers}
          onChangeEmployees={onChange}
          data={data}
          employees={employees}
        />
      )}
    </>
  );
};

export default FormAccess;
