/**
 * Модель для работы с местами
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import { ICoordXY, IId, IOffice } from './types';
import MapsService, { IUpdateOfficeBody } from '../services/MapsService';
import { Dispatch } from '../store';

interface IState {
  list: IOffice[];
  item: IOffice | null;
  coordinates: ICoordXY | null;
}

const initialState: IState = {
  list: [],
  item: null,
  coordinates: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setOffice(state: IState, payload: IOffice): IState {
      return { ...state, item: payload };
    },
    setOffices(state: IState, payload: IOffice[]): IState {
      return { ...state, list: payload };
    },
    setCoordinates(state: IState, payload: ICoordXY): IState {
      return { ...state, coordinates: payload };
    },
    reset(state: IState): IState {
      return { ...state, list: initialState.list };
    },
    resetSetOffice(state: IState): IState {
      return { ...state, coordinates: initialState.coordinates, item: initialState.item };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;
    return {
      async getOffices() {
        const {
          data: { data }
        } = await MapsService.getOffices();

        // ХАК
        // const offices = data.filter((item: any) => item.id !== 1);

        this.setOffices(data);
      },

      async getOffice(id: IId) {
        const { data } = await MapsService.getOffice(id);

        this.setOffice(data);
      },

      async updateOrCreate(body: IUpdateOfficeBody) {
        try {
          await MapsService.updateOrCreateOffice(body);
        } catch (error) {
          return error;
        }
      },

      async remove(id: IId) {
        try {
          await MapsService.deleteOffice(id);
        } catch (error) {
          return error;
        }
      },

      focus(center: ICoordXY) {
        // const payload = { zoom: 20, center };

        const payload = { center };

        d.infrastructureMap.setFocus(payload);
      }
    };
  }
};

export const infrastructureOffices: typeof model = createModel(model);
