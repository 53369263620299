import React, { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ colors }) => ({
  thumb: {
    backgroundColor: colors.greyHeather,
    borderRadius: 4
  },
  track: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    width: 4
  }
}));

const Scrollbar: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Scrollbars
      autoHide
      renderTrackVertical={({ style, ...props }) => <div {...props} className={classes.track} />}
      renderThumbVertical={({ style, ...props }) => <div {...props} className={classes.thumb} />}
    >
      {children}
    </Scrollbars>
  );
};

export default Scrollbar;
