/**
 * Модель для работы с инфраструктурой
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IOffice, ICamera } from './types';

import MapsService from '../services/MapsService';
import CamerasService from '../services/CamerasService';

interface IData {
  offices: IOffice[];
}

interface IList {
  data: ICamera[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    offices: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: offices }
          }
        ] = await Promise.all([
          MapsService.getOffices()
          // CamerasService.getTypeAll(),
          // CamerasService.getSimpleTypeAll()
        ]);

        this.setData({ offices });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await CamerasService.getAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const equipmentCameras: typeof model = createModel(model);
