import React, { FC } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    minWidth: breakpoints.values.lg,
    height: '100vh'
  }
}));

const Wrapper: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      {children}
    </Grid>
  );
};

export default Wrapper;
