import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { Dispatch } from '../../../../../store';
import { guids, paths } from '../../../../Root/paths';

import DrawerAction from '../../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../../components/Drawer/DrawerContent';

import Form from './Form';
import Show from './Show';
import PrivateRoute from '../../../../Root/PrivateRoute';

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    resetFocus: d.infrastructureMap.resetFocus
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Camera: FC<IProps> = ({ resetFocus }) => {
  return (
    <>
      <DrawerAction toUrl={paths.map.office.cameras.root} onClose={resetFocus} />
      <DrawerContent>
        <Switch>
          <PrivateRoute
            requirement={{
              uid: guids.map.office.cameras.create
            }}
            path={paths.map.office.cameras.create}
            component={Form}
          />
          <PrivateRoute
            requirement={{
              uid: guids.map.office.cameras.edit()
            }}
            path={paths.map.office.cameras.edit()}
            component={Form}
          />
          <PrivateRoute
            requirement={{
              uid: guids.map.office.cameras.show()
            }}
            path={paths.map.office.cameras.show()}
            component={Show}
          />
        </Switch>
      </DrawerContent>
    </>
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(Camera);
