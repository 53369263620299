import React, { FC } from 'react';

import Header from '../../components_/Header';
import Main from '../../components_/Main';
import Footer from '../../components_/Footer';
import EmptyState from '../../components_/EmptyState';

interface IProps {
  onLogout: () => void;
}

const ErrorForbiddenPage: FC<IProps> = ({ onLogout }) => {
  return (
    <>
      <Header isForbidden={true} />
      <Main>
        <EmptyState variant="forbidden" action="Выйти" actionOnClick={onLogout} />
      </Main>
      <Footer isForbidden={true} />
    </>
  );
};

export default ErrorForbiddenPage;
