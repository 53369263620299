import React, { FC } from 'react';
import moment from 'moment';

import { IProtection } from '../../../../models/types';
import getFullName from '../../../../utils/getFullName';

import Table from '../../../../components_/Table';
import TableColLink from '../../../../components_/Table/TableColLink';
import TableColText from '../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../components_/Table/TableActionButton';

export interface IProps {
  onClickEdit: any;
  onClickCopy: any;
  onClickUnbind: any;
  onClickDelete: any;
  list: IProtection[];
}

const List: FC<IProps> = ({ list, onClickEdit, onClickCopy, onClickUnbind, onClickDelete }) => {
  const rows = list.map(item => ({
    items: {
      number: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{item.number}</TableColLink>
        </TableColText>
      ),
      className: <TableColText>{item.className}</TableColText>,
      numberType: <TableColText>{item.numberType}</TableColText>,
      mappingType: (
        <TableColText>
          {item.mappingType === 'USER_PERMANENT' ? 'Постоянный' : 'Временный'}
        </TableColText>
      ),
      fio: <TableColText>{getFullName(item.fio)}</TableColText>,
      bestBefore: (
        <TableColText>
          {item.bestBefore && moment(item.bestBefore).format('DD.MM.YYYY')}
        </TableColText>
      ),
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="copy" tooltip="Копировать" onClick={onClickCopy(item)} />
          <TableActionButton
            icon="unlink"
            tooltip="Открепить"
            disabled={!Boolean(item.fio)}
            onClick={onClickUnbind(item)}
          />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'Метка',
      field: 'number'
    },
    {
      title: 'Тип',
      field: 'className'
    },
    {
      title: 'Технология',
      field: 'numberType'
    },
    {
      title: 'Тип использования',
      field: 'mappingType'
    },
    {
      title: 'Владелец',
      field: 'fio'
    },
    {
      title: 'Срок эксплуатации до',
      field: 'bestBefore'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
