import React, { FC } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Input from '../../../../components_/Fields/Input';
import Button from '../../../../components_/Fields/Button';
import DropdownTree from '../../../../components_/Fields/Tree';

import { IProps as IListProps } from './';
import { makeStyles } from '@material-ui/core/styles';
import checkFilter from '../../../../utils/checkFilter';

interface IProps {
  data: IListProps['data'];
  filter: any;
  onChange: any;
  onClickCreate: () => void;
  onReset: () => void;
}
const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    float: 'right',
    marginBottom: '0.25rem'
  },
  resetButton: {
    marginBottom: '0.25rem'
  }
}));

const Filter: FC<IProps> = ({ data, filter, onChange, onClickCreate, onReset }) => {
  const classes = useStyles();
  const { isShowReset } = checkFilter(filter);
  const { fullTextSearch, departmentId } = filter;
  const { departments } = data;

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Input
            name="fullTextSearch"
            type="search"
            placeholder="Найти сотрудника"
            clear
            value={fullTextSearch}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <DropdownTree
            name="departmentId"
            label="Подразделение"
            placeholder="Выберите подразделение"
            treeHierarchy={departments}
            value={departmentId}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          {isShowReset && (
            <Button
              className={classes.resetButton}
              color={'ghost' as ButtonColors}
              onClick={onReset}
            >
              Сбросить фильтры
            </Button>
          )}
        </FormCol>
        <FormCol xs={3}>
          <Button
            className={classes.addButton}
            icon="plus"
            color={'secondary' as ButtonColors}
            onClick={onClickCreate}
          >
            Добавить сотрудника
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
