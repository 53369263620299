import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TopIcon from '@top-ui/core/lib/TopIcon';
import { MtsSpinner, MtsBadge, MtsButton, MtsDivider } from '@mts-ds/components-react';
import {
  SpinnerColors,
  SpinnerSizes
} from '@mts-ds/components/dist/types/components/spinner/enums';
import {
  ButtonFormats,
  ButtonSizes,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import Typography from '../../../../../components_/Typography';
import Tooltip from '../../../../../components_/Tooltip';
import Switch from '../../../../../components_/Fields/Switch';
import Icon from '../../../../../components_/Icon';

const useStyles = makeStyles(({ getColor, colors, spacing }) => ({
  root: {
    padding: spacing(2, 0)
  },
  icon: {
    fontSize: 36
  },
  iconSwitchedOff: {
    '& rect': { fill: colors.lightestBlackberry },
    '& path': { fill: colors.greySteel },
    '& circle': { fill: colors.greySteel }
  },
  title: ({ checked }: IStyleProps) => ({
    color: checked ? colors.blackSmoke : colors.greySlate
  }),
  chip: {
    '--color-text-primary': getColor('text-secondary'),
    '--color-background-secondary': getColor('constant-greyscale-100'),
    '--color-control-primary-active': getColor('constant-blueberry-lightest'),
    '--color-constant-greyscale-0': getColor('constant-blueberry-dark')
  },
  go: ({ link }: IStyleProps) => ({
    visibility: link ? 'visible' : 'hidden'
  }),
  spacing: {
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  }
}));

interface IStyleProps {
  checked: boolean;
  link: string;
}

export interface IProps {
  iconName: string;
  title: string;
  info?: string;
  count?: number;
  total?: number;
  checked: boolean;
  disabled: boolean;
  visible: boolean;
  handle: (isShow: boolean) => void;
  link?: string;
  isLoading: boolean;
}

const Item: FC<IProps> = ({
  iconName,
  title,
  info,
  count,
  total,
  checked,
  handle,
  link = '',
  isLoading,
  disabled,
  visible
}) => {
  const classes = useStyles({ checked, link });
  const history = useHistory();

  const handleChange = (e: any) => {
    handle(e.target.checked);
  };

  const handleClick = () => {
    history.push(link);
  };

  const counter = `${count !== undefined ? `${count}/` : ''}${total}`;

  const isCounter = count !== undefined || total !== undefined;

  return (
    <>
      {visible && (
        <>
          <Grid container justify="space-between" alignItems="center" className={classes.root}>
            <Grid item>
              <Grid container alignItems="center" className={classes.spacing}>
                <TopIcon
                  name={iconName}
                  className={clsx(classes.icon, { [classes.iconSwitchedOff]: !checked })}
                />
                <Typography variant="p2Medium" className={classes.title}>
                  {title}
                </Typography>
                {info && (
                  <Tooltip label={info}>
                    <Grid item xs container>
                      <TopIcon name="help" size="xs" />
                    </Grid>
                  </Tooltip>
                )}
                {isCounter && (
                  <Grid item>
                    {isLoading ? (
                      <MtsSpinner size={'s' as SpinnerSizes} color={'mts-red' as SpinnerColors} />
                    ) : (
                      <MtsBadge active={checked} size="s" className={classes.chip}>
                        {counter}
                      </MtsBadge>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" className={classes.spacing}>
                <Switch disabled={disabled} checked={checked} onMtsChange={handleChange} />
                <MtsButton
                  format={'icon-round' as ButtonFormats}
                  size={'s' as ButtonSizes}
                  color={'ghost' as ButtonColors}
                  className={classes.go}
                  onClick={handleClick}
                >
                  <Grid container justify="center" alignItems="center">
                    <Icon name="arrowRight" size="s" />
                  </Grid>
                </MtsButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs>
              <MtsDivider />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Item;
