import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge } from '@mts-ds/components-react';
import { BadgeSizes } from '@mts-ds/components/dist/types/components/badge/enums/sizes.enum';

import { ICamera } from '../../../models/types';
import { paths } from '../../../routes/Root/paths';

import Typography, { IProps as ITypographyProps } from '../../Typography';
import Icon, { IProps as IconProps } from '../../Icon';
import BeaconIcon, { IProps as BeaconIconProps } from '../../Icon/icons/BeaconIcon';
// import Icon from '../../Icon/icons/Icon';

import DrawerContentItem from '../DrawerContentItem';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  error: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  }
}));

enum Variants {
  default = 'default',
  card = 'card'
}

type IVariants = {
  [key in Variants]: {
    iconSize: IconProps['size'];
    typographyMacAddressVariant: ITypographyProps['variant'];
    typographyNameVariant: ITypographyProps['variant'];
    badgeHardwareTypeSimpleSize: keyof typeof BadgeSizes;
    isVertical: boolean;
    isQueryTime: boolean;
    isClick: boolean;
  };
};

interface IProps {
  camera: ICamera;
  variant?: keyof typeof Variants;
  action?: ReactNode;
}

const DrawerContentItemCamera: FC<IProps> = ({ camera, variant = Variants.default, action }) => {
  const { id, name, description } = camera;

  const classes = useStyles();
  const history = useHistory();

  const variants: IVariants = {
    default: {
      iconSize: 's',
      typographyMacAddressVariant: 'p2Regular',
      typographyNameVariant: 'p2Bold',
      badgeHardwareTypeSimpleSize: 's',
      isVertical: false,
      isQueryTime: true,
      isClick: true
    },
    card: {
      iconSize: 'm',
      typographyMacAddressVariant: 'h3Regular',
      typographyNameVariant: 'h3Bold',
      badgeHardwareTypeSimpleSize: 'm',
      isVertical: true,
      isQueryTime: false,
      isClick: false
    }
  };

  const handleClick = () => {
    history.push(paths.map.office.cameras.show(id));
  };

  const icon = <Icon name="camera" size={variants[variant].iconSize} />;

  const content = (
    <Grid id="cameraItemContent" className={classes.root}>
      <Grid
        container
        justify="space-between"
        {...(variants[variant].isVertical && { direction: 'column' })}
      >
        <Grid>
          <Typography
            id="cameraItemTypographyName"
            variant={variants[variant].typographyNameVariant}
          >
            {name}
          </Typography>
          <Typography
            id="cameraItemTypographyDescription"
            variant={variants[variant].typographyMacAddressVariant}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>

      {/*<Grid container alignItems="center" className={classes.error}>*/}
      {/*  <Icon name="error" size="s" />*/}
      {/*  <Typography variant="p3Regular" color="text-secondary">*/}
      {/*    Устройство недоступно*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}

      {/*<Grid container alignItems="center" className={classes.error}>*/}
      {/*  <BatteryIcon charge={charge} />*/}
      {/*  <Typography variant="p3Medium">{charge} %</Typography>*/}
      {/*  {Number(charge) <= 25 && (*/}
      {/*    <Typography variant="p3Regular" color="text-secondary">*/}
      {/*      Низкий заряд батареи*/}
      {/*    </Typography>*/}
      {/*  )}*/}
      {/*</Grid>*/}

      {/*{variants[variant].isQueryTime && queryTime && (*/}
      {/*  <Typography variant="p3Regular" color="text-secondary">*/}
      {/*    Данные получены{' '}*/}
      {/*    {moment(queryTime)*/}
      {/*      .startOf('hour')*/}
      {/*      .fromNow()}*/}
      {/*  </Typography>*/}
      {/*)}*/}
    </Grid>
  );

  return (
    <DrawerContentItem
      variant={variant}
      icon={icon}
      content={content}
      action={action}
      align="stretch"
      {...(variants[variant].isClick && { onClick: handleClick })}
    />
  );
};

export default DrawerContentItemCamera;
