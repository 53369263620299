import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TopDialog, TopDialogTitle, TopDialogActions, TopDialogContent } from '@top-ui/core';
import TopTypography from '@top-ui/core/lib/TopTypography';
import TopButton from '@top-ui/core/lib/TopButton';

import { ReactComponent as Screen } from '../../assets/images/screen.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: 355
  },
  screen: {
    marginBottom: spacing(4)
  },
  title: {
    marginBottom: spacing(2)
  }
}));

const MinWidth = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMinWidth = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isMinWidth) {
      handleOpen();
    }

    if (isOpen && !isMinWidth) {
      handleClose();
    }
  }, [isMinWidth]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <TopDialog open={isOpen} onClose={handleClose} classes={{ paper: classes.root }}>
      <TopDialogTitle onClose={handleClose} />
      <TopDialogContent>
        <Grid container justify="center" className={classes.screen}>
          <Screen />
        </Grid>
        <TopTypography variant="h4" align="center" className={classes.title}>
          Используйте экран большего размера
        </TopTypography>
        <TopTypography variant="subtitle1" align="center">
          Для корректного отображения личного кабинета перейдите на устройство с большим экраном:
          планшет или компьютер
        </TopTypography>
      </TopDialogContent>
      <TopDialogActions>
        <TopButton variant="special" fullWidth onClick={handleClose}>
          Хорошо
        </TopButton>
      </TopDialogActions>
    </TopDialog>
  );
};
export default MinWidth;
