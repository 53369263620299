import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    marginTop: spacing(5),
    paddingBottom: spacing(5)
  }
}));

const ContentForm: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="space-between" className={classes.root}>
      {children}
    </Grid>
  );
};

export default ContentForm;
