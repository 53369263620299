import React, { FC } from 'react';

import { IProtectionType } from '../../../../models/types';

import Table from '../../../../components_/Table';
import TableColLink from '../../../../components_/Table/TableColLink';
import TableColText from '../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../components_/Table/TableActionButton';

export interface IProps {
  list: IProtectionType[];
  onClickEdit: any;
  onClickDelete: any;
}

const List: FC<IProps> = ({ list, onClickEdit, onClickDelete }) => {
  const rows = list.map(item => ({
    items: {
      name: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{item.name}</TableColLink>
        </TableColText>
      ),
      className: <TableColText>{item.className}</TableColText>,
      multiUse: <TableColText>{item.multiUse ? 'Да' : 'Нет'}</TableColText>,
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'Название',
      field: 'name'
    },
    {
      title: 'Тип',
      field: 'className'
    },
    {
      title: 'Использовать несколько экземпляров',
      field: 'multiUse'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
