import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsDivider } from '@mts-ds/components-react';

import { ICamera } from '../../../../../../models/types';

import Typography from '../../../../../../components_/Typography';
import moment from 'moment/moment';
import VideoPlayer from './VideoPlayer';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  divider: {
    margin: spacing(2, 0)
  }
}));

interface IProps {
  item: ICamera;
  isGeo: boolean;
}

const Attributes: FC<IProps> = ({ item, isGeo }) => {
  const {
    rotationAngle,
    focalLength,
    plan,
    place,
    location,
    videoStreamUrl,
    createDateTime
  } = item;

  const classes = useStyles();

  return (
    <>
      {videoStreamUrl && (
        <>
          <MtsDivider className={classes.divider} />
          <VideoPlayer src={videoStreamUrl} />
        </>
      )}
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography id="typographyLocationTitle" variant="p2Medium">
          Расположение:
        </Typography>
        <Typography id="typographyLocationValue" variant="p2Regular">
          {place && place.name} | {plan && plan.name}
        </Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography id="typographyRotationAngleTitle" variant="p2Medium">
          Угол поворота:
        </Typography>
        <Typography id="typographyRotationAngleValue" variant="p2Regular">
          {rotationAngle !== undefined && rotationAngle !== null ? rotationAngle : '-'}
        </Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography id="typographyFocalLengthTitle" variant="p2Medium">
          ЭФР:
        </Typography>
        <Typography id="typographyFocalLengthValue" variant="p2Regular">
          {focalLength !== undefined && focalLength !== null ? focalLength : '-'}
        </Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography id="typographyCoordinatesTitle" variant="p2Medium">
          Координаты:
        </Typography>
        <Typography id="typographyCoordinatesValue" variant="p2Regular">
          {isGeo ? (
            <Grid className={classes.divider} container>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`Широта: ${location.lat}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`Долгота: ${location.lon}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`Высота: ${
                  location.alt !== undefined && location.alt !== null ? location.alt : '-'
                }`}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.divider} container>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`X: ${location.coordinateX}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`Y: ${location.coordinateY}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p2Regular">{`Z: ${
                  location.coordinateZ !== undefined && location.coordinateZ !== null
                    ? location.coordinateZ
                    : '-'
                }`}</Typography>
              </Grid>
            </Grid>
          )}
        </Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography id="typographyDateTitle" variant="p2Medium">
          Дата установки камеры:
        </Typography>
        <Typography id="typographyDateValue" variant="p2Regular">
          {createDateTime && moment(createDateTime).format('DD.MM.YYYY HH:mm')}
        </Typography>
      </Grid>
      {/*{queryTime && (*/}
      {/*  <>*/}
      {/*    <MtsDivider className={classes.divider} />*/}
      {/*    <Grid container>*/}
      {/*      <Typography variant="p3Regular" color="text-secondary">*/}
      {/*        Данные получены{' '}*/}
      {/*        {moment(queryTime)*/}
      {/*          .startOf('hour')*/}
      {/*          .fromNow()}*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

export default Attributes;
