import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormButton from '../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './index';
import FormUserList from '../../../../components_/Form/FormUserList';
import FormUserListChip from '../../../../components_/Form/FormUserList/FormUserListChip';
import { IDepartament } from '../../../../models/types';
import Typography from '../../../../components_/Typography';

const useStyles = makeStyles(({ spacing }) => ({
  switch: {
    marginBottom: 10
  },
  buttons: {
    marginTop: spacing(4)
  }
}));

type IValues = IDepartament;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: any;
  onSubmitAction: any;
  getDataUsers: any;
}

export const defaultValues: IValues = {
  id: 0,
  name: '',
  parentId: '',
  // leadId: '',
  employees: []
};

const Form: FC<IProps> = ({ data, onSubmitAction, item, getDataUsers }) => {
  const { users, shortUsers } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...item
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase onSubmit={handleSubmit}>
        <ContentForm>
          <FormRow>
            <FormCol>
              <Typography variant="h1Bold" className={classes.switch}>
                {item.name}
              </Typography>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormUserList
                title="Добавьте сотрудников в подразделение (необязательно)"
                users={users}
                getUsers={getDataUsers}
              />
            </FormCol>
            <FormCol>
              <FormUserListChip shortUsers={shortUsers} />
            </FormCol>
          </FormRow>

          <Grid alignItems="flex-end" container justify="center" className={classes.buttons}>
            <FormButton>Сохранить</FormButton>
          </Grid>
        </ContentForm>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmitAction} render={render} />;
};

export default Form;
