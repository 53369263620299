import React, { FC, useState } from 'react';

import { IArea } from '../../../../../../models/types';
import getSearchedData from '../../../../../../utils/getSearchedData';

import Drawer from '../../../../../../components/Drawer';
import DrawerAction from '../../../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../../../components_/Drawer/DrawerContentListSearch';
import DrawerContentItemUser from '../../../../../../components_/Drawer/DrawerContentItemUser';
import EmptyState from '../../../../../../components_/EmptyState';

interface IProps {
  area: IArea;
  onClose: () => void;
}

const AttributesUserList: FC<IProps> = ({ onClose, area }) => {
  const [fullTextSearch, setFullTextSearch] = useState('');

  const { employees } = area;

  const handleChangeFullTextSearch = (event: any) => {
    setFullTextSearch(event.target.value);
  };

  const searchedUsers = getSearchedData(employees, ['lastname', 'name'], fullTextSearch);
  const searchedUsersLength = (searchedUsers || []).length;

  return (
    <Drawer isSub>
      <DrawerAction onClose={onClose} />
      <DrawerContent>
        <DrawerContentList title="Доступ сотрудникам" count={searchedUsersLength}>
          <DrawerContentListSearch
            placeholder="Найти сотрудника"
            value={fullTextSearch}
            onMtsChange={handleChangeFullTextSearch}
          />
          {searchedUsers && (
            <>
              {searchedUsers.map(user => (
                <DrawerContentItemUser key={user.id} user={user} />
              ))}
              {!searchedUsersLength && <EmptyState variant="emptyRequest" />}
            </>
          )}
        </DrawerContentList>
      </DrawerContent>
    </Drawer>
  );
};

export default AttributesUserList;
