import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ArrowDown } from '../../../../assets_/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../assets_/icons/arrowUp.svg';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../../../theme';
import TreeItem from '@material-ui/lab/TreeItem';
import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: 10,
    height: '90vh',
    flexGrow: 1,
    // width: 400,
    overflow: 'auto',
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    }
    // '&:focus > $content, &$selected > $content': {
    //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    //   color: 'var(--tree-view-color)'
    // },
  },
  itemRoot: {
    '&:hover > $content': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'rgba(149,149,149,0.08)'
    }
  },
  typography: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: 5
  },
  iconContainer: {
    width: '24px'
  },
  gridRoot: {
    marginTop: spacing(4)
  }
}));

interface IProps {
  secTreeHierarchy: any;
  defaultSelected: string | null;
}

const FormTree: FC<IProps> = ({ secTreeHierarchy, defaultSelected }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    history.push('?root=' + nodeIds);
  };

  const renderTree = (nodes: { uid: string; friendlyName: React.ReactNode; children: any[] }) => (
    <TreeItem
      key={nodes.uid}
      nodeId={nodes.uid}
      classes={{
        root: classes.itemRoot,
        iconContainer: classes.iconContainer
      }}
      label={
        <>
          <div className={classes.typography}>{nodes.friendlyName}</div>
          <Divider />
        </>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={
        <ArrowUp style={{ width: 24, height: 24 }} className={classes.iconContainer} />
      }
      defaultExpandIcon={
        <ArrowDown style={{ width: 24, height: 24 }} className={classes.iconContainer} />
      }
      onNodeSelect={handleSelect}
      defaultExpanded={['20b3bb0a-6226-4cc7-9bcc-c9cb5c458f47']}
      defaultSelected={[defaultSelected ? defaultSelected : '20b3bb0a-6226-4cc7-9bcc-c9cb5c458f47']}
    >
      {renderTree(secTreeHierarchy)}
    </TreeView>
  );
};

export default FormTree;
