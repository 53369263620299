import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';
import { useAsync, useParams } from '../../../hooks';
import { IGetUseProtectionParams } from '../../../services/ReportService';

import Content from '../../../components_/Content';
import Spinner from '../../../components_/Spinner';
import EmptyState from '../../../components_/EmptyState';
import Pagination from '../../../components_/Pagination';

import Filter from './Filter';
import List from './List';

const initialFilter: Omit<IGetUseProtectionParams, 'pageIndex'> = {
  listOfEmployees: [],
  from: new Date().toDateString(),
  to: new Date().toDateString()
};

const mapState = (state: iRootState) => ({
  data: state.reportUseProtection.data,
  list: state.reportUseProtection.list
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.reportUseProtection.getData,
    getList: d.reportUseProtection.getList,
    resetList: d.reportUseProtection.resetList,
    exportList: d.reportUseProtection.exportList,
    reset: d.reportUseProtection.reset
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const UseProtection: FC<IProps> = ({
  data,
  list: { data: list, pagesCount },
  getData,
  getList,
  resetList,
  exportList,
  reset
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const [runExportList, isLoadingExportList, isExportListLoadingError] = useAsync(exportList);
  const {
    pageIndex,
    filter,
    handlePageChange,
    handleFilterChange,
    handleFilterApply,
    handleFilterReset
  } = useParams(initialFilter, runGetList, false);

  useEffect(() => {
    runGetData();

    return () => {
      reset();
    };
  }, []);

  const isLoading = isLoadingGetData || isLoadingGetList;
  const isLoadingError = isGetDataLoadingError || isGetListLoadingError || isExportListLoadingError;

  const handleExportList = () => {
    runExportList(filter);
  };

  const title = 'Использование СИЗ';

  return (
    <Content
      breadcrumb={[{ name: 'Отчёты', url: paths.reports.root }, { name: title }]}
      title={title}
    >
      {!isLoadingGetData && !isGetDataLoadingError && (
        <>
          <Filter
            data={data}
            filter={filter}
            isLoadingGetList={isLoadingGetList}
            isLoadingExportList={isLoadingExportList}
            list={list}
            onChange={handleFilterChange}
            onClickApply={handleFilterApply}
            onClickExport={handleExportList}
            onClickReset={() => {
              handleFilterReset(true);
              resetList();
            }}
          />
          {!isLoading && !isLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyReport" />}
                  {Boolean(list.length) && (
                    <>
                      <List list={list} />
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UseProtection);
