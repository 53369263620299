import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';

const Main: FC = ({ children }) => {
  return (
    <Grid item xs container direction="column">
      {children}
    </Grid>
  );
};

export default Main;
