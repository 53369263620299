import { useContext } from 'react';

import { ToastContext } from '../components_/Toast';
import toastConstants, { IEntity, IAction } from '../constants/toastConstants';

const useRequestHandler = () => {
  const toast = useContext(ToastContext);

  interface IProps {
    error: any;
    entity: IEntity;
    onSuccess?: () => void;
    onError?: () => void;
    isCreate?: boolean;
    isEdit?: boolean;
    isCopy?: boolean;
    isDelete?: boolean;
    isUnbind?: boolean;
  }

  return ({
    error,
    entity,
    onSuccess,
    onError,
    isCreate,
    isEdit,
    isCopy,
    isDelete,
    isUnbind
  }: IProps) => {
    const action: IAction | null = isCreate
      ? 'create'
      : isEdit
      ? 'edit'
      : isCopy
      ? 'copy'
      : isDelete
      ? 'delete'
      : isUnbind
      ? 'unbind'
      : null;

    if (!action) {
      return;
    }

    const text = toastConstants[entity][action];

    if (error) {
      const friendlyError =
        (error.response &&
          error.response.data.type.toLowerCase().includes('friendly') &&
          ` ${error.response.data.detail}`) ||
        '';

      toast({ text: text.error + ': ' + friendlyError, type: 'error' });

      if (onError) {
        onError();
      }
    } else {
      toast({ text: text.success, type: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    }
  };
};

export default useRequestHandler;
