import React, { FC, useState, useEffect } from 'react';
import { CheckboxSizes } from '@mts-ds/components/dist/types/components/checkbox/enums/sizes.enum';

import { IUser } from '../../../../../../models/types';
import { IProps as IFormProps } from './Form';

import { useDebounce } from '../../../../../../hooks';
import { useAsync } from '../../../../../../hooks';

import Drawer from '../../../../../../components/Drawer';
import DrawerContent from '../../../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../../../components_/Drawer/DrawerContentListSearch';
import DrawerContentItemUser from '../../../../../../components_/Drawer/DrawerContentItemUser';
import Spinner from '../../../../../../components_/Spinner';
import EmptyState from '../../../../../../components_/EmptyState';
import Checkbox from '../../../../../../components_/Fields/Checkbox';
import TopIconButton from '@top-ui/core/lib/TopIconButton';
import TopIcon from '@top-ui/core/lib/TopIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  back: {
    fontSize: 25
  },
  right: { float: 'right' },
  backButton: {
    margin: spacing(1)
  }
}));

interface IProps {
  onClose: () => void;
  getDataUsers: IFormProps['getDataUsers'];
  resetDataUsers: IFormProps['resetDataUsers'];
  onChangeEmployees: (users: IUser[]) => void;
  data: IFormProps['data'];
  employees: IUser[];
}

const FormAccessUsers: FC<IProps> = ({
  onClose,
  getDataUsers,
  resetDataUsers,
  onChangeEmployees,
  data,
  employees
}) => {
  const { users } = data;
  const classes = useStyles();

  const [fullTextSearch, setFullTextSearch] = useState('');
  const debouncedFullTextSearch = useDebounce(fullTextSearch, 500);
  const [runGetDataUsers, isLoadingGetDataUsers, isGetDataUsersLoadingError] = useAsync(
    getDataUsers
  );

  useEffect(() => {
    return () => {
      resetDataUsers();
    };
  }, []);

  useEffect(() => {
    runGetDataUsers({ fullTextSearch });
  }, [debouncedFullTextSearch]);

  const handleChangeFullTextSearch = (event: any) => {
    setFullTextSearch(event.target.value);
  };

  const checkSelected = (user: IUser) =>
    Boolean(employees.find(selectedUser => selectedUser.id === user.id));

  const handleSelectUser = (user: IUser) => (event: any) => {
    const { checked } = event.target;

    const payload = checked
      ? [...employees, user]
      : [...employees.filter(selectedUser => selectedUser.id !== user.id)];

    onChangeEmployees(payload);
  };

  return (
    <Drawer isSub>
      <div className={classes.backButton}>
        <div className={classes.right}>
          <TopIconButton size="small" onClick={onClose}>
            <TopIcon className={classes.back} name="clear" />
          </TopIconButton>
        </div>
      </div>
      <DrawerContent>
        <DrawerContentList title="Доступ сотрудникам">
          <DrawerContentListSearch
            placeholder="Найти сотрудника"
            value={fullTextSearch}
            onMtsChange={handleChangeFullTextSearch}
          />
          {!isLoadingGetDataUsers && !isGetDataUsersLoadingError && users && (
            <>
              {Boolean(users.length) &&
                users.map(user => (
                  <DrawerContentItemUser
                    key={user.id}
                    user={user}
                    action={
                      <Checkbox
                        size={'s' as CheckboxSizes}
                        checked={checkSelected(user)}
                        onClick={handleSelectUser(user)}
                      />
                    }
                  />
                ))}
              {!Boolean(users.length) && <EmptyState variant="emptyRequest" />}
            </>
          )}
          {isLoadingGetDataUsers && <Spinner />}
          {isGetDataUsersLoadingError && <EmptyState variant="serverError" />}
        </DrawerContentList>
      </DrawerContent>
    </Drawer>
  );
};

export default FormAccessUsers;
