import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody, MtsCardTitle } from '@mts-ds/components-react';

import Content from '../../../components_/Content';
import Icon from '../../../components_/Icon';

import ReportCardBg from '../../../assets_/images/reports/cardBg.svg';
import { reports } from './data';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  root: {
    marginTop: spacing(3)
  },
  cardBody: {
    backgroundImage: `url(${ReportCardBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    height: 200,
    cursor: 'pointer'
  },
  icon: {
    marginRight: spacing(2),
    color: getColor('constant-greyscale-500')
  }
}));

const Main: FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const handleCardClick = (url: string) => () => {
    history.push(url);
  };

  return (
    <Content title="Отчёты">
      <Grid container spacing={4} className={classes.root}>
        {reports.map(({ icon, title, description, url }, index) => (
          <Grid key={index} item xs={4}>
            <MtsCard size="l" {...(url && { onClick: handleCardClick(url) })}>
              <MtsCardBody className={classes.cardBody}>
                <MtsCardTitle>
                  <Grid container alignItems="center" wrap="nowrap">
                    <Icon name={icon} size="l" className={classes.icon} />
                    {title}
                  </Grid>
                </MtsCardTitle>
                {description}
              </MtsCardBody>
            </MtsCard>
          </Grid>
        ))}
      </Grid>
    </Content>
  );
};

export default Main;
