import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ArrowDown } from '../../../../assets_/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../assets_/icons/arrowUp.svg';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../../../theme';
import { useHistory } from 'react-router-dom';
import TreeNodeComponent from './TreeItem';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    paddingBottom: spacing(5)
    // height: '90vh',
    // flexGrow: 1,
    // width: 400,
    // overflow: 'auto'
  },
  typography: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: 5
  },
  iconContainer: {
    width: '24px'
  },
  gridRoot: {
    marginTop: spacing(4)
  }
}));

interface IProps {
  secTreeHierarchy: any;
  defaultSelected?: any;
  onClickUpdateOnCrate: any;
  onClickDelete: any;
}
export interface ITreeNode {
  id: string;
  name: string;
  parentId: string;
  children: ITreeNode[];
}

const FormTree: FC<IProps> = ({
  secTreeHierarchy,
  defaultSelected,
  onClickDelete,
  onClickUpdateOnCrate
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    history.push('?root=' + nodeIds);
  };

  const getIds = (array: any[]) => {
    let ids: string[] = [];

    array.forEach(item => {
      ids.push(item.id); // Добавляем ID текущего элемента
      if (item.children) {
        ids = ids.concat(getIds(item.children)); // Рекурсивно добавляем ID дочерних элементов
      }
    });

    return ids;
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={
        <ArrowUp style={{ width: 24, height: 24 }} className={classes.iconContainer} />
      }
      defaultExpandIcon={
        <ArrowDown style={{ width: 24, height: 24 }} className={classes.iconContainer} />
      }
      onNodeSelect={handleSelect}
      defaultExpanded={getIds(secTreeHierarchy)}
      defaultSelected={[defaultSelected ? defaultSelected : null]}
    >
      <TreeNodeComponent
        key={'parent'}
        node={secTreeHierarchy[0]}
        onClickDelete={onClickDelete}
        onClickUpdateOnCrate={onClickUpdateOnCrate}
      />
    </TreeView>
  );
};

export default FormTree;
