import React, { FC } from 'react';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsRadioGroup, MtsFormGroup, MtsRadio } from '@mts-ds/components-react';
import { RadioSizes } from '@mts-ds/components/dist/types/components/radio/enums/sizes.enum';

import Typography from '../../../../components_/Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  group: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  },
  field: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  }
}));

const FormConformation: FC = () => {
  const classes = useStyles();
  const { input } = useField('isNeedConfirmation');

  // Адаптер под mts-ds
  input.onMtsChange = input.onChange;
  input.onMtsFocus = input.onFocus;
  input.onMtsBlur = input.onBlur;

  return (
    <Grid className={classes.root}>
      <Typography variant="p1Bold">Требуется подтверждение о прочтении?</Typography>
      <MtsRadioGroup size={'s' as RadioSizes} className={classes.group} {...input}>
        <MtsFormGroup>
          <Grid container alignItems="center" className={classes.field}>
            <MtsRadio value="true" />
            <Typography variant="p1Regular">Да</Typography>
          </Grid>
        </MtsFormGroup>
        <MtsFormGroup>
          <Grid container alignItems="center" className={classes.field}>
            <MtsRadio value="false" />
            <Typography variant="p1Regular">Нет</Typography>
          </Grid>
        </MtsFormGroup>
      </MtsRadioGroup>
    </Grid>
  );
};

export default FormConformation;
