import React, { FC } from 'react';

import { IRole } from '../../../../../models/types';

import Table from '../../../../../components_/Table';
import TableColLink from '../../../../../components_/Table/TableColLink';
import TableColText from '../../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../../components_/Table/TableActionButton';

export interface IProps {
  list: IRole[];
  onClickEdit: any;
  onClickDelete: any;
  onClickCopy: any;
}

const List: FC<IProps> = ({ list, onClickEdit, onClickDelete, onClickCopy }) => {
  const rows = list.map(item => ({
    items: {
      friendlyName: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{item.friendlyName}</TableColLink>
        </TableColText>
      ),
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="copy" tooltip="Копировать" onClick={onClickCopy(item)} />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'Название',
      field: 'friendlyName'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
