import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from '../Root/paths';

import Main from './Main';
import Violations from './Violations';
import Notifications from './Notifications';
import WorkingTime from './WorkingTime';
import UseProtection from './UseProtection';
import StayInAreas from './StayInAreas';
import PhysicalState from './PhysicalState';
import HeatMap from './HeatMap';
import Tracks from './Tracks';

const Reports: FC = () => {
  return (
    <Switch>
      <Route path={paths.reports.root} exact component={Main} />
      <Route path={paths.reports.violations} component={Violations} />
      <Route path={paths.reports.notifications} component={Notifications} />
      <Route path={paths.reports.workingTime} component={WorkingTime} />
      <Route path={paths.reports.useProtection} component={UseProtection} />
      <Route path={paths.reports.stayInAreas} component={StayInAreas} />
      <Route path={paths.reports.physicalState} component={PhysicalState} />
      <Route path={paths.reports.heatMap} component={HeatMap} />
      <Route path={paths.reports.tracks} component={Tracks} />
    </Switch>
  );
};

export default Reports;
