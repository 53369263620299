import { INotificationVariable } from '../../../models/types';

/**
 * Функция возвращающая строку обозначающую переменную.
 * @param string Переменная
 */
const getVariableTemplate = (variable: string) => {
  return `{${variable}}`;
};

/**
 * Функция подготавливающая шаблонную строку к отправке.
 * @param string Шаблонная строка
 * @param array Список переменных
 */
const prepareTemplate = (template: string, notificationVariables: INotificationVariable[]) => {
  const variablesMap = notificationVariables.map(item => getVariableTemplate(item.descr));

  const variables = variablesMap.join('|');

  const regExp = new RegExp(`${variables}`, 'g');

  const result = template.replace(regExp, match => {
    const defaultNotificationVariablePlaceholder = { placeholder: '' };
    const { placeholder } =
      notificationVariables.find(item => getVariableTemplate(item.descr) === match) ||
      defaultNotificationVariablePlaceholder;

    return getVariableTemplate(placeholder);
  });

  return result;
};

export { getVariableTemplate, prepareTemplate };
