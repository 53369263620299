import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton, MtsDivider } from '@mts-ds/components-react';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  button: {
    width: '100%'
  }
}));

interface IProps {
  onOpenDeleteModal: () => void;
}

const FormDelete: FC<IProps> = ({ onOpenDeleteModal }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <MtsButton
        size={'s' as ButtonSizes}
        color={'secondary' as ButtonColors}
        onClick={onOpenDeleteModal}
        className={classes.button}
      >
        Удалить зону
      </MtsButton>
      <MtsDivider />
    </Grid>
  );
};

export default FormDelete;
