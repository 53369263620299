import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';

import OfficeMarkerListItem from './OfficeMarkerListItem';
import { useLeaflet } from 'react-leaflet';

const mapState = (state: iRootState) => ({
  offices: state.infrastructureOffices.list,
  control: state.infrastructureMap.control
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getOffices: d.infrastructureOffices.getOffices,
    setControl: d.infrastructureMap.setControl
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const OfficeMarkerList: FC<IProps> = ({ offices, control, setControl }) => {
  const { map } = useLeaflet();

  // Устанавливаем zoom, чтобы были видны маркеры всех офисов
  useEffect(() => {
    if (offices.length > 0 && map) {
      map.fitBounds(offices.map(office => [office.location.lat, office.location.lon]), {
        padding: [150, 150],
        maxZoom: 20
      });

      setControl({
        ...control,
        zoom: map.getZoom(),
        center: map.getCenter()
      });
    }
  }, [offices]);

  return (
    <>
      {offices &&
        offices.length > 0 &&
        offices.map((office, index) => (
          <OfficeMarkerListItem key={office.id} office={office} index={index + 1} />
        ))}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(OfficeMarkerList);
