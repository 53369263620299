import React, { useState, useEffect, FC } from 'react';
import { useMtsModal } from '@mts-ds/components-react';
import Modal from '../../components_/Modal';
import { ModalTextAlign } from '@mts-ds/components/dist/types/components/modal/enums';
import { connect } from 'react-redux';
import { Dispatch } from '../../store';

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setSoundEnabled: d.alerts.setSoundEnabled
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const SoundEnablerModal: FC<IProps> = ({ setSoundEnabled }) => {
  useEffect(() => {
    openSoundEnablerModal();
  }, []);

  const [openSoundEnablerModal] = useMtsModal(Modal, {
    buttonTextConfirm: 'Включить',
    header: 'Включить звук уведомлений',
    onMtsModalConfirm: () => {
      setSoundEnabled(true);
    },
    onMtsModalCancel: () => {
      setSoundEnabled(false);
    },
    text: 'Включите звук, чтобы не пропустить важные события',
    textAlign: 'center' as ModalTextAlign
  });
  return <></>;
};
const withConnect = connect(
  null,
  mapDispatch
);
export default withConnect(SoundEnablerModal);
