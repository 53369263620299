import React, { FC } from 'react';
import moment from 'moment';

import { IReportUseProtection } from '../../../models/types';

import Table from '../../../components_/Table';
import TableColText from '../../../components_/Table/TableColText';

interface IProps {
  list: IReportUseProtection[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      number: <TableColText>{item.number}</TableColText>,
      class: <TableColText>{item.class.name}</TableColText>,
      mappingType: (
        <TableColText>
          {item.mappingType === 'USER_PERMANENT' ? 'Постоянный' : 'Временный'}
        </TableColText>
      ),
      employeeName: <TableColText>{item.employeeName}</TableColText>,
      from: (
        <TableColText>{item.from && moment(item.from).format('DD.MM.YYYY HH:mm')}</TableColText>
      ),
      to: <TableColText>{item.to && moment(item.to).format('DD.MM.YYYY HH:mm')}</TableColText>
    }
  }));

  const cols = [
    {
      title: 'Идентификатор',
      field: 'number'
    },
    {
      title: 'Тип',
      field: 'class'
    },
    {
      title: 'Тип использования',
      field: 'mappingType'
    },
    {
      title: 'ФИО сотрудника',
      field: 'employeeName'
    },
    {
      title: 'Дата/Время начала использования',
      field: 'from'
    },
    {
      title: 'Дата/Время окончания использования',
      field: 'to'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
