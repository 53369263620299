import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { IArea, AreaTypes } from '../../../../../../models/types';
import { paths } from '../../../../../Root/paths';

import Tooltip from '../../../../../../components_/Tooltip';
import Icon from '../../../../../../components_/Icon';
import DrawerContentItemArea from '../../../../../../components_/Drawer/DrawerContentItemArea';

interface IProps {
  setBoxEditMode: () => void;
  setCircleEditMode: () => void;
  setPolygonEditMode: () => void;
  isLoading: boolean;
  editAccess: boolean;
  area: IArea | null;
}

const Card: FC<IProps> = ({
  isLoading,
  area,
  setBoxEditMode,
  setCircleEditMode,
  setPolygonEditMode,
  editAccess
}) => {
  const { id, geomType } = area || {};

  const history = useHistory();

  const handleEditClick = () => {
    history.push(paths.map.office.areas.edit(id));

    switch (geomType) {
      case AreaTypes.Box:
        setBoxEditMode();
        break;
      case AreaTypes.Circle:
        setCircleEditMode();
        break;
      case AreaTypes.Polygon:
        setPolygonEditMode();
        break;
    }
  };

  return (
    <DrawerContentItemArea
      isLoading={isLoading}
      area={area}
      variant="card"
      action={
        editAccess && (
          <Tooltip size="s" label="Редактировать">
            <MtsButton
              size={'s' as ButtonSizes}
              format={'icon' as ButtonFormats}
              color={'secondary' as ButtonColors}
              onClick={handleEditClick}
            >
              <Grid container alignItems="center">
                <Icon name="edit" size="m" />
              </Grid>
            </MtsButton>
          </Tooltip>
        )
      }
    />
  );
};

export default Card;
