import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody, MtsButton, MtsDivider } from '@mts-ds/components-react';
import {
  ButtonFormats,
  ButtonSizes,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { IArea, AreaTypes } from '../../../../../../models/types';

import Icon, { IProps as IIconProps } from '../../../../../../components_/Icon';
import Typography from '../../../../../../components_/Typography';

const useStyles = makeStyles(({ getColor, spacing }) => ({
  root: {
    marginTop: spacing(2)
  },
  body: {
    padding: spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  info: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  buttons: {
    marginLeft: spacing(3),
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  active: {
    '& rect': {
      fill: getColor('constant-blueberry-lightest'),
      stroke: getColor('constant-blueberry-dark')
    },
    '& line': {
      stroke: getColor('constant-blueberry-dark')
    }
  },
  divider: {
    margin: spacing(2, 0)
  }
}));

interface IButton {
  icon: IIconProps['name'];
  type: AreaTypes;
  handleClick: () => void;
}

interface IProps {
  area: IArea | null;
  type: AreaTypes;
  setBoxCreateMode: () => void;
  setCircleCreateMode: () => void;
  setPolygonCreateMode: () => void;
}

const FormDraw: FC<IProps> = ({
  area,
  type: currentType,
  setBoxCreateMode,
  setCircleCreateMode,
  setPolygonCreateMode
}) => {
  const classes = useStyles();

  const buttons: IButton[] = [
    // { icon: 'box', type: AreaTypes.Box, handleClick: setBoxCreateMode },
    { icon: 'circle', type: AreaTypes.Circle, handleClick: setCircleCreateMode },
    { icon: 'polygon', type: AreaTypes.Polygon, handleClick: setPolygonCreateMode }
  ];

  const text = area
    ? 'Измените форму зоны или нарисуйте новую'
    : 'Наведите курсор на план и нарисуйте зону';

  return (
    <>
      <MtsCard className={classes.root}>
        <MtsCardBody className={classes.body}>
          <Grid container wrap="nowrap" className={classes.info}>
            <Icon name="plus" size="s" />
            <Typography variant="p3Regular">{text}</Typography>
          </Grid>
          <Grid className={classes.buttons}>
            {buttons.map(({ icon, type, handleClick }, index) => (
              <MtsButton
                key={index}
                format={'icon' as ButtonFormats}
                size={'s' as ButtonSizes}
                color={'outline' as ButtonColors}
                onClick={handleClick}
                className={clsx({ [classes.active]: type === currentType })}
              >
                <Grid container justify="center" alignItems="center">
                  <Icon name={icon} size="s" />
                </Grid>
              </MtsButton>
            ))}
          </Grid>
        </MtsCardBody>
      </MtsCard>
      <MtsDivider className={classes.divider} />
    </>
  );
};

export default FormDraw;
