import React, { FC } from 'react';
import { ZoomControl as LeafletZoomControl } from 'react-leaflet';

const ZoomControl: FC = () => {
  return (
    <LeafletZoomControl position="bottomright" zoomInTitle="Приблизить" zoomOutTitle="Отдалить" />
  );
};

export default ZoomControl;
