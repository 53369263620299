/**
 * Модель для работы с авторизацией пользователя
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { Dispatch } from '../store';
import UsersService from '../services/UsersService';
import LocalStorageService from '../services/LocalStorageService';

import webSsoConstants from '../constants/webSsoConstants';
import { paths } from '../routes/Root/paths';
import config from '../containers/SecurableTree/config';

interface IState {
  isAuth: boolean;
  webSSO: boolean;
}

const initialState: IState = {
  isAuth: false,
  webSSO: false
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    login(state: IState): IState {
      return { ...state, isAuth: true };
    },
    logout(state: IState): IState {
      return { ...state, isAuth: false };
    },
    webSSOTurnOn(state: IState): IState {
      return { ...state, webSSO: true };
    },
    webSSOTurnOff(state: IState): IState {
      return { ...state, webSSO: false };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async initialLogin(code) {
        try {
          const { data } = await UsersService.getToken(code);

          LocalStorageService.setToken(data);

          this.onLogin();
        } catch (error) {
          return error;
        }
      },
      async submitLogin(body) {
        try {
          const {
            data: { access_token, refresh_token, token_type }
          } = await UsersService.login(body);

          LocalStorageService.setToken({ access_token, refresh_token, token_type });

          this.onLogin();
        } catch (error) {
          return error;
        }
      },
      async onLogin() {
        try {
          await UsersService.getByToken();
          d.admin.getCompanyConfig();
          d.securable.getSecurables(config);
          this.login();
        } catch (error) {
          return error;
        }
      },
      onLogout() {
        d({ type: 'resetStore' });

        LocalStorageService.clearToken();

        if (initialState.webSSO) {
          window.location.replace(webSsoConstants.logoutUrl);
        } else {
          window.location.replace(paths.map.root);
        }

        this.logout();
      }
    };
  }
};

export const auth: typeof model = createModel(model);
