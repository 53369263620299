import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IIcon } from '../../assets_/icons';

import Icon from '../Icon';
import Typography from '../Typography';
import TopButton from '@top-ui/core/lib/TopButton';

type IVariant =
  | 'generateReport'
  | 'clickTree'
  | 'emptyReport'
  | 'emptyRequest'
  | 'serverError'
  | 'emptyNotifications'
  | 'forbidden';

type IVariants = {
  [key in IVariant]: {
    iconName: IIcon;
    description: string;
  };
};

const variants: IVariants = {
  generateReport: {
    iconName: 'equalizer',
    description: 'Сформируйте отчёт с помощью фильтров сверху'
  },
  clickTree: {
    iconName: 'equalizer',
    description: 'Выберите элемент дерева'
  },
  emptyReport: {
    iconName: 'equalizer',
    description: 'Отчётов по этому запросу нет'
  },
  emptyRequest: {
    iconName: 'zoomIn',
    description: 'По этому запросу ничего не найдено'
  },
  serverError: {
    iconName: 'warningAmber',
    description: 'Ошибка сервера'
  },
  emptyNotifications: {
    iconName: 'notifications',
    description: 'Уведомлений нет'
  },
  forbidden: {
    iconName: 'lock',
    description: `Приложение Safe Zone доступно только подключенным организациям.\nСвяжитесь с руководством Вашей компании по вопросам получения доступа.\nВ случае если Вы хотите стать клиентом - обращайтесь по номеру +7 931 203 00 00.`
  }
};

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px #E7EAFA',
    borderRadius: '50%',
    width: 64,
    height: 64,
    marginBottom: spacing(2)
  },
  button: {
    marginTop: spacing(2)
  },
  desc: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  }
}));

interface IProps {
  variant: IVariant;
  action?: string;
  actionOnClick?: () => void;
}

const EmptyState: FC<IProps> = ({ variant, action, actionOnClick }) => {
  const classes = useStyles();

  const { iconName, description } = variants[variant];

  return (
    <Grid item xs container direction="column" justify="center" alignItems="center">
      <Grid container justify="center" alignItems="center" className={classes.icon}>
        <Icon name={iconName} color="constant-blackberry-light" />
      </Grid>
      <Typography variant="p2Regular" color="text-tertiary" className={classes.desc}>
        {description}
      </Typography>
      {action && (
        <TopButton onClick={actionOnClick} className={classes.button}>
          {action}
        </TopButton>
      )}
    </Grid>
  );
};

export default EmptyState;
