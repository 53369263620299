import React, { FC } from 'react';
import { default as LeafletHeatmapLayer } from 'react-leaflet-heatmap-layer';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  layers: state.infrastructureMap.layers
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {};
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

export interface IHeatMapPoint {
  lat: number;
  lng: number;
}

// MOCK
const points: IHeatMapPoint[] = [
  { lat: 55.691721117066606, lng: 37.66091155790776 },
  { lat: 55.69171098128724, lng: 37.66093200029218 },
  { lat: 55.69170104642588, lng: 37.66095240113032 },
  { lat: 55.69170587538694, lng: 37.660887013189495 },
  { lat: 55.69169571787598, lng: 37.660907213576145 },
  { lat: 55.69168565485091, lng: 37.660927539691336 },
  { lat: 55.69167689104057, lng: 37.66083978116512 },
  { lat: 55.69165657600098, lng: 37.66088018193842 }
];

const HeatmapLayer: FC<IProps> = ({ map, layers }) => {
  const { id } = map;
  const { isHeatMap } = layers;

  if (id !== 22) {
    return null;
  }

  return (
    <>
      {isHeatMap && (
        <LeafletHeatmapLayer
          points={points}
          latitudeExtractor={(point: IHeatMapPoint) => point.lat}
          longitudeExtractor={(point: IHeatMapPoint) => point.lng}
          intensityExtractor={() => 1}
          radius={15}
          blur={10}
          minOpacity={0.5}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(HeatmapLayer);
