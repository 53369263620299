/**
 * Модель для работы с отчётом "Тепловая карта"
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IOffice, IDepartament, IMap, IReportHeatMapPoint } from './types';
import { IGetHeatMapFilter as IFilter } from '../services/ReportService';
import getInitialFilter from '../utils/getInitialFilter';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import MapsService from '../services/MapsService';
import UsersService from '../services/UsersService';
import ReportService from '../services/ReportService';

interface IData {
  offices: IOffice[];
  brigades: IDepartament[];
}

export interface IHeatMap {
  map: IMap;
  planImg: string;
  data: IReportHeatMapPoint[];
}

interface IState {
  data: IData;
  filter: IFilter;
  heatMap: IHeatMap | null;
}

const initialFilter: IFilter = {
  mapId: '',
  userList: [],
  from: new Date().toDateString(),
  to: new Date().toDateString()
};

const initialState: IState = {
  data: {
    offices: [],
    brigades: []
  },
  filter: getInitialFilter(initialFilter),
  heatMap: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setFilter(state: IState, payload: IFilter): IState {
      return { ...state, filter: { ...state.filter, ...payload } };
    },
    resetFilter(state: IState): IState {
      return { ...state, filter: initialFilter };
    },
    setHeatMap(state: IState, payload: IHeatMap): IState {
      return { ...state, heatMap: payload };
    },
    resetHeatMap(state: IState): IState {
      return { ...state, heatMap: null };
    },
    reset(): IState {
      return { ...initialState, filter: initialFilter };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: offices }
          },
          {
            data: { data: brigades }
          }
        ] = await Promise.all([
          MapsService.getOffices(),
          UsersService.getBrigadeAll({ includeOther: true, pageSize: 100 })
        ]);

        this.setData({ offices, brigades });
      } catch (error) {
        return error;
      }
    },
    async getHeatMap(_, rootState: iRootState) {
      const { filter } = rootState.reportHeatMap;

      const cloneFilter = setStartEndOfDay(filter, ['from', 'to']);

      try {
        const [{ data: map }, planImg, { data }] = await Promise.all([
          MapsService.get(cloneFilter.mapId),
          MapsService.getMapImage(cloneFilter.mapId),
          ReportService.getJsonHeatMap(cloneFilter)
        ]);

        this.setHeatMap({ map, planImg, data });
      } catch (error) {
        return error;
      }
    }
  }
};

export const reportHeatMap: typeof model = createModel(model);
