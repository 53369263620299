import { useState, useCallback } from 'react';

const useAsync = (asyncFunction: any, initial: boolean = false): any => {
  const [isLoading, setIsLoading] = useState(initial);
  const [isError, setIsError] = useState(false);

  const run = useCallback(
    async (...params) => {
      setIsError(false);
      setIsLoading(true);

      // tslint:disable-next-line: no-shadowed-variable
      const isError = await asyncFunction(...params);

      if (isError) {
        setIsError(true);
      }

      setIsLoading(false);

      return isError;
    },
    [asyncFunction]
  );

  return [run, isLoading, isError];
};

export default useAsync;
