/**
 *  Модель для работы с сайдбаром
 */

import { createModel, ModelConfig } from '@rematch/core';

interface IState {
  isExpand: boolean;
}

const initialState: IState = {
  isExpand: true
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    expandDrawer(state: IState): IState {
      return {
        ...state,
        isExpand: true
      };
    },
    collapseDrawer(state: IState): IState {
      return {
        ...state,
        isExpand: false
      };
    }
  },
  effects: {}
};

export const drawer: typeof model = createModel(model);
