import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import DrawerSticky from '../../../components/Drawer/DrawerSticky';
import FormButton from '../../../components_/Form/FormButton';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2, 4, 2)
  },
  button: {
    width: '100%'
  }
}));

interface IProps {
  cancel: string;
  confirm: string;
  onClickCancel: () => void;
}

const DrawerStickyActions: FC<IProps> = ({ cancel, confirm, onClickCancel }) => {
  const classes = useStyles();

  return (
    <DrawerSticky>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs>
          <MtsButton
            id="buttonCancel"
            // size={'s' as ButtonSizes}
            color={'negative' as ButtonColors}
            onClick={onClickCancel}
            className={classes.button}
          >
            {cancel}
          </MtsButton>
        </Grid>
        <Grid item xs>
          <FormButton // size={'s' as ButtonSizes}
            className={classes.button}
          >
            {confirm}
          </FormButton>
        </Grid>
      </Grid>
    </DrawerSticky>
  );
};

export default DrawerStickyActions;
