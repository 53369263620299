import React, { FC } from 'react';

import DrawerStickyActions from '../../../../../../components_/Drawer/DrawerStickyActions';

interface IProps {
  isCreate: boolean;
  onGoBack: () => void;
}

const FormActions: FC<IProps> = ({ onGoBack, isCreate }) => {
  const confirm = isCreate ? 'Сохранить' : 'Добавить';

  return <DrawerStickyActions cancel="Отмена" confirm={confirm} onClickCancel={onGoBack} />;
};

export default FormActions;
