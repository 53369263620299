const hoursStep = [
  {
    id: 1,
    name: '1 час'
  },
  {
    id: 2,
    name: '2 часа'
  },
  {
    id: 3,
    name: '3 часа'
  },
  {
    id: 4,
    name: '4 часа'
  },
  {
    id: 5,
    name: '5 часов'
  },
  {
    id: 6,
    name: '6 часов'
  },
  {
    id: 7,
    name: '7 часов'
  },
  {
    id: 8,
    name: '8 часов'
  },
  {
    id: 9,
    name: '9 часов'
  },
  {
    id: 10,
    name: '10 часов'
  },
  {
    id: 11,
    name: '11 часов'
  },
  {
    id: 12,
    name: '12 часов'
  }
];

const minutesStep = [
  {
    id: 10,
    name: '10 минут'
  },
  {
    id: 20,
    name: '20 минут'
  },
  {
    id: 30,
    name: '30 минут'
  },
  {
    id: 40,
    name: '40 минут'
  },
  {
    id: 50,
    name: '50 минут'
  }
];

export { hoursStep, minutesStep };
