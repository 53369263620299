import React, { FC } from 'react';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';

import checkFilter from '../../../utils/checkFilter';
import { IProps as IPhysicalStateProps } from '../PhysicalState';

import ContentFilter from '../../../components_/Content/ContentFilter';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import Dropdown from '../../../components_/Fields/Dropdown';
import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import Button from '../../../components_/Fields/Button';
import InputWithDate from '../../../components_/Fields/DatePicker/inputWithDate/inputWithDate';

interface IProps {
  data: IPhysicalStateProps['data'];
  filter: any;
  isLoadingGetList: boolean;
  isLoadingExportList: boolean;
  list: IPhysicalStateProps['list']['data'];
  onChange: any;
  onClickApply: () => void;
  onClickExport: () => void;
  onClickReset: () => void;
}

const Filter: FC<IProps> = ({
  data,
  filter,
  isLoadingGetList,
  isLoadingExportList,
  list,
  onChange,
  onClickApply,
  onClickExport,
  onClickReset
}) => {
  const { brigades, types } = data;
  const { listOfEmployees, physType, from, to } = filter;

  const { isApply, isDownload, isShowReset } = checkFilter(filter, ['from', 'to'], list);

  const brigadeOptions = brigades.map(({ name, employees }) => ({
    [name]: employees && employees.map(({ userId, fio }) => ({ id: userId, name: fio }))
  }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Dropdown
            name="listOfEmployees"
            label="Сотрудник/Подразделение (необязательно)"
            placeholder="Выберите значение"
            groupOptions={brigadeOptions}
            multiple
            value={listOfEmployees}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="physType"
            label="Критическое состояние (необязательно)"
            placeholder="Выберите значение"
            options={types}
            value={physType}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={onChange} />*/}
          <InputWithDate
            name="from"
            label="Дата от"
            value={from}
            onChange={onChange}
            maxDate={new Date()}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={onChange} />*/}
          <InputWithDate
            name="to"
            label="Дата до"
            value={to}
            onChange={onChange}
            maxDate={new Date()}
          />
        </FormCol>
        <FormCol xs="auto">
          <MtsButton disabled={isApply} loading={isLoadingGetList} onClick={onClickApply}>
            Применить
          </MtsButton>
        </FormCol>
        <FormCol xs="auto">
          <Tooltip label="Скачать отчёт">
            <MtsButton
              format={'icon' as ButtonFormats}
              disabled={isDownload}
              loading={isLoadingExportList}
              onClick={onClickExport}
            >
              <Icon name="download" />
            </MtsButton>
          </Tooltip>
        </FormCol>
        {isShowReset && (
          <FormCol xs={12}>
            <Button color={'ghost' as ButtonColors} onClick={onClickReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
