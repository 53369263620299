import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { IForm } from '../../../../models/equipmentProtections';
import { IUpdateOrCreateBody } from '../../../../services/DeviceService';
import { required } from '../../../../utils/validation';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import Typography from '../../../../components_/Typography';
import Icon from '../../../../components_/Icon';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';

import { IProps as IFormProps } from './';
import FormDatePicker from '../../../../components_/Form/FormDatePicker';
import FormAntSelect from '../../../../components_/Form/FormAntSelect';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    margin: spacing(5, 0, 2)
  },
  deleteFormButton: {
    marginLeft: spacing(1.5)
  }
}));

type IValues = IUpdateOrCreateBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  index: number;
  form: IForm;
  data: IFormProps['data'];
  item: IValues | null;
  isCreate: boolean;
  isEdit: boolean;
  isCopy: boolean;
  isSubmit: boolean;
  deleteForm: any;
  editForm: any;
  setSubmitForms: any;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues = {
  id: 0,
  number: '',
  classId: '',
  model: '',
  invent: '',
  serial: '',
  mappingType: 'USER_TEMPORARY',
  userId: undefined,
  phone: '',
  bestBefore: null,
  notes: ''
};

const Form: FC<IProps> = ({
  index,
  form,
  data,
  item,
  isCreate,
  isEdit,
  isCopy,
  isSubmit,
  deleteForm,
  editForm,
  setSubmitForms,
  onSubmitAction,
  onCancel
}) => {
  const { types, users } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...((isEdit || isCopy) && {
      ...item,
      bestBefore: item && item.bestBefore && moment(item.bestBefore).format('YYYY-MM-DD')
    }),
    ...(isCopy && {
      id: defaultValues.id,
      userId: defaultValues.userId,
      number: defaultValues.number
    })
  };

  const userOptions = users.map(({ userId, fio }) => ({
    value: userId,
    label: fio
  }));

  const userIdValidate = (value: string | string[], values: object) => {
    const { mappingType } = values as IValues;

    if (mappingType === 'USER_PERMANENT') {
      return required(value);
    }
  };

  const onSubmit = async (values: IValues) => {
    const body = { ...values };

    if (body.bestBefore) {
      body.bestBefore = moment(body.bestBefore)
        .endOf('day')
        .format();
    }

    const error = await onSubmitAction(body);

    requestHandler({
      error,
      entity: 'device',
      onSuccess: onCancel,
      isCreate,
      isEdit,
      isCopy
    });

    setSubmitForms(false);
  };

  const render: IRender = ({
    handleSubmit,
    values: { mappingType, classId },
    hasValidationErrors,
    pristine,
    form: { change }
  }) => {
    const isValidate = !(hasValidationErrors || pristine);

    if (mappingType === 'USER_TEMPORARY') {
      change('userId', defaultValues.userId);
    }

    if (form.isValidate !== isValidate) {
      editForm({ uid: form.uid, isValidate });
    }

    if (isSubmit) {
      handleSubmit();
    }

    const titleIndex = index + 1;
    const title = (
      <Grid container alignItems="center">
        Новое носимое устройство
        {titleIndex > 1 && (
          <>
            ({titleIndex})
            <MtsButton
              size={'xs' as ButtonSizes}
              format={'icon-round' as ButtonFormats}
              color={'secondary' as ButtonColors}
              onClick={() => deleteForm(form.uid)}
              className={classes.deleteFormButton}
            >
              <Grid container alignItems="center">
                <Icon name="minus" size="s" />
              </Grid>
            </MtsButton>
          </>
        )}
      </Grid>
    );

    return (
      <>
        <Typography variant="h3Medium" className={classes.title}>
          {isCreate && title}
        </Typography>
        <FormBase>
          <FormRow>
            <FormCol>
              <FormDropdown
                name="classId"
                label="Тип устройства"
                placeholder="Выберите тип"
                options={types}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormInput
                name="number"
                label="ID"
                placeholder="Введите ID"
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormInput
                name="model"
                label="Модель (необязательно)"
                placeholder="Введите название модели"
                // useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="invent"
                label="Инвентаризационный № (необязательно)"
                placeholder="Введите инвентаризационный №"
                // useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormInput
                name="serial"
                label="Серийный № (необязательно)"
                placeholder="Введите серийный №"
              />
            </FormCol>
            <FormCol>
              <FormDropdown
                name="mappingType"
                label="Тип использования"
                placeholder="Выберите значение"
                options={[
                  {
                    id: 'USER_PERMANENT',
                    name: 'Постоянный'
                  },
                  {
                    id: 'USER_TEMPORARY',
                    name: 'Временный'
                  }
                ]}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol xs={4}>
              <FormAntSelect
                name="userId"
                label="Владелец"
                value={initialValues.userId}
                showSearch
                allowClear
                optionFilterProp="label"
                placeholder="Выберите владельца"
                options={userOptions}
                disabled={mappingType !== 'USER_PERMANENT'}
                useFieldConfig={{ validate: userIdValidate }}
              />
            </FormCol>
            <FormCol>
              <FormInput
                name="phone"
                label="Номер телефона устройства (необязательно)"
                placeholder="+7"
              />
            </FormCol>
            <FormCol xs={4}>
              <FormDatePicker
                name="bestBefore"
                label="Срок эксплуатации до (необязательно)"
                placeholder="дд.мм.гггг"
              />
              {/*<FormBaseInput*/}
              {/*  name="bestBefore"*/}
              {/*  label="Срок эксплуатации до (необязательно)"*/}
              {/*  type="date"*/}
              {/*/>*/}
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol xs={4}>
              <FormInput
                name="notes"
                label="Комментарий (необязательно)"
                placeholder="Введите текст комментария"
              />
            </FormCol>
          </FormRow>
        </FormBase>
      </>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
