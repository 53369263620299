import React, { FC } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import Select, { IProps as IInputProps } from '../../Fields/AntSelect';

type IProps = {
  name: string;
  description?: string;
  useFieldConfig?: UseFieldConfig<string>;
} & IInputProps;

const FormAntSelect: FC<IProps> = ({ name, useFieldConfig, description, ...props }) => {
  const {
    input,
    meta: { touched, error, invalid }
  } = useField(name, useFieldConfig);

  // // Адаптер под mts-ds
  // input.onMtsChange = input.onChange;
  // input.onMtsFocus = input.onFocus;
  // input.onMtsBlur = input.onBlur;

  const message = touched && error ? error : description;

  const isValid = touched && invalid ? !Boolean(error) : undefined;

  return (
    <Select
      description={message}
      size={'large'}
      optionFilterProp="label"
      style={{ width: '100%' }}
      valid={isValid}
      {...input}
      {...props}
    />
  );
};

export default FormAntSelect;
