import React, { FC } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../store';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';
import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import { AreaModes } from '../../../models/types';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'absolute',
    top: 10,
    right: 64,
    zIndex: 1000
  }
}));

const mapState = (state: iRootState) => ({
  mode: state.infrastructureMap.mode,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setLayers: d.infrastructureMap.setLayers
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const LayersControl: FC<IProps> = ({ setLayers, isGeo, mode }) => {
  const classes = useStyles();

  const handleClick = () => () => {
    setLayers({ isGeo: !isGeo });
  };

  return (
    <Grid className={classes.root}>
      <Tooltip size="l" label={isGeo ? 'Свернуть план' : 'Развернуть план'}>
        <MtsButton
          disabled={mode !== AreaModes.Default}
          format={'icon' as ButtonFormats}
          color={'outline' as ButtonColors}
          key={'isGeo'}
          onClick={handleClick()}
        >
          <Grid container justify="center" alignItems="center" wrap="nowrap">
            {isGeo ? <Icon name={'compressRight'} /> : <Icon name={'arrowsExpandLeft'} />}
          </Grid>
        </MtsButton>
      </Tooltip>
    </Grid>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(LayersControl);
