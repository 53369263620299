import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState, store } from '../../../store';

import UserMarkerGroup from './UserMarkerGroup';
import UserMarkerListItem from './UserMarkerListItem';

import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IId } from '../../../models/types';

import apiConstants from '../../../constants/apiConstants';
import LocalStorageService from '../../../services/LocalStorageService';
import PositionsService from '../../../services/PositionsService';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  users: state.infrastructureUsers.users,
  layers: state.infrastructureMap.layers
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return { getUsers: d.infrastructureUsers.getUsers };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const UserMarkerList: FC<IProps> = ({ users, map, layers, getUsers }) => {
  const { id } = map;
  const { isUsers } = layers;
  const { REACT_APP_DEV_URL } = process.env;

  const { Connected, Disconnected } = HubConnectionState;

  const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;
  const { accessToken } = LocalStorageService.getToken();

  const connection = new HubConnectionBuilder()
    .configureLogging(1)
    .withUrl(`${url}hubs/moves`, {
      accessTokenFactory: () => {
        return `${accessToken}`;
      }
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => {
        return 5000;
      }
    })
    .build();

  const signalRStop = () => {
    connection.stop();
  };

  const signalRSubscribe = async (mapId: IId) => {
    const { data } = await PositionsService.getUsers({ mapId, includeOffline: true });
    if (data) {
      const dataMod = await data.map(
        (item: {
          user: { id: IId; username: string };
          trackInfo: {
            mapColor: string;
            lastPosition: {};
          };
        }) => {
          return {
            userId: item.user.id,
            id: item.user.id,
            username: item.user.username,
            mapColor: item.trackInfo.mapColor,
            position: item.trackInfo.lastPosition,
            trackInfo: item.trackInfo
          };
        }
      );
      getUsers(dataMod);
    }
    // store.dispatch.infrastructureUsers.getUsers({
    //   birthday: '2021-09-04T00:00:00',
    //   descr: 'Разработчик web приложений',
    //   email: 'anyakov3@mts.ru',
    //   id: 25,
    //   immobilizedTimeMinutes: 90,
    //   lastname: 'Яковлев',
    //   mapColor: '#FFA080',
    //   name: 'Алексей',
    //   phone: '9778008551',
    //   protectionsList: [22, 17],
    //   resetPassword: false,
    //   role: 'SUPER_ADMIN',
    //   secondname: 'Николаевич',
    //   tabularNumber: '77777',
    //   title: 'Разработчик',
    //   username: '9778008551',
    //   workshiftBreakMinutes: 70,
    //   workshiftDurationHours: 1,
    //   position: {
    //     lat: 55.69190846220035,
    //     lon: 37.65957246474376
    //   }
    // });
    if (connection.state === Disconnected) {
      await connection.start().then(() => {
        connection.invoke('SubscribeToMap', mapId);
      });
      await connection.onreconnected(error => {
        connection.invoke('SubscribeToMap', mapId);
      });
    }
    if (connection.state === Connected) {
      connection.invoke('SubscribeToMap', mapId);
    }
    connection.on('UpdateInfo', payload => {
      // tslint:disable-next-line: no-console
      console.log(payload);

      getUsers(payload);
    });
  };

  const signalRUnsubscribe = (mapId: IId) => {
    if (connection.state === Connected) {
      connection.invoke('UnsubscribeFromMap', mapId);
    }
  };

  useEffect(() => {
    return () => {
      signalRStop();
    };
  }, []);

  useEffect(() => {
    if (id) {
      signalRSubscribe(id);

      return () => {
        signalRUnsubscribe(id);
      };
    }
  }, [id]);

  return (
    <UserMarkerGroup>
      {isUsers && users.map(user => <UserMarkerListItem key={user.username} user={user} />)}
    </UserMarkerGroup>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UserMarkerList);
