import React, { FC, useState } from 'react';
import moment from 'moment';

import { INotification } from '../../models/types';
import { getCalendarTime } from '../../utils/convertTime';

import Table from '../../components_/Table';
import Typography from '../../components_/Typography';

import NotificationIcon from '../../components_/Notification/NotificationIcon';
import NotificationTarget from '../../components_/Notification/NotificationTarget';
import { MtsModalText, MtsModalHeader, MtsModalContent } from '@mts-ds/components-react';
import { useMtsModal } from '@mts-ds/components-react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '../../components_/Icon';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
    background: 'rgba(67,74,81,0.5)',
    width: '66px',
    height: '46px',
    cursor: 'pointer',
    border: '1px solid #BBC1C7',
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover svg': {
      opacity: '1'
    },
    '&:hover img': {
      opacity: '0.6'
    }
  },
  icon: {
    color: 'white',
    opacity: '0',
    position: 'absolute'
  },
  img: {
    height: '46px',
    width: '66px',
    position: 'absolute'
  }
}));
const ModalContent: React.FC<{
  header: string;
  text: string;
  img?: string;
}> = ({ header, text, img }) => (
  <MtsModalContent buttonTextCancel="Отмена">
    <MtsModalHeader>{header}</MtsModalHeader>
    <MtsModalText>{text}</MtsModalText>
    <img src={img} alt="Фото проишествия" />
  </MtsModalContent>
);

interface IProps {
  list: INotification[];
}

const List: FC<IProps> = ({ list }) => {
  const classes = useStyles();
  const [active, setActive] = useState({ header: '', text: '', img: '' });
  const openImgModal = (header: string, text: string, img?: string) => {
    setActive({ header, text, img: img ? img : '' });
    openModal();
  };
  const [openModal] = useMtsModal(ModalContent, {
    header: active.header,
    text: active.text,
    img: active.img
  });
  const rows = list.map(
    ({ raisingTime, class: classId, classFriendly, text, targetSimple, url }) => ({
      group: getCalendarTime(raisingTime, 'DD MMMM YYYY'),
      items: {
        icon: <NotificationIcon notificationClass={classId} />,
        info: (
          <>
            <Typography variant="p1Medium">{classFriendly}</Typography>
            <Typography variant="p2Regular">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Typography>
          </>
        ),
        img: (
          <>
            {url && (
              <a
                className={classes.root}
                onClick={() => {
                  openImgModal(classFriendly, text, url);
                }}
              >
                <img className={classes.img} src={url} alt="Миниатюра проишествия" />
                <Icon name="zoomIn" className={classes.icon} />
              </a>
            )}
          </>
        ),
        time: (
          <Typography variant="p2Regular" color="text-secondary">
            {moment(raisingTime).format('DD.MM.YYYY HH:mm')}
          </Typography>
        ),
        targetIcon: <NotificationTarget targetSimple={targetSimple} />
      }
    })
  );

  const cols = [
    {
      title: '',
      field: 'icon'
    },
    {
      title: '',
      field: 'info'
    },
    {
      title: '',
      field: 'img'
    },
    {
      title: '',
      field: 'time'
    },
    {
      title: '',
      field: 'targetIcon'
    }
  ];

  return <Table cols={cols} rows={rows} hideHead />;
};

export default List;
