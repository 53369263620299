import React, { FC } from 'react';
import moment from 'moment';

import { IReportWorkingTime } from '../../../models/types';
import { getFormatTimeFromMinutes } from './utils';

import Table from '../../../components_/Table';
import TableColText from '../../../components_/Table/TableColText';

interface IProps {
  list: IReportWorkingTime[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      start: (
        <TableColText>{item.start && moment(item.start).format('DD.MM.YYYY HH:mm')}</TableColText>
      ),
      end: <TableColText>{item.end && moment(item.end).format('DD.MM.YYYY HH:mm')}</TableColText>,
      employeeName: <TableColText>{item.employeeName}</TableColText>,
      duration: (
        <TableColText>{item.duration && getFormatTimeFromMinutes(item.duration)}</TableColText>
      ),
      pause: <TableColText>{item.pause && getFormatTimeFromMinutes(item.pause)}</TableColText>,
      durationFact: (
        <TableColText>
          {item.durationFact && getFormatTimeFromMinutes(item.durationFact)}
        </TableColText>
      )
    }
  }));

  const cols = [
    {
      title: 'Начало смены',
      field: 'start'
    },
    {
      title: 'Окончание смены',
      field: 'end'
    },
    {
      title: 'ФИО сотрудника',
      field: 'employeeName'
    },
    {
      title: 'Длительность смены',
      field: 'duration'
    },
    {
      title: 'Перерыв',
      field: 'pause'
    },
    {
      title: 'Фактическое время работы',
      field: 'durationFact'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
