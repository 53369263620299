import React, { FC } from 'react';
import moment from 'moment';

import { IReportPhysicalState } from '../../../models/types';

import Table from '../../../components_/Table';
import TableColText from '../../../components_/Table/TableColText';

interface IProps {
  list: IReportPhysicalState[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      time: (
        <TableColText>{item.time && moment(item.time).format('DD.MM.YYYY HH:mm')}</TableColText>
      ),
      physType: <TableColText>{item.physType}</TableColText>,
      employeeName: <TableColText>{item.employeeName}</TableColText>,
      areaName: <TableColText>{item.areaName}</TableColText>,
      mapName: <TableColText>{item.mapName}</TableColText>,
      officeName: <TableColText>{item.officeName}</TableColText>
    }
  }));

  const cols = [
    {
      title: 'Дата/Время',
      field: 'time'
    },
    {
      title: 'Критическое состояние',
      field: 'physType'
    },
    {
      title: 'ФИО сотрудника',
      field: 'employeeName'
    },
    {
      title: 'Название зоны',
      field: 'areaName'
    },
    {
      title: 'План',
      field: 'mapName'
    },
    {
      title: 'Место',
      field: 'officeName'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
