import React, { FC, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';

import { Dispatch, iRootState } from '../../../../../../store';
import { IId, IUser } from '../../../../../../models/types';
import { getTimeFromMinutes, getMinutesFromTime } from '../../../../../../utils/convertTime';
import useRequestHandler from '../../../../../../hooks/useRequestHandler';

import FormBase from '../../../../../../components_/Form';
import DrawerContentItemUser from '../../../../../../components_/Drawer/DrawerContentItemUser';

import FormPersonal from './FormPersonal';
import FormWorking from './FormWorking';
import FormSettings from './FormSettings';
import FormActions from './FormActions';

const mapState = (state: iRootState) => ({
  data: state.staffUsers.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    updateUser: d.infrastructureUsers.updateUser,
    resetFocus: d.infrastructureMap.resetFocus
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps & {
  user: IUser;
};

interface IFormValues {
  id: IId;
  lastname: string;
  name: string;
  secondname: string;
  birthday: string;
  username: string;
  phone: string;
  email: string;
  brigadeId: number | null;
  title: string;
  tabularNumber: string;
  mapColor: string;
  protectionsList: IId[];
  immobilizedMinutes: number;
  immobilizedHours: number;
  breakMinutes: number;
  breakHours: number;
  descr: string;
  avatar?: File;
}

type IRenderForm = (props: FormRenderProps<IFormValues>) => ReactNode;

const Form: FC<IProps> = ({ data, user, updateUser, resetFocus }) => {
  const { immobilizedTimeMinutes, workshiftBreakMinutes } = user;

  const history = useHistory();
  const requestHandler = useRequestHandler();
  const [immobilizedHours, immobilizedMinutes] = getTimeFromMinutes(immobilizedTimeMinutes);
  const [breakHours, breakMinutes] = getTimeFromMinutes(workshiftBreakMinutes);

  const [initialValues] = useState({
    ...(user && {
      ...user,
      ...(user.protectionsList && {
        protectionsList: user.protectionsList.map(item => String(item))
      })
    }),
    immobilizedHours,
    immobilizedMinutes,
    breakHours,
    breakMinutes
  });

  const goBack = () => {
    resetFocus();

    history.goBack();
  };

  const onSubmit = async (values: IFormValues) => {
    // tslint:disable-next-line: no-shadowed-variable
    const { immobilizedHours, immobilizedMinutes, breakHours, breakMinutes, ...data } = values;

    // tslint:disable-next-line: no-shadowed-variable
    const immobilizedTimeMinutes = getMinutesFromTime(immobilizedHours, immobilizedMinutes) || null;

    // tslint:disable-next-line: no-shadowed-variable
    const workshiftBreakMinutes = getMinutesFromTime(breakHours, breakMinutes) || null;

    const body = {
      ...data,
      immobilizedTimeMinutes,
      workshiftBreakMinutes
    };

    const error = await updateUser(body);

    requestHandler({
      error,
      entity: 'user',
      onSuccess: goBack,
      isEdit: true
    });
  };

  const renderForm: IRenderForm = ({ handleSubmit }) => {
    return (
      <FormBase onSubmit={handleSubmit}>
        <DrawerContentItemUser user={user} variant="form" />
        <FormPersonal />
        <FormWorking data={data} />
        <FormSettings data={data} />
        <FormActions onGoBack={goBack} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={renderForm} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Form);
