import React, { FC } from 'react';

import { ICamera } from '../../../../models/types';

import Table from '../../../../components_/Table';
import TableColText from '../../../../components_/Table/TableColText';

interface IProps {
  list: ICamera[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      name: <TableColText>{item.name}</TableColText>,
      placeName: <TableColText>{item.place.name}</TableColText>,
      planName: <TableColText>{item.plan.name}</TableColText>,
      description: <TableColText>{item.description}</TableColText>
    }
  }));

  const cols = [
    {
      title: 'Название',
      field: 'name'
    },
    {
      title: 'Место',
      field: 'placeName'
    },
    {
      title: 'План',
      field: 'planName'
    },
    {
      title: 'Комментарий',
      field: 'description'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
