import React, { FC, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import L, { divIcon, MarkerCluster } from 'leaflet';
import { Map as LeafletMap, Polyline } from 'react-leaflet';
import { ITrackPoint } from '../../../models/types';
import WMSTileLayer from '../../Main/Map/WMSTileLayer';
import { Dispatch, iRootState } from '../../../store';
import { connect } from 'react-redux';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import StartAndFinishMarker from './StartAndFinishMarker';
import Grid from '@material-ui/core/Grid';
import HoverMarkerPoint from './HoverMarkerPoint';
import { renderToString } from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { getColor } from '../../../theme';

const useStyles = makeStyles(({ getShadow }) => ({
  root: {
    zIndex: 0,
    height: '100%',
    marginLeft: 380,
    backgroundColor: getColor('constant-greyscale-0'),
    // Инструмент редактирования/создания зон
    '& .leaflet-draw': {
      display: 'none'
    },
    // Маркеры редактирования зон
    '& .leaflet-editing-icon': {
      backgroundColor: getColor('constant-apple-dark'),
      borderColor: getColor('constant-apple-dark'),
      width: '16px  !important',
      height: '16px  !important'
    },
    '& .leaflet-edit-move': {
      backgroundColor: getColor('constant-greyscale-0'),
      borderColor: getColor('constant-greyscale-400')
    },
    '& .leaflet-image-layer': {
      boxShadow: getShadow('low')
    }
  },
  point: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: getColor('constant-blackberry-light'),
    width: 28,
    height: 28,
    fontWeight: 500,
    color: getColor('text-inverted')
  },
  count: {
    borderRadius: '50%',
    width: 16,
    height: 16
  }
}));

const mapState = (state: iRootState) => ({
  control: state.infrastructureMap.control,
  plan: state.reportTracks.plan,
  planImg: state.reportTracks.planImg
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setControl: d.infrastructureMap.setControl,
    getPlan: d.reportTracks.getPlan,
    resetPlan: d.reportTracks.resetPlan,
    getPlanImg: d.reportTracks.getPlanImg,
    resetPlanImg: d.reportTracks.resetPlanImg
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = {
  track: ITrackPoint;
} & connectedProps;

const Map: FC<IProps> = ({
  track,
  control,
  setControl,
  getPlan,
  getPlanImg,
  plan,
  planImg,
  resetPlan,
  resetPlanImg
}) => {
  const classes = useStyles();
  const { zoom, center } = control;
  const mapEl = useRef<any>();
  const { userId, points } = track;
  const { gbox } = plan;
  const LatLng = L.latLng as any;

  const handlerGetPlan = () => {
    if (points[0] && points[0].mapId) {
      resetPlan();
      const mapId = points[0].mapId;
      getPlan(mapId);
    } else {
      resetPlan();
    }
  };
  const dataStory = points.map((item: any) => {
    return item;
  });

  const fitToBounds = () => {
    if (mapEl.current) {
      const useMap = mapEl.current.leafletElement;
      useMap.fitBounds(dataStory, {
        padding: [150, 150],
        maxZoom: 20
      });

      setControl({
        ...control,
        zoom: useMap.getZoom(),
        center: useMap.getCenter()
      });
    }
  };
  useEffect(() => {
    fitToBounds(); // Устанавливаем zoom, чтобы был виден весь трек
    handlerGetPlan(); // Загружаем план если есть
  }, [track]);

  useEffect(() => {
    resetPlanImg();
    if (plan.id) {
      getPlanImg(plan.id);
    }
  }, [plan.id]);

  const renderIcon = (count: number) => (
    <Grid container justify="center" alignItems="center" className={classes.point}>
      <Grid item container justify="center" alignItems="center" className={classes.count}>
        {count}
      </Grid>
    </Grid>
  );
  const iconGroup = (cluster: MarkerCluster) => {
    const count = cluster.getChildCount();

    return divIcon({
      iconSize: undefined,
      html: renderToString(renderIcon(count))
    });
  };

  return (
    <>
      <LeafletMap
        ref={mapEl}
        center={center}
        zoom={zoom}
        className={classes.root}
        maxZoom={30}
        minZoom={3}
        animate={true}
        duration={1}
        zoomSnap={0}
        zoomControl={true}
        attributionControl={false}
        maxBounds={[[-86.5, -180], [85, 180]]}
      >
        <WMSTileLayer />
        <Polyline weight={4} color={getColor('constant-blackberry-light')} positions={dataStory} />
        <MarkerClusterGroup iconCreateFunction={iconGroup}>
          {points.slice(1, -1).map((point, i) => (
            <HoverMarkerPoint point={point} key={i} />
          ))}
        </MarkerClusterGroup>
        {/*{point && <HoverMarkerPoint point={point} />}*/}
        <MarkerClusterGroup iconCreateFunction={iconGroup}>
          <StartAndFinishMarker points={points} />
        </MarkerClusterGroup>
        {plan.id && (
          <ReactDistortableImageOverlay
            key={plan.contentId}
            url={planImg}
            corners={[
              new LatLng(gbox[5], gbox[4]),
              new LatLng(gbox[7], gbox[6]),
              new LatLng(gbox[1], gbox[0]),
              new LatLng(gbox[3], gbox[2])
            ]}
          />
        )}
      </LeafletMap>
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Map);
