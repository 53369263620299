import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MtsRoot } from '@mts-ds/components-react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TopIconProvider from '@top-ui/core/lib/TopIcon/TopIconProvider';
import { TopCssBaseline } from '@top-ui/core';
import { ConfigProvider } from 'antd';

import 'moment/locale/ru';
import ruRU from 'antd/locale/ru_RU';

import icons from './assets/icons';
import Root from './routes/Root';
import { store } from './store';
import theme from './theme';

const App = () => {
  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        components: {
          Collapse: {
            contentPadding: '3px 3px'
          },
          Table: {
            headerBg: '#f2f3f7'
          }
        },
        token: {
          colorInfo: '#ff0032',
          colorPrimary: '#ff0032',
          colorSuccess: '#26cd58',
          colorWarning: '#fac031',
          colorError: '#f95721',
          colorTextBase: '#1d2023',
          wireframe: false,
          borderRadius: 8,
          borderRadiusXS: 6,
          borderRadiusSM: 8,
          lineWidth: 2,
          fontSizeHeading1: 32,
          fontSizeHeading2: 24,
          fontSizeHeading3: 20,
          fontSizeHeading4: 18,
          fontSizeLG: 17,
          fontSizeSM: 14
        }
      }}
    >
      <Provider store={store}>
        <MtsRoot>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TopCssBaseline />
              <TopIconProvider value={icons}>
                <BrowserRouter>
                  <Root />
                </BrowserRouter>
              </TopIconProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </MtsRoot>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
